


export const calPay = async (value, note) => {
    // console.log("dataPay", dataPay) // ค่าแรก
    // console.log("value", value) // ค่าที่กดคำนวณ
    // console.log("note", note)
    // console.log("value?.Dok", value?.Dok)
    // console.log("dataSend.sale", dataSend.sale) // ส่วนลด
    var AMT = 0
    var FOLLOW = 0
    var INTEFF = 0
    var TON = 0
    var all = 0
    if (note === "") {
        // if (dataPay?.ALLPAYAMT && dataPay?.ALLPAYFOLLOW && dataPay?.ALLPAYINTEFF && dataPay?.ALLPAYTON) {
        //   AMT = value.ALLPAYAMT - dataPay.ALLPAYAMT
        //   FOLLOW = value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW
        //   INTEFF = value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF
        //   TON = (value.ALLPAYTON - dataPay.ALLPAYTON)
        // } else {
        AMT = value.ALLPAYAMT
        FOLLOW = value.ALLPAYFOLLOW
        INTEFF = value.ALLPAYINTEFF
        TON = value.ALLPAYTON
        // }

        // console.log("AMT", AMT)
        // console.log("FOLLOW", FOLLOW)
        // console.log("INTEFF", INTEFF)
        // console.log("TON", TON)
        if (value?.PaySm > 0) {
            if (value?.Dok > 0) {
                // all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON) + (value?.PaySm + value?.Dok)
                all = (value.ALLPAYFOLLOW) + (value.ALLPAYINTEFF) + (value.ALLPAYTON) + (value?.PaySm + value?.Dok)
                // console.log("value.ALLPAYFOLLOW", value.ALLPAYFOLLOW)
                // console.log("dataPay.ALLPAYFOLLOW", dataPay.ALLPAYFOLLOW)
                // console.log("value.ALLPAYINTEFF", value.ALLPAYINTEFF)
                // console.log("dataPay.ALLPAYINTEFF", dataPay.ALLPAYINTEFF)
                // console.log("value.ALLPAYTON", value.ALLPAYTON)
                // console.log("dataPay.ALLPAYTON", dataPay.ALLPAYTON)
                // console.log("value?.PaySm", value?.PaySm)
                // console.log("value?.Dok", value?.Dok)
            } else {
                // all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON) + value?.PaySm
                all = (value.ALLPAYFOLLOW) + (value.ALLPAYINTEFF) + (value.ALLPAYTON) + value?.PaySm
                // console.log("value.ALLPAYFOLLOW", value.ALLPAYFOLLOW)
                // console.log("dataPay.ALLPAYFOLLOW", dataPay.ALLPAYFOLLOW)
                // console.log("value.ALLPAYINTEFF", value.ALLPAYINTEFF)
                // console.log("dataPay.ALLPAYINTEFF", dataPay.ALLPAYINTEFF)
                // console.log("value.ALLPAYTON", value.ALLPAYTON)
                // console.log("dataPay.ALLPAYTON", dataPay.ALLPAYTON)
                // console.log("value?.PaySm", value?.PaySm)
            }
            return {
                ALLPAYAMT: AMT,
                ALLPAYFOLLOW: FOLLOW,
                ALLPAYINTEFF: INTEFF,
                ALLPAYTON: TON,
                ALLCAL: all,
                PaySm: value?.PaySm,
                Dok: value?.Dok,
                ccb: value?.ccb,
                text: ""
            }
        } else {
            // if (dataPay.ALLPAYFOLLOW && dataPay.ALLPAYINTEFF && dataPay.ALLPAYTON) {
            //   all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON)
            // } else {
            all = value.ALLPAYFOLLOW + value.ALLPAYINTEFF + value.ALLPAYTON
            // }
            return {
                ALLPAYAMT: AMT,
                ALLPAYFOLLOW: FOLLOW,
                ALLPAYINTEFF: INTEFF,
                ALLPAYTON: TON,
                ALLCAL: all,
                ccb: value?.ccb,
                text: ""
            }
        }
    } else {
        if (value?.NewTonKong < 0) { // เงินที่จ่ายเข้ามาต้องไม่เกินต้นคงเหลือใหม่
            return {
                ALLPAYAMT: 0,
                ALLPAYFOLLOW: 0,
                ALLPAYINTEFF: 0,
                ALLPAYTON: 0,
                ALLCAL: value.ALLPAYTON + value.NewTonKong,
                text: "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ"
            }
        } else {
            return {
                ALLPAYAMT: 0,
                ALLPAYFOLLOW: 0,
                ALLPAYINTEFF: value.ALLPAYINTEFF,
                ALLPAYTON: value.ALLPAYTON,
                ALLCAL: value.ALLPAYTON + value.ALLPAYINTEFF,
                text: "ไม่ตัดเข้าตารางดิว"
            }
        }
    }
}