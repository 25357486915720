import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, Upload, Card, message } from 'antd'
import React, { useState, useEffect } from 'react'
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
// import ModalEditGarantor from './ModalEditGarantor';S
// import AddGuarantors from './AddGuarantors';
import dayjs from 'dayjs';
import { checkCCAP, checkNewCCAP, testupload, uploadImgGuaTable } from '../../../file_mid/all_api';
// import { checkCCAP, checkNewCCAP, testupload, uploadImgGua } from '../../file_mid/all_api';

function AddGua({ open, close, indexedit, guarantors, getAddData, dataGuarantor, datafromtable }) {
  var countGu = 0
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm()
  const [form1] = Form.useForm()

  const addCustomer = localStorage.getItem('addCustomer')
  const dataAddCustomer = JSON.parse(addCustomer)
  const { confirm } = Modal

  const [guarantors1, setGuarantors] = useState();
  const [images1, setImage1] = useState();

  const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
  const [dataPostCustomer, setDataPostCustomer] = useState(dataAddCustomer) // customerGuarantor

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [fileListGuarantor, setFileListGuarantor] = useState([]);
  const [fileListGuarantor1, setFileListGuarantor1] = useState([]);
  const [fileListGuarantors, setFileListGuarantors] = useState([]);
  const [fileListGuarantors1, setFileListGuarantors1] = useState([]);

  const [indexedit1, setIndexedit1] = useState(indexedit);
  const [CheckIdCard, setCheckIdCard] = useState(false)

  useEffect(() => {

    if (images1?.length === 0) {
      // if (images1.length > 0) {
      form.setFieldsValue({
        ...images1[0]
        // ...images1[0], ...images1[0].phones[0]
      })
      // }

    } else if (images1?.length >= 1) {
      if (images1[0]?.phones) {
        form.setFieldsValue({
          ...images1[0], ...images1[0].phones[0],
        })
      } else {
        form.setFieldsValue({
          ...images1[0]
        })
      }
    }

  }, [images1])


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleCancel1 = () => {
    setPreviewVisible(false);
  }

  const handleCancel = () => {
    close(false);
  };
  //////////// โชว์รูปภาพ ////////////////
  function createImageUrl(imageData) {
    // console.log(imageData)
    // const blob = new Blob([imageData.originFileObj], { type: imageData.type });
    // return URL.createObjectURL(blob);
    return imageData;
    // , fileList.pdf;
  }

  /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
  const handleScanIdCardGuarantor = async (value) => {
    // console.log("value", value)
    if (guarantors?.length > 0) {
      var dataListGuarantor = [...guarantors]
    } else {
      var dataListGuarantor = []
    }
    for (let i = 0; i < value.length; i++) {
      var imgId = { image: value[i].image64 }
      const headers = { 'Content-Type': 'application/json' }
      await axios.post(testupload, imgId, { headers: headers })
        .then(async (res) => {
          delete res.data[0].address
          if (res.data) {
            if (dataGuarantor !== undefined) {
              dataGuarantor?.map((e) => {
                if (e.identificationId === res.data[0]?.identificationId) {
                  countGu = 1
                  setFileListGuarantor()
                  setFileListGuarantor1()
                  console.log("บัตรคนค้ำซ้ำกัน")
                  alert('บัตรคนค้ำซ้ำกัน')
                }
              })
            }
            if (countGu === 0) {
              const b = await loadDataOneGuarantor(res.data[0])
              dataListGuarantor.push({ ...b[0], imgs: value[i] })
            }
          }
          else {
            message.info('ไม่สามารถอ่านได้')
          }
        })
        .catch((err) => console.log(err))
    }
    if (countGu === 0) {
      setGuarantors({ ...guarantors, dataListGuarantor })
      showConfirm({ dataListGuarantor })
    }
  }

  ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
  const loadDataOneGuarantor = async (data) => {
    //setLoading(true)
    var dataListGuarantor = []
    if (!data?.identificationId) {
      setCheckIdCard(true)
      var unDataGua = {}
      // countGu = 1
      setFileListGuarantor()
      setFileListGuarantor1()
      console.log("ไม่มีเลขบัตรคนค้ำ")
      alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
      unDataGua.identificationId = ""
      unDataGua.customerId = 0
      unDataGua.firstname = data?.firstname
      unDataGua.lastname = data?.lastname
      unDataGua.snam = data?.snam
      unDataGua.nickname = ""
      unDataGua.phones = []
      unDataGua.gender = data?.gender
      unDataGua.birthdate = data?.birthdate
      unDataGua.expdt = data?.expdt
      unDataGua.issuby = data?.issuby
      dataListGuarantor.push(unDataGua)
      setDataPostGuarantor({ ...dataPostGuarantor, ...unDataGua })
      form.setFieldsValue({ nickname: "", telp: "" })
    } else if (dataPostCustomer?.identificationId === data?.identificationId) {
      countGu = 1
      setFileListGuarantor()
      setFileListGuarantor1()
      console.log("คนกู้กับคนค้ำเป็นคนเดียวกัน")
      alert('คนกู้กับคนค้ำเป็นคนเดียวกัน')
    }
    else {
      var one = { identificationId: data.identificationId }
      await axios.post(checkCCAP, one)
        .then((res) => {
          if (res.data) {
            message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
            delete res.data.address
            delete res.data.landLoanDetails
            delete res.data.emails
            delete res.data.ojs
            delete res.data.socials
            dataListGuarantor.push(res.data)
            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
            setCheckIdCard(false)
          }
          else {
            message.info('ไม่พบข้อมูลในฐานข้อมูล')
            // console.log("RES-DATA NO =", data)
            dataListGuarantor.push(data)
            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
            setCheckIdCard(true)
          }
        })
        .catch((err) => console.log(err))
    }
    delete dataListGuarantor[0].carLoanDetails
    return dataListGuarantor
  }


  const showConfirm = ({ dataListGuarantor }) => {
    var note = dataListGuarantor?.length;
    var lastIndex = note - 1;
    var imageGuarantors1 = [...dataListGuarantor];
    setImage1(imageGuarantors1)
    guarantors?.map((img, index) => {
      imageGuarantors1.push({ ...img.imgs })
    })

    setLoading(true)
    confirm({
      title: 'เพิ่มคนค้ำ?',
      content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
      onOk() {
        setGuarantors(dataListGuarantor)
        setLoading(false)
        setTimeout(() => {
          setFileListGuarantor([])
        },)
      },
      onCancel() {
        message.error('ยกเลิก')
        setGuarantors(guarantors)
        setFileListGuarantor1([])
        setTimeout(() => {
          setFileListGuarantor([])
        },)
        setLoading(false)
      },
    });
  }

  //////////////////// อ่านบัตรคนค้ำ /////////////////////////
  const handleChangeGuarantor = async ({ fileList }) => {
    setLoading(true)
    setFileListGuarantor(fileList);
    var imageListGuarantor = []
    for (const file of fileList) {
      const compressedFile = await new Promise((resolve) => {
        new Compressor(file.originFileObj, {
          quality: 0.1, // เช่น, 60%
          success: (result) => {
            resolve(result);
          },
        });
      });
      var image = {}
      var filename = file.name
      var image64 = await getBase64(compressedFile)
      // var image64 = file.originFileObj(compressedFile)
      image.filename = filename
      image.image64 = image64
      image.type = `5.${indexedit1 + 1}`
      imageListGuarantor.push(image)
    }
    await handleScanIdCardGuarantor(imageListGuarantor)
    setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
    setLoading(false)
  };

  //////////////////// รูปเอกสาร ///////////////////////
  const handleChangeGuarantors = async ({ fileList }) => {
    setLoading(true)
    setFileListGuarantors(fileList);
    var imageListGuarantors = []
    var i = 0
    for (const file of fileList) {
      const compressedFile = await new Promise((resolve) => {
        new Compressor(file.originFileObj, {
          quality: 0.1, // เช่น, 60%
          success: (result) => {
            resolve(result);
          },
        });
      });
      var image = {}
      var filename = file.name
      var image64 = await getBase64(compressedFile)
      //var image64 = file.originFileObj
      image.filename = filename
      image.image64 = image64
      image.type = `5.${indexedit1 + 1}`
      imageListGuarantors.push(image)
      i++
    }
    setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
    setLoading(false)
  };
  const success = () => {
    Modal.success({
      content: 'บันทึกสำเร็จ',
      // path: navigate('/checkInfoCar')
    })
  }

  const axi = async (InputImage) => {
    setLoading(true)
    await axios.post(uploadImgGuaTable, InputImage)
      .then(response => {
        if (response) {
          setLoading(false)
        }
      }).catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
  }

  // const DataImagesGu = ({ images1, imageListGuarantorPush }) => {
  const DataImagesGu = async ({ data }) => {
    // console.log("data", data)
    setIndexedit1(indexedit1 + 1)

    let AddData2
    if (guarantors1?.length === 0) {
      let path = `one_images/land/${datafromtable.landId}/5/${indexedit + 1}`
      // console.log("path1", path)
      AddData2 = [{ ...data[0], pathImg: path }];
      //รอเซตเข้าฐานข้อมูล path

    } else if (guarantors1?.length >= 1) {
      let path = `one_images/land/${datafromtable.landId}/5/${indexedit + 1}`
      // console.log("path2", path)
      AddData2 = [{ ...data[0], pathImg: path }];//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
      // console.log("AddData2", AddData2)
      //รอเซตเข้าฐานข้อมูล path
    }
    const InputImage = {
      mainImg: { carId: datafromtable.landId.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 2, img: data[0].imgs }
    }

    // เรียกใช้ function axi
    await axi(InputImage)

    delete AddData2[0]?.imgs
    // โยนค่าข้าม Component
    getAddData({ AddData2, indexedit1 })
    setLoading(false)
    close(false)
  }

  // ส่งค่าไปหน้าหลักของคนค้ำ
  const handleSubmit = () => {

    const imageListGuarantorPush = []
    if (fileListGuarantor1?.imageListGuarantor) {
      imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
    }
    if (fileListGuarantors1?.imageListGuarantors) {
      imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
    }

    var data = []
    if (guarantors1?.length === 0) {
      data.push({ ...data[0] })
      // console.log("กรณียังไม่มีคนค้ำ")
    }
    if (guarantors1?.length > 0) {
      data.push({ ...data[0], ...images1[0], imgs: imageListGuarantorPush }) //ต่อ array
      // console.log("กรณีมีคนค้ำแล้ว")
    }

    if (data) {
      if (!data[0]?.phones || data[0]?.phones?.length === 0 || data[0]?.phones[0]?.telp === "") {
        alert('กรุณาเพิ่มเบอร์โทร')
      } else if (!data[0]?.nickname) {
        alert('กรุณาเพิ่มชื่อเล่น')
      } else if (!data[0]?.identificationId || data[0]?.identificationId.length !== 13) {
        alert('กรุณาเพิ่มเลขบัตรประชาชนให้ถูกต้อง')
      } else if (!data[0]?.firstname) {
        alert('กรุณาเพิ่มชื่อ')
      } else if (!data[0]?.lastname) {
        alert('กรุณาเพิ่มนามสกุล')
      }
      else {
        // console.log("handleSubmit", data)
        DataImagesGu({ data })
      }
    }
  }

  const handlePhone = (e) => {
    const updatedGuarantors = [...images1];

    if (images1[0]?.phones?.length > 0) {
      updatedGuarantors[0].phones[0].telp = e.target.value;
      setImage1(updatedGuarantors);
    }
    else {
      setImage1([{ ...images1[0], phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }] }])
    }

  }

  const handleIdCard = async (value) => {
    const sumOne = { identificationId: value }
    setLoading(true)
    if (dataGuarantor !== undefined) {
      dataGuarantor?.map((e) => {
        if (e.identificationId === sumOne?.identificationId) {
          countGu = 1
          // console.log("บัตรคนค้ำซ้ำกัน")
          setImage1([{ ...images1[0], identificationId: "", phones: [] }])
          form.setFieldsValue({ identificationId: "", telp: "" })
          alert('บัตรคนค้ำซ้ำกัน กรุณากรอกเลขบัตรใหม่ !!!')
        }
      })
    }
    if (countGu === 0) {
      // console.log("ตอนกรอกเองไม่ซ้ำ")
      if (sumOne?.identificationId === dataPostCustomer?.identificationId) {
        alert('เลขบัตรคนกู้กับคนค้ำเป็นคนเดียวกัน กรุณากรอกเลขบัตรใหม่')
        setImage1([{ ...images1[0], identificationId: "", phones: [] }])
        form.setFieldsValue({ identificationId: "", telp: "" })
      } else {
        await axios.post(checkNewCCAP, sumOne)
          .then((res) => {
            if (res.data) {
              delete res.data.address
              delete res.data.emails
              delete res.data.ojs
              delete res.data.socials
              message.info('มีข้อมูลในฐานข้อมูล')
              setCheckIdCard(false)
              setDataPostGuarantor({ ...dataPostGuarantor, ...res.data })
              setGuarantors([res.data])
              setImage1([{
                ...images1[0], ...res.data,
              }])
            }
            else {
              message.info('ไม่พบข้อมูลในฐานข้อมูล')
              setCheckIdCard(true)
              // setDataPostGuarantor({ ...dataPostGuarantor })
              setImage1([{ ...images1[0], identificationId: value, customerId: 0, phones: [] }])
              form.setFieldsValue({ telp: "" })
            }
          })
          .catch((err) => console.log(err))
      }
    }
    setLoading(false)
  }


  const handleIdentificationId = async (e) => {
    if (e.target.value.length === 13) {
      await handleIdCard(e.target.value)
    } else {
      setImage1([{ ...images1[0], identificationId: e.target.value }])
    }
  }
  const handleFirstname = (e) => {
    setImage1([{ ...images1[0], firstname: e.target.value }])
  }
  const handleLastname = (e) => {
    setImage1([{ ...images1[0], lastname: e.target.value }])
  }
  const handleNickname = (e) => {
    setImage1([{ ...images1[0], nickname: e.target.value }])
  }

  const genGuarantors = ({ item, index }) => {

    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: '100%',
        }}
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}>
          <div>
            <Form.Item style={{ margin: 3 }} label='เลขบัตรประชาชน' name='identificationId'>
              <Input
                disabled={CheckIdCard ? false : true}
                style={{ color: 'black' }}
                type='text'
                name='identificationId'
                maxLength={13}
                onChange={(e) => { handleIdentificationId(e) }}
              >
              </Input>
            </Form.Item>
          </div>
          <div>
            <Form.Item style={{ margin: 3 }} label='ชื่อ' name='firstname'>
              {/* <span>ชื่อ : </span> */}
              <Input
                //disabled
                style={{ color: 'black' }}
                type='text'
                name='firstname'
                onChange={(e) => { handleFirstname(e) }}
              >
              </Input>
              {/* <b>{item?.firstname}</b> */}
            </Form.Item>
          </div>
          <div>
            <Form.Item style={{ margin: 3 }} label='นามสกุล' name='lastname'>
              {/* <span>นามสกุล : </span> */}
              <Input
                //disabled
                style={{ color: 'black' }}
                type='text'
                name='lastname'
                onChange={(e) => { handleLastname(e) }}
              >
              </Input>
              {/* <b>{item?.lastname}</b> */}
            </Form.Item>
          </div>
          <div>
            <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' name='nickname'>
              {/* <span>ชื่อเล่น : </span> */}
              <Input
                type='text'
                name='nickname'
                // disabled
                style={{ color: 'black' }}
                onChange={(e) => { handleNickname(e) }}
              >
              </Input>
              {/* <b>{item?.nickname}</b> */}
            </Form.Item>
          </div>
          <div>
            {item?.phones?.length > 0 ?
              <>
                <Form.Item
                  label='เบอร์โทร'
                  style={{ margin: 3 }}
                  name='telp'
                // name='phones'
                >
                  {/* <span>เบอร์โทร : </span> */}
                  {/* <Input
                                              type='text'
                                              name='telp'
                                              defaultValue={item?.phones[0]?.telp}
                                              // disabled
                                              style={{ color: 'black' }}
                                          >
                                          </Input> */}
                  <Input
                    type='text'
                    name='telp'
                    // defaultValue={item?.phones[0]?.telp}
                    // defaultValue={item?.phones[0] ? item?.phones[0]?.telp : ""}
                    // disabled
                    style={{ color: 'black' }}
                    onChange={(e) => { handlePhone(e) }}></Input>
                  {/* <b>{item?.phones[0]?.telp}</b> */}
                </Form.Item>
              </>
              :
              <Form.Item
                label='เบอร์โทร'
                style={{ margin: 0 }}
                // name='phones'
                name='telp'
              >
                {/* <span>เบอร์โทร : </span> */}
                {/* <Input
                                          type='text'
                                          name='telp'
                                          // disabled
                                          style={{ color: 'black' }}
                                      >
                                      </Input> */}
                <Input
                  type='text'
                  name='telp'
                  // disabled
                  style={{ color: 'black' }}
                  onChange={(e) => { handlePhone(e) }}></Input>
                {/* <b>{item?.phones?.telp}</b> */}
              </Form.Item>
            }
          </div>
        </Card>
      </Form>
    )
  }


  return (
    <Modal open={open} onCancel={handleCancel} width={1000} footer={[null]}>
      <Spin spinning={loading} size='large' tip=" Loading... ">
        <Form
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          initialValues={{
            remember: true,
          }}
          form={form1}
          autoComplete="off"
        >
          <Card>
            <Row justify={'center'}><b><u>เพิ่มข้อมูลคนค้ำ</u></b></Row>
            <Divider style={{ margin: 5 }} />
            <Row justify={'center'} className='main2'>
              <Col span={22}>
                <aside>
                  <b>
                    <div>
                      <Row justify={'center'}>
                        {guarantors1?.length > 0 ?
                          <>
                            {guarantors1?.map((item, index) => {
                              return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                            })}
                          </>
                          :
                          null
                        }
                      </Row>
                      {
                        guarantors1?.length >= 1 ?
                          <>
                            {
                              fileListGuarantor1?.imageListGuarantor?.map((item, index) => {
                                return (
                                  <>
                                    <Divider />
                                    <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                    <Row justify={'center'}>
                                      <Col span={24} style={{ textAlign: 'center' }}>
                                        {/*กรณีโชว์รูปโดยที่ยังไม่เข้าฐานข้อมูล */}
                                        <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item.image64)} alt={item.filename} />
                                        {/*กรณีโชว์รูปที่เข้าฐานข้อมูลแล้ว */}
                                      </Col>
                                    </Row>
                                  </>
                                )
                              })}
                          </>
                          :
                          <>
                            <Row justify={'left'} > <span><b>รูปบัตรประชาชนคนค้ำ (1 รูป) :</b></span></Row>
                            <Row justify={'center'}>
                              <Form.Item label="" name='imgGuarantor'>
                                <Upload
                                  // multiple={true}
                                  accept="image/*"
                                  style={{ textAlign: 'center' }}
                                  listType="picture-card"
                                  fileList={fileListGuarantor}
                                  onPreview={handlePreview}
                                  onChange={handleChangeGuarantor}
                                  beforeUpload={() => false}
                                >
                                  {fileListGuarantor?.length >= 1 ? null : (
                                    <div>
                                      <PlusOutlined />
                                      <div className="ant-upload-text">upload</div>
                                    </div>
                                  )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel1}>
                                  <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                              </Form.Item>
                            </Row>
                          </>
                      }
                    </div>
                    <Divider />
                    <div>
                      <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                      <Row justify={'center'}>
                        <Form.Item label='' name='imgGuarantor'>
                          <Upload
                            multiple={true}
                            accept="image/*"
                            listType="picture-card"
                            fileList={fileListGuarantors}
                            onPreview={handlePreview}
                            onChange={handleChangeGuarantors}
                            beforeUpload={() => false}
                          >
                            {fileListGuarantors?.length >= 10 ? null : (
                              <div>
                                <PlusOutlined />
                                <div className="ant-upload-text">upload</div>
                              </div>
                            )}
                          </Upload>
                          <Modal open={previewVisible} onCancel={handleCancel1}>
                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                          </Modal>
                        </Form.Item>
                      </Row>
                    </div>
                  </b>
                </aside>
              </Col>
            </Row>
          </Card>
          <Divider />
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                <Button type="primary" onClick={handleSubmit} style={{ background: "green" }} >บันทึก</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default AddGua