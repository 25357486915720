
export function currencyFormatOne(amount) {
  if (amount) {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return "-"
  }
}

export function msOK (api, placement) {
  api.success({
    message: "สำเร็จ",
    description:
      'ทำรายการสำเร็จ',
    placement,
  });
};
export function msCancel (api, placement) {
  api.success({
    message: "ยกเลิก",
    description:
      'ยกเลิกทำรายการสำเร็จ',
    placement,
  });
};
export function msPAYCODE (api, placement) {
  api.error({
    message: "คำเตือน !!!",
    description:
      'กรุณาเลือกประเภทการจ่ายก่อน',
    placement,
  });
};
export function msSale (api, placement) {
  api.error({
    message: "คำเตือน !!!",
    description:
      'ไม่สามารถใส่ส่วนลดเกินจำนวนดอกเบี้ยได้',
    placement,
  });
};
export function msError (api, placement) {
  api.error({
    message: "error",
    description:
      'กรุณาติดต่อไอที',
    placement,
  });
};
export function msDue (api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่พบข้อมูลตารางดิว',
    placement,
  });
};
export function msConnt (api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่พบข้อมูลสัญญานี้ กรุณาตรวจสอบเลขที่สัญญา ว่าถูกต้องหรือไม่ ?',
    placement,
  });
};
export function msNil (api, placement) {
  api.error({
    message: "error",
    description:
      'กรุณากรอกข้อมูลให้ถูกต้อง *ยอดที่จ่ายต้องไม่เป็น 0 หรือมีค่าติดลบ',
    placement,
  });
};
export function msLimit (api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ',
    placement,
  });
};
export function msCreateDue (api, placement) {
  api.warning({
    message: "คำเตือนควรสร้างดิว",
    description:
      'จำนวนเงินที่จ่ายเข้ามา น้อยกว่าดอกเบี้ย',
    placement,
  });
};
export function msDueNil (api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'ไม่พบดิวล่าสุด',
    placement,
  });
};

export function msData (api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'ไม่พบข้อมูล',
    placement,
  });
};

export function msPays (api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'กรุณาเลือกสัญญาที่จะตัดเข้าค่างวดก่อน',
    placement,
  });
};