import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Upload, message, Spin, notification, Checkbox } from 'antd'
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import '../../css/Media.css'
import { irr } from 'node-irr'
import { useSelector } from 'react-redux';
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import { ShowDataBrokers } from '../../file_mid/show_data_broker';
import {
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from "async";
import ModalShowImages from '../../ModalShowImageSlide/ModalShowImages';
import {
    checkcarcus, checkgu, customerconfirmcar, findcarmk, loadOJS, mutips2, resendcarmk, getImagess, getImagessGua,
    getImagessPre, getAllFolderGuaImg, findDataBroker,
    findlandmk,
    checklandcus,
    findlandpn,
    customerconfirmland,
    mutips3,
    resendlandmk1,
    resendlandmk,
    mklandmix
} from '../../file_mid/all_api';
import ModalCusConfirm from '../../check_infoCar/modals/ModalCusConfirm';
import ModalEditBroker from '../../check_infoCar/modals/ModalEditBroker';
import ModalMainMix from '../../approved_land_pn/modal/ModalMainMix';
import { colorApprovalStatusNEW } from '../status_color';

function ModalInfoMK({ open, dataFromTable, close, typeLoan, SuccSend }) {

    const branch = localStorage.getItem('branch');
    const guarantor = useSelector(state => state.guarantor)
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token');
    const [number, setNumber] = useState(0);
    const [form] = Form.useForm()
    const { confirm } = Modal;

    // คำนวนที่ดิน
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})

    const [loanAmountt, setLoanAmountt] = useState();
    const [installmentCount, setInstallmentCount] = useState()
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)
    // const [resultIrr, setResultIrr] = useState(0.0)
    const [arr, setArr] = useState()
    const [interestt, setInterestt] = useState(0.0)
    const [getimages, setGetImages] = useState();
    const [checkOpen, setCheckOpen] = useState(false)
    const [checkOpenHIRE, setCheckOpenHIRE] = useState(false)
    const [isModalEditBroker, setIsModalEditBroker] = useState(false)
    const [checkApprove, setCheckApprove] = useState({})
    const [oldData, setOldData] = useState();
    const [modalConfirm, setModalConfirm] = useState() // เปิด modal ของผู้กู้ยอมรับ
    const [ap, setAp] = useState();
    const [carLoanDetails, setCarLoanDetails] = useState();
    const [dataBroker, setDataBroker] = useState();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [dataCustomer, setDataCustomer] = useState()  // เก็บข้อมูล ลูกค้า
    const [dataAddress, setDataAddress] = useState([])  // เก็บข้อมูล ที่อยู่
    const [dataCareer, setDataCareer] = useState([])    // เก็บข้อมูล อาชีพ
    const [dataLoan, setDataLoan] = useState()          // เก็บข้อมูล loan
    const [dataOldLoan, setDataOldLoan] = useState()    // เก็บข้อมูล oldloan
    const [dataLoanRe, setDataLoanRe] = useState()      // เก็บข้อมูล loanRe
    const [dataAP, setDataAP] = useState()              // เก็บข้อมูล ap
    const [dataCarLand, setDataCarLand] = useState()    // เก็บข้อมูล carland
    const [dataCheckGua, setDataCheckGua] = useState()  // เก็บข้อมูล checkGua
    // const [dataCheckbox, setDataCheckbox] = useState()  // เก็บข้อมูล checkbox
    const [dataGuarantor, setDataGuarantor] = useState([]) // เก็บข้อมูล อาชีพ
    const [dataNote, setDataNote] = useState([])        // เก็บข้อมูล note
    const [dataNoteRe, setDataNoteRe] = useState([])    // เก็บข้อมูล noteRe
    const [dataImg, setDataImg] = useState([])          // เก็บข้อมูล รูป
    const [dataApproval, setDataApproval] = useState()  // เก็บข้อมูล พี่หนุ่ม
    const [dataEmail, setDataEmail] = useState([])      // เก็บข้อมูล email
    const [dataPhones, setDataPhones] = useState([])    // เก็บข้อมูล เบอร์โทร
    const [dataSocials, setDataSocials] = useState([])  // เก็บข้อมูล Social
    const [dataLandArray, setDataLandArray] = useState([])  // เก็บข้อมูล LandArray
    const [dataLandAppLand, setDataLandAppLand] = useState({
        landLoanId: dataFromTable?.landLoanId,
        findLandPN: []
    })  // เก็บข้อมูล LandAPLand
    const [dataCarAun, setDataCarAun] = useState()  // เก็บข้อมูล aun car

    //เพิ่มรูป
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListCustomerImageCar, setFileListCustomerImageCar] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    const [fileListOtherMent, setFileListOtherMent] = useState([]);
    // const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListLand, setFileListLand] = useState([]);
    const [fileListDeep, setFileListDeep] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);

    //แว่นขยายฝั่งเคาะราคา
    const [fileListLandAppLand, setFileListLandAppLand] = useState([]);
    const [fileListDeepAppLand, setFileListDeepAppLand] = useState([]);
    const [fileListCustomerLeafDividerAppLand, setFileListCustomerLeafDividerAppLand] = useState([]);
    const [fileListCustomerBurdenAppLand, setFileListCustomerBurdenAppLand] = useState([]);
    //แว่นขยายฝั่งเคาะราคา

    const [checkSendNEW, setCheckSendNEW] = useState(false);
    const [isModalMainMix, setIsModalMainMix] = useState(false);

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage] = useState([]);
    const [resetPage, setResetPage] = useState();

    const [dataEditIndex, setDataEditIndex] = useState()
    const [checkEdit, setCheckEdit] = useState(false)
    const [dataEdit, setDataEdit] = useState()
    const [editMode, setEditMode] = useState(false)

    const [checkImage, setCheckImage] = useState(false)

    const [newDataLand, setNewDataLand] = useState([]);
    // const [loanRe, setLoanRe] = useState();
    // const [isu, setIsu] = useState();

    const [moonnee, setMoonnee] = useState()
    const [hug, setHug] = useState()
    const [kong, setKong] = useState()
    const [jaimoney, setJaimoney] = useState()
    const [yodjut, setYodjut] = useState()
    const [dateceo, setDateceo] = useState()
    const [exp, setExp] = useState()
    const [ngod, setNgod] = useState()
    const [pon, setPon] = useState()

    // image
    const [openShowImage, setOpenShowImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // แยก type image car
    // แยก type image car
    const [carimage1, setCarImage1] = useState([]);
    const [carimagenew1, setCarImagenew1] = useState([]);
    const [carimage2, setCarImage2] = useState([]);
    const [carimagenew2, setCarImagenew2] = useState([]);
    const [carimage3, setCarImage3] = useState([]);
    const [carimagenew3, setCarImagenew3] = useState([]);
    const [carimage4, setCarImage4] = useState([]);
    const [carimagenew4, setCarImagenew4] = useState([]);
    const [carimage5, setCarImage5] = useState([]);
    const [carimagenew5, setCarImagenew5] = useState([]);
    const [carimage6, setCarImage6] = useState([]);
    const [carimagenew6, setCarImagenew6] = useState([]);
    const [carimage7, setCarImage7] = useState([]);
    const [carimagenew7, setCarImagenew7] = useState([]);
    const [carimage8, setCarImage8] = useState([]);
    const [carimagenew8, setCarImagenew8] = useState([]);
    const [carimage9, setCarImage9] = useState([]);
    const [carimagenew9, setCarImagenew9] = useState([]);
    const [carimage10, setCarImage10] = useState([]);
    const [carimagenew10, setCarImagenew10] = useState([]);
    const [carimage11, setCarImage11] = useState([]);
    const [carimagenew11, setCarImagenew11] = useState([]);
    const [carimage12, setCarImage12] = useState([]);
    const [carimagenew12, setCarImagenew12] = useState([]);
    const [carimage14, setCarImage14] = useState([]);
    const [carimagenew14, setCarImagenew14] = useState([]);
    const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
    const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
    const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [current, setCurrent] = React.useState(0);
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        loadData().then(async () => {
            await loadGuarantors()
            await loadCareer()
        })
    }, [resetPage])


    useEffect(() => {
        dataImg?.map((e) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
        loadImage(typeLoan)
    }, [dataImg])

    useEffect(() => {
        setDataLandAppLand({ ...dataLandAppLand, findLandPN: newDataLand })
    }, [newDataLand])

    const onDownload = () => {
        const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
        const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

        // ใช้ fetch ดึงรูปภาพจาก path
        fetch(imageObj.path)
            .then((response) => response.blob()) // เปลี่ยน response เป็น blob
            .then((blob) => {
                // สร้าง Blob URL สำหรับการดาวน์โหลด
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
                document.body.appendChild(link);
                link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
                URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
                link.remove(); // ลบ link ออกจาก DOM
            })
            .catch((error) => {
                console.error("Error downloading the image:", error);
            });
    };

    const handleSelectImage = (e, image) => {
        // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
        setSelectedImages(prevSelected => [...prevSelected, image]);
    };

    ////////////////////////////////////////////////////////////////
    const openModalImages = (e, sg) => {
        loadImage(typeLoan, sg)
        // setSg(sg)
        if (e.type === 1) {
            setCarShowModal(carimage1)
        } else if (e.type === 2) {
            setCarShowModal(carimage2)
        } else if (e.type === 3) {
            setCarShowModal(carimage3)
        } else if (e.type === 4) {
            setCarShowModal(carimage4)
        } else if (e.type === 5) {
            setCarShowModal(carimage5)
            setGuarantorNew(sg)
            const n = 5;
            setGuarantorNum(n)
        } else if (e.type === 6) {
            setCarShowModal(carimage6)
        } else if (e.type === 7) {
            setCarShowModal(carimage7)
        } else if (e.type === 8) {
            setCarShowModal(carimage8)
        } else if (e.type === 9) {
            setCarShowModal(carimage9)
        } else if (e.type === 10) {
            setCarShowModal(carimage10)
        } else if (e.type === 11) {
            setCarShowModal(carimage11)
        } else if (e.type === 12) {
            setCarShowModal(carimage12)
        } else if (e.type === 14) {
            setCarShowModal(carimage14)
        } else {

        }
        setOpenShowImage(true)
    };

    function extractFolderGroup(path) {
        const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
        return match ? match[1] : null;
    }


    const loadImage = async (value) => {
        var carimg1 = [];
        var carimg2 = [];
        var carimg3 = [];
        var carimg4 = [];
        var carimg5 = [];
        var carimg6 = [];
        var carimg7 = [];
        var carimg8 = [];
        var carimg9 = [];
        var carimg10 = [];
        var carimg11 = [];
        var carimg12 = [];
        var carimg14 = [];
        var carimgnew1 = [];
        var carimgnew2 = [];
        var carimgnew3 = [];
        var carimgnew4 = [];
        var carimgnew5 = [];
        var carimgnew6 = [];
        var carimgnew7 = [];
        var carimgnew8 = [];
        var carimgnew9 = [];
        var carimgnew10 = [];
        var carimgnew11 = [];
        var carimgnew12 = [];
        var carimgnew14 = [];
        if (value === "car") {
            setImageBlobzz(dataImg)
            dataImg?.map((car, i) => {
                if (car.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 1) {
                        carimg1.push(car)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 1) {
                        carimgnew1.push(car)
                        setCarImagenew1(carimgnew1)
                    }
                    // carimg1.push(car)
                    // setCarImage1(carimg1)
                } else if (car.type === 2) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 2) {
                        console.log("22", car)
                        carimg2.push(car)
                        setCarImage2(carimg2)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 2) {
                        carimgnew2.push(car)
                        setCarImagenew2(carimgnew2)
                    }
                    // carimg2.push(car)
                    // setCarImage2(carimg2)
                } else if (car.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 4) {
                        carimg4.push(car)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 4) {
                        carimgnew4.push(car)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(car)
                    // setCarImage4(carimg4)
                } else if (car.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(car);
                    if (group === '1') {
                        group1.push({ ...car, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...car, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...car, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...car, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...car, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...car, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (car.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 6) {
                        carimg6.push(car)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 6) {
                        carimgnew6.push(car)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(car)
                    // setCarImage6(carimg6)
                } else if (car.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 7) {
                        carimg7.push(car)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 7) {
                        carimgnew7.push(car)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(car)
                    // setCarImage7(carimg7)
                } else if (car.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 8) {
                        carimg8.push(car)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 8) {
                        carimgnew8.push(car)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(car)
                    // setCarImage8(carimg8)
                } else if (car.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 9) {
                        carimg9.push(car)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 9) {
                        carimgnew9.push(car)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(car)
                    // setCarImage9(carimg9)
                } else if (car.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 14) {
                        carimg14.push(car)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 14) {
                        carimgnew14.push(car)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(car)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 12) {
                        carimg12.push(car)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 12) {
                        carimgnew12.push(car)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(car)
                    // setCarImage12(carimg12)
                }
            })
        } else {
            setImageBlobzz(dataImg)
            dataImg?.map((land, i) => {
                if (land.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
                        carimg1.push(land)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
                        carimgnew1.push(land)
                        setCarImagenew1(carimgnew4)
                    }
                    // carimg1.push(land)
                    // setCarImage1(carimg1)
                } else if (land.type === 3) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
                        carimg3.push(land)
                        setCarImage3(carimg3)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
                        carimgnew3.push(land)
                        setCarImagenew3(carimgnew3)
                    }
                    // carimg3.push(land)
                    // setCarImage3(carimg3)
                } else if (land.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
                        carimg4.push(land)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
                        carimgnew4.push(land)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(land)
                    // setCarImage4(carimg4)
                } else if (land.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(land);
                    if (group === '1') {
                        group1.push({ ...land, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...land, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...land, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...land, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...land, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...land, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (land.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
                        carimg6.push(land)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
                        carimgnew6.push(land)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(land)
                    // setCarImage6(carimg6)
                } else if (land.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
                        carimg7.push(land)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
                        carimgnew7.push(land)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(land)
                    // setCarImage7(carimg7)
                } else if (land.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
                        carimg8.push(land)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
                        carimgnew8.push(land)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(land)
                    // setCarImage8(carimg8)
                } else if (land.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
                        carimg9.push(land)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
                        carimgnew9.push(land)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(land)
                    // setCarImage9(carimg9)
                } else if (land.type === 10) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
                        carimg10.push(land)
                        setCarImage10(carimg10)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
                        carimgnew10.push(land)
                        setCarImagenew10(carimgnew10)
                    }
                    // carimg10.push(land)
                    // setCarImage10(carimg10)
                } else if (land.type === 11) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
                        carimg11.push(land)
                        setCarImage11(carimg11)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
                        carimgnew11.push(land)
                        setCarImagenew11(carimgnew11)
                    }
                    // carimg11.push(land)
                    // setCarImage11(carimg11)
                } else if (land.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
                        carimg14.push(land)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
                        carimgnew14.push(land)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(land)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
                        carimg12.push(land)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
                        carimgnew12.push(land)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(land)
                    // setCarImage12(carimg12)
                }
            })
        }
    }

    const imgSend = (resultImgs) => {
        if (typeLoan === "car") {
            return new Promise(async (resolve, reject) => {
                try {
                    var status;
                    newFormData.append('idcus', dataCarLand?.carId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        image.type = file.type
                        // resultImage.push(image)
                    }
                    const response = await axios.post(mutips2, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        console.log("TY", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                } catch (error) {
                    error(error);
                    reject(error);
                } finally {
                    //setLoading(false);
                    setResetPage(dayjs())
                    setFileListCustomer([]);
                    setFileListCustomerImageCareer([]);
                    setFileListCustomerImageCar([]);
                    setFileListCustomerDocument([]);
                    setFileListStateMent([]);
                    setFileListStateMentPDF([]);
                    setFileListOtherMent([]);
                    // setFileListGuarantor([]);
                    setFileListLand([]);
                    setFileListDeep([]);
                    setFileListCustomerLeafDivider([]);
                    setFileListCustomerBurden([]);
                    form.setFieldsValue({
                        fileListCustomer: [],
                        fileListCustomerImageCareer: [],
                        fileListCustomerImageCar: [],
                        fileListCustomerDocument: [],
                        fileListStatement: [],
                        fileListStatementPDF: [],
                        fileListOtherMent: [],
                        // fileListGuarantor: [],
                        fileListLand: [],
                        fileListDeep: [],
                        fileListCustomerLeafDivider: [],
                        fileListCustomerBurden: [],
                    })
                    console.log("OK")
                }
            });
        } else {
            return new Promise(async (resolve, reject) => {
                try {
                    var status;
                    newFormData.append('idcus', dataCarLand?.landId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        image.type = file.type
                        // resultImage.push(image)
                    }
                    const response = await axios.post(mutips3, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        console.log("TY", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                } catch (error) {
                    error(error);
                    reject(error);
                } finally {
                    //setLoading(false);
                    setResetPage(dayjs())
                    setFileListCustomer([]);
                    setFileListCustomerImageCareer([]);
                    setFileListCustomerImageCar([]);
                    setFileListCustomerDocument([]);
                    setFileListStateMent([]);
                    setFileListStateMentPDF([]);
                    setFileListOtherMent([]);
                    // setFileListGuarantor([]);
                    setFileListLand([]);
                    setFileListDeep([]);
                    setFileListCustomerLeafDivider([]);
                    setFileListCustomerBurden([]);
                    form.setFieldsValue({
                        fileListCustomer: [],
                        fileListCustomerImageCareer: [],
                        fileListCustomerImageCar: [],
                        fileListCustomerDocument: [],
                        fileListStatement: [],
                        fileListStatementPDF: [],
                        fileListOtherMent: [],
                        // fileListGuarantor: [],
                        fileListLand: [],
                        fileListDeep: [],
                        fileListCustomerLeafDivider: [],
                        fileListCustomerBurden: [],
                    })
                    console.log("OK")

                }
            });
        }

    };

    const successMix = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            // description:
            //     'กรุณา "ปิด" หน้าส่งเอกสารไปตรวจสอบ แล้วเข้าใหม่',
            placement,
        });
    }
    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคสสำเร็จ</b>,
            // description:
            //     'กรุณา "ปิด" หน้าส่งเอกสารไปตรวจสอบ แล้วเข้าใหม่',
            placement,
        });
    }

    const errorMix = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description:
                'เพิ่มข้อมูลไม่สำเร็จ กรุณาติดต่อ IT',
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคสไม่สำเร็จ</b>,
            description:
                'เพิ่มข้อมูลไม่สำเร็จ กรุณาติดต่อ IT',
            placement,
        });
    }

    const handleSubmitAppLand = async () => {
        setLoading(true);
        axios.post(mklandmix, dataLandAppLand)
            .then(res => {
                if (res.status === 200) {
                    console.log("OK")
                    successMix('top')
                    handleSendCase()
                } else {
                    errorMix('top')
                    setLoading(false);
                }
            }).catch((err) => {
                errorMix('top')
                setLoading(false);
                // console.log(err)
            })
    }

    const handleSendCase = async () => {
        var mix = { ...dataLoan, land: { ...dataCarLand, carInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: "รอพี่หนุ่มรับ", rejectStatus: false, }
        delete mix.notes
        // console.log("handleNewSend", mix)
        axios.put(resendlandmk, mix)
            .then(res => {
                if (res.status === 200) {
                    successSend("top")
                    setTimeout(() => {
                        close(false)
                    }, 1500);
                } else {
                    errorSend("top")
                    setLoading(false);
                }
            }).catch((err) => {
                errorSend("top")
                setLoading(false);
            })
    }

    const loadBroker = async (value) => {
        const id = { loanId: value }
        await axios.post(findDataBroker, id)
            .then((res) => {
                // console.log("res", res.data)
                setDataBroker(res.data)
            })
            .catch((err) => console.log(err))
    }
    // const getImg = async () => {
    //     let path
    //     if (typeLoan === "car" || typeLoan === "carre") {
    //         path = `one_images/car/${dataFromTable?.carId}/5`
    //     } else if (typeLoan === "land" || typeLoan === "landre") {
    //         path = `one_images/land/${dataFromTable?.landId}/5`
    //     }
    //     // var path = `one_images/car/${dataFromTable?.carId}/5`
    //     setLoading(true)
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //                 setLoading(false)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //             setLoading(false)
    //         })
    //     setLoading(false)
    // }

    const loadDataCar = async () => {
        await axios.post(checkcarcus, { carPlateNumber: dataFromTable.carPlateNumber, carProvince: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    // console.log("OldDataCar", res.data)
                    setOldData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadDataLand = async () => {
        await axios.post(checklandcus, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
            .then(res => {
                if (res.status === 200) {
                    // console.log("OldDataLand", res.data)
                    setOldData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleCheckIDBroker = () => {
        if (dataBroker === undefined || dataBroker === null) {
            console.log("pass")
            showConfirmOK()
        } else {
            if (dataBroker.customerBroker.idCard === "" || dataBroker.customerBroker.idCard === null) {
                notiNoIdCard("top")
                setIsModalEditBroker(true)
            } else {
                showConfirmOK()
            }
        }
    };

    const showConfirmOK = () => {
        confirm({
            title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = true
                customerConfirm(checkOK)
                setTimeout(() => {

                }, 1500)
            },
            onCancel() {
            },
        });
    };

    const ConfirmOK = () => {
        setModalConfirm(true)
    };

    const showConfirmCancel = () => {
        confirm({
            title: 'ผู้กู้ปฏิเสธยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = false
                customerConfirm(checkOK)

            },
            onCancel() {
            },
        });
    };

    const customerConfirm = async (checkOK) => {
        setLoading(true)
        if (typeLoan === "car") {
            if (checkOK === true) {
                var data = { loanId: dataLoan.loanId, approvalStatus: "ลูกค้าคอนเฟิร์ม", cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
            } else {
                var data = { loanId: dataLoan.loanId, approvalStatus: "ลูกค้าปฏิเสธ", cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
            }
            // console.log("data car", data)
            await axios.put(customerconfirmcar, data)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        if (data.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
                            message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
                        } else {
                            message.error('ผู้กู้ปฏิเสธยอดที่อนุมัติ')
                        }
                        setTimeout(() => {
                            handleCancel();
                        }, 2000)

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            if (checkOK === true) {
                var data = { landLoanId: dataLoan.landLoanId, approvalStatus: "ลูกค้าคอนเฟิร์ม", cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
            } else {
                var data = { landLoanId: dataLoan.landLoanId, approvalStatus: "ลูกค้าปฏิเสธ", cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
            }
            // console.log("data land", data)
            await axios.put(customerconfirmland, data)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        if (data.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
                            message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
                        } else {
                            message.error('ผู้กู้ปฏิเสธยอดที่อนุมัติ')
                        }
                        setTimeout(() => {
                            handleCancel();
                        }, 2000)

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    };

    const calYellowCard = (value) => {
        // console.log("calYellowCard", value)
        // มูลหนี้ balanc
        // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
        // คงเหลือ มูลหนี้ - หักส่วนลด
        // จ่ายเงิน (newDamt+followPay)
        // ยอดจัด approvedLoanAmount
        // วันที่ approvalDate
        // ใบนี้หมดอายุ ณ วันที่ newOfferDate
        // จำนวนงวด pgLoanTerm
        // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
        // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

        setMoonnee(currencyFormatOne(~~value?.balanc))
        setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
        setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
        setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
        setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
        setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
        setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
        setNgod(~~value?.pgLoanTerm)
        setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
    }

    ///////////////////////////////////อัพโหลดรูปภาพใหม่/////////////////////////////////////////////
    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const compressImage2 = async (ez, types, index) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        // แบบ base64
                        var filename = ez.name
                        var image64 = await getBase64(compressedFile)
                        image.filename = filename
                        image.image64 = image64
                        image.type = types
                        // allImg.push(image)

                        if (dataLandAppLand?.findLandPN[index]?.img2 === undefined) {
                            setDataLandAppLand({
                                ...dataLandAppLand,
                                findLandPN: dataLandAppLand?.findLandPN?.map((item, idx) =>
                                    idx === index ? { ...item, img2: [image] } : item
                                )
                            });
                        } else {
                            dataLandAppLand.findLandPN[index].img2.push(image);
                        }

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                // allImg.push(image);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };
    const beforeUpts2 = async (file, ty, index) => {
        try {
            // setLoading(true)
            const compressedFile = await compressImage2(file, ty, index);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleCustomerImage = async ({ fileList }) => {
        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomer(fileList);
        setLoading(false)
    };
    const handleCustomerImageCareer = async ({ fileList }) => {
        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerImageCareer(fileList);
        setLoading(false)
    };
    const handleCustomerImageCar = async ({ fileList }) => {
        for (const removedFile of fileListCustomerImageCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerImageCar(fileList);
        setLoading(false)
    };
    const handleCustomerImageDocument = async ({ fileList }) => {
        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerDocument(fileList);
        setLoading(false)
    };
    // const handleGuarantor = async ({ fileList }) => {
    //     for (const removedFile of fileListGuarantor.filter(file => file.status === 'removed')) {
    //         if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 5)) {
    //             const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 5));
    //             setAllImg(updatedAllImg);
    //         }
    //     }
    //     setFileListGuarantor(fileList);
    //     setLoading(false)
    // };
    const handleStatement = async ({ fileList }) => {
        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMent(fileList);
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {
        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMentPDF(fileList);
        setLoading(false)
    };
    const handleOtherMent = async ({ fileList }) => {
        for (const removedFile of fileListOtherMent.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
                setAllImg(updatedAllImg);
            }
        }
        setFileListOtherMent(fileList);
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const openTable = () => {
        setCheckOpen(true)
    };
    const openTable2 = () => {
        setCheckOpenHIRE(true)
    };

    const handleCancel = () => {
        close(false)
    }

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const loadData = async () => {
        setLoading(true)

        if (typeLoan === "car" || typeLoan === "carre") {
            await axios.post(findcarmk, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        // console.log("CarCar", res.data)
                        setDataCustomer(res.data)
                        setDataAddress(res.data.address)
                        // setDataCareer()
                        setDataOldLoan(res.data.cars.carOldLoanDetails)
                        setDataLoan(res.data.cars.carLoanDetails)
                        setDataLoanRe(res.data.cars.carLoanDetailsRe)
                        setDataAP(res.data.cars.apCarLoanDetails)
                        setDataCarLand(res.data.cars)
                        setDataCheckGua(res.data.cars.carLoanDetails.checkGua)
                        // setDataCheckbox(res.data.cars.carLoanDetails.checkbox)
                        // setDataGuarantor()
                        setDataNote(res.data.cars.carLoanDetails.notes)
                        setDataNoteRe(res.data.cars.carLoanDetailsRe.notes)
                        setDataImg(res.data.cars.img)
                        setDataPhones(res.data.phones)
                        setDataEmail(res.data.email)
                        setDataSocials(res.data.socials)
                        setDataCarAun(res.data.cars.carsApproveCeo)
                        calYellowCard(res.data.cars?.carsApproveCeo)
                        setCarLoanDetails(res.data.cars.carLoanDetails)
                        setCheckApprove(res.data.cars.carLoanDetails.approvalStatus)
                        if (res.data.cars.apCarLoanDetails.loanId !== 0) {
                            setLoanAmountt(parseInt(res.data.cars.apCarLoanDetails.apLoanAmount))
                            setInstallmentCount(parseInt(res.data.cars.apCarLoanDetails.apLoanTerm))
                        } else {
                            setLoanAmountt(parseInt(res.data.cars.carLoanDetails.approvedLoanAmount))
                            setInstallmentCount(parseInt(res.data.cars.carLoanDetails.approvedLoanTerm))
                        }
                        if (res.data.cars.carMemo) {
                            form.setFieldsValue(
                                {
                                    ...res.data.cars
                                }
                            )
                        }
                        await loadGuarantors()
                        await loadCareer()
                        await loadDataCar()
                        await handleCheckForCal(res.data.cars)
                        await loadBroker(res.data.cars.carLoanDetails.loanId)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else if (typeLoan === "land" || typeLoan === "landre" || typeLoan === "landappland") {
            await axios.post(findlandmk, { carId: dataFromTable.landId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        // console.log("LandLand", res.data)

                        setDataCustomer(res.data)
                        setDataAddress(res.data.address)
                        // setDataCareer()
                        setDataOldLoan(res.data.lands.landOldLoanDetails)
                        setDataLoan(res.data.lands.landLoanDetails)
                        setDataLoanRe(res.data.lands.landLoanDetailsRe)
                        setDataAP(res.data.lands.apLandLoanDetails)
                        setDataCarLand(res.data.lands)
                        setDataCheckGua(res.data.lands.landLoanDetails.checkGua)
                        // setDataCheckbox(res.data.lands.landLoanDetails.checkbox)
                        // setDataGuarantor()
                        setDataNote(res.data.lands.landLoanDetails.notes)
                        setDataNoteRe(res.data.lands.landLoanDetailsRe.notes)
                        setDataImg(res.data.lands.img)
                        setDataApproval(res.data.lands.approval)
                        setDataPhones(res.data.phones)
                        setDataEmail(res.data.email)
                        setDataSocials(res.data.socials)

                        setCheckApprove(res.data.lands.landLoanDetails.approvalStatus)


                        await loadMixData(res.data.lands)
                        if (typeLoan !== "landappland") {
                            await land_Calculate(res.data.lands.landLoanDetails)
                            await loadGuarantors()
                            await loadDataLand()
                            await loadCareer()

                        } else {
                            setDataLandAppLand({ ...dataLandAppLand, findLandPN: dataLandArray })
                        }

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }

        setLoading(false)
    }

    const loadMixData = async (value) => {
        await axios.post(findlandpn, { landLoanId: value.landLoanDetails.landLoanId })
            .then(res => {
                // console.log("loadMixData", res.data)
                if (res.status === 200) {
                    if (typeLoan === "landre") {
                        res.data.map((newLand) => {
                            if (newLand.landId === value.landId) {
                                setDataLandArray([newLand]) // ตอนนี้ยังไม่รองรับการผูกโฉนด รี/ปรับ/เปลี่ยน
                            }
                        })
                    } else {
                        setDataLandArray(res.data)
                        res.data.map((e, index) => {
                            form.setFieldsValue({
                                [`storyLand${index}`]: e?.storyLand,
                            })
                        })
                    }
                    if (typeLoan === "landappland") {
                        var addIMG2 = res.data.map((data) => {
                            return Object.assign({}, data, { img2: [] });
                        })
                        setNewDataLand(addIMG2)
                    }
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const land_Calculate = async (dataLand11) => {
        // console.log("dataLand11",dataLand11)
        var interestRateLand = parseFloat(dataLand11?.interestRateLand) / 100 // อัตราดอก / 100
        var rate = (parseFloat(dataLand11?.approvedLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(dataLand11?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(dataLand11?.approvedLoanAmount)) / parseInt(dataLand11?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(dataLand11?.approvedLoanTerm)) - parseFloat(dataLand11?.approvedLoanAmount)
        var resultPrice = loanTerm * parseInt(dataLand11?.approvedLoanTerm)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)
    }

    const loadCareer = async () => {
        const id = { customerId: dataFromTable.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                // console.log("res", res.data)
                setDataCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadGuarantors = async () => { //คนค้ำ
        let value
        if (typeLoan === "car" || typeLoan === "carre") {
            value = { typeLoan: "car", loanId: dataFromTable?.loanId }
        } else if (typeLoan === "land" || typeLoan === "landre") {
            value = { typeLoan: "land", loanId: dataFromTable?.landLoanId }
        }
        await axios.post(checkgu, value)
            .then(res => {
                if (res.status === 200) {
                    // console.log("res", res.data)
                    setDataGuarantor(res.data)
                    // getImg()
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleSave = async () => {
        setLoading(true)
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }
        setTimeout(() => {
            setLoading(false)
            message.success('บันทึกสำเร็จ')
            setNumber(1)
        }, 1000)
    };

    const notiNoIdCard = (placement) => {
        api.warning({
            message: "ไม่พบข้อมูลของผู้แนะนำ",
            description:
                'กรุณาเพิ่มข้อมูลผู้แนะนำ',
            placement,
        });
    };

    const showConfirm = async () => {
        confirm({
            title: 'คุณต้องการที่จะส่งเคสนี้อีกครั้ง...?',
            onOk() {
                if (typeLoan === "car") {
                    reSendCaseCar()
                } else if (typeLoan === "land") {
                    reSendCaseLand()
                }
            },
            onCancel() {
            },
        });
    };

    const handleAddLand = async () => {
        setIsModalMainMix(true);
    };

    const reSendCaseCar = async () => {
        let mix = {
            ...dataLoan,
            guarantors: guarantor?.data,
            cars: {
                ...dataCarLand,
                carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImage
            },
            approvalStatus: "รอธุรการรับ"
        }
        // console.log("reSendCaseCar", mix);
        axios.put(resendcarmk, mix)
            .then(res => {
                if (res.status === 200) {
                    SuccSend("top")
                    setTimeout(() => {
                        handleCancel()
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const reSendCaseLand = async () => {
        let mix = {
            ...dataLoan,
            guarantors: guarantor?.data,
            lands: {
                ...dataCarLand,
                landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                img: resultImage
            },
            approvalStatus: "รอธุรการรับ"
        }
        // console.log("reSendCaseLand", mix);
        axios.put(resendlandmk1, mix)
            .then(res => {
                if (res.status === 200) {
                    SuccSend("top")
                    setTimeout(() => {
                        handleCancel()
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const handleCheckForCal = async (value) => {
        if (value.carLoanDetails?.productType === "p-loan") {
            // console.log("1 = p-loan")
            await handleCalculate(value.carLoanDetails)
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ") {
            if (dataCarLand?.productTypeCar === 1) {
                // console.log("2 = รถเล็ก เช่าซื้อ")
                await handleCalculateHIRE(value.carLoanDetails)
            } else {
                // console.log("3 = รถใหญ่ เช่าซื้อ")
                await car_Calculate(value.carLoanDetails)
            }
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ1") {
            // console.log("4 = รถเล็ก เช่าซื้อ1")
            await car_Calculate()
        }
    }

    // คำนวน
    const car_Calculate = async (value) => {

        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm
        var IR = value.interestRate

        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setArr(newLoanTermCar)
        setInterestt(resultRateCar)
        setInstallmentWithInterestt(newResultPriceCar)
    }
    const handleCalculate = async (value) => {
        var LA = parseInt(value.approvedLoanAmount)
        var LT = parseInt(value.approvedLoanTerm)

        if (LA && LT) {

            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = LA
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                setInstallmentWithInterestt(result)
            }
        }
    }
    const handleCalculateHIRE = async (value) => {
        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm

        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = amountWithVat
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                setInstallmentWithInterestt(result)
            }
        }
    }
    // คำนวน
    const handleLand = async ({ fileList }) => {
        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListLand(fileList);
        setLoading(false)
    };
    const handleDeep = async ({ fileList }) => {

        for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }
        setFileListDeep(fileList);
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => {
        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerLeafDivider(fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => {

        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerBurden(fileList)
        setLoading(false)
    };

    //แว่นขยายฝั่งเคาะราคา
    const handleLandAppLand = async (value, index) => {
        const newFileList = [];
        for (const removedFile of fileListLandAppLand.filter(file => file.status === 'removed')) {
            if (dataLandAppLand.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 1)) {
                const updatedImg2 = dataLandAppLand.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 1));
                const updatedFindLandPN = [...dataLandAppLand.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataLandAppLand({ ...dataLandAppLand, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListLandAppLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListLandAppLand.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    const handleDeepAppLand = async (value, index) => {
        const newFileList = [];
        for (const removedFile of fileListDeepAppLand.filter(file => file.status === 'removed')) {
            if (dataLandAppLand.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 3)) {
                const updatedImg2 = dataLandAppLand.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 3));
                const updatedFindLandPN = [...dataLandAppLand.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataLandAppLand({ ...dataLandAppLand, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListDeepAppLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListDeepAppLand.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    const handleChangeCustomerLeafDividerAppLand = async (value, index) => {
        const newFileList = [];
        for (const removedFile of fileListCustomerLeafDividerAppLand.filter(file => file.status === 'removed')) {
            if (dataLandAppLand.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 10)) {
                const updatedImg2 = dataLandAppLand.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 10));
                const updatedFindLandPN = [...dataLandAppLand.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataLandAppLand({ ...dataLandAppLand, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListCustomerLeafDividerAppLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListCustomerLeafDividerAppLand.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurdenAppLand = async (value, index) => {
        const newFileList = [];
        for (const removedFile of fileListCustomerBurdenAppLand.filter(file => file.status === 'removed')) {
            if (dataLandAppLand.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 11)) {
                const updatedImg2 = dataLandAppLand.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 11));
                const updatedFindLandPN = [...dataLandAppLand.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataLandAppLand({ ...dataLandAppLand, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListCustomerBurdenAppLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListCustomerBurdenAppLand.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    //แว่นขยายฝั่งเคาะราคา

    const currencyFormatOne = (amount) => {
        if (amount > 0) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }
    }

    const currencyFormatRe = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "..."
        }
    }

    const handleEditMode = async () => {
        if (editMode) {
            setEditMode(false)
        } else {
            setEditMode(true)
        }
    }

    const funcEdit = (value, index) => {
        let swapData = [...newDataLand]
        swapData[index] = { ...value, img2: [] }
        setNewDataLand(swapData);
    }

    const funcAdd = (value) => {
        // console.log("funcAdd", value)
        setDataLandAppLand(prevData => ({ ...prevData, findLandPN: [...prevData.findLandPN, { ...value, img2: [] }] }));
        dataLandArray.push(value)
    }

    const handleDeleteLand = (value, index) => {
        confirm({
            title: 'ต้องการที่จะยกเลิกจดรวมข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการยกเลิกจดรวม',
            onOk() {
                const deletearray = [...newDataLand]
                const deletearray2 = [...dataLandArray]
                deletearray.splice(index, 1)
                deletearray2.splice(index, 1)
                setNewDataLand(deletearray)
                setDataLandArray(deletearray2)
            },
            onCancel() {
            },
        });
    };

    const renderLandData = ({ item, index }) => {
        return (
            <>
                {/* <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {dataLandArray?.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Divider> */}
                <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {dataLandArray?.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Row>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            ไร่
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            งาน
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท / ตารางวา
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ราคารวม : </span> {<b>{currencyFormatOne(item?.resultLandPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                </aside>
                {
                    editMode ?
                        <>
                            {
                                index === 0 ?
                                    null :
                                    <>
                                        <Space direction='vertical'>
                                            {/* <Button style={{ width: "100px", backgroundColor: "#DCD9D4" }} onClick={() => handleEditLand(dataForMix.findLandPN[index], index)}>เปลี่ยนที่ดิน</Button> */}
                                            <Button style={{ width: "100px" }} type='primary' danger onClick={() => handleDeleteLand(dataLandAppLand.findLandPN[index], index)}>ยกเลิกจดรวม</Button>
                                        </Space>
                                    </>
                            }
                        </> : null
                }
                <Divider />
                {/* <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider> */}
                <Row justify={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Row>
                <Form.Item name={`storyLand${index}`} label="">
                    <TextArea style={{ color: 'blue' }} rows={5} disabled />
                </Form.Item>
            </>
        )

    }
    const renderCoordinates = ({ item }) => { //render Land Array
        return (
            <>
                <div>
                    <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
                    <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
                        {`${item.parcellat},${item.parcellon}`}
                    </a>
                </div>
            </>
        )
    }
    const renderApproval = ({ item }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{currencyFormatOne(item?.approval?.minPrice)} - {currencyFormatOne(item?.approval?.maxPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                        </span>
                        {/* <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span> */}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note ? item?.approval?.note : "-"}</b>}
                    </div>
                </aside>
            </>
        )
    }
    const renderMixNote = ({ item, index }) => {
        return (
            <>
                {
                    index === 0 ?
                        null :
                        <>
                            <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={24}>
                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                                        <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                        </>
                }
            </>
        )
    }

    const renderGuarantor = ({ item, index }) => {
        var itemSplit = []
        var itemSplitnum = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        carimage5?.map((e, i) => {
            const segments = e?.pathImage.split("/")
            if (itemSplit[4] === segments[4]) {
                itemSplitnum.push(e)
            }
        })
        const count = itemSplitnum.length || 0;
        return (
            <Card>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {itemSplit.length > 0 ? (
                    <>
                        <Row justify={"center"}>
                            <Image.PreviewGroup
                                preview={{
                                    countRender: (index, total) => {
                                        const displayedIndex = Math.min(index, count);
                                        const displayTotal = Math.max(count, count);
                                        return `${displayedIndex} / ${displayTotal}`;
                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                    toolbarRender: (
                                        _,
                                        {
                                            transform: { scale },
                                            actions: {
                                                onActive,
                                                onFlipY,
                                                onFlipX,
                                                onRotateLeft,
                                                onRotateRight,
                                                onZoomOut,
                                                onZoomIn,
                                                onReset,
                                            },
                                        },
                                    ) => (
                                        <>
                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                {carimage5?.map((e, index) => {
                                                    const segments = e?.pathImage.split("/")
                                                    if (itemSplit[4] === segments[4] && e.type === 5) {
                                                        return (
                                                            <Image
                                                                width={auto}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                            />
                                                        )
                                                    }
                                                    return null;
                                                }
                                                )}
                                            </Row>
                                            <Row style={{ zIndex: 2 }}>
                                                <Space size={12} className="toolbar-wrapper">
                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                    <DownloadOutlined onClick={onDownload} />
                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                    <SwapOutlined onClick={onFlipX} />
                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                    <UndoOutlined onClick={onReset} />
                                                </Space>
                                            </Row>
                                        </>
                                    ),
                                    onChange: (index) => {
                                        setCurrent(index);
                                    },
                                }}
                            >
                                <Row gutter={32} justify={'center'}>
                                    {carimage5?.map((e, index) => {
                                        const segments = e?.pathImage.split("/")
                                        if (itemSplit[4] === segments[4] && e.type === 5) {
                                            return (
                                                <Image
                                                    width={150}
                                                    key={index}
                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                    src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                    alt={`รูปภาพ ${index + 1}`}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )
                                        }
                                        return null;
                                    }
                                    )}
                                </Row>
                            </Image.PreviewGroup>
                        </Row>
                    </>
                ) : null
                }
            </Card>
        )
    }
    const renderItemCareer = ({ item }) => {
        return (
            <Row gutter={32} justify={'center'}>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0, }}>
                        <span>กลุ่มอาชีพ : </span>
                        <b>{item?.desc}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                        <b>{item?.companyName}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>อาชีพ  : </span>
                        <b>{item?.descSub}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>รายได้ / เดือน : </span>
                        <b>{currencyFormatOne(item?.incomeMonth)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>สาขาอาชีพ : </span>
                        <b>{item?.descSection}</b>
                    </div>

                    <div style={{ marginBottom: 0, }}>
                        <span>
                            รายจ่าย / เดือน : </span>
                        <b>{currencyFormatOne(item?.expensesMonth)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                        </span>
                    </div>
                </aside>
            </Row>
        )
    }
    const renderItemAddress = ({ item, index }) => {
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>
                </aside>
                {dataAddress?.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item }) => {
        return (
            <>
                <div style={{ marginBottom: 0 }}>
                    <span>{item?.phoneType}</span>
                    <b> {item?.telp}</b>
                </div>
            </>
        )
    }
    const renderItemEmails = ({ item }) => {
        return (
            <>
                <div style={{ marginBottom: 0 }}>
                    <b> {item?.emailData}</b>
                </div>
            </>
        )
    }
    const renderItemSocials = ({ item }) => {
        return (
            <>
                <div style={{ marginBottom: 0 }}>
                    <span>{item?.socialType}</span>
                    <b> {item?.socialData}</b>
                </div>
            </>
        )
    }
    const renderDataNote = ({ item }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>หมายเหตุ : </span>
                                        <b>{item.note}</b>
                                    </div>
                                    {/* <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{item.noteBy}</b>
                                        </div> */}
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item.status}</b>
                                    </div>
                                </>
                            </Card>
                        </>

                    </Col>
                </Row>
            </>
        )
    }

    const renderLandBuildings = ({ item, index }) => { //render Land Array
        // console.log(item)
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    {/* <Space direction='vertical'> */}
                    <Row justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 1) {
                                    return (
                                        // <Row gutter={32} justify={'center'}>
                                        //     <Col span={24} style={{ textAlign: 'center' }}>
                                        //         <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        //     </Col>
                                        // </Row>
                                        <div key={index}>
                                            <img
                                                onClick={(v) => openModalImages(e)}
                                                style={{ width: 200 }}
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index}`}
                                            />
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                    {
                        dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b>
                                        <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                            {
                                                typeLoan === "landappland" ?
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={(value) => handleLandAppLand(value, index)}
                                                            beforeUpload={(file) => beforeUpts2(file, 1, index).then(() => false).catch(() => true)}
                                                        >
                                                            {fileListLandAppLand?.length >= 10 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                    </>
                                                    :
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={handleLand}
                                                            beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                                                        >
                                                            {fileListLand?.length >= 10 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                    </>
                                            }

                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                    }
                    {/* </Space> */}
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Row justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 1) {
                                                    return (
                                                        // <div style={{ margin: 3 }}>
                                                        //     <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        // </div>
                                                        <div key={index}>
                                                            <img
                                                                onClick={(v) => openModalImages(e)}
                                                                style={{ width: 200 }}
                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index}`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    {/* <Space direction='vertical'> */}
                    <Row justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 3) {
                                    return (
                                        // <Row gutter={32} justify={'center'}>
                                        //     <Col span={24} style={{ textAlign: 'center' }}>
                                        //         <Image width={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        //     </Col>
                                        // </Row>
                                        <div key={index}>
                                            <img
                                                onClick={(v) => openModalImages(e)}
                                                style={{ width: 200 }}
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index}`}
                                            />
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                    {
                        dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b>
                                        <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                            {
                                                typeLoan === "landappland" ?
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            // fileList={fileListDeepAppLand}
                                                            onPreview={handlePreview}
                                                            onChange={(value) => handleDeepAppLand(value, index)}
                                                            beforeUpload={(file) => beforeUpts2(file, 3, index).then(() => false).catch(() => true)}
                                                        >
                                                            {fileListDeepAppLand?.length >= 10 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                    </>
                                                    :
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            // fileList={fileListDeep}
                                                            onPreview={handlePreview}
                                                            onChange={handleDeep}
                                                            beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                                                        >
                                                            {fileListDeep?.length >= 10 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                    </>
                                            }
                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                    }
                    {/* </Space> */}
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Row justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 3) {
                                                    return (
                                                        // <Row gutter={32} justify={'center'}>
                                                        //     <Col span={24} style={{ textAlign: 'center' }}>
                                                        //         <Image width={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        //     </Col>
                                                        // </Row>
                                                        <div key={index}>
                                                            <img
                                                                onClick={(v) => openModalImages(e)}
                                                                style={{ width: 200 }}
                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index}`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    {/* <Space direction="vertical"> */}
                    <Row justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 10) {
                                    return (
                                        // <>
                                        //     <Row gutter={32} justify={'center'}>
                                        //         <Col span={24} style={{ textAlign: 'center' }}>
                                        //             <Image width={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        //         </Col>
                                        //     </Row>
                                        // </>
                                        <div key={index}>
                                            <img
                                                onClick={(v) => openModalImages(e)}
                                                style={{ width: 200 }}
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index}`}
                                            />
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                    {
                        dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b>
                                        <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                            {
                                                typeLoan === "landappland" ?
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={(value) => handleChangeCustomerLeafDividerAppLand(value, index)}
                                                            beforeUpload={(file) => beforeUpts2(file, 10, index).then(() => false).catch(() => true)}
                                                        >
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        </Upload>
                                                    </>
                                                    :
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerLeafDivider}
                                                            beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                                                        >
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        </Upload>
                                                    </>
                                            }

                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                    }
                    {/* </Space> */}
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Row justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 10) {
                                                    return (
                                                        // <>
                                                        //     <Row gutter={32} justify={'center'}>
                                                        //         <Col span={24} style={{ textAlign: 'center' }}>
                                                        //             <Image width={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        //         </Col>
                                                        //     </Row>
                                                        // </>
                                                        <div key={index}>
                                                            <img
                                                                onClick={(v) => openModalImages(e)}
                                                                style={{ width: 200 }}
                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index}`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    {/* <Space direction="vertical"> */}
                    <Row justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 11) {
                                    return (
                                        // <>
                                        //     <Row gutter={32} justify={'center'}>
                                        //         <Col span={24} style={{ textAlign: 'center' }}>
                                        //             <Image width={'50px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        //         </Col>
                                        //     </Row>
                                        // </>
                                        <div key={index}>
                                            <img
                                                onClick={(v) => openModalImages(e)}
                                                style={{ width: 200 }}
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index}`}
                                            />
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                    {
                        dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b>
                                        <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                            {
                                                typeLoan === "landappland" ?
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={(value) => handleChangeCustomerBurdenAppLand(value, index)}
                                                            beforeUpload={(file) => beforeUpts2(file, 11, index).then(() => false).catch(() => true)}
                                                        >
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        </Upload>
                                                    </>
                                                    :
                                                    <>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerBurden}
                                                            beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                                                        >
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        </Upload>
                                                    </>
                                            }
                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                    }
                    {/* </Space> */}
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Row justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 11) {
                                                    return (
                                                        // <>
                                                        //     <Row gutter={32} justify={'center'}>
                                                        //         <Col span={24} style={{ textAlign: 'center' }}>
                                                        //             <Image width={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        //         </Col>
                                                        //     </Row>
                                                        // </>
                                                        <div key={index}>
                                                            <img
                                                                onClick={(v) => openModalImages(e)}
                                                                style={{ width: 200 }}
                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index}`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                    {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                                        <>
                                            <div className='gutter-row' span={12}>
                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                >
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerBurden}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerBurden}
                                                        beforeUpload={() => false}
                                                    >
                                                        {/* {fileListCustomerBurden?.length >= 10 ? null : ( */}
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                        {/* )} */}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                                </b>
                                            </div>
                                        </>
                                        : null
                                    }
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }


    return (
        <Modal title="ตรวจสอบข้อมูล" open={open} onCancel={handleCancel} width={'1200px'}
            footer={[

                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                        {
                            checkApprove === "อนุมัติ" ?
                                <>
                                    {
                                        oldData !== null ?
                                            <>
                                                {
                                                    typeLoan === "car" ?
                                                        <Button type='primary' onClick={ConfirmOK}>
                                                            รถคันนี้เคยเสนอแล้ว
                                                        </Button>
                                                        :
                                                        <Button type='primary' onClick={ConfirmOK}>
                                                            ที่ดินนี้เคยเสนอแล้ว
                                                        </Button>

                                                }
                                            </>
                                            :
                                            <>
                                                <Button type='primary' danger onClick={showConfirmCancel}>
                                                    ผู้กู้ไม่ยอมรับ
                                                </Button>
                                                <Button type='primary' style={{ backgroundColor: 'green' }} onClick={handleCheckIDBroker}>
                                                    ผู้กู้ยอมรับ
                                                </Button>
                                            </>
                                    }
                                </>
                                : null
                        }
                        {
                            checkApprove === "ไม่อนุมัติ" ?
                                <>
                                    {
                                        loading === false ?
                                            <>
                                                <Button type='primary' onClick={handleSave}>บันทึกข้อมูล</Button>
                                            </>
                                            :
                                            <>
                                                <Button type='primary' disabled style={{ color: 'success' }}>
                                                    บันทึกข้อมูล
                                                </Button>
                                            </>
                                    }
                                    {
                                        number === 1 ? <>
                                            <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                                ส่งเคสอีกครั้ง
                                            </Button>
                                        </> : null
                                    }
                                </>
                                : null
                        }
                        {
                            checkApprove === "ขอเอกสารเพิ่ม" ?
                                <>
                                    <Button key="back" type='primary' style={{ backgroundColor: "#F7A419" }} onClick={handleAddLand}>
                                        ผูกโฉนด
                                    </Button>
                                    {
                                        dataLandArray?.length > 1 ?
                                            <>
                                                {
                                                    editMode ?
                                                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >ยกเลิกแก้ไข</Button>
                                                        :
                                                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >แก้ไข</Button>
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        checkImage?.length >= 1
                                            ?
                                            <>
                                                <Button type='primary' onClick={handleSubmitAppLand}>
                                                    บันทึกข้อมูล / ส่งเคส
                                                </Button>
                                            </> :
                                            <>
                                                <Button type='primary' disabled
                                                    style={{ color: 'success' }}
                                                >
                                                    บันทึกข้อมูล / ส่งเคส
                                                </Button>
                                                {number === 1 ? <>
                                                    <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                                        ส่งเคสอีกครั้ง
                                                    </Button>
                                                </> : null}
                                            </>
                                    }

                                    {number === 1 ? <>
                                        <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                            ส่งเคสอีกครั้ง
                                        </Button>
                                    </> : null}
                                </>
                                : null
                        }
                    </Space>


                </Row>
            ]} >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <style>
                    {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                .ant-image-preview-count {
                    display: none !important;
                }
                `}
                </style>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={colorApprovalStatusNEW(dataLoan?.approvalStatus !== "" ? dataLoan?.approvalStatus : dataLoanRe?.approvalStatus)}>{dataLoan?.approvalStatus !== "" ? dataLoan?.approvalStatus : dataLoanRe?.approvalStatus}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                            {
                                                typeLoan === "car" || typeLoan === "carre" ?
                                                    <>
                                                        ประเภทขอกู้ : <Tag color="green">{dataLoan?.productLoanType !== "" ? dataLoan?.productLoanType : dataLoanRe?.productLoanType} </Tag>
                                                    </>
                                                    :
                                                    <>
                                                        ประเภทขอกู้ : <Tag color="green">{dataLoan?.productLoanLandType !== "" ? dataLoan?.productLoanLandType : dataLoanRe?.productLoanLandType} </Tag>
                                                    </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                {
                                    typeLoan === "landappland" ?
                                        null :
                                        <>
                                            {
                                                dataCustomer?.nationalId === "นิติบุคคล" ?
                                                    <>
                                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                {
                                                                    dataLoanRe?.reLoanId !== 0 ?
                                                                        <>
                                                                            <b>
                                                                                <div style={{ margin: 0, }}>
                                                                                    <span>ตัดสด ณ วันที่ : </span>
                                                                                    <b style={{ fontSize: '18px' }}>
                                                                                        <u>
                                                                                            {dayjs(dataLoanRe?.ftime).format("DD-MM-YYYY")}
                                                                                        </u>
                                                                                    </b>
                                                                                </div>
                                                                            </b>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>เลขที่สัญญา : </span> <Tag color='geekblue'><b>{dataCarLand?.ISSUNO}</b></Tag>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ประเภท : </span> <b>{dataCustomer?.nationalId}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataCustomer?.identificationId}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>
                                                                        {dataCustomer?.snam} : </span> <b>{dataCustomer?.firstname + ' ' + dataCustomer?.lastname}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>วันจดทะเบียน : </span> <b> {dataCustomer?.birthdate}</b>
                                                                </div>
                                                                {
                                                                    dataLoanRe?.reLoanId !== 0 ?
                                                                        <div style={{ marginBottom: 3 }}>
                                                                            <span>เกรด : </span> <b> {dataLoanRe?.GRDCOD}</b>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataPhones?.map((item, index) => {
                                                                                return renderItemPhones({ item, index, key: `{ item.identification } - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataEmail?.map((item, index) => {
                                                                                return renderItemEmails({ item, index, key: `{ item.identification } - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataSocials?.map((item, index) => {
                                                                                return renderItemSocials({ item, index, key: `{ item.identification } - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </aside>
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                {
                                                                    dataLoanRe?.reLoanId !== 0 ?
                                                                        <>
                                                                            <b>
                                                                                <div style={{ margin: 0, }}>
                                                                                    <span>ตัดสด ณ วันที่ : </span>
                                                                                    <b style={{ fontSize: '18px' }}>
                                                                                        <u>
                                                                                            {dayjs(dataLoanRe?.ftime).format("DD-MM-YYYY")}
                                                                                        </u>
                                                                                    </b>
                                                                                </div>
                                                                            </b>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>เลขที่สัญญา : </span> <Tag color='geekblue'><b>{dataCarLand?.ISSUNO}</b></Tag>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ชื่อ - นามสกุล : </span>
                                                                    <b>{dataCustomer?.snam} {dataCustomer?.firstname} {dataCustomer?.lastname}</b>
                                                                </div>

                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ชื่อเล่น : </span>
                                                                    <b>{dataCustomer?.nickname}</b>
                                                                </div>

                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>เลขบัตรประชาชน : </span>
                                                                    <b>{dataCustomer?.identificationId}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>เพศ : </span>
                                                                    <b>{dataCustomer?.gender}</b>
                                                                </div>
                                                                {
                                                                    dataLoanRe?.reLoanId !== 0 ?
                                                                        <div style={{ marginBottom: 3 }}>
                                                                            <span>เกรด : </span> <b> {dataLoanRe?.GRDCOD}</b>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataPhones?.map((item, index) => {
                                                                                return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataEmail?.map((item, index) => {
                                                                                return renderItemEmails({ item, index, key: `{ item.identification } - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <b>
                                                                        {
                                                                            dataSocials?.map((item, index) => {
                                                                                return renderItemSocials({ item, index, key: `{ item.identification } - ${index}` })
                                                                            })
                                                                        }
                                                                    </b>
                                                                </div>

                                                            </aside>
                                                        </Row>
                                                    </>
                                            }
                                            <Divider />
                                            {
                                                dataAddress?.length >= 1 ?
                                                    <>
                                                        <Row justify={'left'}>
                                                            <b><u>รายละเอียดที่อยู่</u></b>
                                                        </Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            {
                                                                dataAddress?.map((item, index) => {
                                                                    return renderItemAddress({ item, index })
                                                                })
                                                            }
                                                        </Row>
                                                    </>
                                                    : null
                                            }
                                            {/* <Divider /> */}
                                            <Row justify={'left'}>
                                                <b><u>อาชีพ - รายได้</u></b>
                                            </Row>
                                            {
                                                dataCareer?.length > 0 ?
                                                    <>
                                                        {
                                                            dataCareer?.map((item, index) => {
                                                                return renderItemCareer({ item, index })
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <Row gutter={32} justify={'center'}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ไม่พบอาชีพ </span>
                                                        </div>
                                                    </Row>
                                            }
                                            <Divider />
                                        </>
                                }

                                {
                                    typeLoan === "car" ?
                                        <>
                                            {
                                                dataLoan?.productLoanType === "ย้ายไฟแนนซ์" ||
                                                    dataLoan?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                                    <>
                                                        <Row justify={'left'}><b><u>รายละเอียดยอดกู้ {dataLoan?.productLoanType}</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>

                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ยอดกู้ : </span>
                                                                    <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    <b>{dataLoan?.loanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }} >
                                                                    <span>ดอกเบี้ย : </span>
                                                                    <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>จำนวนผ่อนต่อเดือน : </span>
                                                                    <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }} >
                                                                    <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                    <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ผู้เสนอเคส : </span>
                                                                    <b>{dataLoan?.proposalBy}</b>
                                                                </div>
                                                                {/* <b>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>เรทรถ : </span>
                                                                        <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </b> */}
                                                            </aside>
                                                        </Row>
                                                        <Divider />
                                                        <Row justify={'left'}><b><u>รายละเอียด ({dataLoan?.productLoanType})</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                    <span>จากไฟแนนซ์ : </span>
                                                                    <b>{dataOldLoan?.issuno}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, textAlign: 'left' }} >
                                                                    <span>ยอดปิดไฟแนนซ์ : </span>
                                                                    <b>{currencyFormatOne(dataOldLoan?.oldLoanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                    <span>ค่างวด / เดือน : </span>
                                                                    <b>{currencyFormatOne(dataOldLoan?.oldMonthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                    <span>จำนวนงวดที่ผ่อน : </span>
                                                                    <b>{dataOldLoan?.oldLoanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                            </aside>
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row justify={'left'}><b><u>รายละเอียด({dataLoan?.productLoanType})</u></b></Row>
                                                        {
                                                            dataLoan?.productType === 'เช่าซื้อ' ?
                                                                <>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0 }} >
                                                                                <span>ยอดกู้เช่าซื้อ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }} >
                                                                                <span>จำนวนงวดเช่าซื้อ : </span>
                                                                                <b>{dataLoan?.loanTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>

                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                                <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                                <b>{dataLoan?.proposalBy}</b>
                                                                            </div>
                                                                            {/* <b>
                                                                                <div style={{ margin: 0 }}>
                                                                                    <span>เรทรถ : </span>
                                                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                            </b> */}
                                                                        </aside>
                                                                    </Row>
                                                                </>
                                                                : dataLoan?.productType === 'เช่าซื้อ1' ?
                                                                    <>
                                                                        <Row gutter={32} justify={'center'}>
                                                                            <aside style={{ width: '90%' }}>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ยอดกู้เช่าซื้อ : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนงวดเช่าซื้อ : </span>
                                                                                    <b>{dataLoan?.loanTerm}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        งวด
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                                    <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                                    <b>{dataLoan?.proposalBy}</b>
                                                                                </div>
                                                                                {/* <b>
                                                                                    <div style={{ margin: 0, }}>
                                                                                        <span>เรทรถ : </span>
                                                                                        <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>
                                                                                </b> */}
                                                                            </aside>
                                                                        </Row>
                                                                        <Divider />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* p - loan */}
                                                                        <Row gutter={32} justify={'center'}>
                                                                            <aside style={{ width: '90%' }}>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ยอดกู้ p-loan : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนงวด p-loan : </span>
                                                                                    <b>{dataLoan?.loanTerm}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        งวด
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ดอกเบี้ย p-loan : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                                    <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }} >
                                                                                    <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                                    <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ผู้เสนอเคส p-loan : </span>
                                                                                    <b>{dataLoan?.proposalBy}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                {/* <b>
                                                                                    <div style={{ margin: 0, }}>
                                                                                        <span>เรทรถ : </span>
                                                                                        <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>
                                                                                </b> */}
                                                                            </aside>
                                                                        </Row>
                                                                    </>
                                                        }
                                                    </>
                                            }
                                            {
                                                dataAP?.loanId > 0 || dataLoan?.approvedLoanAmount > 0 ?
                                                    <>
                                                        <Divider />
                                                        <Row justify={'left'}>
                                                            <b><u>อนุมัติ {dataAP?.apProductType !== "" ? dataAP?.apProductType : dataLoan?.productType}
                                                                {
                                                                    dataAP?.apProductType === "เช่าซื้อ1" || dataLoan?.productType === "เช่าซื้อ1" ?
                                                                        <>
                                                                            {dataAP?.apInterestRate !== "" ? <>({dataAP?.apInterestRate})</> : <>({dataLoan?.interestRate})</>}
                                                                        </>
                                                                        : null
                                                                }
                                                            </u></b>
                                                        </Row>
                                                        <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ยอดกู้ : </span>
                                                                    {
                                                                        dataAP?.apLoanAmount !== "0" ?
                                                                            <b>{currencyFormatOne(dataAP?.apLoanAmount)}</b>
                                                                            :
                                                                            <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                    }
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    {
                                                                        dataAP?.apLoanTerm !== "0" ?
                                                                            <b>{currencyFormatOne(dataAP?.apLoanTerm)}</b>
                                                                            :
                                                                            <b>{currencyFormatOne(dataLoan?.approvedLoanTerm)}</b>
                                                                    }
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ดอกเบี้ย : </span>
                                                                    {
                                                                        dataAP?.apInterest !== "0" ?
                                                                            <b>{currencyFormatOne(dataAP?.apInterest)}</b>
                                                                            :
                                                                            <b>{interestt}</b>
                                                                    }
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }} >
                                                                    <span>จำนวนผ่อนต่อเดือน : </span>
                                                                    {
                                                                        dataAP?.apMonthlyPayment !== "0" ?
                                                                            <b>{currencyFormatOne(dataAP?.apMonthlyPayment)}</b>
                                                                            :
                                                                            <b>{arr}</b>
                                                                    }
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }} >
                                                                    <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                    {
                                                                        dataAP?.apInstallmentWithInterest !== "0" ?
                                                                            <b>{currencyFormatOne(dataAP?.apInstallmentWithInterest)}</b>
                                                                            :
                                                                            <b>{installmentWithInterestt}</b>
                                                                    }
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ผู้อนุมัติเคส : </span>
                                                                    {
                                                                        dataAP?.apApprovedBy !== "" ?
                                                                            <b>{dataAP?.apApprovedBy}</b>
                                                                            :
                                                                            <b>{dataLoan?.approvedBy}</b>
                                                                    }

                                                                </div>
                                                                <div>
                                                                    {/* ตาราง */}
                                                                    {
                                                                        dataAP?.loanId !== 0 ?
                                                                            <>
                                                                                {
                                                                                    dataAP?.apProductType === "p-loan" ?
                                                                                        <>
                                                                                            <Col span={24}>
                                                                                                <div style={{ textAlign: "right" }}>
                                                                                                    <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                dataAP?.apInterestRate === "0.75" || dataAP?.apInterestRate === "0.84" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            dataCarLand?.productTypeCar === 1 ?
                                                                                                                <>
                                                                                                                    <Col span={24}>
                                                                                                                        <div style={{ textAlign: "right" }}>
                                                                                                                            <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }

                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    dataLoan?.productType === "p-loan" ?
                                                                                        <>
                                                                                            <Col span={24}>
                                                                                                <div style={{ textAlign: "right" }}>
                                                                                                    <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                dataLoan?.interestRate === "0.75" || dataLoan?.interestRate === "0.84" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            dataCarLand?.productTypeCar === 1 ?
                                                                                                                <>
                                                                                                                    <Col span={24}>
                                                                                                                        <div style={{ textAlign: "right" }}>
                                                                                                                            <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                            </aside>
                                                        </Row>

                                                    </>
                                                    : null
                                            }
                                            <Divider />
                                            {
                                                dataLoanRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "เปลี่ยนสัญญา" ?
                                                    <>
                                                        {/* <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider> */}
                                                        <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                        <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                            <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div name='overdue' style={{ marginBottom: 3 }}>
                                                                <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(dataLoanRe?.overdue)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div name='preChange' style={{ marginBottom: 3 }}>
                                                                <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div><br />
                                                            <div name='preChange' style={{ marginBottom: 3 }}>
                                                                <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                        </aside>
                                                        <Divider style={{ margin: 3 }} />
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                dataCarLand?.ISSUNO !== null ?
                                                    <>
                                                        {
                                                            dataCarAun?.id > 0 ?
                                                                <Card style={{ backgroundColor: "lightyellow" }}>
                                                                    <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง</u></b></Row>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Image
                                                                            width={100}
                                                                            src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                            alt="My Image"
                                                                        />
                                                                    </Col>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>วันที่ : </span>
                                                                                <b><u>{dateceo}</u></b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>มูลหนี้เช่าซื้อคงเหลือรวม : </span>
                                                                                <b><u>{moonnee}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>หักส่วนลด : </span>
                                                                                <b><u>{hug}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>คงเหลือ : </span>
                                                                                <b><u>{kong}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จ่ายเงิน : </span>
                                                                                <b><u>{jaimoney}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                <b><u>{ngod}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผ่อน : </span>
                                                                                <b>{pon}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                    (รวมภาษีมูลค่าเพิ่ม)
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดจัด : </span>
                                                                                <b style={{ color: 'red', fontSize: '18px' }}><u>{yodjut}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{exp}</u></b>
                                                                                    {/* <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span> */}
                                                                                </div>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                </Card>
                                                                :
                                                                <>
                                                                    <Row justify={'left'}><b><u>รายละเอียดสัญญา ({dataCarLand?.ISSUNO})</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ค่างวด : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.DAMT)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดจัดครั้งที่แล้ว : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.tcshprc)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.balanc)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.totalpayment)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ล/น คงเหลือรวม : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.neekong)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>เบี้ยปรับ : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.beeypup)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ลูกหนี้อื่นๆ : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.OTHR)} บาท</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.NOPAY)} งวด</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวดทั้งหมด : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.nopays)} งวด</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ตัดสด งวดที่ : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.reqNo)}</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                                <b>{currencyFormatOne(dataLoanRe?.re1)} บาท</b>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                    <Divider />
                                                                    <aside style={{ width: '100%' }}>
                                                                        <b>
                                                                            <div style={{ margin: 0, }}>
                                                                                <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                                <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataLoanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                            </div>
                                                                        </b>
                                                                    </aside>
                                                                </>
                                                        }

                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยี่ห้อ : </span>
                                                        <b>{dataCarLand?.carBrand}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขทะเบียน : </span>
                                                        <b>{dataCarLand?.carPlateNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขเครื่อง : </span>
                                                        <b>{dataCarLand?.carEngineNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>รุ่นสินค้า : </span>
                                                        <b>{dataCarLand?.carModel}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขคัสซี : </span>
                                                        <b>{dataCarLand?.carChassisNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>สี : </span>
                                                        <b>{dataCarLand?.carColor}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>แบบรถ : </span>
                                                        <b>{dataCarLand?.carBaab}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ปีรถ : </span>
                                                        <b>{dataCarLand?.carYear}</b>
                                                    </div>
                                                    <b>
                                                        <div style={{ margin: 0, }}>
                                                            <span>เรทรถ : </span>
                                                            <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </b>
                                                </aside>
                                            </Row>
                                            <Divider />
                                            {
                                                ShowDataBrokers(branch, carLoanDetails, dataBroker)
                                            }
                                            {/* <Divider /> */}
                                        </>
                                        : typeLoan === "carre" ?
                                            <>
                                                {
                                                    dataLoanRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                        dataLoanRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                        dataLoanRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                        dataLoanRe?.productLoanType === "เปลี่ยนสัญญา" ?
                                                        <>
                                                            <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                            <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                                <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                    <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div name='overdue' style={{ marginBottom: 3 }}>
                                                                    <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(dataLoanRe?.overdue)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div name='preChange' style={{ marginBottom: 3 }}>
                                                                    <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div><br />
                                                                <div name='preChange' style={{ marginBottom: 3 }}>
                                                                    <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>

                                                            </aside>
                                                            <Divider style={{ margin: 3 }} />
                                                        </>
                                                        :
                                                        null
                                                }
                                                <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยี่ห้อ : </span>
                                                            <b>{dataCarLand?.carBrand}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขทะเบียน : </span>
                                                            <b>{dataCarLand?.carPlateNumber}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขเครื่อง : </span>
                                                            <b>{dataCarLand?.carEngineNumber}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>รุ่นสินค้า : </span>
                                                            <b>{dataCarLand?.carModel}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขคัสซี : </span>
                                                            <b>{dataCarLand?.carChassisNumber}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>สี : </span>
                                                            <b>{dataCarLand?.carColor}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>แบบรถ : </span>
                                                            <b>{dataCarLand?.carBaab}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ปีรถ : </span>
                                                            <b>{dataCarLand?.carYear}</b>
                                                        </div>
                                                        <b>
                                                            <div style={{ margin: 0, }}>
                                                                <span>เรทรถ : </span>
                                                                <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </b>
                                                    </aside>
                                                </Row>
                                                <Divider />
                                                {
                                                    dataCarAun?.id > 0 ?
                                                        <Card style={{ backgroundColor: "lightyellow" }}>
                                                            <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง</u></b></Row>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <Image
                                                                    width={100}
                                                                    src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                    alt="My Image"
                                                                />
                                                            </Col>
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>วันที่ : </span>
                                                                        <b><u>{dateceo}</u></b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>มูลหนี้เช่าซื้อคงเหลือรวม : </span>
                                                                        <b><u>{moonnee}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>หักส่วนลด : </span>
                                                                        <b><u>{hug}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>คงเหลือ : </span>
                                                                        <b><u>{kong}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จ่ายเงิน : </span>
                                                                        <b><u>{jaimoney}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด : </span>
                                                                        <b><u>{ngod}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผ่อน : </span>
                                                                        <b>{pon}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                            (รวมภาษีมูลค่าเพิ่ม)
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดจัด : </span>
                                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{yodjut}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </aside>
                                                            </Row>
                                                        </Card>
                                                        :
                                                        <>
                                                            <Row justify={'left'}><b><u>รายละเอียดยอดกู้</u></b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ค่างวด : </span>
                                                                        <b>{dataLoanRe?.DAMT ? currencyFormatOne(dataLoanRe?.DAMT) + " บาท" : "0"}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดจัดครั้งที่แล้ว : </span>
                                                                        <b>{dataLoanRe?.tcshprc ? currencyFormatOne(dataLoanRe?.tcshprc) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                                        <b>{dataLoanRe?.balanc ? currencyFormatOne(dataLoanRe?.balanc) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                                        <b>{dataLoanRe?.totalpayment ? currencyFormatOne(dataLoanRe?.totalpayment) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ล/น คงเหลือรวม : </span>
                                                                        <b>{dataLoanRe?.neekong ? currencyFormatOne(dataLoanRe?.neekong) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>เบี้ยปรับ : </span>
                                                                        <b>{dataLoanRe?.beeypup ? currencyFormatOne(dataLoanRe?.beeypup) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ลูกหนี้อื่นๆ : </span>
                                                                        <b>{dataLoanRe?.OTHR ? currencyFormatOne(dataLoanRe?.OTHR) + " บาท" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                                                        <b>{dataLoanRe?.NOPAY ? currencyFormatOne(dataLoanRe?.NOPAY) + " งวด" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวดทั้งหมด : </span>
                                                                        <b>{dataLoanRe?.nopays ? currencyFormatOne(dataLoanRe?.nopays) + " งวด" : 0}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ตัดสด งวดที่ : </span>
                                                                        <b>{dataLoanRe?.reqNo ? currencyFormatOne(dataLoanRe?.reqNo) : 0}</b>
                                                                    </div>
                                                                </aside>
                                                            </Row>
                                                            <Divider />
                                                            <Row justify={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.re1)}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดจัดใหม่ : </span>
                                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.approvedLoanAmount)}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด : </span>
                                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.approvedLoanTerm)}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                </aside>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                }
                                                {/* <Divider /> */}
                                                <aside style={{ width: '100%' }}>
                                                    <b>
                                                        <div style={{ margin: 0, }}>
                                                            <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                            <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataLoanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                        </div>
                                                    </b>
                                                </aside>
                                                <Divider />
                                            </>
                                            : typeLoan === "land" ?
                                                <>
                                                    {
                                                        dataLoan?.productLoanLandType === 'ย้ายไฟแนนซ์' ||
                                                            dataLoan?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                                            <>
                                                                <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, textAlign: 'left' }} >
                                                                            <span>จากไฟแนนซ์ : </span>
                                                                            <b>{dataOldLoan?.issuno}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <span>ยอดปิดไฟแนนซ์ : </span>
                                                                            <b>{currencyFormatOne(dataOldLoan?.oldLandLoanAmount)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <span>ค่างวด / เดือน : </span>
                                                                            <b>{currencyFormatOne(dataOldLoan?.oldLandMonthlyPayment)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <span>จำนวนงวดที่ผ่อน : </span>
                                                                            <b>{dataOldLoan?.oldLandLoanTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                                <Row justify={'left'}><b><u>รายละเอียด ({dataLoan?.productLoanLandType})</u></b></Row>
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดจัด : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.loanAmountLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>จำนวนงวด : </span>
                                                                            <b>{dataLoan?.loanLandTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>งวดละ : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.monthlyPaymentLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ดอกเบี้ย : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.interestLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ราคารวม : </span>
                                                                            <b>{currencyFormatOne(~~dataLoan?.monthlyPaymentLand * ~~dataLoan?.loanLandTerm)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                            <b>{dataLoan?.proposalBy}</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                            </>
                                                            :
                                                            <>
                                                                <Row justify={'left'}><b><u>รายละเอียด ({dataLoan?.productLoanLandType})</u></b></Row>
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดจัด : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.loanAmountLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>จำนวนงวด : </span>
                                                                            <b>{dataLoan?.loanLandTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>งวดละ : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.monthlyPaymentLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ดอกเบี้ย : </span>
                                                                            <b>{currencyFormatOne(dataLoan?.interestLand)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ราคารวม : </span>
                                                                            <b>{currencyFormatOne(~~dataLoan?.monthlyPaymentLand * ~~dataLoan?.loanLandTerm)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                            <b>{dataLoan?.proposalBy}</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                            </>
                                                    }
                                                    {
                                                        dataAP?.loanId > 0 || dataLoan?.approvedLoanAmount > 0 ?
                                                            <>
                                                                <Row justify={'left'}>
                                                                    <b><u>อนุมัติ {dataAP?.apProductType !== "" ? dataAP?.apProductType : dataLoan?.productType}
                                                                        {
                                                                            dataAP?.apProductType === "เช่าซื้อ1" || dataLoan?.productType === "เช่าซื้อ1" ?
                                                                                <>
                                                                                    {dataAP?.apInterestRate !== "" ? <>({dataAP?.apInterestRate})</> : <>({dataLoan?.interestRate})</>}
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </u></b>
                                                                </Row>
                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดจัด : </span>
                                                                            {
                                                                                dataAP?.apLoanAmount !== "0" ?
                                                                                    <b>{currencyFormatOne(dataAP?.apLoanAmount)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                            }
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>จำนวนงวด : </span>
                                                                            {
                                                                                dataAP?.apLoanTerm !== "0" ?
                                                                                    <b>{dataAP?.apLoanTerm}</b>
                                                                                    :
                                                                                    <b>{dataLoan?.approvedLoanTerm}</b>
                                                                            }
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>งวดละ : </span>
                                                                            {
                                                                                dataAP?.apMonthlyPayment !== "0" ?
                                                                                    <b>{currencyFormatOne(dataAP?.apMonthlyPayment)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(loanTermDATA)}</b>
                                                                            }
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ดอกเบี้ย : </span>
                                                                            {
                                                                                dataAP?.apInterest !== "0" ?
                                                                                    <b>{currencyFormatOne(dataAP?.apInterest)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(resultRateDATA)}</b>
                                                                            }
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ราคารวม : </span>
                                                                            {
                                                                                dataAP?.apInstallmentWithInterest !== "0" ?
                                                                                    <b>{currencyFormatOne(dataAP?.apInstallmentWithInterest)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                                            }
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                            <b>{dataLoan?.approvedBy}</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                            </>
                                                            : null
                                                    }
                                                    {/* <Divider /> */}
                                                    {
                                                        dataLoanRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                            dataLoanRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                            dataLoanRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                            dataLoanRe?.productLoanLandType === "เปลี่ยนสัญญา" ?
                                                            <>
                                                                {/* <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider> */}
                                                                <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                                <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                                    <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                        <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b> บาท
                                                                    </div>
                                                                    <div name='overdue' style={{ marginBottom: 3 }}>
                                                                        <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(dataLoanRe?.overdue)}</b>  บาท
                                                                    </div>
                                                                    <div name='preChange' style={{ marginBottom: 3 }}>
                                                                        <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>  บาท
                                                                    </div><br />
                                                                    <div>
                                                                        <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange
                                                                        )}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </aside>
                                                                <Divider style={{ margin: 3 }} />
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        dataCarLand?.ISSUNO !== null ?
                                                            <>
                                                                <Row justify={'left'}><b><u>รายละเอียดสัญญา ({dataCarLand?.ISSUNO})</u></b></Row>
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ค่างวด : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.DAMT)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดจัดครั้งที่แล้ว : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.tcshprc)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.balanc)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.totalpayment)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ล/น คงเหลือรวม : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.neekong)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>เบี้ยปรับ : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.beeypup)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ลูกหนี้อื่นๆ : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.OTHR)} บาท</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.NOPAY)} งวด</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>จำนวนงวดทั้งหมด : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.nopays)} งวด</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ตัดสด งวดที่ : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.reqNo)}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                            <b>{currencyFormatOne(dataLoanRe?.re1)} บาท</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                                <aside style={{ width: '100%' }}>
                                                                    <b>
                                                                        <div style={{ margin: 0, }}>
                                                                            <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                            <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataLoanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                        </div>
                                                                    </b>
                                                                </aside>
                                                                <Divider />
                                                            </>
                                                            : null
                                                    }
                                                    <Col gutter={32} justify={'center'}>
                                                        {
                                                            dataLandArray?.map((item, index) => {
                                                                return renderLandData({ item, index })
                                                            })
                                                        }
                                                    </Col>
                                                    <Divider />
                                                    {
                                                        dataCarLand?.parcellat ?
                                                            <>
                                                                <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                                                {
                                                                    dataLandArray?.map((item, index) => {
                                                                        return renderCoordinates({ item, index })
                                                                    })
                                                                }
                                                                <Divider />
                                                            </>
                                                            : null
                                                    }
                                                    <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        {
                                                            dataLandArray?.map((item, index) => {
                                                                return renderApproval({ item, index })
                                                            })
                                                        }
                                                    </Row>
                                                    <Divider />
                                                    {
                                                        dataApproval?.mixNote === "" ||
                                                            dataApproval?.mixNote === undefined ||
                                                            dataApproval?.mixNote === null ?
                                                            null
                                                            :
                                                            <>
                                                                {
                                                                    dataLandArray?.map((item, index) => {
                                                                        return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                                                                    })
                                                                }
                                                            </>
                                                    }
                                                </>
                                                : typeLoan === "landre" ?
                                                    <>
                                                        {
                                                            dataLoanRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                dataLoanRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                dataLoanRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                                dataLoanRe?.productLoanLandType === "เปลี่ยนสัญญา" ?
                                                                <>
                                                                    {/* <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider> */}
                                                                    <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                                    <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                                        <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                            <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b> บาท
                                                                        </div>
                                                                        <div name='overdue' style={{ marginBottom: 3 }}>
                                                                            <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(dataLoanRe?.overdue)}</b>  บาท
                                                                        </div>
                                                                        <div name='preChange' style={{ marginBottom: 3 }}>
                                                                            <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>  บาท
                                                                        </div><br />
                                                                        <div>
                                                                            <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange
                                                                            )}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                    </aside>
                                                                    <Divider style={{ margin: 3 }} />
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <Col gutter={32} justify={'center'}>
                                                            {
                                                                dataLandArray?.map((item, index) => {
                                                                    return renderLandData({ item, index })
                                                                })
                                                            }
                                                        </Col>
                                                        <Divider />
                                                        <Row justify={'left'}><b><u>รายละเอียดยอดกู้</u></b></Row>
                                                        <aside style={{ width: '100%' }}>
                                                            <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                                <span>ค่างวด : </span><b>{dataLoanRe?.DAMT ? currencyFormatOne(dataLoanRe?.DAMT) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                                <span>ยอดจัดครั้งที่แล้ว : </span><b>{dataLoanRe?.tcshprc ? currencyFormatOne(dataLoanRe?.tcshprc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                                <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{dataLoanRe?.balanc ? currencyFormatOne(dataLoanRe?.balanc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                                <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{dataLoanRe?.totalpayment ? currencyFormatOne(dataLoanRe?.totalpayment) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                                <span>ล/น คงเหลือรวม : </span><b>{dataLoanRe?.neekong ? currencyFormatOne(dataLoanRe?.neekong) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                                <span>เบี้ยปรับ : </span><b>{dataLoanRe?.beeypup ? currencyFormatOne(dataLoanRe?.beeypup) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                                <span>ลูกหนี้อื่นๆ : </span><b>{dataLoanRe?.OTHR ? currencyFormatOne(dataLoanRe?.OTHR) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{dataLoanRe?.NOPAY ? currencyFormatOne(dataLoanRe?.NOPAY) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดทั้งหมด : </span><b>{dataLoanRe?.nopays ? currencyFormatOne(dataLoanRe?.nopays) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                                                                <span>ตัดสด งวดที่ : </span><b>{dataLoanRe?.reqNo ? currencyFormatOne(dataLoanRe?.reqNo) : 0}</b>
                                                            </div>
                                                        </aside>
                                                        <Divider />
                                                        <Row justify={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Row>
                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.re1)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.approvedLoanAmount)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatRe(dataLoanRe?.approvedLoanTerm)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                        <Divider orientation={'left'}></Divider>
                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataLoanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                        <Divider />
                                                        {
                                                            dataCarLand?.parcellat ?
                                                                <>
                                                                    <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                                                    {
                                                                        dataLandArray?.map((item, index) => {
                                                                            return renderCoordinates({ item, index })
                                                                        })
                                                                    }
                                                                    <Divider />
                                                                </>
                                                                : null
                                                        }
                                                        <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            {
                                                                dataLandArray?.map((item, index) => {
                                                                    return renderApproval({ item, index })
                                                                })
                                                            }
                                                        </Row>
                                                        <Divider />
                                                        {
                                                            dataApproval?.mixNote === "" ||
                                                                dataApproval?.mixNote === undefined ||
                                                                dataApproval?.mixNote === null ?
                                                                null
                                                                :
                                                                <>
                                                                    {
                                                                        dataLandArray?.map((item, index) => {
                                                                            return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                                                                        })
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                    : typeLoan === "landappland" ?
                                                        <>
                                                            <Col gutter={32} justify={'center'}>
                                                                {
                                                                    dataLandArray?.map((item, index) => {
                                                                        return renderLandData({ item, index })
                                                                    })
                                                                }
                                                            </Col>
                                                            <Divider />
                                                            {
                                                                dataCarLand?.parcellat ?
                                                                    <>
                                                                        <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                                                        {
                                                                            dataLandArray?.map((item, index) => {
                                                                                return renderCoordinates({ item, index })
                                                                            })
                                                                        }
                                                                        <Divider />
                                                                    </>
                                                                    : null
                                                            }
                                                            <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                {
                                                                    dataLandArray?.map((item, index) => {
                                                                        return renderApproval({ item, index })
                                                                    })
                                                                }
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                }
                                {
                                    typeLoan === "landappland" ?
                                        null :
                                        <>
                                            <Row justify={'left'}><Col span={24}><b><u>การค้ำโอน</u></b></Col></Row>
                                            <Row justify={'center'}>
                                                <Checkbox
                                                    style={{ fontSize: '20px' }}
                                                    checked={dataCheckGua === "ค้ำ-ไม่โอน" ? true : false}
                                                >ค้ำ-ไม่โอน
                                                </Checkbox>
                                                <Checkbox
                                                    style={{ fontSize: '20px' }}
                                                    checked={dataCheckGua === "ค้ำ-โอน" ? true : false}
                                                >ค้ำ-โอน
                                                </Checkbox>
                                                <Checkbox
                                                    style={{ fontSize: '20px' }}
                                                    checked={dataCheckGua === "ไม่ค้ำ-ไม่โอน" ? true : false}
                                                >ไม่ค้ำ-ไม่โอน
                                                </Checkbox>
                                                <Checkbox
                                                    style={{ fontSize: '20px' }}
                                                    checked={dataCheckGua === "ไม่ค้ำ-โอน" ? true : false}
                                                >ไม่ค้ำ-โอน
                                                </Checkbox>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    dataGuarantor?.length >= 1 ?
                                                        dataGuarantor?.map((item, index) => {
                                                            return renderGuarantor({ item, index })
                                                        })
                                                        : <>* ไม่พบคนค้ำ!</>
                                                }
                                            </Row>
                                            <Divider />
                                        </>
                                }
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        dataNote?.length >= 1 ?
                                            dataNote?.map((item, index) => {
                                                return renderDataNote({ item, index });
                                            })
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                {
                                    typeLoan === "landappland" ?
                                        null :
                                        <>
                                            <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ รี/ปรับ</u></b></Col></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    dataNoteRe?.length >= 1 ?
                                                        dataNoteRe?.map((item, index) => {
                                                            return renderDataNote({ item, index });
                                                        })
                                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                                }
                                            </Row>
                                            < Divider />
                                            {
                                                dataLoan?.approvalDescription !== "" && dataLoan?.approvalDescription !== null && dataLoan?.approvalDescription !== undefined ?
                                                    <>
                                                        <Row justify={'left'}><Col span={24}><b><u>หมายเหตุเงื่อนไขการอนุมัติ</u></b></Col></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>เงื่อนไขการอนุมัติ : </span>
                                                                <b>{dataLoan?.approvalDescription}</b>
                                                            </div>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {/* <Space direction="vertical" > */}
                                                <Image.PreviewGroup
                                                    preview={{
                                                        countRender: (index, total) => {
                                                            const imageCount4 = carimage4?.length || 0; // นับจำนวนภาพจริง
                                                            const displayedIndex = Math.min(index, imageCount4);
                                                            const displayTotal = Math.max(imageCount4, imageCount4);
                                                            return `${displayedIndex} / ${displayTotal}`;
                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                        toolbarRender: (
                                                            _,
                                                            {
                                                                transform: { scale },
                                                                actions: {
                                                                    onActive,
                                                                    onFlipY,
                                                                    onFlipX,
                                                                    onRotateLeft,
                                                                    onRotateRight,
                                                                    onZoomOut,
                                                                    onZoomIn,
                                                                    onReset,
                                                                },
                                                            },
                                                        ) => (
                                                            <>
                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                    {carimage4?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={auto}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                                <Row style={{ zIndex: 2 }}>
                                                                    <Space size={12} className="toolbar-wrapper">
                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                        <DownloadOutlined onClick={onDownload} />
                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                        <SwapOutlined onClick={onFlipX} />
                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                        <UndoOutlined onClick={onReset} />
                                                                    </Space>
                                                                </Row>
                                                            </>
                                                        ),
                                                        onChange: (index) => {
                                                            setCurrent(index);
                                                        },
                                                    }}
                                                >
                                                    <Row gutter={32} justify={'center'}>
                                                        {carimage4?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={150}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                </Image.PreviewGroup>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b>
                                                                    <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                        <Upload
                                                                            name='imageIDGuCar'
                                                                            multiple={true}
                                                                            listType="picture-card"
                                                                            fileList={fileListCustomer}
                                                                            onPreview={handlePreview}
                                                                            onChange={handleCustomerImage}
                                                                            beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                                        >
                                                                            {fileListCustomer?.length >= 5 ? null : (
                                                                                <div>
                                                                                    <PlusOutlined />
                                                                                    <div className="ant-upload-text">upload</div>
                                                                                </div>
                                                                            )}
                                                                        </Upload>
                                                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                        </Modal>
                                                                    </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            {/* <Space direction="vertical" > */}
                                                            {dataImg?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 4) {
                                                                        return (
                                                                            // <>
                                                                            //     <Row gutter={32} justify={'center'}>
                                                                            //         <Col span={24} style={{ textAlign: 'center' }}>
                                                                            //             <Image width={'50px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            //         </Col>
                                                                            //     </Row>
                                                                            // </>
                                                                            <div key={index}>
                                                                                <img
                                                                                    onClick={(v) => openModalImages(e)}
                                                                                    style={{ width: 200 }}
                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index}`}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                            {/* </Space> */}
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                }

                                {
                                    typeLoan === "car" || typeLoan === "carre" ?
                                        <>
                                            <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Image.PreviewGroup
                                                    preview={{
                                                        countRender: (index, total) => {
                                                            const imageCounts2 = carimage2?.length || 0; // นับจำนวนภาพจริง
                                                            const displayedIndex = Math.min(index, imageCounts2);
                                                            const displayTotal = Math.max(imageCounts2, imageCounts2);
                                                            return `${displayedIndex} / ${displayTotal}`;
                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                        toolbarRender: (
                                                            _,
                                                            {
                                                                transform: { scale },
                                                                actions: {
                                                                    onActive,
                                                                    onFlipY,
                                                                    onFlipX,
                                                                    onRotateLeft,
                                                                    onRotateRight,
                                                                    onZoomOut,
                                                                    onZoomIn,
                                                                    onReset,
                                                                },
                                                            },
                                                        ) => (
                                                            <>
                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                    {carimage2?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={auto}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                                <Row style={{ zIndex: 2 }}>
                                                                    <Space size={12} className="toolbar-wrapper">
                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                        <DownloadOutlined onClick={onDownload} />
                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                        <SwapOutlined onClick={onFlipX} />
                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                        <UndoOutlined onClick={onReset} />
                                                                    </Space>
                                                                </Row>
                                                            </>
                                                        ),
                                                        onChange: (index) => {
                                                            setCurrent(index);
                                                        },
                                                    }}
                                                >
                                                    <Row gutter={32} justify={'center'}>
                                                        {carimage2?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={150}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                </Image.PreviewGroup>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                                >
                                                                    <Upload
                                                                        name='imageIDGuCar'
                                                                        multiple={true}
                                                                        listType="picture-card"
                                                                        fileList={fileListCustomerDocument}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleCustomerImageDocument}
                                                                        beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListCustomerDocument?.length >= 3 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {/* </Space> */}
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>หน้าคู่มือ</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount2 = carimagenew2?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount2);
                                                                        const displayTotal = Math.max(imageCount2, imageCount2);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimagenew2?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimagenew2?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {dataCarLand?.carMemo !== "" && dataCarLand?.carMemo !== undefined ?
                                                    <>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Form.Item name="carMemo" >
                                                                <TextArea disabled name='carMemo' style={{ color: 'blue' }} rows={8}>
                                                                    <b style={{ color: 'blue' }}>{dataCarLand?.carMemo}</b>
                                                                </TextArea>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    : null
                                                }
                                                <Row justify={'center'}>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCounts1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCounts1);
                                                                const displayTotal = Math.max(imageCounts1, imageCounts1);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimage1?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimage1?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Row>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                    <Upload
                                                                        name='imageIDGuCar'
                                                                        multiple={true}
                                                                        listType="picture-card"
                                                                        fileList={fileListCustomerImageCar}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleCustomerImageCar}
                                                                        //beforeUpload={() => false}
                                                                        beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListCustomerImageCar?.length >= 20 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount1 = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount1);
                                                                        const displayTotal = Math.max(imageCount1, imageCount1);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimagenew1?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimagenew1?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderLandBuildings({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderTitleDeed({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderDivision({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderLawang({ item, index })
                                                })
                                            }
                                        </>
                                }
                                {
                                    typeLoan === "landappland" ?
                                        null :
                                        <>
                                            <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row justify={'center'}>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount6 = carimage6?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount6);
                                                                const displayTotal = Math.max(imageCount6, imageCount6);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimage6?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimage6?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Row>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>

                                                                    <Upload
                                                                        name='imageIDGuCar'
                                                                        multiple={true}
                                                                        listType="picture-card"
                                                                        fileList={fileListCustomerImageCareer}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleCustomerImageCareer}
                                                                        //beforeUpload={() => false}
                                                                        beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListCustomerImageCareer?.length >= 25 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {/* </Space> */}
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount1 = carimagenew6?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount1);
                                                                        const displayTotal = Math.max(imageCount1, imageCount1);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimagenew6?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimagenew6?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row justify={'center'}>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount8 = carimage8?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount8);
                                                                const displayTotal = Math.max(imageCount8, imageCount8);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimage8?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimage8?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Row>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                                >
                                                                    <Upload
                                                                        name='imageIDGuCar'
                                                                        multiple={true}
                                                                        listType="picture-card"
                                                                        fileList={fileListStatement}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleStatement}
                                                                        //beforeUpload={() => false}
                                                                        beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListStatement?.length >= 15 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Row justify={'center'}>
                                                                <Image.PreviewGroup
                                                                    preview={{
                                                                        countRender: (index, total) => {
                                                                            const imageCount8 = carimagenew8?.length || 0; // นับจำนวนภาพจริง
                                                                            const displayedIndex = Math.min(index, imageCount8);
                                                                            const displayTotal = Math.max(imageCount8, imageCount8);
                                                                            return `${displayedIndex} / ${displayTotal}`;
                                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                        toolbarRender: (
                                                                            _,
                                                                            {
                                                                                transform: { scale },
                                                                                actions: {
                                                                                    onActive,
                                                                                    onFlipY,
                                                                                    onFlipX,
                                                                                    onRotateLeft,
                                                                                    onRotateRight,
                                                                                    onZoomOut,
                                                                                    onZoomIn,
                                                                                    onReset,
                                                                                },
                                                                            },
                                                                        ) => (
                                                                            <>
                                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                    {carimagenew8?.map((e, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Image
                                                                                                    width={auto}
                                                                                                    key={index}
                                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                />
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Row>
                                                                                <Row style={{ zIndex: 2 }}>
                                                                                    <Space size={12} className="toolbar-wrapper">
                                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                        <DownloadOutlined onClick={onDownload} />
                                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                        <SwapOutlined onClick={onFlipX} />
                                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                        <UndoOutlined onClick={onReset} />
                                                                                    </Space>
                                                                                </Row>
                                                                            </>
                                                                        ),
                                                                        onChange: (index) => {
                                                                            setCurrent(index);
                                                                        },
                                                                    }}
                                                                >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {carimagenew8?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={150}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Image.PreviewGroup>
                                                            </Row>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {/* <Space direction="vertical" > */}
                                                {dataImg?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 7) {
                                                            return (
                                                                <Row gutter={32} justify={'center'}>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Space direction='vertical'>
                                                                            <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                            </object>
                                                                            <p></p>
                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    }
                                                    return null;
                                                })}
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b>
                                                                    <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                        <Upload

                                                                            name='imageIDGuCar'
                                                                            multiple={true}
                                                                            listType="picture-card"
                                                                            fileList={fileListStatementPDF}
                                                                            onPreview={handlePreview}
                                                                            onChange={handleStatementPDF}
                                                                            beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                                        >
                                                                            {fileListStatementPDF?.length >= 10 ? null : (
                                                                                <div>
                                                                                    <PlusOutlined />
                                                                                    <div className="ant-upload-text">upload</div>
                                                                                </div>
                                                                            )}
                                                                        </Upload>
                                                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                        </Modal>
                                                                    </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {/* </Space> */}
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            {/* <Space direction="vertical" > */}
                                                            {dataImg?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 7) {
                                                                        return (
                                                                            <Row gutter={32} justify={'center'}>
                                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                                    <Space direction='vertical'>
                                                                                        <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                            <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                                        </object>
                                                                                        <p></p>
                                                                                    </Space>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                            {/* </Space> */}
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row justify={'center'}>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount12 = carimage12?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount12);
                                                                const displayTotal = Math.max(imageCount12, imageCount12);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimage12?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimage12?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Row>
                                                {
                                                    dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                        <>
                                                            <div className='gutter-row' span={12}>
                                                                <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                    <Upload
                                                                        name='imageIDGuCar'
                                                                        multiple={true}
                                                                        listType="picture-card"
                                                                        fileList={fileListOtherMent}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleOtherMent}
                                                                        beforeUpload={(file) => beforeUpts(file, 12).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListOtherMent?.length >= 20 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                                </b>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Divider />
                                                        <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Row justify={'center'}>
                                                                <Image.PreviewGroup
                                                                    preview={{
                                                                        countRender: (index, total) => {
                                                                            const imageCount12 = carimagenew12?.length || 0; // นับจำนวนภาพจริง
                                                                            const displayedIndex = Math.min(index, imageCount12);
                                                                            const displayTotal = Math.max(imageCount12, imageCount12);
                                                                            return `${displayedIndex} / ${displayTotal}`;
                                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                        toolbarRender: (
                                                                            _,
                                                                            {
                                                                                transform: { scale },
                                                                                actions: {
                                                                                    onActive,
                                                                                    onFlipY,
                                                                                    onFlipX,
                                                                                    onRotateLeft,
                                                                                    onRotateRight,
                                                                                    onZoomOut,
                                                                                    onZoomIn,
                                                                                    onReset,
                                                                                },
                                                                            },
                                                                        ) => (
                                                                            <>
                                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                    {carimagenew12?.map((e, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Image
                                                                                                    width={auto}
                                                                                                    key={index}
                                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                />
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Row>
                                                                                <Row style={{ zIndex: 2 }}>
                                                                                    <Space size={12} className="toolbar-wrapper">
                                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                        <DownloadOutlined onClick={onDownload} />
                                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                        <SwapOutlined onClick={onFlipX} />
                                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                        <UndoOutlined onClick={onReset} />
                                                                                    </Space>
                                                                                </Row>
                                                                            </>
                                                                        ),
                                                                        onChange: (index) => {
                                                                            setCurrent(index);
                                                                        },
                                                                    }}
                                                                >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {carimagenew12?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={150}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Image.PreviewGroup>
                                                            </Row>
                                                            {
                                                                dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                                                                    <>
                                                                        <div className='gutter-row' span={12}>
                                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                                <Upload
                                                                                    name='imageIDGuCar'
                                                                                    multiple={true}
                                                                                    listType="picture-card"
                                                                                    fileList={fileListOtherMent}
                                                                                    onPreview={handlePreview}
                                                                                    onChange={handleOtherMent}
                                                                                    beforeUpload={(file) => beforeUpts(file, 12).then(() => false).catch(() => true)}
                                                                                >
                                                                                    {fileListOtherMent?.length >= 20 ? null : (
                                                                                        <div>
                                                                                            <PlusOutlined />
                                                                                            <div className="ant-upload-text">upload</div>
                                                                                        </div>
                                                                                    )}
                                                                                </Upload>
                                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                                </Modal>
                                                                            </Form.Item>
                                                                            </b>
                                                                        </div>
                                                                    </>
                                                                    : null
                                                            }
                                                        </Row>
                                                    </>
                                                    : null
                                            }
                                            <Divider />
                                            <Row justify={'left'}><b><u>เอกสารขอรี/ปรับ/เปลี่ยน</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Image.PreviewGroup
                                                    preview={{
                                                        countRender: (index, total) => {
                                                            const imageCount14 = carimage14?.length || 0; // นับจำนวนภาพจริง
                                                            const displayedIndex = Math.min(index, imageCount14);
                                                            const displayTotal = Math.max(imageCount14, imageCount14);
                                                            return `${displayedIndex} / ${displayTotal}`;
                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                        toolbarRender: (
                                                            _,
                                                            {
                                                                transform: { scale },
                                                                actions: {
                                                                    onActive,
                                                                    onFlipY,
                                                                    onFlipX,
                                                                    onRotateLeft,
                                                                    onRotateRight,
                                                                    onZoomOut,
                                                                    onZoomIn,
                                                                    onReset,
                                                                },
                                                            },
                                                        ) => (
                                                            <>
                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                    {carimage14?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={auto}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                                <Row style={{ zIndex: 2 }}>
                                                                    <Space size={12} className="toolbar-wrapper">
                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                        <DownloadOutlined onClick={onDownload} />
                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                        <SwapOutlined onClick={onFlipX} />
                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                        <UndoOutlined onClick={onReset} />
                                                                    </Space>
                                                                </Row>
                                                            </>
                                                        ),
                                                        onChange: (index) => {
                                                            setCurrent(index);
                                                        },
                                                    }}
                                                >
                                                    <Row gutter={32} justify={'center'}>
                                                        {carimage14?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={150}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                </Image.PreviewGroup>
                                            </Row>
                                        </>
                                }
                            </Card>
                        </div>
                    </Form>
                </Row>
            </Spin >
            {contextHolder}
            {
                checkOpen ?
                    <Installment
                        open={checkOpen}
                        close={setCheckOpen}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                checkOpenHIRE ?
                    <HirePurchase
                        open={checkOpenHIRE}
                        close={setCheckOpenHIRE}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }

            {
                modalConfirm ?
                    <ModalCusConfirm
                        open={modalConfirm}
                        close={setModalConfirm}
                        closeAll={close}
                        dataFromTable={dataFromTable}
                        oldData={oldData}
                        typeLoan={typeLoan}
                    />

                    : null
            }
            {
                isModalEditBroker ?
                    <ModalEditBroker
                        open={isModalEditBroker}
                        close={setIsModalEditBroker}
                        // closeAll={close} 
                        dataFromTable={dataBroker.customerBroker}
                        setResetPage={setResetPage}
                    />
                    : null
            }
            {
                isModalMainMix ?
                    <ModalMainMix
                        open={isModalMainMix}
                        close={setIsModalMainMix}
                        funcAdd={funcAdd}

                        dataEdit={dataEdit}
                        dataEditIndex={dataEditIndex}
                        checkEdit={checkEdit}
                        setCheckEdit={setCheckEdit}
                        funcEdit={funcEdit}
                        dataForCheckDup={dataFromTable}
                        setDataEdit={setDataEdit}
                        setDataEditIndex={setDataEditIndex}
                        newDataLand={dataLandAppLand.findLandPN}
                    />
                    : null
            }
            {
                openShowImage ?
                    <ModalShowImages
                        open={openShowImage}
                        close={setOpenShowImage}
                        carimage1={carshowMadol}
                        nuarantorNew={nuarantorNew}
                        nuarantorNum={nuarantorNum}
                        setGuarantorNum={setGuarantorNum}
                    />

                    : null
            }
        </Modal >
    )
}

export default ModalInfoMK