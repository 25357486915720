import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Checkbox, Tooltip, notification } from 'antd'
import { PrinterOutlined, ReloadOutlined, TagOutlined, TagsOutlined } from '@ant-design/icons'
import Currency from 'currency.js';
import dayjs from 'dayjs';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { addGuarantor } from './../../../redux/Guarantor';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import { Installment } from '../../offer_case/ploan/Installment';
import { irr } from 'node-irr'
import '../../css/Media.css'
import TextArea from 'antd/es/input/TextArea';
// import Note from './AddToApprove/Note';
import {
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from "async";
import {
    checkgu, findcarpg, findlandpg, findlandpn, loadOJS, getImagess, getImagessPre,
    getAllFolderGuaImg, detailBroker, getcontnoJojonoi, alldataland1, detaillandchangepg,
    detailcarchangepg, updatere1, getImagessGua,
    loadDetailAunSendPG
} from './../all_api';
import ModalPreemptionCertificateNEW from '../../document/ModalPreemptionCertificateNEW';
import ModalDrescriptionPrint from '../../check_info_PG/modals/AddToApprove/ModalDrescriptionPrint';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import ModalAddContractNumber from '../../check_info_ad/modals/ModalAddContractNumber';
import Note from '../../check_info_ad/modals/AddToApprove/Note';
import ModalPreApproveEDIT from '../../check_info_ad/modals/ModalPreApproveEDIT';
import NoteChangeStatus from '../note/NoteChangeStatus';
import { checkApprovalStatus, checkProductLoanType } from '../condition';
import NoteRe from '../../check_info_PG_RE/modals/NoteRe';
import ModalInstallment from '../../check_info_PG_RE/modals/ModalInstallment';
import ModalEditDate from '../../check_info_PG_RE/modals/ModalEditDate';
import { colorApprovalStatusNEW } from '../status_color';
import Edit_Accept from '../edit_approve_accept/Edit_Accept';
import ModalShowImages from '../../ModalShowImageSlide/ModalShowImages'
import { productLoanTypeLand } from '../all_options';


function ModalInfoADPGDC({ open, dataFromTable, close, typeLoans, checkPosi }) {
    const dispatch = useDispatch()
    // const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const idSep = localStorage.getItem('idSep');
    const username = localStorage.getItem('username');
    const idDepartment = localStorage.getItem('idDepartment');
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();

    //Print
    const [modalDrescriptionPrint, setModalDrescriptionPrint] = useState(false); // Info Print
    const [modalPreemptionCertificateNEW, setModalPreemptionCertificateNEW] = useState(false); // Info Print

    // คำนวนรถ
    const [loanAmountt, setLoanAmountt] = useState();
    const [installmentCount, setInstallmentCount] = useState()

    // p-loan
    const [arr, setArr] = useState()
    const [interestt, setInterestt] = useState(0.0)
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)

    // เช่าซื้อ
    const [resultRateCarDATA, setresultRateCarDATA] = useState({})
    const [newLoanTermCarDATA, setnewLoanTermCarDATA] = useState({})
    const [newResultPriceCarDATA, setnewResultPriceCarDATA] = useState({})

    const [checkOpen, setCheckOpen] = useState(false)
    const [checkOpenHIRE, setCheckOpenHIRE] = useState(false)

    // คำนวนที่ดิน
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})

    const [approval, setApproval] = useState();
    const [monthlyPaymentCAL, setMonthlyPaymentCAL] = useState();
    const [installmentWithInterestCAL, setInstallmentWithInterestCAL] = useState();
    const [checkSendNEW, setCheckSendNEW] = useState(false);

    const [checkBroker, setCheckBroker] = useState(false)
    const [dataBroker, setDataBroker] = useState([]);
    const [brokerName, setbrokerName] = useState()
    const [data627, setData627] = useState()
    const [data630, setData630] = useState()
    const [mixBroker, setMixBroker] = useState()
    const [isModalOpenAddNumber, setIsModalOpenAddNumber] = useState(false);
    const [appStatus, setAppStatus] = useState({})
    const [modalnote, setModalNote] = useState(false)
    const [resetpage, setResetpage] = useState();
    const [openPreeApprove, setOpenPreeApprove] = useState(false);

    //เก็บข้อมูลแบบใหม่
    const [dataCustomer, setDataCustomer] = useState()  // เก็บข้อมูล ลูกค้า
    const [dataAddress, setDataAddress] = useState([])  // เก็บข้อมูล ที่อยู่
    const [dataCareer, setDataCareer] = useState([])    // เก็บข้อมูล อาชีพ
    const [dataLoan, setDataLoan] = useState()          // เก็บข้อมูล loan
    const [dataOldLoan, setDataOldLoan] = useState()    // เก็บข้อมูล oldloan
    const [dataLoanRe, setDataLoanRe] = useState()      // เก็บข้อมูล loanRe
    const [dataAP, setDataAP] = useState()              // เก็บข้อมูล ap
    const [dataCarLand, setDataCarLand] = useState()    // เก็บข้อมูล carland
    const [dataCarLands, setDataCarLands] = useState()    // เก็บข้อมูล carlands
    const [dataCheckGua, setDataCheckGua] = useState()  // เก็บข้อมูล checkGua
    const [dataCheckbox, setDataCheckbox] = useState()  // เก็บข้อมูล checkbox
    const [dataGuarantor, setDataGuarantor] = useState([]) // เก็บข้อมูล อาชีพ
    const [dataNote, setDataNote] = useState([])        // เก็บข้อมูล note
    const [dataNoteRe, setDataNoteRe] = useState([])    // เก็บข้อมูล noteRe
    const [dataImg, setDataImg] = useState([])          // เก็บข้อมูล รูป
    const [dataApproval, setDataApproval] = useState()  // เก็บข้อมูล พี่หนุ่ม
    const [dataEmail, setDataEmail] = useState([])      // เก็บข้อมูล email
    const [dataPhones, setDataPhones] = useState([])    // เก็บข้อมูล เบอร์โทร
    const [dataSocials, setDataSocials] = useState([])  // เก็บข้อมูล Social
    const [dataLandArray, setDataLandArray] = useState([])  // เก็บข้อมูล LandArray
    const [dataCarAun, setDataCarAun] = useState()  // เก็บข้อมูล aun car
    const [checkCancelCase, setCheckCancelCase] = useState(false)
    const [newOfferDate, setNewOfferDate] = useState()
    const [currentDate, setCurrentDate] = useState()
    const [numberRe, setNumberRe] = useState()
    const [dataCont, setDataCont] = useState()
    const [dataChange, setDataChange] = useState()
    const [dataContno, setDataContno] = useState()
    const [beeypupLand, setBeeypupLand] = useState()

    //Modal
    const [isModalNoteChangeStatus, setIsModalNoteChangeStatus] = useState(false)  // เปิด Modal แก้ไขสถานะ
    const [isModalAccept, setIsModalAccept] = useState(false)  // เปิด Modal แก้ไขยอดอนุมัติ
    const [isModalEditDate, setIsModalEditDate] = useState(false)
    const [isModalCancelCase, setIsModalCancelCase] = useState(false)
    const [isModalTable, setIsModalTable] = useState(false)
    const [isModalUpDateRe, setIsModalUpDateRe] = useState(false)

    const [typeOpenNote, setTypeOpenNote] = useState()
    const [loading, setLoading] = useState(false);

    const [moonnee, setMoonnee] = useState()
    const [hug, setHug] = useState()
    const [kong, setKong] = useState()
    const [jaimoney, setJaimoney] = useState()
    const [yodjut, setYodjut] = useState()
    const [dateceo, setDateceo] = useState()
    const [exp, setExp] = useState()
    const [ngod, setNgod] = useState()
    const [pon, setPon] = useState()
    const [dataApproved, setDataApproved] = useState();
    // image
    const [openShowImage, setOpenShowImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // แยก type image car
    const [carimage1, setCarImage1] = useState([]);
    const [carimagenew1, setCarImagenew1] = useState([]);
    const [carimage2, setCarImage2] = useState([]);
    const [carimagenew2, setCarImagenew2] = useState([]);
    const [carimage3, setCarImage3] = useState([]);
    const [carimagenew3, setCarImagenew3] = useState([]);
    const [carimage4, setCarImage4] = useState([]);
    const [carimagenew4, setCarImagenew4] = useState([]);
    const [carimage5, setCarImage5] = useState([]);
    const [carimagenew5, setCarImagenew5] = useState([]);
    const [carimage6, setCarImage6] = useState([]);
    const [carimagenew6, setCarImagenew6] = useState([]);
    const [carimage7, setCarImage7] = useState([]);
    const [carimagenew7, setCarImagenew7] = useState([]);
    const [carimage8, setCarImage8] = useState([]);
    const [carimagenew8, setCarImagenew8] = useState([]);
    const [carimage9, setCarImage9] = useState([]);
    const [carimagenew9, setCarImagenew9] = useState([]);
    const [carimage10, setCarImage10] = useState([]);
    const [carimagenew10, setCarImagenew10] = useState([]);
    const [carimage11, setCarImage11] = useState([]);
    const [carimagenew11, setCarImagenew11] = useState([]);
    const [carimage12, setCarImage12] = useState([]);
    const [carimagenew12, setCarImagenew12] = useState([]);
    const [carimage14, setCarImage14] = useState([]);
    const [carimagenew14, setCarImagenew14] = useState([]);
    const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
    const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
    const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [current, setCurrent] = React.useState(0);
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        if (typeLoans === "land") {
            loadMixData()
        }
        loadData().then(async () => {
            await loadGuarantors()
            await loadCareer()
        })
        if (typeLoans === "car") {
            loadBroker()
        }
    }, [resetpage])

    useEffect(() => {
        dataLandArray.map((e, index) => {
            form.setFieldsValue({
                [`mixNote${index}`]: e?.approval?.mixNote,
                // [`storyLand${index}`]: e?.storyLand,
            })
        })
    }, [dataLandArray])

    useEffect(() => {
        if (dataBroker?.length > 0) {
            setCheckBroker(true)
            dataBroker?.map((data) => {
                if (data.snam !== "" || data.snam !== null || data.snam !== undefined) {
                    // console.log("name", data.payamt)
                    setbrokerName(data?.snam + data?.firstname + " " + data?.lastname)
                }
                if (data.payfor === "630") {
                    // console.log("map630", data.payamt)
                    setData630(data.payamt)
                }
                if (data.payfor === "627") {
                    // console.log("map627", data.payamt)
                    setData627(data.payamt)
                }
                return null
            })
        } else {
            setCheckBroker(false)
        }

    }, [dataBroker])

    useEffect(() => {
        dataImg?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
        loadImage(typeLoans)
    }, [dataImg])


    const onDownload = () => {
        const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
        const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

        // ใช้ fetch ดึงรูปภาพจาก path
        fetch(imageObj.path)
            .then((response) => response.blob()) // เปลี่ยน response เป็น blob
            .then((blob) => {
                // สร้าง Blob URL สำหรับการดาวน์โหลด
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
                document.body.appendChild(link);
                link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
                URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
                link.remove(); // ลบ link ออกจาก DOM
            })
            .catch((error) => {
                console.error("Error downloading the image:", error);
            });
    };

    const handleSelectImage = (e, image) => {
        // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
        setSelectedImages(prevSelected => [...prevSelected, image]);
    };

    function extractFolderGroup(path) {
        const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
        return match ? match[1] : null;
    }

    const loadImage = async (value) => {
        var carimg1 = [];
        var carimg2 = [];
        var carimg3 = [];
        var carimg4 = [];
        var carimg5 = [];
        var carimg6 = [];
        var carimg7 = [];
        var carimg8 = [];
        var carimg9 = [];
        var carimg10 = [];
        var carimg11 = [];
        var carimg12 = [];
        var carimg14 = [];
        var carimgnew1 = [];
        var carimgnew2 = [];
        var carimgnew3 = [];
        var carimgnew4 = [];
        var carimgnew5 = [];
        var carimgnew6 = [];
        var carimgnew7 = [];
        var carimgnew8 = [];
        var carimgnew9 = [];
        var carimgnew10 = [];
        var carimgnew11 = [];
        var carimgnew12 = [];
        var carimgnew14 = [];
        if (value === "car") {
            setImageBlobzz(dataImg)
            dataImg?.map((car, i) => {
                if (car.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 1) {
                        carimg1.push(car)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 1) {
                        carimgnew1.push(car)
                        setCarImagenew1(carimgnew1)
                    }
                    // carimg1.push(car)
                    // setCarImage1(carimg1)
                } else if (car.type === 2) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 2) {
                        console.log("22", car)
                        carimg2.push(car)
                        setCarImage2(carimg2)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 2) {
                        carimgnew2.push(car)
                        setCarImagenew2(carimgnew2)
                    }
                    // carimg2.push(car)
                    // setCarImage2(carimg2)
                } else if (car.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 4) {
                        carimg4.push(car)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 4) {
                        carimgnew4.push(car)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(car)
                    // setCarImage4(carimg4)
                } else if (car.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(car);
                    if (group === '1') {
                        group1.push({ ...car, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...car, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...car, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...car, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...car, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...car, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (car.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 6) {
                        carimg6.push(car)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 6) {
                        carimgnew6.push(car)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(car)
                    // setCarImage6(carimg6)
                } else if (car.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 7) {
                        carimg7.push(car)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 7) {
                        carimgnew7.push(car)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(car)
                    // setCarImage7(carimg7)
                } else if (car.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 8) {
                        carimg8.push(car)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 8) {
                        carimgnew8.push(car)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(car)
                    // setCarImage8(carimg8)
                } else if (car.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 9) {
                        carimg9.push(car)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 9) {
                        carimgnew9.push(car)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(car)
                    // setCarImage9(carimg9)
                } else if (car.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 14) {
                        carimg14.push(car)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 14) {
                        carimgnew14.push(car)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(car)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 12) {
                        carimg12.push(car)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 12) {
                        carimgnew12.push(car)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(car)
                    // setCarImage12(carimg12)
                }
            })
        } else {
            setImageBlobzz(dataImg)
            dataImg?.map((land, i) => {
                if (land.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
                        carimg1.push(land)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
                        carimgnew1.push(land)
                        setCarImagenew1(carimgnew4)
                    }
                    // carimg1.push(land)
                    // setCarImage1(carimg1)
                } else if (land.type === 3) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
                        carimg3.push(land)
                        setCarImage3(carimg3)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
                        carimgnew3.push(land)
                        setCarImagenew3(carimgnew3)
                    }
                    // carimg3.push(land)
                    // setCarImage3(carimg3)
                } else if (land.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
                        carimg4.push(land)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
                        carimgnew4.push(land)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(land)
                    // setCarImage4(carimg4)
                } else if (land.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(land);
                    if (group === '1') {
                        group1.push({ ...land, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...land, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...land, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...land, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...land, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...land, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (land.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
                        carimg6.push(land)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
                        carimgnew6.push(land)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(land)
                    // setCarImage6(carimg6)
                } else if (land.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
                        carimg7.push(land)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
                        carimgnew7.push(land)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(land)
                    // setCarImage7(carimg7)
                } else if (land.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
                        carimg8.push(land)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
                        carimgnew8.push(land)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(land)
                    // setCarImage8(carimg8)
                } else if (land.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
                        carimg9.push(land)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
                        carimgnew9.push(land)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(land)
                    // setCarImage9(carimg9)
                } else if (land.type === 10) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
                        carimg10.push(land)
                        setCarImage10(carimg10)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
                        carimgnew10.push(land)
                        setCarImagenew10(carimgnew10)
                    }
                    // carimg10.push(land)
                    // setCarImage10(carimg10)
                } else if (land.type === 11) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
                        carimg11.push(land)
                        setCarImage11(carimg11)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
                        carimgnew11.push(land)
                        setCarImagenew11(carimgnew11)
                    }
                    // carimg11.push(land)
                    // setCarImage11(carimg11)
                } else if (land.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
                        carimg14.push(land)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
                        carimgnew14.push(land)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(land)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
                        carimg12.push(land)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
                        carimgnew12.push(land)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(land)
                    // setCarImage12(carimg12)
                }
            })
        }
    }


    const loadMixData = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.loanId })
            .then(res => {
                if (res.status === 200) {
                    if (dataFromTable?.approvalStatus === "รับคำร้อง" ||
                        dataFromTable?.approvalStatus === "คำร้องไม่ถูกต้อง" ||
                        dataFromTable?.approvalStatus === "ปฏิเสธคำร้อง") {
                        res.data.map((e) => {
                            if (e?.landId === dataFromTable?.carId) {
                                setDataLandArray([e])
                            } else {
                                return null
                            }
                        })
                    } else {
                        setDataLandArray(res.data)
                    }
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadBroker = async () => {
        const result = { loanId: dataFromTable.loanId }
        await axios.post(detailBroker, result)
            .then((res) => {
                if (res.data) {
                    setDataBroker(res.data)
                }
            })
            .catch((err) => console.log(err))
    }

    // const printdrescription = () => {
    //     setModalDrescriptionPrint(true)
    // }

    const newPrint = () => {
        setMixBroker({ checkBroker, data627, data630, brokerName, })
        setModalPreemptionCertificateNEW(true)
    }

    const handleOpenChangeStatus = () => {
        setTypeOpenNote("STA")
        setIsModalNoteChangeStatus(true)
    }

    const handleOpenChangeStatusPLT = () => {
        setTypeOpenNote("PLT")
        setIsModalNoteChangeStatus(true)
    }
    const handleOpenEditApproveAccept = () => {
        setTypeOpenNote("EDA")
        setIsModalAccept(true)
    }

    const loadCareer = async () => {
        const id = { customerId: dataFromTable.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                setDataCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadGuarantors = async () => { //คนค้ำ
        await axios.post(checkgu, { typeLoan: typeLoans, loanId: dataFromTable?.loanId })
            .then(res => {
                if (res.status === 200) {
                    setDataGuarantor(res.data)
                    // getImg()
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    // const getImg = async () => {
    //     var path = `one_images/${typeLoans}/${dataFromTable?.carId}/5`
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         // await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`, {
    //         //     responseType: 'blob'  // ตั้ง responseType เป็น blob
    //         // })
    //         .then(res => {
    //             console.log("getImg res.data", res.data)
    //             if (res.data) {
    //                 setGetImages(res.data)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //         })
    // }

    const loadDataReAun = async () => {
        setLoading(true);
        // await axios.post(loadDetailAunSendPG, { contno: isu})
        await axios
            .post(loadDetailAunSendPG, { contno: dataFromTable.ISSUNO })
            .then((res) => {
                // if (res.status === 200) {
                // console.log("res.dataNewPageAun", res.data);
                setDataApproved(res?.data?.carsApproveve);
                // setDataDB2(res.data?.dataDB2);
                // calData(res?.data?.carsApproveve);
                setLoading(false);
                // }
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        loadDataReAun();
    }, []);

    const loadData = async () => {
        if (typeLoans === "car") {
            await axios.post(findcarpg, { carId: dataFromTable?.carId, customerId: dataFromTable?.customerId })
                .then(async res => {
                    if (res.status === 200) {
                        // console.log("CarCar ", res.data)
                        if (res.data.cars.apCarLoanDetails.loanId !== 0) {
                            setLoanAmountt(parseInt(res.data.cars.apCarLoanDetails.apLoanAmount))
                            setInstallmentCount(parseInt(res.data.cars.apCarLoanDetails.apLoanTerm))
                        } else {
                            setLoanAmountt(parseInt(res.data.cars.carLoanDetails.approvedLoanAmount))
                            setInstallmentCount(parseInt(res.data.cars.carLoanDetails.approvedLoanTerm))
                        }
                        if (res.data.cars.carMemo) {
                            form.setFieldsValue(
                                {
                                    ...res.data.cars
                                }
                            )
                        }
                        setApproval(res.data.cars.carLoanDetails.approvalStatus)


                        setDataCustomer(res.data)
                        setDataAddress(res.data.address)
                        // setDataCareer()
                        setDataOldLoan(res.data.cars.carOldLoanDetails)
                        setDataLoan(res.data.cars.carLoanDetails)
                        setDataLoanRe(res.data.cars.carLoanDetailsRe)
                        setDataAP(res.data.cars.apCarLoanDetails)
                        setDataCarLand(res.data.cars)
                        setDataCarLands(res.data)
                        setDataCheckGua(res.data.cars.carLoanDetails.checkGua)
                        setDataCheckbox(res.data.cars.carLoanDetails.checkbox)
                        // setDataGuarantor()
                        setDataNote(res.data.cars.carLoanDetails.notes)
                        setDataNoteRe(res.data.cars.carLoanDetailsRe.notes)
                        setDataImg(res.data.cars.img)
                        setDataPhones(res.data.phones)
                        setDataEmail(res.data.email)
                        setDataSocials(res.data.socials)

                        if (res.data?.cars?.carLoanDetailsRe?.reLoanId > 0) {
                            setNewOfferDate(dayjs(res.data?.cars?.carLoanDetailsRe?.newOfferDate).startOf('day')) //แบบนับแต่วันที่
                            setCurrentDate(dayjs().startOf('day')) //แบบนับแต่วันที่
                        }

                        if (res?.data?.cars?.carLoanDetailsRe?.approvalStatus === "รับคำร้อง" && (dayjs(res?.data?.cars?.carLoanDetailsRe?.ftime).add(14, 'day') <= dayjs())) {
                            setCheckCancelCase(true)
                        }
                        setDataCarAun(res.data?.cars?.carsApproveCeo)
                        calYellowCard(res.data?.cars?.carsApproveCeo)
                        await handleCheckCal(res.data.cars)

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            await axios.post(findlandpg, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async res => {
                    if (res.status === 200) {
                        // console.log("LandLand", res.data)
                        setApproval(res.data.lands.landLoanDetails.approvalStatus)

                        setDataCustomer(res.data)
                        setDataAddress(res.data.address)
                        // setDataCareer()
                        setDataOldLoan(res.data.lands.landOldLoanDetails)
                        setDataLoan(res.data.lands.landLoanDetails)
                        setDataLoanRe(res.data.lands.landLoanDetailsRe)
                        setDataAP(res.data.lands.apLandLoanDetails)
                        setDataCarLand(res.data.lands)
                        setDataCarLands(res.data)
                        setDataCheckGua(res.data.lands.landLoanDetails.checkGua)
                        setDataCheckbox(res.data.lands.landLoanDetails.checkbox)
                        // setDataGuarantor()
                        setDataNote(res.data.lands.landLoanDetails.notes)
                        setDataNoteRe(res.data.lands.landLoanDetailsRe.notes)
                        setDataImg(res.data.lands.img)
                        setDataApproval(res.data.lands.approval)
                        setDataPhones(res.data.phones)
                        setDataEmail(res.data.email)
                        setDataSocials(res.data.socials)

                        if (res.data?.lands?.landLoanDetailsRe?.reLoanId > 0) {
                            setNewOfferDate(dayjs(res.data?.lands?.landLoanDetailsRe?.newOfferDate).startOf('day')) //แบบนับแต่วันที่
                            setCurrentDate(dayjs().startOf('day')) //แบบนับแต่วันที่
                        }
                        if (res?.data?.lands?.landLoanDetailsRe?.approvalStatus === "รับคำร้อง" && (dayjs(res?.data?.lands?.landLoanDetailsRe?.ftime).add(14, 'day') <= dayjs())) {
                            setCheckCancelCase(true)
                        }
                        await handleCheckCal(res.data?.lands)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }

    const calYellowCard = (value) => {
        // console.log("calYellowCard", value)
        // มูลหนี้ balanc
        // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
        // คงเหลือ มูลหนี้ - หักส่วนลด
        // จ่ายเงิน (newDamt+followPay)
        // ยอดจัด approvedLoanAmount
        // วันที่ approvalDate
        // ใบนี้หมดอายุ ณ วันที่ newOfferDate
        // จำนวนงวด pgLoanTerm
        // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
        // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

        setMoonnee(currencyFormatOne(~~value?.balanc))
        setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
        setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
        setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
        setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
        setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
        setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
        setNgod(~~value?.pgLoanTerm)
        setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
    }

    const loadDataContno = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${dataCarLand?.ISSUNO}`)
            .then(async res => {
                if (res.data) {
                    const result = Number(Math.round(Math.min(res.data?.re1, res.data?.re2)))
                    setNumberRe(String(result))
                    // console.log("result", numberRe)
                    // console.log("loadDataContno", res.data.re1)
                    // if (parseFloat(res.data?.re1) < parseFloat(res.data?.re2)) {
                    //     // console.log("re1 น้อยกว่า re2");
                    //     setNumberRe(res.data?.re1)
                    // } else if (parseFloat(res.data?.re2) < parseFloat(res.data?.re1)) {
                    //     // console.log("re2 น้อยกว่า re1");
                    //     setNumberRe(res.data?.re2)
                    // } else {
                    //     // console.log("re1 เท่ากับ re2");
                    //     setNumberRe(res.data?.re1)
                    // }
                    setDataCont(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: dataCarLand?.ISSUNO })
            .then(res => {
                if (res.data) {
                    // console.log("loadDataContnoLand", res.data)
                    // setDataLand(res.data?.Data2)
                    setDataCont(res.data?.Data2)
                    setNumberRe(res.data?.Data2?.re1)
                    setDataContno(res.data?.Data1)
                    setBeeypupLand(res.data?.beeypup)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const handleUpdateRe = async () => {
        let dataUpdate
        if (typeLoans === "car") {
            dataUpdate = {
                ...dataCont?.bu1[0],
                ...dataCont?.custmast[0],
                reqNo: dataCont?.bu1[0].req_no,
                re1: numberRe,
                ...dataChange?.detailChange,
                nopays: dataCont?.bu1[0].nopay,
                typeLoan: typeLoans,
                reLoanId: dataLoanRe?.reLoanId,
                carId: dataCarLand?.carId,
                customerId: dataCustomer?.customerId,
                productLoanType: dataLoan?.productLoanType,
                loanId: dataLoan?.loanId,
            }
        } else {
            dataUpdate = {
                ...dataCont,
                ...dataChange?.detailChange,
                ...dataContno[0],
                typeLoan: typeLoans,
                reLoanId: dataLoanRe?.reLoanId,
                carId: dataCarLand?.landId,
                customerId: dataCustomer?.customerId,
                productLoanType: dataLoan?.productLoanLandType,
                beeypup: beeypupLand,
                loanId: dataLoan?.landLoanId,
            }
        }

        await axios.put(updatere1, dataUpdate)
            .then(async res => {
                if (res.status === 200) {
                    updateReSucc('top')
                    setResetpage(dayjs())
                    setTimeout(() => {
                        setIsModalUpDateRe(false)
                    }, 1500)
                }
            }).catch((err) => {
                updateReError('top')
                console.log("err", err)
            })
    };

    const showConfirmUpdateRe = () => {
        if (typeLoans === "car") {
            loadDataContno()
            if (dataLoanRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanType === "เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanType === "รี+เปลี่ยนสัญญา") {
                handleLoadChangeCar()
            }
        } else {
            loadDataContnoLand()
            if (dataLoanRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanLandType === "เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                dataLoanRe?.productLoanLandType === "รี+เปลี่ยนสัญญา") {
                handleLoadChangeLand()
            }
        }
        setIsModalUpDateRe(true)
    };

    const handleLoadChangeLand = async () => {
        await axios.post(detaillandchangepg, { CONTNO: dataCarLand?.ISSUNO })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        // console.log("handleLoadChangeLand", res.data)
                        setDataChange(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };
    const handleLoadChangeCar = async () => {
        await axios.post(detailcarchangepg, { CONTNO: dataCarLand?.ISSUNO })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        // console.log("handleLoadChangeCar", res.data)
                        setDataChange(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };
    const handleCheckCal = async (value) => {
        if (typeLoans === "car") {
            if (value.carLoanDetails?.productType === "p-loan") {
                // console.log("1 = รถเล็ก p-loan")
                await handleCalculate(value)
            } else if (value.carLoanDetails?.productType === "เช่าซื้อ") {
                if (value.productTypeCar === 1) {
                    // console.log("2 = รถเล็ก เช่าซื้อ")
                    await handleCalculateHIRE(value)
                } else {
                    // console.log("3 = รถใหญ่ เช่าซื้อ")
                    await car_Calculate(value)
                }
            } else if (value.carLoanDetails?.productType === "เช่าซื้อ1") {
                // console.log("4 = รถเล็ก เช่าซื้อ .84")
                await car_Calculate(value)
            }
        } else if (typeLoans === "land") {
            // console.log("5 = ที่ดิน")
            await land_Calculate(value)
        }
    };
    const openTable = () => {
        setCheckOpen(true)
    };
    const openTable2 = () => {
        setCheckOpenHIRE(true)
    };
    const handleCalculate = async (value) => { //กรณี p-loan

        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm

        if (LA && LT) {
            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
                return null;
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
                return null;
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = LA //ยอดทั้งหมด
                // var totalInterest = 0.0 // ดอกเบี้ย
                var result = LA
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    // let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    // let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    // let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    TotalPrincipleBalance -= principleAmount
                }
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)

                setMonthlyPaymentCAL(installmentAmount)
                setInstallmentWithInterestCAL(result)
            }
        }
    }
    const car_Calculate = async (value) => {
        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm
        var IR = value.carLoanDetails.interestRate

        //แก้ใหม่
        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด


        setresultRateCarDATA(resultRateCar)
        setnewLoanTermCarDATA(newLoanTermCar)
        setnewResultPriceCarDATA(newResultPriceCar)

        setArr(resultRateCar)
        setInterestt(newLoanTermCar)
        setInstallmentWithInterestt(newResultPriceCar)

        setMonthlyPaymentCAL(resultRateCar)
        setInstallmentWithInterestCAL(newResultPriceCar)
    }
    const handleCalculateHIRE = async (value) => {

        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm

        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
                return null;
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
                // var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~LA
                var result = amountWithVat
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    // let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    // let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย

                    TotalPrincipleBalance -= principleAmount
                }
                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)

                setMonthlyPaymentCAL(installmentAmount)
                setInstallmentWithInterestCAL(result)
            }
        }
    }
    const land_Calculate = async (value) => {

        var LA = value.landLoanDetails.approvedLoanAmount
        var LT = value.landLoanDetails.approvedLoanTerm
        var IR = value.landLoanDetails.interestRateLand

        var interestRateLand = parseFloat(IR) / 100 // อัตราดอก / 100
        var rate = (parseFloat(LA) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(LA)) / parseInt(LT), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(LT)) - parseFloat(LA)
        var resultPrice = loanTerm * parseInt(LT)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)

        setMonthlyPaymentCAL(loanTerm)
        setInstallmentWithInterestCAL(resultPrice)
    }
    const handleOk = async () => {
        close(false);
    };
    const handleCancel = () => {
        dispatch(addGuarantor())
        close(false);
    };
    const handleCancelUpdateRe = () => {
        setIsModalUpDateRe(false);
    };
    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const showConfirmCancel = async () => {
        setAppStatus("cancel")
        setModalNote(true)
    }
    const handleOpenAddNumber = () => {
        setIsModalOpenAddNumber(true)
    };
    const openModal = () => {
        setOpenPreeApprove(true)
    };
    const handleCancelCase = () => {
        setIsModalCancelCase(true)
    }
    const handleOpenEdit = () => {
        setIsModalEditDate(true)
    }
    const handleOpenTable = () => {
        setIsModalTable(true)
    }
    const updateReSucc = (placement) => {
        api.success({
            message: <b>อัพเดทสำเร็จ</b>,
            placement,
        });
    }
    const updateReError = (placement) => {
        api.error({
            message: <b>อัพเดทไม่สำเร็จ</b>,
            placement,
        });
    }
    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        var itemSplit = []
        var itemSplitnum = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        carimage5?.map((e, i) => {
            const segments = e?.pathImage.split("/")
            if (itemSplit[4] === segments[4]) {
                itemSplitnum.push(e)
            }
        })
        const count = itemSplitnum.length || 0;

        return (
            <Card>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam} {item?.firstname} {item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {/* <Divider/> */}
                {
                    itemSplit.length > 0 ? (
                        <>
                            <Row justify={"center"}>
                                <Image.PreviewGroup
                                    preview={{
                                        countRender: (index, total) => {
                                            const displayedIndex = Math.min(index, count);
                                            const displayTotal = Math.max(count, count);
                                            return `${displayedIndex} / ${displayTotal}`;
                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                        toolbarRender: (
                                            _,
                                            {
                                                transform: { scale },
                                                actions: {
                                                    onActive,
                                                    onFlipY,
                                                    onFlipX,
                                                    onRotateLeft,
                                                    onRotateRight,
                                                    onZoomOut,
                                                    onZoomIn,
                                                    onReset,
                                                },
                                            },
                                        ) => (
                                            <>
                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                    {carimage5?.map((e, index) => {
                                                        const segments = e?.pathImage.split("/")
                                                        if (itemSplit[4] === segments[4] && e.type === 5) {
                                                            return (
                                                                <Image
                                                                    width={auto}
                                                                    key={index}
                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                    src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                />
                                                            )
                                                        }
                                                        return null;
                                                    }
                                                    )}
                                                </Row>
                                                <Row style={{ zIndex: 2 }}>
                                                    <Space size={12} className="toolbar-wrapper">
                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                        <DownloadOutlined onClick={onDownload} />
                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                        <SwapOutlined onClick={onFlipX} />
                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                        <UndoOutlined onClick={onReset} />
                                                    </Space>
                                                </Row>
                                            </>
                                        ),
                                        onChange: (index) => {
                                            setCurrent(index);
                                        },
                                    }}
                                >
                                    <Row gutter={32} justify={'center'}>
                                        {carimage5?.map((e, index) => {
                                            const segments = e?.pathImage.split("/")
                                            if (itemSplit[4] === segments[4] && e.type === 5) {
                                                return (
                                                    <Image
                                                        width={150}
                                                        key={index}
                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                        src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index + 1}`}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                )
                                            }
                                            return null;
                                        }
                                        )}
                                    </Row>
                                </Image.PreviewGroup>
                            </Row>
                        </>
                    ) : null
                }
            </Card>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>

                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>

                </aside>
                {/* {address?.length >= 1 ? <Divider /> : null} */}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div style={{ marginBottom: 0 }}>
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }
    const renderItemCareer = ({ item }) => {
        return (
            <Row gutter={32} justify={'center'}>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0, }}>
                        <span>กลุ่มอาชีพ : </span>
                        <b>{item?.desc}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                        <b>{item?.companyName}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>อาชีพ  : </span>
                        <b>{item?.descSub}</b>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>รายได้ / เดือน : </span>
                        <b>{currencyFormatOne(item?.incomeMonth)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0, }}>
                        <span>สาขาอาชีพ : </span>
                        <b>{item?.descSection}</b>
                    </div>

                    <div style={{ marginBottom: 0, }}>
                        <span>
                            รายจ่าย / เดือน : </span>
                        <b>{currencyFormatOne(item?.expensesMonth)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                        </span>
                    </div>
                </aside>
            </Row>
        )
    }
    const renderDataNote = ({ item }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <Card style={{ width: '270px', textAlign: 'left' }}>
                            <div style={{ marginBottom: 0 }}>
                                <span>สาเหตุ : </span>
                                <b>{item.cause}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>หมายเหตุ : </span>
                                <b>{item.note}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>ผู้สร้างหมายเหตุ : </span>
                                <b>{item.noteBy}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                <b>{item.status}</b>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandData = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {dataLandArray?.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            ไร่
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งาน
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท / ตารางวา
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                </aside>
                            </Row>
                        </>
                    </Col>
                </Row>
            </>
        )

    }
    const renderApproval = ({ item, index }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{item?.approval?.minPrice ? currencyFormatOne(item?.approval?.minPrice) : 0} - {item?.approval?.maxPrice ? currencyFormatOne(item?.approval?.maxPrice) : 0}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note ? item?.approval?.note : "-"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุการจดรวม : </span>{<b>{item?.approval?.mixNote ? item?.approval?.mixNote : "-"}</b>}
                    </div>
                </aside>
            </>
        )
    }
    const renderMixNote = ({ item, index }) => {
        return (
            <>
                {
                    index === 0 ?
                        null :
                        <>
                            {/* <Card style={{ width: '1000px' }}> */}
                            <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={24}>
                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                                        <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            {/* </Card> */}
                        </>
                }
            </>
        )
    }

    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            key={index}
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts3 = carimage3?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts3);
                                    const displayTotal = Math.min(imageCounts3, imageCounts3);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive, // support after 5.21.0
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage3?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (indexs) => {
                                    setCurrent(indexs);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage3?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        key={index}
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCounts3 = carimagenew3?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCounts3);
                                                const displayTotal = Math.min(imageCounts3, imageCounts3);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive, // support after 5.21.0
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew3?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (indexs) => {
                                                setCurrent(indexs);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew3?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            key={index}
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts10 = carimage10?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts10);
                                    const displayTotal = Math.min(imageCounts10, imageCounts10);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive, // support after 5.21.0
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage10?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (indexs) => {
                                    setCurrent(indexs);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage10?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        key={index}
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCounts10 = carimagenew10?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCounts10);
                                                const displayTotal = Math.min(imageCounts10, imageCounts10);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive, // support after 5.21.0
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew10?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (indexs) => {
                                                setCurrent(indexs);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew10?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            key={index}
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts11 = carimage11?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts11);
                                    const displayTotal = Math.min(imageCounts11, imageCounts11);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive, // support after 5.21.0
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage11?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (indexs) => {
                                    setCurrent(indexs);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage11?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        key={index}
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCounts11 = carimagenew11?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCounts11);
                                                const displayTotal = Math.min(imageCounts11, imageCounts11);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive, // support after 5.21.0
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew11?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (indexs) => {
                                                setCurrent(indexs);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew11?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบประเมิน / รูปที่ดิน / สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            key={index}
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts1);
                                    const displayTotal = Math.min(imageCounts1, imageCounts1);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive, // support after 5.21.0
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage1?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (indexs) => {
                                    setCurrent(indexs);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage1?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบประเมิน / รูปที่ดิน / สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        key={index}
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCounts1 = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCounts1);
                                                const displayTotal = Math.min(imageCounts1, imageCounts1);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive, // support after 5.21.0
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew1?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (indexs) => {
                                                setCurrent(indexs);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew1?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                                {/* <Space>
                                    <Row justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 1) {
                                                    return (
                                                        <div key={index} >
                                                            <img
                                                                onClick={(v) => openModalImages(e)}
                                                                style={{ width: 200 }}
                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index}`}
                                                            />
                                                        </div>

                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space> */}
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }

    return (
        <div>
            <Modal
                title="ยืนยันการอัพเดทข้อมูล"
                open={isModalUpDateRe}
                onOk={handleUpdateRe}
                onCancel={handleCancelUpdateRe}
                okButtonProps={{
                    disabled: loading,
                }}
                cancelButtonProps={{
                    disabled: loading,
                }}
            >
                <p>กด OK เพื่อยืนยันการอัพเดท</p>
                <Divider />
            </Modal>
            <Modal title="ตรวจสอบข้อมูล"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'1100px'}
                footer={[
                    <Row justify={'center'}>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Row>
                ]} >
                <style>
                    {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                .ant-image-preview-count {
                    display: none !important;
                }
                `}
                </style>
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={12} style={{ textAlign: 'left' }}>
                                        {
                                            dataLoanRe?.reLoanId > 0 ?
                                                <>
                                                    <div style={{ margin: 0 }}>
                                                        เลขสัญญา : <Tag color={'green'}>{dataCarLand?.ISSUNO ? dataCarLand?.ISSUNO : null}</Tag>
                                                    </div>
                                                    <div style={{ margin: 0 }}>
                                                        เกรด  : <Tag color={'green'}>{dataLoanRe?.GRDCOD ? dataLoanRe?.GRDCOD : null}</Tag>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            {
                                                dataLoanRe?.reLoanId === 0 ?
                                                    <>
                                                        สถานะ : <Tag color={colorApprovalStatusNEW(dataLoan?.approvalStatus)}>{dataLoan?.approvalStatus}</Tag>
                                                    </>
                                                    :
                                                    <>
                                                        สถานะ : <Tag color={colorApprovalStatusNEW(dataLoanRe?.approvalStatus)}>{dataLoanRe?.approvalStatus}</Tag>
                                                    </>
                                            }
                                        </div>
                                        <div style={{ margin: 0 }}>
                                            {
                                                typeLoans === "car" ?
                                                    <>
                                                        วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataCarLand?.carDateStatus).format("DD-MM-YYYY")}
                                                    </>
                                                    :
                                                    <>
                                                        วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataCarLand?.LandDateStatus).format("DD-MM-YYYY")}
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                </Row>
                                <Divider />
                                {/* รายละเอียดผู้ขอกู้ */}
                                {
                                    dataCustomer?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>ประเภท : </span> <b>{dataCustomer?.nationalId}</b>
                                                    </div>
                                                    <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataCustomer.identificationId}</b>
                                                    </div>
                                                    <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                        <span>
                                                            {dataCustomer?.snam} : </span> <b>{dataCustomer?.firstname + ' ' + dataCustomer?.lastname}</b>
                                                    </div>
                                                    <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                        <span>วันจดทะเบียน : </span> <b> {dataCustomer?.birthdate}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>
                                                            {
                                                                dataPhones?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชื่อ - นามสกุล : </span>
                                                        <b>{dataCustomer?.snam + '   ' + dataCustomer?.firstname + '   ' + dataCustomer?.lastname}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชื่อเล่น : </span>
                                                        <b>{dataCustomer?.nickname}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขบัตรประชาชน : </span>
                                                        <b>{dataCustomer?.identificationId}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>
                                                            {
                                                                dataPhones?.map((item, index) => {
                                                                    return renderItemPhones({ item, index })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                    <div style={{ margin: 0, }}>
                                                        <span>เพศ : </span>
                                                        <b>{dataCustomer?.gender}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                        <b>{dataEmail?.emailData}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>{dataSocials?.socialType}  </span>
                                                        <b>{dataSocials?.socialData}</b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                }
                                {/* รายละเอียดผู้ขอกู้ */}

                                <Divider />

                                {/* ที่อยู่ */}
                                <Row justify={'left'}><b><u>รายละเอียดที่อยู่</u></b> </Row>
                                {
                                    dataAddress?.length >= 1 ?
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    dataAddress?.map((item, index) => {
                                                        return renderItemAddress({ item, index, key: `{item.identificationId} - ${index}` })
                                                    })
                                                }
                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                {/* ที่อยู่ */}
                                <Row justify={'left'}><b><u>อาชีพ - รายได้</u></b></Row>
                                {
                                    dataCareer?.length > 0 ?
                                        <>
                                            {
                                                dataCareer?.map((item, index) => {
                                                    return renderItemCareer({ item, index })
                                                })
                                            }
                                        </>
                                        :
                                        <Row gutter={32} justify={'center'}>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>*ไม่พบอาชีพ! </span>
                                            </div>
                                        </Row>
                                }
                                <Divider />
                                {
                                    typeLoans === "car" ?
                                        <>
                                            {
                                                dataLoanRe?.approvalStatus === "รับคำร้อง" ||
                                                    dataLoanRe?.approvalStatus === "คำร้องไม่ถูกต้อง" ||
                                                    dataLoanRe?.approvalStatus === "ปฏิเสธคำร้อง" ?
                                                    null
                                                    :
                                                    <>
                                                        {
                                                            dataLoan?.productLoanType === "ย้ายไฟแนนซ์" ||
                                                                dataLoan?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                                <>
                                                                    <Row justify={'left'}><b><u>รายละเอียดยอดกู้ ({dataLoan?.productLoanType})</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดกู้ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                <b>{dataLoan?.loanTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผู้เสนอเคส : </span>
                                                                                <b>{dataLoan?.proposalBy}</b>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                    <Divider />
                                                                    <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                                <span>จากไฟแนนซ์ : </span>
                                                                                <b>{dataOldLoan?.issuno}</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                                <span>ยอดปิดไฟแนนซ์ : </span>
                                                                                <b>{currencyFormatOne(dataOldLoan?.oldLoanAmount)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                                <span>ค่างวด / เดือน : </span>
                                                                                <b>{currencyFormatOne(dataOldLoan?.oldMonthlyPayment)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                                <span>จำนวนงวดที่ผ่อน : </span>
                                                                                <b>{dataOldLoan?.oldLoanTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                        </aside>
                                                                        {/* <Divider /> */}
                                                                    </Row>
                                                                </>
                                                                :
                                                                <>
                                                                    <Row justify={'left'}><b><u>รายละเอียด ({dataLoan?.productLoanType}) ({dataLoan?.productType})</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดกู้ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                <b>{dataLoan?.loanTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.interest)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผู้เสนอเคส : </span>
                                                                                <b>{dataLoan?.proposalBy}</b>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                </>
                                                        }
                                                        {
                                                            dataLoan?.approvalStatus === 'อนุมัติ' ||
                                                                dataLoan?.approvalStatus === 'อนุมัติแบบมีเงื่อนไข' ||
                                                                dataLoan?.approvalStatus === 'รอทำสัญญา' ||
                                                                dataLoan?.approvalStatus === 'ลูกค้าปฏิเสธ' ||
                                                                dataLoan?.approvalStatus === 'รอตรวจสอบเอกสาร' ||
                                                                dataLoan?.approvalStatus === 'ลูกค้าคอนเฟิร์ม' ?
                                                                <>
                                                                    <Divider />
                                                                    <Row justify={'left'}><b><u>อนุมัติ ({dataAP?.apProductType !== "" ? dataAP?.apProductType : dataLoan?.productType}) {
                                                                        dataAP?.apProductType === "เช่าซื้อ1" || dataLoan?.productType === "เช่าซื้อ1" ?
                                                                            <>
                                                                                {dataAP?.apInterestRate !== "" ? <>({dataAP?.apInterestRate})</> : <>({dataLoan?.interestRate})</>}
                                                                            </>
                                                                            : null
                                                                    }</u></b></Row>
                                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดกู้ : </span>
                                                                                {
                                                                                    dataAP?.apLoanAmount !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apLoanAmount)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                {
                                                                                    dataAP?.apLoanTerm !== "0" ?
                                                                                        <b>{dataAP?.apLoanTerm}</b>
                                                                                        :
                                                                                        <b>{dataLoan?.approvedLoanTerm}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                {/* <b>{currencyFormatOne(interestt)}</b> */}
                                                                                {
                                                                                    dataAP?.apInterest !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apInterest)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(interestt)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                {/* <b>{currencyFormatOne(arr)}</b> */}
                                                                                {
                                                                                    dataAP?.apMonthlyPayment !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apMonthlyPayment)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(arr)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                {/* <b>{currencyFormatOne(installmentWithInterestt)}</b> */}
                                                                                {
                                                                                    dataAP?.apInstallmentWithInterest !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apInstallmentWithInterest)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผู้อนุมัติเคส : </span>
                                                                                {/* <b>{dataLoan?.approvedBy}</b> */}
                                                                                {
                                                                                    dataAP?.apApprovedBy !== "" ?
                                                                                        <b>{dataAP?.apApprovedBy}</b>
                                                                                        :
                                                                                        <b>{dataLoan.approvedBy}</b>
                                                                                }
                                                                            </div>
                                                                            <b> <div style={{ margin: 0, }}></div></b>
                                                                        </aside>
                                                                        {/* ตาราง */}
                                                                        {
                                                                            dataAP?.loanId !== 0 ?
                                                                                <>
                                                                                    {
                                                                                        dataAP?.apProductType === "p-loan" ?
                                                                                            <>
                                                                                                <Col span={24}>
                                                                                                    <div style={{ textAlign: "right" }}>
                                                                                                        <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    dataAP?.apInterestRate === "0.75" || dataAP?.apInterestRate === "0.84" || dataAP?.apProductType === "เช่าซื้อ1" ?
                                                                                                        null
                                                                                                        :
                                                                                                        <>
                                                                                                            {
                                                                                                                dataCarLand?.productTypeCar === 1 ?
                                                                                                                    <>
                                                                                                                        <Col span={24}>
                                                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                                                <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </>
                                                                                                                    : null
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        dataLoan?.productType === "p-loan" ?
                                                                                            <>
                                                                                                <Col span={24}>
                                                                                                    <div style={{ textAlign: "right" }}>
                                                                                                        <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    dataLoan?.interestRate === "0.75" || dataLoan?.interestRate === "0.84" ?
                                                                                                        null
                                                                                                        :
                                                                                                        <>
                                                                                                            {
                                                                                                                dataCarLand?.productTypeCar === 1 ?
                                                                                                                    <>
                                                                                                                        <Col span={24}>
                                                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                                                <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </>
                                                                                                                    : null
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </Row>
                                                                </>
                                                                : null
                                                        }
                                                        <Divider />
                                                    </>
                                            }
                                            <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยี่ห้อ : </span>
                                                        <b>{dataCarLand?.carBrand}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขทะเบียน : </span>
                                                        <b>{dataCarLand?.carPlateNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขเครื่อง : </span>
                                                        <b>{dataCarLand?.carEngineNumber}</b>
                                                    </div>
                                                    <b>
                                                        <div style={{ margin: 0, }}>
                                                            <span>เรทรถ : </span>
                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataCarLand?.carPrice)}</u></b>
                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </b>
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>รุ่นสินค้า : </span>
                                                        <b>{dataCarLand?.carModel}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขคัสซี : </span>
                                                        <b>{dataCarLand?.carChassisNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>สี : </span>
                                                        <b>{dataCarLand?.carColor}</b>
                                                    </div>
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>แบบรถ : </span>
                                                        <b>{dataCarLand?.carBaab}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ปีรถ : </span>
                                                        <b>{dataCarLand?.carYear}</b>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Divider />

                                        </>
                                        :
                                        <>
                                            {/* ที่ดิน */}
                                            {
                                                dataLoanRe?.approvalStatus === "รับคำร้อง" ||
                                                    dataLoanRe?.approvalStatus === "คำร้องไม่ถูกต้อง" ||
                                                    dataLoanRe?.approvalStatus === "ปฏิเสธคำร้อง" ?
                                                    null
                                                    :
                                                    <>
                                                        {
                                                            dataLoan?.productLoanType === "ย้ายไฟแนนซ์" ||
                                                                dataLoan?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                                <>
                                                                    <Row justify={'left'}><b><u>รายละเอียดขอ ({dataLoan?.productLoanLandType}) </u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ประเภทขอกู้ : </span>
                                                                                <b>{dataLoan?.productType}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดจัด : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.loanAmountLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>อัตราดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.interestRateLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    <b>% +VAT</b>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                <b>{dataLoan?.loanLandTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(~~dataLoan?.interestLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>งวดละ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.monthlyPaymentLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <b> <div style={{ margin: 0, }}>
                                                                                <span>ราคารวม : </span>
                                                                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(~~dataLoan?.monthlyPaymentLand * ~~dataLoan?.loanLandTerm)}</u></b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            </b>
                                                                        </aside>
                                                                    </Row>
                                                                    <Divider />
                                                                    <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>จากไฟแนนซ์ : </span>
                                                                                <b>{dataOldLoan?.issuno}</b>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>ยอดปิดไฟแนนซ์ : </span>
                                                                                <b>{currencyFormatOne(dataOldLoan?.oldLandLoanAmount)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>ค่างวด / เดือน : </span>
                                                                                <b>{currencyFormatOne(dataOldLoan?.oldLandMonthlyPayment)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <span>จำนวนงวดที่ผ่อน : </span>
                                                                                <b>{dataOldLoan?.oldLandLoanTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                </>
                                                                :
                                                                <>
                                                                    <Row justify={'left'}><b><u>รายละเอียด ({dataLoan?.productLoanLandType})</u></b></Row>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดจัด : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.loanAmountLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                <b>{dataLoan?.loanLandTerm}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>งวดละ : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.monthlyPaymentLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                <b>{currencyFormatOne(dataLoan?.interestLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ราคารวม : </span>
                                                                                <b>{currencyFormatOne(~~dataLoan?.loanLandTerm * ~~dataLoan?.monthlyPaymentLand)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                                <b>{dataLoan?.proposalBy}</b>
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                </>
                                                        }
                                                        {
                                                            dataLoan?.approvalStatus === "อนุมัติ" ||
                                                                dataLoan?.approvalStatus === "อนุมัติแบบมีเงื่อนไข" ||
                                                                dataLoan?.approvalStatus === 'รอทำสัญญา' ||
                                                                dataLoan?.approvalStatus === 'ลูกค้าปฏิเสธ' ||
                                                                dataLoan?.approvalStatus === 'รอตรวจสอบเอกสาร' ||
                                                                dataLoan?.approvalStatus === 'ลูกค้าคอนเฟิร์ม' ?
                                                                <>
                                                                    <Divider />
                                                                    <Row justify={'left'}><b><u>อนุมัติ</u></b></Row>
                                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                        <aside style={{ width: '90%' }}>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ยอดจัด : </span>
                                                                                {
                                                                                    dataAP?.apLoanAmount !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apLoanAmount)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนงวด : </span>
                                                                                {
                                                                                    dataAP?.apLoanTerm !== "0" ?
                                                                                        <b>{dataAP?.apLoanTerm}</b>
                                                                                        :
                                                                                        <b>{dataLoan?.approvedLoanTerm}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    งวด
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>งวดละ : </span>
                                                                                {
                                                                                    dataAP?.apMonthlyPayment !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apMonthlyPayment)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(loanTermDATA)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ดอกเบี้ย : </span>
                                                                                {
                                                                                    dataAP?.apInterest !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apInterest)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(resultRateDATA)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ราคารวม : </span>
                                                                                {
                                                                                    dataAP?.apInstallmentWithInterest !== "0" ?
                                                                                        <b>{currencyFormatOne(dataAP?.apInstallmentWithInterest)}</b>
                                                                                        :
                                                                                        <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                                                }
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ผู้อนุมัติเคส : </span>
                                                                                {
                                                                                    dataAP?.apApprovedBy !== "" ?
                                                                                        <b>{dataAP?.apApprovedBy}</b>
                                                                                        :
                                                                                        <b>{dataLoan?.approvedBy}</b>
                                                                                }
                                                                            </div>
                                                                        </aside>
                                                                    </Row>
                                                                </>
                                                                : null
                                                        }
                                                        <Divider />
                                                    </>
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderLandData({ item, index })
                                                })
                                            }
                                            <Divider />
                                            <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderApproval({ item, index })
                                                })
                                            }
                                            <Divider />
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderMixNote({ item, index })
                                                })
                                            }
                                        </>
                                }
                                {/* <Divider /> */}
                                {
                                    typeLoans === "car" ?
                                        <>
                                            {
                                                dataLoanRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanType === "เปลี่ยนสัญญา" ?
                                                    <>
                                                        {/* <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider> */}
                                                        <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                        <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                            <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b> บาท
                                                            </div>
                                                            <div name='overdue' style={{ marginBottom: 3 }}>
                                                                <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(~~dataLoanRe?.overdue + ~~dataLoanRe?.beeypup)}</b>  บาท
                                                            </div>
                                                            <div name='preChange' style={{ marginBottom: 3 }}>
                                                                <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>  บาท
                                                            </div>
                                                            <div>
                                                                <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange + ~~dataLoanRe?.beeypup)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </aside>
                                                        <Divider style={{ margin: 3 }} />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                dataLoanRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                    dataLoanRe?.productLoanLandType === "เปลี่ยนสัญญา" ?
                                                    <>
                                                        {/* <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider> */}
                                                        <Row justify={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Row>
                                                        <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                            <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(dataLoanRe?.priceChange)}</b> บาท
                                                            </div>
                                                            <div name='overdue' style={{ marginBottom: 3 }}>
                                                                <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(~~dataLoanRe?.overdue + ~~dataLoanRe?.beeypup)}</b>  บาท
                                                            </div>
                                                            <div name='preChange' style={{ marginBottom: 3 }}>
                                                                <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(dataLoanRe?.preChange)}</b>  บาท
                                                            </div>
                                                            <div>
                                                                <span>ยอดรวม : </span> <b> {currencyFormatOne(~~dataLoanRe?.preChange + ~~dataLoanRe?.overdue + ~~dataLoanRe?.priceChange + ~~dataLoanRe?.beeypup)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </aside>
                                                        <Divider style={{ margin: 3 }} />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                }

                                {
                                    dataCarAun?.id > 0 ? (
                                        <>
                                            <Card style={{ backgroundColor: "lightyellow" }}>
                                                <Row justify={"left"}>
                                                    <b>
                                                        <u style={{ backgroundColor: "#f39c12" }}>
                                                            เงื่อนไขการปรับโครงสร้าง ({dataCarAun?.CONTNO})
                                                        </u>
                                                    </b>
                                                </Row>
                                                <Col span={24} style={{ textAlign: "center" }}>
                                                    <Image
                                                        width={100}
                                                        src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                        alt="My Image"
                                                    />
                                                </Col>
                                                <Row gutter={32} justify={"center"}>
                                                    <aside style={{ width: "90%" }}>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>วันที่ : </span>
                                                            <b>
                                                                <u>{dateceo}</u>
                                                            </b>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>มูลหนี้เช่าซื้อคงเหลือรวม : </span>
                                                            <b>
                                                                <u>{moonnee}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>หักส่วนลด : </span>
                                                            <b>
                                                                <u>{hug}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>คงเหลือ : </span>
                                                            <b>
                                                                <u>{kong}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>จ่ายเงิน : </span>
                                                            <b>
                                                                <u>{jaimoney}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>จำนวนงวด : </span>
                                                            <b>
                                                                <u>{ngod}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                งวด
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>ผ่อน : </span>
                                                            <b>{pon}</b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                งวด (รวมภาษีมูลค่าเพิ่ม)
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>ยอดจัด : </span>
                                                            <b style={{ color: "red", fontSize: "18px" }}>
                                                                <u>{yodjut}</u>
                                                            </b>
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                            <b style={{ color: "red", fontSize: "18px" }}>
                                                                <u>{exp}</u>
                                                            </b>
                                                            {/* <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span> */}
                                                        </div>
                                                    </aside>
                                                </Row>
                                            </Card>
                                            <Divider />
                                        </>
                                    ) : null
                                }

                                {
                                    typeLoans === "car" ?
                                        <>
                                            {
                                                dataLoanRe?.carId > 0 ? (
                                                    <>
                                                        <Row justify={"left"}>
                                                            <b>
                                                                <u>รายละเอียดสัญญา ({dataCarLand?.ISSUNO})</u>
                                                            </b>
                                                        </Row>
                                                        {username === "MIT00028" ||
                                                            username === "admin777" ||
                                                            username === "admin888" ||
                                                            username === "admin999" ? (
                                                            <>
                                                                {newOfferDate < currentDate ? (
                                                                    <Row>
                                                                        <Col span={24} style={{ textAlign: "right" }}>
                                                                            <Button
                                                                                icon={<ReloadOutlined />}
                                                                                type="primary"
                                                                                style={{
                                                                                    backgroundColor: "#faad14",
                                                                                    color: "black",
                                                                                }}
                                                                                onClick={showConfirmUpdateRe}
                                                                            >
                                                                                อัพเดทยอดปิด
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                        <Row gutter={32} justify={"center"}>
                                                            <aside style={{ width: "90%" }}>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ค่างวด : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.DAMT)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดจัดครั้งที่แล้ว : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.tcshprc)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.balanc)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.totalpayment)}{" "}
                                                                        บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ล/น คงเหลือรวม : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.neekong)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>เบี้ยปรับ : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.beeypup)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ลูกหนี้อื่นๆ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.OTHR)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.NOPAY)} งวด</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>จำนวนงวดทั้งหมด : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.nopays)} งวด</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ตัดสด งวดที่ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.reqNo)}</b>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, background: "#1677ff" }}
                                                                >
                                                                    <span>จำนวนงวดที่ค้าง : </span>
                                                                    <b>
                                                                        {~~dataLoanRe?.nopays - ~~dataLoanRe?.NOPAY} งวด
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.re1)}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                    <b>
                                                                        <u style={{ color: "red" }}>
                                                                            {dayjs(dataLoanRe?.newOfferDate).format(
                                                                                "DD-MM-YYYY"
                                                                            )}
                                                                        </u>
                                                                    </b>
                                                                </div>
                                                            </aside>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                ) : null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                dataLoanRe?.landId > 0 ? (
                                                    <>
                                                        <Row justify={"left"}>
                                                            <b>
                                                                <u>รายละเอียดสัญญา ({dataCarLand?.ISSUNO})</u>
                                                            </b>
                                                        </Row>
                                                        {username === "MIT00028" ||
                                                            username === "admin777" ||
                                                            username === "admin888" ||
                                                            username === "admin999" ? (
                                                            <>
                                                                {newOfferDate < currentDate ? (
                                                                    <Row>
                                                                        <Col span={24} style={{ textAlign: "right" }}>
                                                                            <Button
                                                                                icon={<ReloadOutlined />}
                                                                                type="primary"
                                                                                style={{
                                                                                    backgroundColor: "#faad14",
                                                                                    color: "black",
                                                                                }}
                                                                                onClick={showConfirmUpdateRe}
                                                                            >
                                                                                อัพเดทยอดปิด
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                        <Row gutter={32} justify={"center"}>
                                                            <aside style={{ width: "90%" }}>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ค่างวด : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.DAMT)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดจัดครั้งที่แล้ว : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.tcshprc)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.balanc)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.totalpayment)}{" "}
                                                                        บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ล/น คงเหลือรวม : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.neekong)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>เบี้ยปรับ : </span>
                                                                    <b>
                                                                        {currencyFormatOne(dataLoanRe?.beeypup)} บาท
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ลูกหนี้อื่นๆ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.OTHR)} บาท</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.NOPAY)} งวด</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>จำนวนงวดทั้งหมด : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.nopays)} งวด</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ตัดสด งวดที่ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.reqNo)}</b>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, background: "#1677ff" }}
                                                                >
                                                                    <span>จำนวนงวดที่ค้าง : </span>
                                                                    <b>
                                                                        {~~dataLoanRe?.nopays - ~~dataLoanRe?.NOPAY} งวด
                                                                    </b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                    <b>{currencyFormatOne(dataLoanRe?.re1)}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                    <b>
                                                                        <u style={{ color: "red" }}>
                                                                            {dayjs(dataLoanRe?.newOfferDate).format(
                                                                                "DD-MM-YYYY"
                                                                            )}
                                                                        </u>
                                                                    </b>
                                                                </div>
                                                            </aside>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                ) : null
                                            }
                                        </>
                                }



                                {
                                    dataApproved?.id > 0 ? (
                                        <>
                                            <Divider
                                                orientation="center"
                                                align={"middle"}
                                                style={{ marginTop: "10px", marginBottom: "10px" }}
                                            >
                                                <b>คำอนุมัติใหม่</b>
                                            </Divider>

                                            <Row>
                                                <Col span={24} style={{ textAlign: "center" }}>
                                                    <div
                                                        label="ค่างวดที่ต้องชำระ"
                                                        style={{
                                                            fontFamily: "Arial, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        <b>ค่างวดที่ต้องชำระ : </b>
                                                        {!dataApproved?.newDamt
                                                            ? "-"
                                                            : currencyFormatOne(dataApproved?.newDamt)}{" "}
                                                        บาท
                                                    </div>
                                                    <div
                                                        label="ค่าติดตาม"
                                                        style={{
                                                            fontFamily: "Arial, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        <b>ค่าติดตาม : </b>
                                                        {!dataApproved?.followPay
                                                            ? "-"
                                                            : currencyFormatOne(dataApproved?.followPay)}{" "}
                                                        บาท
                                                    </div>
                                                    <div
                                                        label="เงินต้น"
                                                        style={{
                                                            fontFamily: "Arial, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        <b>เงินต้น : </b>
                                                        {!dataApproved?.approvedLoanAmount
                                                            ? "-"
                                                            : currencyFormatOne(
                                                                dataApproved?.approvedLoanAmount
                                                            )}{" "}
                                                        บาท
                                                    </div>
                                                    <div
                                                        label="จำนวนงวด"
                                                        style={{
                                                            fontFamily: "Arial, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        <b>จำนวนงวด : </b>
                                                        {!dataApproved?.approvedLoanTerm
                                                            ? null
                                                            : dataApproved?.approvedLoanTerm}{" "}
                                                        {" ถึง: "}
                                                        {!dataApproved?.approvedLoanTermTo
                                                            ? "-"
                                                            : dataApproved?.approvedLoanTermTo}{" "}
                                                        งวด
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Divider />
                                        </>
                                    ) : null
                                }

                                {
                                    checkBroker ?
                                        <>
                                            <Row justify={'left'}><b><u>ค่าแนะนำ</u></b></Row>
                                            <Row justify={'center'} gutter={32}>
                                                <Col span={7}>
                                                    <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                                                        <span>ผู้แนะนำ : </span>
                                                        <b>{brokerName}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                                                        <span>จำนวนเงิน : </span>
                                                        <b>{data630}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                                                        <span>ค่าอนุมัติ/บริการ : </span>
                                                        <b>{data627}</b>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                {/* <Divider /> */}
                                <Row justify={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Row>
                                {dataGuarantor?.length >= 1 ?
                                    <>
                                        {
                                            dataGuarantor?.map((item, index) => {
                                                return renderGuarantor({ item, index })
                                            })
                                        }
                                    </>
                                    : null
                                }
                                <Divider />
                                {/* ช่องติ๊ก */}
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbPreaaprove === true ? true : false}
                                    >Pre-Aaprove
                                    </Checkbox>
                                    {
                                        typeLoans === "car" ?
                                            <Checkbox
                                                style={{ fontSize: '20px' }}
                                                checked={dataCheckbox?.cbCar === true ? true : false}
                                            >รถ
                                            </Checkbox>
                                            :
                                            <Checkbox
                                                style={{ fontSize: '20px' }}
                                                checked={dataCheckbox?.cbLand === true ? true : false}
                                            >ที่ดิน
                                            </Checkbox>
                                    }
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbHome === true ? true : false}
                                    >บ้าน
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbHomeRent === true ? true : false}
                                    >บ้านเช่า
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbHomeGov === true ? true : false}
                                    >บ้านพักราการ
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbPark === true ? true : false}
                                    >ลานจอด
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbVat === true ? true : false}
                                    >ภาษี
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbVdo === true ? true : false}
                                    >VDO
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckbox?.cbGps === true ? true : false}
                                    >GPS
                                    </Checkbox>
                                </Col>
                                <Divider />
                                {/* ช่องติ๊ก */}
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        key={"0"}
                                        value={1}
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckGua === "ค้ำ-ไม่โอน"}
                                        isPacked={true}
                                    >
                                        ค้ำ-ไม่โอน
                                    </Checkbox>
                                    <Checkbox
                                        key={"1"}
                                        value={2}
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckGua === "ค้ำ-โอน"}
                                        isPacked={true}
                                    >
                                        ค้ำ-โอน
                                    </Checkbox>
                                    <Checkbox
                                        key={"2"}
                                        value={3}
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckGua === "ไม่ค้ำ-ไม่โอน"}
                                        isPacked={true}
                                    >
                                        ไม่ค้ำ-ไม่โอน
                                    </Checkbox>
                                    <Checkbox
                                        key={"3"}
                                        value={4}
                                        style={{ fontSize: '20px' }}
                                        checked={dataCheckGua === "ไม่ค้ำ-โอน"}
                                        isPacked={true}
                                    >
                                        ไม่ค้ำ-โอน
                                    </Checkbox>
                                </Col>
                                <Divider orientation={'left'}></Divider>
                                {
                                    dataLoanRe?.approvalStatus === "ไม่อนุมัติ" || dataLoanRe?.approvalStatus === "ปฏิเสธคำร้อง" ?
                                        <>
                                            <Row justify={'left'}><b><u>ไม่อนุมัติ/ปฏิเสธคำร้อง</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>วันที่ไม่อนุมัติ/ปฏิเสธคำร้องติ : </span>
                                                        {
                                                            dataLoanRe?.approvalStatus === "ไม่อนุมัติ" ?
                                                                <>
                                                                    <b style={{ color: "red" }}>{dayjs(dataLoanRe?.approvalDate).format("DD-MM-YYYY")}</b>
                                                                </>
                                                                :
                                                                <>
                                                                    <b style={{ color: "red" }}>{dayjs(dataLoanRe?.acceptDatePg).format("DD-MM-YYYY")}</b>
                                                                </>
                                                        }

                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>วันที่นำเคสกลับมารีใหม่ได้ : </span>
                                                        <b style={{ color: "red" }}>{dataLoanRe?.newOfferDate ? <> {dayjs(dataLoanRe?.newOfferDate).format("DD-MM-YYYY")}</> : null}</b>
                                                    </div>
                                                </aside>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        dataNote?.length >= 1 ?
                                            dataNote?.map((item, index) => {
                                                return renderDataNote({ item, index });
                                            })
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ รี/ปรับ/เปลี่ยน</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        dataNoteRe?.length >= 1 ?
                                            dataNoteRe?.map((item, index) => {
                                                return renderDataNote({ item, index });
                                            })
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Image.PreviewGroup
                                                preview={{
                                                    countRender: (index, total) => {
                                                        const imageCount4 = carimage4?.length || 0; // นับจำนวนภาพจริง
                                                        const displayedIndex = Math.min(index, imageCount4);
                                                        const displayTotal = Math.max(imageCount4, imageCount4);
                                                        return `${displayedIndex} / ${displayTotal}`;
                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                    toolbarRender: (
                                                        _,
                                                        {
                                                            transform: { scale },
                                                            actions: {
                                                                onActive,
                                                                onFlipY,
                                                                onFlipX,
                                                                onRotateLeft,
                                                                onRotateRight,
                                                                onZoomOut,
                                                                onZoomIn,
                                                                onReset,
                                                            },
                                                        },
                                                    ) => (
                                                        <>
                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                {carimage4?.map((e, index) => {
                                                                    return (
                                                                        <>
                                                                            <Image
                                                                                width={auto}
                                                                                key={index}
                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                            />
                                                                        </>
                                                                    );
                                                                })}
                                                            </Row>
                                                            <Row style={{ zIndex: 2 }}>
                                                                <Space size={12} className="toolbar-wrapper">
                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                    <DownloadOutlined onClick={onDownload} />
                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                    <SwapOutlined onClick={onFlipX} />
                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                    <UndoOutlined onClick={onReset} />
                                                                </Space>
                                                            </Row>
                                                        </>
                                                    ),
                                                    onChange: (index) => {
                                                        setCurrent(index);
                                                    },
                                                }}
                                            >
                                                <Row gutter={32} justify={'center'}>
                                                    {carimage4?.map((e, index) => {
                                                        return (
                                                            <>
                                                                <Image
                                                                    width={150}
                                                                    key={index}
                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </>
                                                        );
                                                    })}
                                                </Row>
                                            </Image.PreviewGroup>
                                        </Space>
                                    </Row>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Image.PreviewGroup
                                                            preview={{
                                                                countRender: (index, total) => {
                                                                    const imageCount4 = carimagenew4?.length || 0; // นับจำนวนภาพจริง
                                                                    const displayedIndex = Math.min(index, imageCount4);
                                                                    const displayTotal = Math.max(imageCount4, imageCount4);
                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                toolbarRender: (
                                                                    _,
                                                                    {
                                                                        transform: { scale },
                                                                        actions: {
                                                                            onActive,
                                                                            onFlipY,
                                                                            onFlipX,
                                                                            onRotateLeft,
                                                                            onRotateRight,
                                                                            onZoomOut,
                                                                            onZoomIn,
                                                                            onReset,
                                                                        },
                                                                    },
                                                                ) => (
                                                                    <>
                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                            {carimagenew4?.map((e, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Image
                                                                                            width={auto}
                                                                                            key={index}
                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </Row>
                                                                        <Row style={{ zIndex: 2 }}>
                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                <UndoOutlined onClick={onReset} />
                                                                            </Space>
                                                                        </Row>
                                                                    </>
                                                                ),
                                                                onChange: (index) => {
                                                                    setCurrent(index);
                                                                },
                                                            }}
                                                        >
                                                            <Row gutter={32} justify={'center'}>
                                                                {carimagenew4?.map((e, index) => {
                                                                    return (
                                                                        <>
                                                                            <Image
                                                                                width={150}
                                                                                key={index}
                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                            />
                                                                        </>
                                                                    );
                                                                })}
                                                            </Row>
                                                        </Image.PreviewGroup>
                                                    </Space>
                                                    {/* {
                                                        dataImg?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            // <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 4) {
                                                                    return (
                                                                        <div key={index} >
                                                                            <img
                                                                                onClick={(v) => openModalImages(e)}
                                                                                style={{ width: 200 }}
                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index}`}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })} */}
                                                </Row>
                                            </Row>
                                            <Divider />
                                        </> : null
                                }
                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const imageCount6 = carimage6?.length || 0; // นับจำนวนภาพจริง
                                                    const displayedIndex = Math.min(index, imageCount6);
                                                    const displayTotal = Math.max(imageCount6, imageCount6);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage6?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage6?.map((e, index) => {
                                                    return (
                                                        <>
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </Image.PreviewGroup>
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount1 = carimagenew6?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount1);
                                                                const displayTotal = Math.max(imageCount1, imageCount1);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimagenew6?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimagenew6?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Space>
                                                {/* {dataImg?.map((e, index) => {
                                                        var PreAaproveSplit = []
                                                        PreAaproveSplit = e.pathImage.split("/")
                                                        if (PreAaproveSplit[3] === "13") {
                                                            if (e.type === 6) {
                                                                return (
                                                                    <div key={index} >
                                                                        <img
                                                                            onClick={(v) => openModalImages(e)}
                                                                            style={{ width: 200 }}
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index}`}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        return null;
                                                    })} */}

                                            </Row>
                                            <Divider />
                                        </> : null
                                }
                                {
                                    typeLoans === "car" ?
                                        <>
                                            <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row justify={'center'}>
                                                    {dataCarLand?.carMemo !== "" && dataCarLand?.carMemo !== undefined ?
                                                        <>
                                                            <Row justify={'left'}>
                                                                <Col span={24} >
                                                                    <b>คอมเม้นรถ :  </b>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={'center'}>
                                                                <Col span={24} >
                                                                    <Form.Item name="carMemo" >
                                                                        <TextArea disabled name='carMemo' style={{ width: '800px', color: 'blue' }} rows={8}>
                                                                        </TextArea>
                                                                    </Form.Item>
                                                                </Col>

                                                            </Row>

                                                        </>
                                                        : null
                                                    }
                                                </Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Image.PreviewGroup
                                                            preview={{
                                                                countRender: (index, total) => {
                                                                    const imageCount1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                                                    const displayedIndex = Math.min(index, imageCount1);
                                                                    const displayTotal = Math.max(imageCount1, imageCount1);
                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                toolbarRender: (
                                                                    _,
                                                                    {
                                                                        transform: { scale },
                                                                        actions: {
                                                                            onActive,
                                                                            onFlipY,
                                                                            onFlipX,
                                                                            onRotateLeft,
                                                                            onRotateRight,
                                                                            onZoomOut,
                                                                            onZoomIn,
                                                                            onReset,
                                                                        },
                                                                    },
                                                                ) => (
                                                                    <>
                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                            {carimage1?.map((e, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Image
                                                                                            width={auto}
                                                                                            key={index}
                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </Row>
                                                                        <Row style={{ zIndex: 2 }}>
                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                <UndoOutlined onClick={onReset} />
                                                                            </Space>
                                                                        </Row>
                                                                    </>
                                                                ),
                                                                onChange: (index) => {
                                                                    setCurrent(index);
                                                                },
                                                            }}
                                                        >
                                                            <Row gutter={32} justify={'center'}>
                                                                {carimage1?.map((e, index) => {
                                                                    return (
                                                                        <>
                                                                            <Image
                                                                                width={150}
                                                                                key={index}
                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                            />
                                                                        </>
                                                                    );
                                                                })}
                                                            </Row>
                                                        </Image.PreviewGroup>
                                                    </Space>
                                                    {/* {dataImg?.map((e, index) => {
                                                        var PreAaproveSplit = []
                                                        PreAaproveSplit = e.pathImage.split("/")
                                                        if (PreAaproveSplit[3] !== "13") {
                                                            if (e.type === 1) {
                                                                return (
                                                                    <div key={index}>
                                                                        <img
                                                                            onClick={(v) => openModalImages(e)}
                                                                            style={{ width: 200 }}
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index}`}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        return null;
                                                    })} */}
                                                </Row>
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Space direction="vertical">
                                                                <Image.PreviewGroup
                                                                    preview={{
                                                                        countRender: (index, total) => {
                                                                            const imageCount1 = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                                                            const displayedIndex = Math.min(index, imageCount1);
                                                                            const displayTotal = Math.max(imageCount1, imageCount1);
                                                                            return `${displayedIndex} / ${displayTotal}`;
                                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                        toolbarRender: (
                                                                            _,
                                                                            {
                                                                                transform: { scale },
                                                                                actions: {
                                                                                    onActive,
                                                                                    onFlipY,
                                                                                    onFlipX,
                                                                                    onRotateLeft,
                                                                                    onRotateRight,
                                                                                    onZoomOut,
                                                                                    onZoomIn,
                                                                                    onReset,
                                                                                },
                                                                            },
                                                                        ) => (
                                                                            <>
                                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                    {carimagenew1?.map((e, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Image
                                                                                                    width={auto}
                                                                                                    key={index}
                                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                />
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Row>
                                                                                <Row style={{ zIndex: 2 }}>
                                                                                    <Space size={12} className="toolbar-wrapper">
                                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                        <DownloadOutlined onClick={onDownload} />
                                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                        <SwapOutlined onClick={onFlipX} />
                                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                        <UndoOutlined onClick={onReset} />
                                                                                    </Space>
                                                                                </Row>
                                                                            </>
                                                                        ),
                                                                        onChange: (index) => {
                                                                            setCurrent(index);
                                                                        },
                                                                    }}
                                                                >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {carimagenew1?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={150}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Image.PreviewGroup>
                                                            </Space>
                                                        </Row>
                                                        <Divider />
                                                    </> : null
                                            }
                                            <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount2 = carimage2?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount2);
                                                                const displayTotal = Math.max(imageCount2, imageCount2);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimage2?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimage2?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Space>
                                            </Row>
                                            <Divider />
                                            {
                                                checkSendNEW ?
                                                    <>
                                                        <Row justify={'left'}><b><u>หน้าคู่มือ</u> (ใหม่)</b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Space direction="vertical">
                                                                <Image.PreviewGroup
                                                                    preview={{
                                                                        countRender: (index, total) => {
                                                                            const imageCount2 = carimagenew2?.length || 0; // นับจำนวนภาพจริง
                                                                            const displayedIndex = Math.min(index, imageCount2);
                                                                            const displayTotal = Math.max(imageCount2, imageCount2);
                                                                            return `${displayedIndex} / ${displayTotal}`;
                                                                        }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                        toolbarRender: (
                                                                            _,
                                                                            {
                                                                                transform: { scale },
                                                                                actions: {
                                                                                    onActive,
                                                                                    onFlipY,
                                                                                    onFlipX,
                                                                                    onRotateLeft,
                                                                                    onRotateRight,
                                                                                    onZoomOut,
                                                                                    onZoomIn,
                                                                                    onReset,
                                                                                },
                                                                            },
                                                                        ) => (
                                                                            <>
                                                                                <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                    {carimagenew2?.map((e, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Image
                                                                                                    width={auto}
                                                                                                    key={index}
                                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                    src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                                    style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                />
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Row>
                                                                                <Row style={{ zIndex: 2 }}>
                                                                                    <Space size={12} className="toolbar-wrapper">
                                                                                        {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                        <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                        <DownloadOutlined onClick={onDownload} />
                                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                        <SwapOutlined onClick={onFlipX} />
                                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                        <UndoOutlined onClick={onReset} />
                                                                                    </Space>
                                                                                </Row>
                                                                            </>
                                                                        ),
                                                                        onChange: (index) => {
                                                                            setCurrent(index);
                                                                        },
                                                                    }}
                                                                >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {carimagenew2?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={150}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                </Image.PreviewGroup>
                                                            </Space>
                                                            {/* <Row gutter={32} justify={'center'}>
                                                                {dataImg?.map((e, index) => {
                                                                    var PreAaproveSplit = []
                                                                    PreAaproveSplit = e.pathImage.split("/")
                                                                    if (PreAaproveSplit[3] === "13") {
                                                                        if (e.type === 2) {
                                                                            return (
                                                                                <div key={index} >
                                                                                    <img
                                                                                        onClick={(v) => openModalImages(e)}
                                                                                        style={{ width: 200 }}
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index}`}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })}
                                                            </Row> */}
                                                        </Row>
                                                        <Divider />
                                                    </> : null
                                            }

                                        </>
                                        :
                                        <>
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderLandBuildings({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderTitleDeed({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderDivision({ item, index })
                                                })
                                            }
                                            {
                                                dataLandArray?.map((item, index) => {
                                                    return renderLawang({ item, index })
                                                })
                                            }
                                        </>
                                }
                                {/* <Divider /> */}
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const imageCount8 = carimage8?.length || 0; // นับจำนวนภาพจริง
                                                    const displayedIndex = Math.min(index, imageCount8);
                                                    const displayTotal = Math.max(imageCount8, imageCount8);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage8?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage8?.map((e, index) => {
                                                    return (
                                                        <>
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </Image.PreviewGroup>
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount8 = carimagenew8?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount8);
                                                                const displayTotal = Math.max(imageCount8, imageCount8);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimagenew8?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimagenew8?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Space>
                                                {/* <Row gutter={32} justify={'center'}>
                                                    {dataImg?.map((e, index) => {
                                                        var PreAaproveSplit = []
                                                        PreAaproveSplit = e.pathImage.split("/")
                                                        if (PreAaproveSplit[3] === "13") {
                                                            if (e.type === 8) {
                                                                return (
                                                                    <div key={index} >
                                                                        <img
                                                                            onClick={(v) => openModalImages(e)}
                                                                            style={{ width: 200 }}
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index}`}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        return null;
                                                    })}
                                                </Row> */}
                                            </Row>
                                            <Divider />
                                        </> : null
                                }
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Row gutter={32} justify={'center'}>
                                        {dataImg?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] !== "13") {
                                                if (e.type === 7) {
                                                    return (
                                                        <>
                                                            <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                            </object>
                                                        </>
                                                    )
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Row gutter={32} justify={'center'}>
                                                    {dataImg?.map((e, index) => {
                                                        var PreAaproveSplit = []
                                                        PreAaproveSplit = e.pathImage.split("/")
                                                        if (PreAaproveSplit[3] === "13") {
                                                            if (e.type === 7) {
                                                                return (
                                                                    <>
                                                                        <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                            <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                        </object>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                        return null;
                                                    })}
                                                </Row>
                                            </Row>
                                            <Divider />
                                        </> : null
                                }
                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const imageCount12 = carimage9?.length || 0; // นับจำนวนภาพจริง
                                                    const displayedIndex = Math.min(index, imageCount12);
                                                    const displayTotal = Math.max(imageCount12, imageCount12);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage9?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage9?.map((e, index) => {
                                                    return (
                                                        <>
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </Image.PreviewGroup>
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const imageCount12 = carimage12?.length || 0; // นับจำนวนภาพจริง
                                                    const displayedIndex = Math.min(index, imageCount12);
                                                    const displayTotal = Math.max(imageCount12, imageCount12);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage12?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage12?.map((e, index) => {
                                                    return (
                                                        <>
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </Image.PreviewGroup>
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount9 = carimagenew9?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount9);
                                                                const displayTotal = Math.max(imageCount9, imageCount9);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimagenew9?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimagenew9?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            countRender: (index, total) => {
                                                                const imageCount12 = carimagenew12?.length || 0; // นับจำนวนภาพจริง
                                                                const displayedIndex = Math.min(index, imageCount12);
                                                                const displayTotal = Math.max(imageCount12, imageCount12);
                                                                return `${displayedIndex} / ${displayTotal}`;
                                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onActive,
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <>
                                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                        {carimagenew12?.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Image
                                                                                        width={auto}
                                                                                        key={index}
                                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                    />
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                    <Row style={{ zIndex: 2 }}>
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                            <DownloadOutlined onClick={onDownload} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                            <UndoOutlined onClick={onReset} />
                                                                        </Space>
                                                                    </Row>
                                                                </>
                                                            ),
                                                            onChange: (index) => {
                                                                setCurrent(index);
                                                            },
                                                        }}
                                                    >
                                                        <Row gutter={32} justify={'center'}>
                                                            {carimagenew12?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={150}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Image.PreviewGroup>
                                                </Space>
                                                {/* <Row gutter={32} justify={'center'}>
                                                    {dataImg?.map((e, index) => {
                                                        var PreAaproveSplit = []
                                                        PreAaproveSplit = e.pathImage.split("/")
                                                        if (PreAaproveSplit[3] === "13") {
                                                            if (e.type === 12) {
                                                                return (
                                                                    <div key={index} >
                                                                        <img
                                                                            onClick={(v) => openModalImages(e)}
                                                                            style={{ width: 200 }}
                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index}`}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                        return null;
                                                    })}
                                                </Row> */}
                                            </Row>
                                            <Divider />
                                        </> : null
                                }

                                <Row justify={'left'}><b><u>เอกสารขอรี/ปรับ/เปลี่ยน</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const imageCount14 = carimage14?.length || 0; // นับจำนวนภาพจริง
                                                    const displayedIndex = Math.min(index, imageCount14);
                                                    const displayTotal = Math.max(imageCount14, imageCount14);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage14?.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage14?.map((e, index) => {
                                                    return (
                                                        <>
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </Image.PreviewGroup>
                                    </Space>
                                </Row>
                                <Divider />
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    {/* <Button
                                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                        onClick={printdrescription}
                                        icon={<PrinterOutlined />}
                                    >
                                        พิมพ์รายละเอียด
                                    </Button> */}
                                    {
                                        dataAP?.loanId > 0 && (idSep === "1" || idSep === "3") && (idDepartment === "1" || idDepartment === "4")
                                            // dataFromTable?.approvalStatus === "อนุมัติแบบมีเงื่อนไข" ||
                                            //     dataFromTable?.approvalStatus === "อนุมัติ" ||
                                            //     dataFromTable?.approvalStatus === "ลูกค้าคอนเฟิร์ม" ||
                                            //     dataFromTable?.approvalStatus === "รอทำสัญญา"
                                            ?
                                            <>
                                                {checkProductLoanType(dataFromTable?.productLoanType)
                                                    ?
                                                    <>
                                                        <Button
                                                            disabled
                                                            type='primary'
                                                            style={{ margin: '5px', background: '#blue' }}
                                                            onClick={handleOpenEditApproveAccept}
                                                            icon={<TagsOutlined />}
                                                        >
                                                            แก้ไขยอดอนุมัติ
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button
                                                            type='primary'
                                                            style={{ margin: '5px', background: 'blue' }}
                                                            onClick={handleOpenEditApproveAccept}
                                                            icon={<TagsOutlined />}
                                                        >
                                                            แก้ไขยอดอนุมัติ
                                                        </Button>
                                                    </>

                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        (idSep === "1" || idSep === "3") && (idDepartment === "1" || idDepartment === "4") ?
                                            <>
                                                {
                                                    dataFromTable?.approvalStatus === "อนุมัติ" ?
                                                        <>
                                                            {
                                                                checkProductLoanType(dataFromTable?.productLoanType) ?
                                                                    <>
                                                                        <Tooltip title="*เคส รี/ปรับ/เปลี่ยน ไม่สามารถเปลี่ยนประเภทขอกู้ได้!" color={"red"}>
                                                                            <Button
                                                                                disabled
                                                                                type='primary'
                                                                                style={{ margin: '5px', background: '#F39C12' }}
                                                                                onClick={handleOpenChangeStatusPLT}
                                                                                icon={<TagsOutlined />}
                                                                            >
                                                                                แก้ไขประเภทขอกู้
                                                                            </Button>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <>

                                                                        <Button
                                                                            type='primary'
                                                                            style={{ margin: '5px', background: '#F39C12' }}
                                                                            onClick={handleOpenChangeStatusPLT}
                                                                            icon={<TagsOutlined />}
                                                                        >
                                                                            แก้ไขประเภทขอกู้
                                                                        </Button>
                                                                    </>
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    dataFromTable?.loanId > 0 ?
                                                        <>
                                                            {
                                                                checkApprovalStatus(dataFromTable?.approvalStatus) || checkApprovalStatus(dataFromTable?.approvalStatusLoan) ?
                                                                    <>
                                                                        <Button
                                                                            type='primary'
                                                                            style={{ margin: '5px', background: '#F39C12' }}
                                                                            onClick={handleOpenChangeStatus}
                                                                            icon={<TagOutlined />}
                                                                        >
                                                                            แก้ไขสถานะ
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title="*สามารถแก้ไขสถานะได้เฉพาะ สถานะ ไม่อนุมัติ/อนุมัติ/อนุมัติแบบมีเงื่อนไข!" color={"red"}>
                                                                            <Button
                                                                                disabled
                                                                                type='primary'
                                                                                style={{ margin: '5px', background: '#F39C12' }}
                                                                                onClick={handleOpenChangeStatus}
                                                                                icon={<TagOutlined />}
                                                                            >
                                                                                แก้ไขสถานะ
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </>
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    checkCancelCase ? <Button type='primary' style={{ margin: '5px' }} danger onClick={handleCancelCase}>ปฏิเสธคำร้อง</Button> : null
                                                }
                                                {
                                                    dataLoanRe?.approvalStatus === "ไม่อนุมัติ" || dataLoanRe?.approvalStatus === "ปฏิเสธคำร้อง" ?
                                                        <Button type='primary' style={{ margin: '5px' }} onClick={handleOpenEdit}>แก้ไขวันที่นำเคสกลับมารีใหม่ได้</Button>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        dataLoanRe?.reLoanId > 0 ?
                                            <Button type='primary' style={{ backgroundColor: "#FEA13F", margin: '5px' }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                            : null
                                    }
                                    <Button
                                        type='primary'
                                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                        onClick={newPrint}
                                        icon={<PrinterOutlined />}
                                    >
                                        พิมพ์ใบจอง
                                    </Button>
                                    {
                                        checkPosi === "ad" ?
                                            <>
                                                {
                                                    dataLoan?.approvalStatus === "ลูกค้าคอนเฟิร์ม" ?
                                                        <>
                                                            <Button
                                                                type='primary'
                                                                danger
                                                                style={{ margin: '5px' }}
                                                                onClick={showConfirmCancel}
                                                            >
                                                                ยกเลิกการคอนเฟิร์ม
                                                            </Button>
                                                            <Button
                                                                type='primary'
                                                                style={{ margin: '5px', backgroundColor: 'green' }}
                                                                onClick={handleOpenAddNumber}
                                                            >
                                                                ส่งเอกสารไปตรวจสอบ
                                                            </Button>
                                                        </>
                                                        : dataLoan?.approvalStatus === "ไม่อนุมัติ" ?
                                                            <>
                                                                {/* ปิดวันที่ 13/02/2024 ตามคำสั่งหัวหน้า */}
                                                                {/* เปิด วันที่ 29/02/2024 */}
                                                                <Button
                                                                    type='primary'
                                                                    style={{ margin: '5px' }}
                                                                    onClick={openModal}
                                                                >
                                                                    เพิ่ม/แก้ไข
                                                                </Button>
                                                            </>
                                                            : null
                                                }
                                            </>
                                            : checkPosi === "pg" ?
                                                null
                                                : checkPosi === "dc" ?
                                                    null
                                                    : checkPosi === "mg" ?
                                                        null
                                                        :
                                                        null
                                    }
                                </Col>
                            </Card>
                        </div>
                    </Form>
                </Row>
                {contextHolder}
            </Modal >
            {
                modalPreemptionCertificateNEW ?
                    <ModalPreemptionCertificateNEW
                        open={modalPreemptionCertificateNEW}
                        close={setModalPreemptionCertificateNEW}
                        notes={dataNote}
                        data={dataCustomer}
                        guarantor={dataGuarantor}
                        typeLoan={typeLoans}
                        approval={approval}
                        dataCarLand={dataCarLand}
                        dataLoan={dataLoan}
                        dataOldloan={dataOldLoan}
                        dataAP={dataAP}
                        // data={dataModalCars}
                        monthlyPaymentCAL={monthlyPaymentCAL}
                        installmentWithInterestCAL={installmentWithInterestCAL}
                        getData={dataCustomer}
                        mixBroker={mixBroker}
                    />
                    : null
            }
            {
                modalDrescriptionPrint ?
                    <ModalDrescriptionPrint
                        open={modalDrescriptionPrint}
                        close={setModalDrescriptionPrint}
                        getData1={dataCustomer}
                        printData={dataGuarantor}
                        sendtypesDes={typeLoans}
                        //car
                        resultRateCarDATA={resultRateCarDATA}
                        newLoanTermCarDATA={newLoanTermCarDATA}
                        newResultPriceCarDATA={newResultPriceCarDATA}
                        notes={dataNote} //ส่ง notes แบบเอาตัวล่าสุด
                        //car
                        arr={arr}
                        interestt={interestt}
                        installmentWithInterestt={installmentWithInterestt}
                        //land
                        loanTermDATA={loanTermDATA}
                        resultRateDATA={resultRateDATA}
                        resultPriceDATA={resultPriceDATA}
                    />
                    : null
            }
            {
                checkOpen ?
                    <Installment
                        open={checkOpen}
                        close={setCheckOpen}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                checkOpenHIRE ?
                    <HirePurchase
                        open={checkOpenHIRE}
                        close={setCheckOpenHIRE}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                isModalOpenAddNumber ?
                    <ModalAddContractNumber
                        open={isModalOpenAddNumber}
                        close={setIsModalOpenAddNumber}
                        dataModalCars={dataCarLand}
                        dataModalLand={dataCarLand}
                        closeall={close}
                        // syncData={syncData}
                        dataFromTable={dataFromTable}
                    />
                    : null
            }
            {
                modalnote ?
                    <Note
                        open={modalnote}
                        close={setModalNote}
                        appStatus={appStatus}
                        shootdata={dataFromTable}
                        resetpage={setResetpage}
                        closeAll={close}
                    />
                    : null
            }
            {
                openPreeApprove ?
                    <ModalPreApproveEDIT
                        open={openPreeApprove}
                        close={setOpenPreeApprove}
                        dataFromTable={dataFromTable}
                        resetpage={setResetpage}
                        closeAll={close}
                    />
                    : null
            }
            {
                isModalNoteChangeStatus ?
                    <NoteChangeStatus
                        open={isModalNoteChangeStatus}
                        close={setIsModalNoteChangeStatus}
                        dataFromTable={dataFromTable}
                        typeOpenNote={typeOpenNote}
                        typeLoans={typeLoans}
                        resetpage={setResetpage}
                        closeAll={close}
                    />
                    : null
            }
            {
                isModalAccept ?
                    <Edit_Accept
                        open={isModalAccept}
                        close={setIsModalAccept}
                        dataFromTable={dataFromTable}
                        dataCarLand={dataCarLand}
                        dataCarLands={dataCarLands}
                        typeOpenNote={typeOpenNote}
                        typeLoans={typeLoans}
                        sendback={setResetpage}
                        closeAll={close}
                    />
                    : null
            }
            {
                isModalCancelCase ?
                    <NoteRe
                        open={isModalCancelCase}
                        close={setIsModalCancelCase}
                        loanId={dataFromTable?.reLoanId}
                        CloseAll={handleCancel}
                        typeNote={"ปฏิเสธคำร้อง"}
                        allData={dataCustomer}
                        dataFromTable={dataFromTable}
                    />
                    : null
            }
            {
                isModalTable ?
                    <ModalInstallment
                        open={isModalTable}
                        close={setIsModalTable}
                        contno={dataFromTable?.ISSUNO}
                    />
                    : null
            }
            {
                isModalEditDate ?
                    <ModalEditDate
                        open={isModalEditDate}
                        close={setIsModalEditDate}
                        loanRe={dataLoanRe}
                        dataFromTable={dataFromTable}
                        setResetPage={setResetpage}
                    />
                    : null
            }
            {
                openShowImage ?
                    <ModalShowImages
                        open={openShowImage}
                        close={setOpenShowImage}
                        carimage1={carshowMadol}
                        nuarantorNew={nuarantorNew}
                        nuarantorNum={nuarantorNum}
                        setGuarantorNum={setGuarantorNum}
                    />

                    : null
            }
        </div >
    )
};

export default ModalInfoADPGDC