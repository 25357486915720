import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { DataExcelPay } from './excel/data_excel';
import { mainDataExcel, headersThai, headersColumsName, mainDataExcelDetail, headersThaiDetail, headersColumsNameDetail } from './excel/cl';
import { columns, columnsDetail } from './table/cm';
import { currencyFormatOne, msSale, msError, msDue, msNil, msLimit, msCreateDue, msDueNil, msConnt, msPAYCODE, msOK } from "../file_mid/allFormat";
import { calPay } from "./cal/Cal";

export default function Main() {
  const token = localStorage.getItem('token')
  const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [checkGuas, setCheckGuas] = useState("")
  const [isClicked, setIsClicked] = useState(true);
  const [checkDue, setCheckDue] = useState(false)
  const [checkButton, setCheckButton] = useState(true)
  const [checkButtonPay, setCheckButtonPay] = useState(true)
  const [checkCt, setCheckCt] = useState(true)
  const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState({
    CONTNO: "",
    // CONTNO: "3-0001310",
    money: 0,
    sale: 0,
    date: currentDateTime,
    payType: "จ่ายเข้าตารางดิว",
    PAYCODE: "",
  });
  const [getPAYCODE, setGetPAYCODE] = useState([]);
  const [dataAll, setDataAll] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableDetail, setDataTableDetail] = useState([]);
  const [dataPay, setDataPay] = useState([]);
  const [calData, setCalData] = useState({
    ALLPAYAMT: 0,
    ALLPAYFOLLOW: 0,
    ALLPAYINTEFF: 0,
    ALLPAYTON: 0,
    ALLCAL: 0,
    text: "",
  });
  const [sum, setSum] = useState({
    sumAmt: 0,
    sumFollow: 0,
    sumAll: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    setLoading(true)
    // await axios.get("http://localhost:8070/api-db2/get-paytype")
    await axios.get("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/get-paytype")
      .then(async (res) => {
        console.log("loadData", res.data)
        if (res.data) {
          setGetPAYCODE(res.data)
        } else {
          setGetPAYCODE([])
        }
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })

  }

  const changePAYCODE = (value) => {
    console.log("value =:", value)
    setDataSend({ ...dataSend, PAYCODE: value })
  }

  const funcSale = (value) => {
    console.log("vv", value)
    console.log("calData?.ALLPAYINTEFF", calData?.ALLPAYINTEFF)
    if (value <= calData?.ALLPAYINTEFF) {
      setDataSend({ ...dataSend, sale: parseFloat(value) })
    } else {
      setDataSend({ ...dataSend, sale: 0 })
      form.setFieldsValue({ sale: "", });
      msSale(api, "top")
    }
  }
  const onChangecheckGuas = (value) => {
    // console.log(value)
    setCheckGuas(value);
    setDataSend({ ...dataSend, payType: value })
  };

  const handleSubmitPay = async () => {
    if (dataSend.CONTNO !== "" && dataSend.money > 0) {
      setCheckButtonPay(false)
      let mixData
      if (dataSend?.sale > 0) {
        mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: dataSend.sale, date: dataSend.date, payType: dataSend.payType }
      } else {
        mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: 0, date: dataSend.date, payType: dataSend.payType }
      }
      console.log("mixData", mixData)
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post(checkcontnopgre, dataSend, { headers: headers })
      // await axios.post("http://localhost:8070/api-db2/payamt", mixData)
      await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/payamt", mixData)
        .then(async (res) => {
          if (res.status === 200) {
            setDataAll(res.data)
            console.log("res.data payamt", res.data)
            // await calPay(res.data, "")
            const result = await calPay(res.data, "");
            setCalData(result)
            setDataTable(res.data.result)
            if (res.data?.detailPay) {
              setDataTableDetail(res.data.detailPay)
            } else {
              setDataTableDetail([])
            }
          } else if (res.status === 202) {
            // setDataAll(res.data)
            console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
            // await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน")
            const result = await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน");
            setCalData(result)
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
          } else if (res.status === 203) {
            console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ", res.data)
            // setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: res.data.ccb, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msLimit(api, "top")
          } else if (res.status === 204) {
            console.log("กรอกยอดเงินไม่ถูกต้อง")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "กรอกยอดเงินไม่ถูกต้อง" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msNil(api, "top")
          } else if (res.status === 205) {
            console.log("เงินน้อยกว่าดอกเบี้ยสร้างดิว")
            setDataTable([])
            setDataTableDetail([])
            msCreateDue(api, "top")
          } else if (res.status === 208) {
            console.log("ไม่พบดิวล่าสุด")
            setDataTable([])
            setDataTableDetail([])
            msDueNil(api, "top")
          } else {
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msNil(api, "top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataTableDetail([])
          // setDataPay([])
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msNil(api, "top")
    }
  }

  const handleSubmit = async () => {
    if (dataSend?.CONTNO !== "") {
      setCheckCt(false)
      setCheckButtonPay(true)
      setCheckButton(true)
      setIsClicked(true)
      let sumAmt = 0;
      let sumFollow = 0;
      let sumAll = 0;
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post(checkcontnopgre, dataSend, { headers: headers })
      // await axios.post("http://localhost:8070/api-db2/load-payamt", dataSend)
      await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/load-payamt", dataSend)
        .then(async (res) => {
          console.log("res", res.data)
          setDataSend({ ...dataSend, money: 0, sale: 0 })
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          form.setFieldsValue({
            money: "",
            sale: "",
          });
          if (res.status === 200) {
            onChangecheckGuas("จ่ายเข้าตารางดิว")
            console.log("res.data load-payamt", res.data)
            setDataPay(res.data)
            if (res.data?.result) {
              setCheckDue(true)
              setDataTable(res.data.result)
              await res.data.result.forEach((item) => {
                sumAmt += item.DUEAMT - item.PAYAMT;
                sumFollow += item.FOLLOWAMT - item.PAYFOLLOW;
              });
              sumAll = sumAmt + sumFollow
              setSum({ sumAmt: sumAmt, sumFollow: sumFollow, sumAll: sumAll })
              if (res.data?.detailPay) {
                setDataTableDetail(res.data?.detailPay)
              } else {
                setDataTableDetail([])
              }
              console.log("sumAmt", sumAmt)
              console.log("sumFollow", sumFollow)
            } else {
              setCheckDue(false)
              setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0 })
              setDataTable([])
              setDataTableDetail([])
            }
          } else if (res.status === 201) {
            console.log("res.data", res.data)
            setCheckCt(true)
            onChangecheckGuas("")
            setCheckDue(false)
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0 })
            msDue(api, "top")
          } else {
            setCheckCt(true)
            onChangecheckGuas("")
            setCheckDue(false)
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0 })
            msConnt(api, "top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setCheckCt(true)
          onChangecheckGuas("")
          setCheckDue(false)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataTableDetail([])
          setDataPay([])
          msError(api, "top")
          setLoading(false)
        })
    } else {

    }
  }

  const insert = async () => {
    let mix = {}
    if (dataAll !== null) {
      if (dataSend.PAYCODE !== "") {
        console.log("dataAll", dataAll)
        const tk = JSON.parse(token)
        const headers = {
          "Authorization": `Bearer ${tk}`,
          "Menu": JSON.stringify("5")
        }
        mix = { ...dataAll, PAYCODE: dataSend.PAYCODE }
        console.log("mix", mix)
        setLoading(true)
        // await axios.post("http://localhost:8060/mid-call/pay", mix, { headers: headers }) // api-call
        await axios.post("https://api-call-sync-i67f6gaaqa-as.a.run.app/mid-call/pay", mix, { headers: headers }) // api-call
          .then(async (res) => {
            if (res.status === 200) {
              console.log("ok", res.data)
              msOK(api, "top")
            }
            setLoading(false)
          })
          .catch((err) => {
            console.log("err", err)
            msError(api, "top")
            setLoading(false)
          })
      } else {
        msPAYCODE(api, "top")
      }
    } else {
      msError(api, "top")
    }
  }

  const exportToExcel = async () => {
    if (dataTable?.length > 0) {
      var allData = []
      const workbook = new ExcelJS.Workbook();
      if (isClicked) {
        allData = await DataExcelPay(allData, dataTable);
        // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          // กำหนดความกว้างของแต่ละคอลัมน์
          worksheet.columns = mainDataExcel
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        // หัวข้อใน Excel
        const headers_thai = headersThai
        const headers_colums_name = headersColumsName
        // // นำหัวข้อลงในแถวแรกของ Excel
        // // เพิ่มข้อมูลลงในแต่ละชีท 
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        // addSheet(`รายงานรับเงินสัญญา ${"3-0001310"}`, allData, headers_colums_name, headers_thai);
        // console.log("addSheet")
        // บันทึกไฟล์ Excel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPay ${dataSend.CONTNO}.xlsx`);
          // link.setAttribute('download', `dataPay ${"3-0001310"}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else {
        allData = await DataExcelPay(allData, dataTableDetail);
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          worksheet.columns = mainDataExcelDetail
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        const headers_thai = headersThaiDetail
        const headers_colums_name = headersColumsNameDetail
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPayDetail ${dataSend.CONTNO}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    } else {
      console.log("ไม่มีข้อมูล")
    }
  }

  const buttonAmt = async () => {
    setCheckButton(true)
    setIsClicked(true)
  }
  const buttonPay = async () => {
    setCheckButton(false)
    setIsClicked(false)
  }

  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">

              <Col span={24}>
                <div className='text-center'>
                  <h2>รับเงิน test</h2>
                </div>
                <div className='text-right'>
                  <Space>
                    <Button onClick={exportToExcel}>Export to Excel</Button>
                    <Button type='primary' onClick={insert} >Insert-mysql</Button>
                  </Space>
                </div>
              </Col>

              {/* <Col span={24} style={{ textAlign: 'right' }}>

              </Col> */}
              <Divider />
              <Row gutter={16}>
                <Col span={8}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Space>
                      <Form.Item label="วันที่ชำระ">
                        <DatePicker format={'YYYY-MM-DD'}
                          defaultValue={currentDateTime}
                          style={{ height: '30px', width: '100%' }}
                          onChange={(e) => {
                            if (e) {
                              setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                            }
                          }} />
                      </Form.Item>
                      <Form.Item label=""></Form.Item>
                    </Space>
                    <Space>
                      <Form.Item label="เลขสัญญา" style={{ height: '30px', width: '100%' }}>
                        {/* <Input disabled value={"3-0001310"} onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input> */}
                        <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input>
                      </Form.Item>
                      <Form.Item label="">
                        <Button type='primary' onClick={handleSubmit} >ตกลง</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    {
                      calData?.ccb > 0 ?
                        <b> <div
                          style={{ margin: 0, }}
                        >
                          <span>ยอดปิด : </span>
                          <b style={{ fontSize: '20px', color: 'red' }}><u>{currencyFormatOne(calData?.ccb)}</u></b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                          </span>
                        </div>
                          <Divider />
                        </b>
                        : dataPay?.ccb > 0 ?
                          <b> <div
                            style={{ margin: 0, }}
                          >
                            <span>ยอดปิด : </span>
                            <b style={{ fontSize: '20px', color: 'red' }}><u>{currencyFormatOne(dataPay?.ccb)}</u></b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                            <Divider />
                          </b>
                          : null
                    }
                    <Col span={16}>
                      <Form.Item label="ค้างค่างวด" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumAmt)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                      <Form.Item label="ค้างค่าทวงถาม" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumFollow)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                      <Form.Item label="รวม" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumAll)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                    </Col>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รับเงิน" bordered={false}>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายเข้าตารางดิว"}
                      isPacked={true}
                      onChange={() => onChangecheckGuas("จ่ายเข้าตารางดิว")}
                    >จ่ายเข้าตารางดิว
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                      isPacked={true}
                      disabled={checkDue}
                      onChange={() => onChangecheckGuas("จ่ายแบบไม่เข้าตารางดิว")}
                    >จ่ายแบบไม่เข้าตารางดิว
                    </Checkbox>
                    <Divider />
                    {
                      checkGuas !== "" && dataTable?.length > 0 ?
                        <>
                          <Form.Item label="ประเภท" name="PAYCODE">
                            <Select
                              showSearch
                              style={{ width: '100%', height: '40px' }}
                              onChange={changePAYCODE}
                              optionFilterProp="children"
                              placeholder="Search to Select"
                              filterOption={(inputValue, option) =>
                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                              }
                            >
                              {getPAYCODE?.map((e) => {
                                return (
                                  <Select.Option key={e.keyId} value={e.PAYCODE}>
                                    {e.PAYCODE} ({e.PAYDESC})
                                    {/* ({e.PAYDESC}) */}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item label="จำนวนเงิน" name="money">
                            <InputNumber
                              disabled={checkCt}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              size="large"
                              style={{ width: '100%', color: 'black' }}
                              onChange={(value) => setDataSend({ ...dataSend, money: parseFloat(value) })}
                            />
                          </Form.Item>
                          <Form.Item label="ใส่ส่วนลด" name="sale">
                            <InputNumber
                              disabled={checkCt}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              size="large"
                              style={{ width: '100%', color: 'black' }}
                              onChange={(value) => funcSale(value)}
                            />
                          </Form.Item>
                          <Form.Item label="">
                            <Button type='primary' onClick={handleSubmitPay} >คำนวณ</Button>
                          </Form.Item>
                        </>
                        : null
                    }
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รายละเอียดที่คำนวณได้" bordered={false}>
                    <Col span={24}>
                      <Form.Item label="รับเงิน" name="money" style={{ margin: 0 }}>
                        <InputNumber
                          disabled
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          size="large"
                          style={{ width: '100%', color: 'black' }}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    {
                      calData.text === "" ?
                        <Col span={16}>
                          <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          {
                            calData?.Dok > 0 ?
                              <Form.Item label="ดอกเบี้ยรายวัน" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.Dok)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          {
                            calData?.PaySm > 0 ?
                              <Form.Item label="ตัดเข้าเงินต้นคงเหลือ" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.PaySm)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          {
                            dataSend?.sale > 0 ?
                              <Form.Item label="ส่วนลด" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(dataSend?.sale)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLCAL)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                        </Col>
                        : calData.text === "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ" || calData.text === "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" ?
                          <Col span={16}>
                            <Form.Item label="Note" style={{ margin: 0 }}>
                              <Tag color="geekblue">{calData.text}</Tag>
                            </Form.Item>
                            <Form.Item label="ยอดที่รับได้ไม่เกิน" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(dataPay?.ccb)}</b>
                              <span style={{ marginLeft: '10px' }}>บาท</span>
                            </Form.Item>
                          </Col>
                          : calData.text === "ไม่ตัดเข้าตารางดิว" ?
                            <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                              <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                            </Col>
                            : <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                            </Col>
                    }
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Col span={24}>
                <div className='text-right'>
                  <Space>
                    <Button onClick={buttonAmt} style={{ background: isClicked ? '#00FF00' : 'white' }}>ตารางค่างวด</Button>
                    <Button disabled={checkButtonPay} onClick={buttonPay} style={{ background: !isClicked ? '#00FF00' : 'white' }}>ตารางรายละเอียดการจ่าย</Button>
                  </Space>
                </div>
              </Col>
              {
                checkButton ?
                  <Table
                    rowKey={(record) => record.uid}
                    dataSource={dataTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  :
                  <Table
                    rowKey={(record) => record.uid}
                    dataSource={dataTableDetail}
                    columns={columnsDetail}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
              }
            </Spin>
          </Col>
        </Row >
      </Form >
      {contextHolder}
    </Card >
  )
}
