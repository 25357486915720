import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tag, Space, Card, Form, Divider, Checkbox, Image, Modal, notification, Radio, Input } from 'antd';
import { PrinterOutlined, SendOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux';
import Note from "./Note";
import dayjs from 'dayjs';
import axios from "axios";
import ViewGuarantor from "./Guarantor/ViewGuarantor";
import ModalAddGuarantor from "./Guarantor/ModalAddGuarantor";
import ModalEditGuarantor from "./Guarantor/ModalEditGuarantor";
import ModalDrescriptionPrint from "./ModalDrescriptionPrint";
// import { addGuarantor } from '../../../../redux/Guarantor';
import TextArea from "antd/es/input/TextArea";
import { checkgu, findlandpn, loadOJS, resendcarpg, resendlandpg, getImagess, getImagessPre, getAllFolderGuaImg, getImagessGua, loadDetailAunSendPG } from "../../../file_mid/all_api";
import ModalShowImages from "../../../ModalShowImageSlide/ModalShowImages";
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from "async";

function Conclusion({ close, changepage, getData1, page, sendback, closeAll, dataBroker, dataFromTable }) {

  const token = localStorage.getItem('token');
  const [form] = Form.useForm()
  // const dispatch = useDispatch()

  const customers = useSelector(state => state.customers)
  const guarantor = useSelector(state => state.guarantor)

  const [dataPost] = useState(customers.data); //มาจากรีดัค
  const [getNotification, setGetNotification] = useState();
  const [modalAddGuarantor, setModalAddGuarantor] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  // const [dataCareer, setDataCareer] = useState();
  const [modalView, setModalView] = useState(false)

  const [modalnote, setModalNote] = useState(false)
  const [appStatus, setAppStatus] = useState({})
  const [notes, setNotes] = useState([])
  const [notesRe, setNotesRe] = useState([])
  const [types] = useState(guarantor?.data?.typeLoan)
  // const [btnDisabled, setBtnDisabled] = useState(true)
  const [getData] = useState(getData1)
  const [getCarId] = useState(getData1?.carLoanDetails?.cars?.carId)
  const [dataedit] = useState([]); // for edit
  const [index1] = useState();
  const [guarantorData, setGuarantorData] = useState([])
  const [api, contextHolder] = notification.useNotification();
  const [imageBlobzz, setImageBlobzz] = useState([]);

  const [address] = useState(getData1.address)
  const [dataPhoneCus] = useState(getData1?.phones);
  const [, setDataEmail] = useState([]);
  const [dataSocials, setDataSocials] = useState([]);
  // const [dataGuarantor, setDataGuarantor] = useState(guarantor.data);
  const [, setLoading] = useState(false);
  const [checkApprove, setCheckApprove] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState();
  const [getimages, setGetImages] = useState();

  // ตัวติ๊ก ผ่าน/ไม่ผ่าน
  const [npCustomer, setNpCustomer] = useState(true);
  const [npAddress] = useState(true);
  const [npLoan, setNpLoan] = useState(true);
  const [npOldLoan, setNpOldLoan] = useState(true);
  const [npProduct, setNpProduct] = useState(true);
  const [npCareer, setNpCareer] = useState(true);
  const [npGuarantor, setNpGuarantor] = useState(true);

  const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
  const [notPassData, setNotPassData] = useState() // เก็บข้อมูลการไม่ผ่านพิจารณา
  const [app, setApp] = useState()

  const [newDataLand, setNewDataLand] = useState([]);

  const [checkSendNEW, setCheckSendNEW] = useState(false)
  const [checkBroker, setCheckBroker] = useState(false)

  const [brokerName, setbrokerName] = useState()
  const [data627, setData627] = useState()
  const [data630, setData630] = useState()
  const [loanRe, setLoanRe] = useState()
  const [isu, setIsu] = useState()

  const [moonnee, setMoonnee] = useState()
  const [hug, setHug] = useState()
  const [kong, setKong] = useState()
  const [jaimoney, setJaimoney] = useState()
  const [yodjut, setYodjut] = useState()
  const [dateceo, setDateceo] = useState()
  const [exp, setExp] = useState()
  const [ngod, setNgod] = useState()
  const [pon, setPon] = useState()
  const [dataApproved, setDataApproved] = useState();

  // image
  const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
  const [openShowImage, setOpenShowImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // แยก type image car
  const [carimage1, setCarImage1] = useState([]);
  const [carimagenew1, setCarImagenew1] = useState([]);
  const [carimage2, setCarImage2] = useState([]);
  const [carimagenew2, setCarImagenew2] = useState([]);
  const [carimage3, setCarImage3] = useState([]);
  const [carimagenew3, setCarImagenew3] = useState([]);
  const [carimage4, setCarImage4] = useState([]);
  const [carimagenew4, setCarImagenew4] = useState([]);
  const [carimage5, setCarImage5] = useState([]);
  const [carimagenew5, setCarImagenew5] = useState([]);
  const [carimage6, setCarImage6] = useState([]);
  const [carimagenew6, setCarImagenew6] = useState([]);
  const [carimage7, setCarImage7] = useState([]);
  const [carimagenew7, setCarImagenew7] = useState([]);
  const [carimage8, setCarImage8] = useState([]);
  const [carimagenew8, setCarImagenew8] = useState([]);
  const [carimage9, setCarImage9] = useState([]);
  const [carimagenew9, setCarImagenew9] = useState([]);
  const [carimage10, setCarImage10] = useState([]);
  const [carimagenew10, setCarImagenew10] = useState([]);
  const [carimage11, setCarImage11] = useState([]);
  const [carimagenew11, setCarImagenew11] = useState([]);
  const [carimage12, setCarImage12] = useState([]);
  const [carimagenew12, setCarImagenew12] = useState([]);
  const [carimage14, setCarImage14] = useState([]);
  const [carimagenew14, setCarImagenew14] = useState([]);
  const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
  const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
  const [current, setCurrent] = React.useState(0);
  const [selectedImages, setSelectedImages] = useState([]);


  const { confirm } = Modal
  var note = notes?.length;
  var lastIndex = note - 1;
  var note = notesRe?.length;
  var lastIndexRe = note - 1;

  useEffect(() => {
    // console.log("con ad", dataFromTable)
    if (getData1?.carLoanDetails?.approvalStatus === 'ผ่านการวิเคราะห์' || getData1?.landLoanDetails?.approvalStatus === 'ผ่านการวิเคราะห์') {
      setTimeout(() => {
        ApproveSuccess()
        handleCancel()
      }, 1500)
    } else if (getData1?.carLoanDetails?.approvalStatus === 'ไม่ผ่านการวิเคราะห์' || getData1?.landLoanDetails?.approvalStatus === 'ไม่ผ่านการวิเคราะห์') {
      setTimeout(() => {
        onUnApprove()
        handleCancel()
      }, 1500)
    }
    setDataEmail({ ...getData?.emails })
    setDataSocials({ ...getData?.socials })
    if (guarantor?.data?.typeLoan === "car") {
      if (getData1?.carLoanDetails?.checkGua === "") {
        setSelectedCheckbox()
      } else {
        setSelectedCheckbox(getData1?.carLoanDetails?.checkGua)
      }
      calYellowCard(getData1?.carLoanDetails?.cars?.carsApproveCeo)
      setImageBlobzz(getData1?.carLoanDetails?.cars?.img)
      setApp(getData1?.carLoanDetails?.approvalStatus)
      var dataloadGua = { typeLoan: guarantor?.data?.typeLoan, loanId: getData1?.carLoanDetails?.loanId }
      setNotes(getData1?.carLoanDetails?.notes)
      setLoanRe(getData1?.carLoanDetailsRe)
      setNotesRe(getData1?.carLoanDetailsRe?.notes)
      if (getData1?.carLoanDetails?.cars?.ISSUNO !== "" || getData1?.carLoanDetails?.cars?.ISSUNO !== null) {
        setIsu(getData1?.carLoanDetails?.cars?.ISSUNO)
      }
      form.setFieldsValue({
        ...getData1?.carLoanDetails?.noteResend
      })
    } else if (guarantor?.data?.typeLoan === "land") {
      loadDataNew()
      if (getData1?.landLoanDetails?.checkGua === "") {
        setSelectedCheckbox()
      } else {
        setSelectedCheckbox(getData1?.landLoanDetails?.checkGua)
      }
      setImageBlobzz(getData1?.landLoanDetails?.land?.img)
      setApp(getData1?.landLoanDetails?.approvalStatus)
      var dataloadGua = { typeLoan: guarantor?.data?.typeLoan, loanId: getData1?.landLoanDetails?.landLoanId }
      setNotes(getData1?.landLoanDetails?.notes)
      setNotesRe(getData1?.landLoanDetailsRe?.notes)
      setLoanRe(getData1?.landLoanDetailsRe)
      if (getData1?.landLoanDetails?.land?.ISSUNO !== "" || getData1?.landLoanDetails?.land?.ISSUNO !== null) {
        setIsu(getData1?.landLoanDetails?.land?.ISSUNO)
      }
      form.setFieldsValue({
        ...getData1?.landLoanDetails?.noteResend
      })
    }
    loadGuarantors(dataloadGua)
    loadCareer()
  }, [getData1, guarantor?.data?.typeLoan])


  useEffect(() => {
    imageBlobzz?.map((e, index) => {
      var PreAaproveSplit = []
      PreAaproveSplit = e.pathImage.split("/")
      if (PreAaproveSplit[3] === "13") {
        setCheckSendNEW(true)
      }
    })
    loadImage(guarantor.data.typeLoan)
  }, [imageBlobzz])

  useEffect(() => {
    if (getNotification === 1) {
      ApproveSuccess("top")
      setGetNotification(0)
    }
    if (getNotification === 2) {
      UnApproveSuccess("top")
      setGetNotification(0)
    }
    if (getNotification === 3) {
      RejectSuccess("top")
      setGetNotification(0)
    }
  }, [modalnote])


  useEffect(() => {
    setNotPassData({ ...notPassData, npCustomer, npAddress, npLoan, npOldLoan, npProduct, npCareer, npGuarantor })
  }, [npCustomer, npAddress, npLoan, npOldLoan, npProduct, npCareer, npGuarantor])

  useEffect(() => {
    newDataLand.map((e, index) => {
      form.setFieldsValue({
        [`mixNote${index}`]: e?.approval?.mixNote,
      })
    })
  }, [newDataLand])

  useEffect(() => {
    if (dataBroker?.length > 0) {
      setCheckBroker(true)
      dataBroker?.map((data) => {
        if (data.snam !== "" || data.snam !== null || data.snam !== undefined) {
          // console.log("name", data.payamt)
          setbrokerName(data?.snam + data?.firstname + " " + data?.lastname)
        }
        if (data.payfor === "630") {
          // console.log("map630", data.payamt)
          setData630(data.payamt)
        }
        if (data.payfor === "627") {
          // console.log("map627", data.payamt)
          setData627(data.payamt)
        }
      })
    } else {
      setCheckBroker(false)
    }
  }, [dataBroker])



  const onDownload = () => {
    const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
    const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

    // ใช้ fetch ดึงรูปภาพจาก path
    fetch(imageObj.path)
      .then((response) => response.blob()) // เปลี่ยน response เป็น blob
      .then((blob) => {
        // สร้าง Blob URL สำหรับการดาวน์โหลด
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
        document.body.appendChild(link);
        link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
        URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
        link.remove(); // ลบ link ออกจาก DOM
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  const handleSelectImage = (e, image) => {
    // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
    setSelectedImages(prevSelected => [...prevSelected, image]);
  };


  ////////////////////////////////////////////////////////////////
  const openModalImages = (e, sg) => {
    if (e.type === 1) {
      setCarShowModal(carimage1)
    } else if (e.type === 2) {
      setCarShowModal(carimage2)
    } else if (e.type === 3) {
      setCarShowModal(carimage3)
    } else if (e.type === 4) {
      setCarShowModal(carimage4)
    } else if (e.type === 5) {
      setCarShowModal(carimage5)
      setGuarantorNew(sg)
      const n = 5;
      setGuarantorNum(n)
    } else if (e.type === 6) {
      setCarShowModal(carimage6)
    } else if (e.type === 7) {
      setCarShowModal(carimage7)
    } else if (e.type === 8) {
      setCarShowModal(carimage8)
    } else if (e.type === 9) {
      setCarShowModal(carimage9)
    } else if (e.type === 10) {
      setCarShowModal(carimage10)
    } else if (e.type === 11) {
      setCarShowModal(carimage11)
    } else if (e.type === 12) {
      setCarShowModal(carimage12)
    } else if (e.type === 14) {
      setCarShowModal(carimage14)
    } else {

    }
    setOpenShowImage(true)
    // จะทำตอนกดภาพแล้วภาพใหญ่ขึ้น
  };

  function extractFolderGroup(path) {
    const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
    return match ? match[1] : null;
  }


  const loadImage = async (value) => {
    var carimg1 = [];
    var carimg2 = [];
    var carimg3 = [];
    var carimg4 = [];
    var carimg5 = [];
    var carimg6 = [];
    var carimg7 = [];
    var carimg8 = [];
    var carimg9 = [];
    var carimg10 = [];
    var carimg11 = [];
    var carimg12 = [];
    var carimg14 = [];
    var carimgnew1 = [];
    var carimgnew2 = [];
    var carimgnew3 = [];
    var carimgnew4 = [];
    var carimgnew5 = [];
    var carimgnew6 = [];
    var carimgnew7 = [];
    var carimgnew8 = [];
    var carimgnew9 = [];
    var carimgnew10 = [];
    var carimgnew11 = [];
    var carimgnew12 = [];
    var carimgnew14 = [];
    if (value === "car") {
      setImageBlobzz(getData1?.carLoanDetails?.cars?.img)
      getData1?.carLoanDetails?.cars?.img?.map((car, i) => {
        if (car.type === 1) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 1) {
            carimg1.push(car)
            setCarImage1(carimg1)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 1) {
            carimgnew1.push(car)
            setCarImagenew1(carimgnew1)
          }
          // carimg1.push(car)
          // setCarImage1(carimg1)
        } else if (car.type === 2) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 2) {
            console.log("22", car)
            carimg2.push(car)
            setCarImage2(carimg2)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 2) {
            carimgnew2.push(car)
            setCarImagenew2(carimgnew2)
          }
          // carimg2.push(car)
          // setCarImage2(carimg2)
        } else if (car.type === 4) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 4) {
            carimg4.push(car)
            setCarImage4(carimg4)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 4) {
            carimgnew4.push(car)
            setCarImagenew4(carimgnew4)
          }
          // carimg4.push(car)
          // setCarImage4(carimg4)
        } else if (car.type === 5) {
          var group1 = [];
          const group = extractFolderGroup(car);
          if (group === '1') {
            group1.push({ ...car, num: 1 });
          } else if (group === '2') {
            group1.push({ ...car, num: 2 });
          } else if (group === '3') {
            group1.push({ ...car, num: 3 });
          } else if (group === '4') {
            group1.push({ ...car, num: 4 });
          } else if (group === '5') {
            group1.push({ ...car, num: 5 });
          } else if (group === '6') {
            group1.push({ ...car, num: 6 });
          }
          carimg5.push(...group1)
          setCarImage5(carimg5)
        } else if (car.type === 6) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 6) {
            carimg6.push(car)
            setCarImage6(carimg6)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 6) {
            carimgnew6.push(car)
            setCarImagenew6(carimgnew6)
          }
          // carimg6.push(car)
          // setCarImage6(carimg6)
        } else if (car.type === 7) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 7) {
            carimg7.push(car)
            setCarImage7(carimg7)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 7) {
            carimgnew7.push(car)
            setCarImagenew7(carimgnew7)
          }
          // carimg7.push(car)
          // setCarImage7(carimg7)
        } else if (car.type === 8) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 8) {
            carimg8.push(car)
            setCarImage8(carimg8)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 8) {
            carimgnew8.push(car)
            setCarImagenew8(carimgnew8)
          }
          // carimg8.push(car)
          // setCarImage8(carimg8)
        } else if (car.type === 9) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 9) {
            carimg9.push(car)
            setCarImage9(carimg9)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 9) {
            carimgnew9.push(car)
            setCarImagenew9(carimgnew9)
          }
          // carimg9.push(car)
          // setCarImage9(carimg9)
        } else if (car.type === 14) {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 14) {
            carimg14.push(car)
            setCarImage14(carimg14)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 14) {
            carimgnew14.push(car)
            setCarImagenew14(carimgnew14)
          }
          // carimg14.push(car)
          // setCarImage14(carimg14)
        } else {
          var PreAaproveSplit = []
          PreAaproveSplit = car.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && car?.type === 12) {
            carimg12.push(car)
            setCarImage12(carimg12)
          } else if (PreAaproveSplit[3] === "13" && car?.type === 12) {
            carimgnew12.push(car)
            setCarImagenew12(carimgnew12)
          }
          // carimg12.push(car)
          // setCarImage12(carimg12)
        }
      })
    } else {
      setImageBlobzz(getData1?.landLoanDetails?.land?.img)
      getData1?.landLoanDetails?.land?.img?.map((land, i) => {
        if (land.type === 1) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
            carimg1.push(land)
            setCarImage1(carimg1)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
            carimgnew1.push(land)
            setCarImagenew1(carimgnew4)
          }
          // carimg1.push(land)
          // setCarImage1(carimg1)
        } else if (land.type === 3) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
            carimg3.push(land)
            setCarImage3(carimg3)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
            carimgnew3.push(land)
            setCarImagenew3(carimgnew3)
          }
          // carimg3.push(land)
          // setCarImage3(carimg3)
        } else if (land.type === 4) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
            carimg4.push(land)
            setCarImage4(carimg4)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
            carimgnew4.push(land)
            setCarImagenew4(carimgnew4)
          }
          // carimg4.push(land)
          // setCarImage4(carimg4)
        } else if (land.type === 5) {
          var group1 = [];
          const group = extractFolderGroup(land);
          if (group === '1') {
            group1.push({ ...land, num: 1 });
          } else if (group === '2') {
            group1.push({ ...land, num: 2 });
          } else if (group === '3') {
            group1.push({ ...land, num: 3 });
          } else if (group === '4') {
            group1.push({ ...land, num: 4 });
          } else if (group === '5') {
            group1.push({ ...land, num: 5 });
          } else if (group === '6') {
            group1.push({ ...land, num: 6 });
          }
          carimg5.push(...group1)
          setCarImage5(carimg5)
        } else if (land.type === 6) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
            carimg6.push(land)
            setCarImage6(carimg6)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
            carimgnew6.push(land)
            setCarImagenew6(carimgnew6)
          }
          // carimg6.push(land)
          // setCarImage6(carimg6)
        } else if (land.type === 7) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
            carimg7.push(land)
            setCarImage7(carimg7)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
            carimgnew7.push(land)
            setCarImagenew7(carimgnew7)
          }
          // carimg7.push(land)
          // setCarImage7(carimg7)
        } else if (land.type === 8) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
            carimg8.push(land)
            setCarImage8(carimg8)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
            carimgnew8.push(land)
            setCarImagenew8(carimgnew8)
          }
          // carimg8.push(land)
          // setCarImage8(carimg8)
        } else if (land.type === 9) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
            carimg9.push(land)
            setCarImage9(carimg9)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
            carimgnew9.push(land)
            setCarImagenew9(carimgnew9)
          }
          // carimg9.push(land)
          // setCarImage9(carimg9)
        } else if (land.type === 10) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
            carimg10.push(land)
            setCarImage10(carimg10)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
            carimgnew10.push(land)
            setCarImagenew10(carimgnew10)
          }
          // carimg10.push(land)
          // setCarImage10(carimg10)
        } else if (land.type === 11) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
            carimg11.push(land)
            setCarImage11(carimg11)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
            carimgnew11.push(land)
            setCarImagenew11(carimgnew11)
          }
          // carimg11.push(land)
          // setCarImage11(carimg11)
        } else if (land.type === 14) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
            carimg14.push(land)
            setCarImage14(carimg14)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
            carimgnew14.push(land)
            setCarImagenew14(carimgnew14)
          }
          // carimg14.push(land)
          // setCarImage14(carimg14)
        } else {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
            carimg12.push(land)
            setCarImage12(carimg12)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
            carimgnew12.push(land)
            setCarImagenew12(carimgnew12)
          }
          // carimg12.push(land)
          // setCarImage12(carimg12)
        }
      })
    }
  }



  const calYellowCard = (value) => {
    // console.log("calYellowCard", value)
    // มูลหนี้ balanc
    // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
    // คงเหลือ มูลหนี้ - หักส่วนลด
    // จ่ายเงิน (newDamt+followPay)
    // ยอดจัด approvedLoanAmount
    // วันที่ approvalDate
    // ใบนี้หมดอายุ ณ วันที่ newOfferDate
    // จำนวนงวด pgLoanTerm
    // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
    // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

    setMoonnee(currencyFormatOne(~~value?.balanc))
    setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
    setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
    setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
    setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
    setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
    setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
    setNgod(~~value?.pgLoanTerm)
    setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
  }

  const loadDataReAun = async () => {
    setLoading(true);
    await axios.post(loadDetailAunSendPG, { contno: dataFromTable.ISSUNO })
      // await axios
      // .post(loadDetailAunSendPG, { contno: "8-0012601" })
      .then((res) => {
        // if (res.status === 200) {
        // console.log("res.dataNewPageAun", res.data);
        setDataApproved(res?.data?.carsApproveve);
        // setDataDB2(res.data?.dataDB2);
        // calData(res?.data?.carsApproveve);
        setLoading(false);
        // }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };


  useEffect(() => {
    loadDataReAun();
  }, []);


  const loadDataNew = async () => {
    await axios.post(findlandpn, { landLoanId: getData1?.landLoanDetails?.land?.landLoanId })
      .then(res => {
        if (res.status === 200) {
          setNewDataLand(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  // const getImg = async () => {
  //   if (guarantor?.data?.typeLoan === "car") {
  //     var path = `one_images/car/${getData1?.carLoanDetails?.cars?.carId}/5`
  //   } else {
  //     var path = `one_images/land/${getData1?.landLoanDetails?.land?.landId}/5`
  //   }
  //   setLoading(true)
  //   await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
  //     .then(res => {
  //       if (res.data) {
  //         // console.log("NEW IMG", res.data)
  //         setGetImages(res.data)
  //         setLoading(false)
  //       } else {
  //         setGetImages([])
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err)
  //       setLoading(false)
  //     })
  //   setLoading(false)
  // }

  const onChange = (value) => {
    setSelectedCheckbox(value);
  };

  const onChangeRadioCus = (value) => {
    if (value.target.value === true) {
      delete notPassData.npCustomerNote
      form.setFieldsValue({
        npCustomerNote: null
      })
    }
    setNpCustomer(value.target.value);

  };

  const onChangeRadioLoan = (value) => {
    if (value.target.value === true) {
      delete notPassData.npLoanNote
      form.setFieldsValue({
        npLoanNote: null
      })
    }
    // console.log("npLoan", value.target.value);
    setNpLoan(value.target.value);
  };
  const onChangeRadioOldLoan = (value) => {
    if (value.target.value === true) {
      delete notPassData.npOldLoanNote
      form.setFieldsValue({
        npOldLoanNote: null
      })
    }
    // console.log("npOldLoan", value.target.value);
    setNpOldLoan(value.target.value);
  };
  const onChangeRadioProduct = (value) => {
    if (value.target.value === true) {
      delete notPassData.npProductNote
      form.setFieldsValue({
        npProductNote: null
      })
    }
    // console.log("value", value.target.value);
    setNpProduct(value.target.value);
  };
  const onChangeRadioCareer = (value) => {
    if (value.target.value === true) {
      delete notPassData.npCareerNote
      form.setFieldsValue({
        npCareerNote: null
      })
    }
    // console.log("npCareer", value.target.value);
    setNpCareer(value.target.value);
  };
  const onChangeRadioGuarantor = (value) => {
    if (value.target.value === true) {
      delete notPassData.npGuarantorNote
      form.setFieldsValue({
        npGuarantorNote: null
      })
    }
    // console.log("npCareer", value.target.value);
    setNpGuarantor(value.target.value);
  };

  const loadCareer = async () => {
    const id = { customerId: customers.data }
    setLoading(true)
    await axios.post(loadOJS, id)
      .then((res) => {
        //console.log("res", res.data)
        setCareer(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadGuarantors = async (value) => {
    await axios.post(checkgu, value)
      .then(res => {
        if (res.status === 200) {
          // console.log("asssa", res.data)
          setGuarantorData(res.data)
        } else if (res.status === 204) {
          // console.log("ไม่พบข้อมูล")
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  // const handleAddGua = async () => {
  //   // console.log("DD", guarantorData)
  //   await axios.post(makegarno, guarantorData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setAddCount(0)
  //         setLoading(false)
  //         notification3("top")
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     }
  //     )
  // }

  // const updatgarno = async () => {
  //   setLoading(true)
  //   await axios.put(updatgarno1, guarantorData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setAddCount(0)
  //         setLoading(false)
  //         notification3("top")
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     }
  //     )
  // }

  const onChangeBack = () => {
    changepage(page - 1)
  }

  const showConfirmResend = () => {
    confirm({
      title: 'คุณต้องการที่จะส่งเคสนี้อีกครั้ง...?',
      onOk() {
        handleReSend()
      },
      onCancel() {

      },
    });
  };

  const handleReSend = async () => {
    if (guarantor.data.typeLoan === "car") {
      var mix = { ...getData1.carLoanDetails, approvalStatus: "ผ่านการวิเคราะห์" }

      await axios.put(resendcarpg, mix)
        .then(res => {
          if (res.status === 200) {
            sendback(dayjs())
            setTimeout(() => {
              closeAll(false)
            }, 1000);
          }
        }).catch((err) => console.log(err))
    } else {
      var mix = { ...getData1.landLoanDetails, approvalStatus: "ผ่านการวิเคราะห์" }
      await axios.put(resendlandpg, mix)
        .then(res => {
          if (res.status === 200) {
            sendback(dayjs())
            setTimeout(() => {
              closeAll(false)
            }, 1000);
          }
        }).catch((err) => console.log(err))
    }
    // console.log("handleReSend", mix)

  };

  const onUnApprove = () => {
    if (notPassData.npCustomer === true &&
      notPassData.npAddress === true &&
      notPassData.npLoan === true &&
      notPassData.npOldLoan === true &&
      notPassData.npProduct === true &&
      notPassData.npCareer === true &&
      notPassData.npGuarantor === true
    ) {
      ErrorUnApprove('top')
    } else {
      setAppStatus("ไม่ผ่านการวิเคราะห์")
      setModalNote(true)
    }

  }

  const onApprove = async () => {
    // console.log("getData1",getData1)
    if (getData1?.carLoanDetails?.cars?.carChassisNumber === null || getData1?.carLoanDetails?.cars?.carChassisNumber === "") {
      ErrorChassis("top")
    } else if (getData1?.carLoanDetails?.cars?.carEngineNumber === null || getData1?.carLoanDetails?.cars?.carEngineNumber === "") {
      ErrorEngine("top")
    } else if (getData1?.carLoanDetails?.cars?.color === null || getData1?.carLoanDetails?.cars?.color === "") {
      ErrorColor("top")
    } else if (getData1?.address?.length === 0) {
      ErrorAddress("top")
    } else if (getData1?.phones?.length === 0) {
      ErrorPhone("top")
    } else if (getData1?.nationalId === "บุคคลธรรมดา" && (!getData1.birthdate || !getData1.issudt || !getData1.expdt ||
      getData1.birthdate === "undefined" || getData1.issudt === "undefined" || getData1.expdt === "undefined" ||
      getData1.birthdate === "null" || getData1.issudt === "null" || getData1.expdt === "null" ||
      getData1.birthdate === "Invalid Date" || getData1.issudt === "Invalid Date" || getData1.expdt === "Invalid Date")) {
      ErrorCustomer("top")
    } else {
      let checkDes = 0
      let checkAddress = 0
      let checkphone = 0
      let checkChBox = 0
      guarantorData?.map((e, index) => {
        if (e.description === "") {
          checkDes = 1
          const CheckDesGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มความสัมพันธ์ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มสัมพันธ์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckDesGua("top")
        } else if (e.address.length === 0) {
          checkAddress = 1
          const CheckAddrGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckAddrGua("top")
        } else if (e.phones?.length === 0) {
          checkphone = 1
          const CheckPhnGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckPhnGua("top")
        }
      })
      if (selectedCheckbox === undefined) {
        checkChBox = 1
        const CheckCheckbox = (placement) => {
          api.error({
            message: <b>กรุณาเลือกเช็คบล็อก การค้ำโอน</b>,
            // description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
            placement,
          });
        }
        CheckCheckbox("top")
      }
      if (checkDes !== 1 && checkAddress !== 1 && checkphone !== 1 && checkChBox !== 1) {

        if (notPassData.npCustomer === true &&
          notPassData.npAddress === true &&
          notPassData.npLoan === true &&
          notPassData.npOldLoan === true &&
          notPassData.npProduct === true &&
          notPassData.npCareer === true &&
          notPassData.npGuarantor === true
        ) {
          setAppStatus("ผ่านการวิเคราะห์")
          setModalNote(true)
        } else {
          ErrorApprove('top')
        }
      }
    }
  }

  const onApprovePRE = async () => {
    if (getData1?.carLoanDetails?.cars?.carChassisNumber === null || getData1?.carLoanDetails?.cars?.carChassisNumber === "") {
      ErrorChassis("top")
    } else if (getData1?.carLoanDetails?.cars?.carEngineNumber === null || getData1?.carLoanDetails?.cars?.carEngineNumber === "") {
      ErrorEngine("top")
    } else if (getData1?.carLoanDetails?.cars?.color === null || getData1?.carLoanDetails?.cars?.color === "") {
      ErrorColor("top")
    } else if (getData1?.address?.length === 0) {
      ErrorAddress("top")
    } else if (getData1?.phones?.length === 0) {
      ErrorPhone("top")
    } else if (getData1?.nationalId === "บุคคลธรรมดา" && (!getData1.birthdate || !getData1.issudt || !getData1.expdt ||
      getData1.birthdate === "undefined" || getData1.issudt === "undefined" || getData1.expdt === "undefined" ||
      getData1.birthdate === "null" || getData1.issudt === "null" || getData1.expdt === "null" ||
      getData1.birthdate === "Invalid Date" || getData1.issudt === "Invalid Date" || getData1.expdt === "Invalid Date")) {
      ErrorCustomer("top")
    } else {
      let checkDes = 0
      let checkAddress = 0
      let checkphone = 0
      let checkChBox = 0
      guarantorData?.map((e, index) => {
        if (e.description === "") {
          checkDes = 1
          const CheckDesGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มความสัมพันธ์ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มสัมพันธ์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckDesGua("top")
        } else if (e.address.length === 0) {
          checkAddress = 1
          const CheckAddrGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckAddrGua("top")
        } else if (e.phones?.length === 0) {
          checkphone = 1
          const CheckPhnGua = (placement) => {
            api.error({
              message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
              description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
              placement,
            });
          }
          CheckPhnGua("top")
        }
      })
      if (selectedCheckbox === undefined) {
        checkChBox = 1
        const CheckCheckbox = (placement) => {
          api.error({
            message: <b>กรุณาเลือกเช็คบล็อก การค้ำโอน</b>,
            // description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${e.garno}`,
            placement,
          });
        }
        CheckCheckbox("top")
      }
      if (checkDes !== 1 && checkAddress !== 1 && checkphone !== 1 && checkChBox !== 1) {

        if (notPassData.npCustomer === true &&
          notPassData.npAddress === true &&
          notPassData.npLoan === true &&
          notPassData.npOldLoan === true &&
          notPassData.npProduct === true &&
          notPassData.npCareer === true &&
          notPassData.npGuarantor === true
        ) {
          setAppStatus("ผ่านการวิเคราะห์ (Pre)")
          setModalNote(true)
        } else {
          ErrorApprove('top')
        }
      }
    }
  }

  const handleReject = () => {
    setAppStatus("Reject")
    setModalNote(true)
  }

  const handleCancel = () => {
    close(false)
  }

  const ErrorChassis = (placement) => {
    api.error({
      message: "ยังไม่เพิ่มเลขคัสซี",
      description:
        'กรุณาเพิ่มเลขคัสซี',
      placement,
    });
  };
  const ErrorColor = (placement) => {
    api.error({
      message: "ยังไม่เพิ่มสีรถ",
      description:
        'กรุณาเพิ่มสีรถ',
      placement,
    });
  };
  const ErrorEngine = (placement) => {
    api.error({
      message: "ยังไม่เพิ่มเลขเครื่อง",
      description:
        'กรุณาเพิ่มเลขเครื่อง',
      placement,
    });
  };
  const ErrorAddress = (placement) => {
    api.error({
      message: "ยังไม่ที่อยู่",
      description:
        'กรุณาที่อยู่',
      placement,
    });
  };
  const ErrorPhone = (placement) => {
    api.error({
      message: "ยังไม่เบอร์โทรศัพท์",
      description:
        'กรุณาเบอร์โทรศัพท์',
      placement,
    });
  };

  const ErrorCustomer = (placement) => {
    api.error({
      message: "กรุณาตรวจสอบวันเกิด/วันออกบัตร/วันบัตรหมดอายุของผู้ขอกู้",
      description:
        'กรุณาเพิ่มข้อมูลให้ถูกต้อง',
      placement,
    });
  };


  const ApproveSuccess = (placement) => {
    api.success({
      message: "ผ่านการวิเคราะห์",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const UnApproveSuccess = (placement) => {
    api.warning({
      message: "ไม่ผ่านการวิเคราะห์",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };
  const RejectSuccess = (placement) => {
    api.warning({
      message: "Reject",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const ErrorUnApprove = (placement) => {
    api.error({
      message: <b>ไม่สามารถดำเนินการได้</b>,
      description:
        'กรุณาติ๊กช่อง ผ่าน/ไม่ผ่าน ทางด้านขวาเพื่อบอกสาเหตุที่ไม่ผ่านการวิเคราะห์',
      placement,
    });
  };
  const ErrorApprove = (placement) => {
    api.error({
      message: <b>ไม่สามารถดำเนินการได้</b>,
      description:
        'กรุณาติ๊กช่อง ผ่าน/ไม่ผ่าน ทางด้านขวาให้เป็น ผ่าน! เท่านั้น เพื่อที่จะผ่านการวิเคราะห์',
      placement,
    });
  };

  const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    // console.log("Item", item, index)
    var itemSplit = []
    var itemSplitnum = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }
    carimage5?.map((e, i) => {
      const segments = e?.pathImage.split("/")
      if (itemSplit[4] === segments[4]) {
        itemSplitnum.push(e)
      }
    })
    const count = itemSplitnum.length || 0;

    return (
      <>
        <Row style={{ margin: '3px' }} justify={'center'} >
          <Card
            title={"ลำดับที่ " + (item.garno)}
            style={{ textAlign: "center" }}
          >
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
                <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
                <b>{item?.phones ? item?.phones[0]?.telp : ""}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
                <b>{item?.description}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
                <b>{item.address[0]?.houseNumber} {item.address[0]?.subdistrict} {item.address[0]?.district} {item.address[0]?.province} {item.address[0]?.zipCode}</b>
              </Form.Item>
              <Divider />
              {itemSplit.length > 0 ? (
                <>
                  <Row justify={"center"}>
                    <Image.PreviewGroup
                      preview={{
                        countRender: (index, total) => {
                          const displayedIndex = Math.min(index, count);
                          const displayTotal = Math.max(count, count);
                          return `${displayedIndex} / ${displayTotal}`;
                        }, // ใช้เพื่อแสดงจำนวนภาพ
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: {
                              onActive,
                              onFlipY,
                              onFlipX,
                              onRotateLeft,
                              onRotateRight,
                              onZoomOut,
                              onZoomIn,
                              onReset,
                            },
                          },
                        ) => (
                          <>
                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                              {carimage5?.map((e, index) => {
                                const segments = e?.pathImage.split("/")
                                if (itemSplit[4] === segments[4] && e.type === 5) {
                                  return (
                                    <Image
                                      width={auto}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                    />
                                  )
                                }
                                return null;
                              }
                              )}
                            </Row>
                            <Row style={{ zIndex: 2 }}>
                              <Space size={12} className="toolbar-wrapper">
                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined onClick={onReset} />
                              </Space>
                            </Row>
                          </>
                        ),
                        onChange: (index) => {
                          setCurrent(index);
                        },
                      }}
                    >
                      <Row gutter={32} justify={'center'}>
                        {carimage5?.map((e, index) => {
                          const segments = e?.pathImage.split("/")
                          if (itemSplit[4] === segments[4] && e.type === 5) {
                            return (
                              <Image
                                width={150}
                                key={index}
                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                alt={`รูปภาพ ${index + 1}`}
                                style={{ cursor: 'pointer' }}
                              />

                            )
                          }
                          return null;
                        }
                        )}
                      </Row>
                    </Image.PreviewGroup>
                  </Row>
                </>
              ) : null
              }
            </Col>
          </Card>
        </Row>
      </>
    )
  }

  const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }

    return (
      <Row style={{ margin: '3px' }} justify={'center'} >
        <Card
          title={"ลำดับที่ " + (item?.garno)}
          style={{ textAlign: "center" }}
        >
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
              <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
              <b>{item?.phones ? item?.phones[0]?.telp : ""}</b>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
              <b>{item?.description}</b>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
              <b>{item.address[0]?.houseNumber} {item.address[0]?.subdistrict} {item.address[0]?.district} {item.address[0]?.province} {item.address[0]?.zipCode}</b>
            </Form.Item>
          </Col>
        </Card>
      </Row>
    )
  }

  const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <Form
          name='basic'
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Row gutter={32} justify={'center'}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <span>เลขทะเบียนบ้าน : </span>
                <b>{item.houseRegistrationNumber}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <span>ถนน : </span>
                <b>{item.road}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <span>ตำบล : </span>
                <b>{item.subdistrict}</b>
              </Form.Item>

            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <span>บ้านเลขที่ : </span>
                <b>{item.houseNumber}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <span>หมู่บ้าน : </span>
                <b>{item.village}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, }}
              >
                <span>อำเภอ : </span>
                <b>{item.district}</b>
              </Form.Item>

            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, }}
              >
                <span>หมู่ / ซอย : </span>
                <b>{item.soi}</b>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 0, }}
              >
                <span>จังหวัด : </span>
                <b>{item.province}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, }}
              >
                <span>รหัสไปรษณีย์ : </span>
                <b>{item.zipCode}</b>
              </Form.Item>

            </Col>
          </Row>
        </Form>
        <Divider style={{ margin: 5 }} />
      </>
    )
  }

  const renderItemCareer = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <Form
          name='basic'
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Row gutter={32} justify={'center'}>
            <Col className='gutter-row' span={7}>
              <div style={{ marginBottom: 0, }}>
                <span>กลุ่มอาชีพ : </span>
                <b>{item?.desc}</b>
              </div>
              <div style={{ marginBottom: 0, }}>
                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                <b>{item?.companyName}</b>
              </div>
            </Col>
            <Col className='gutter-row' span={7}>
              <div style={{ marginBottom: 0, }}>
                <span>อาชีพ  : </span>
                <b>{item?.descSub}</b>
              </div>
              <div style={{ marginBottom: 0, }}>
                <span>รายได้ / เดือน : </span>
                <b>{currencyFormatOne(item?.incomeMonth)}</b>
              </div>
            </Col>
            <Col className='gutter-row' span={7}>
              <div style={{ marginBottom: 0, }}>
                <span>สาขาอาชีพ : </span>
                <b>{item?.descSection}</b>
              </div>

              <div style={{ marginBottom: 0, }}>
                <span>
                  รายจ่าย / เดือน : </span>
                <b>{currencyFormatOne(item?.expensesMonth)}</b>
              </div>
            </Col>
            {/* <Col className='gutter-row' span={3}>
              <Row className="center">
                <Radio
                  key={"24"}
                  value={true}
                  style={{ color: '#389e0d' }}
                  checked={npCareer === true}
                  isPacked={true}
                  onChange={onChangeRadioCareer}
                >
                  <b>ผ่าน</b>
                </Radio>
                <Radio
                  key={"25"}
                  value={false}
                  style={{ color: '#cf1322' }}
                  checked={npCareer === false}
                  isPacked={true}
                  onChange={onChangeRadioCareer}
                >
                  <b>ไม่ผ่าน</b>
                </Radio>
              </Row>

            </Col>
            <Col className='gutter-row' span={24}>
              {
                npCareer === false ?
                  <>
                    <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npCareerNote"
                      style={{ margin: 0, textAlign: 'left' }}
                      rules={[
                        {
                          required: true,
                          message: 'กรุณากรอกสาเหตุ !'
                        },]}

                    >
                      <Input name="npCareerNote" onChange={(e) => setNotPassData({ ...notPassData, npCareerNote: e.target.value })} />
                    </Form.Item>
                  </> : null
              }
            </Col> */}

          </Row>
        </Form>
        <Divider style={{ margin: 5 }} />
      </>
    )
  }

  const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <Form
          name='basic'
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Form.Item
            style={{ marginBottom: 0 }}
          // label={item.phoneType}
          >
            <span>{item.phoneType} : </span>
            <b> {item.telp}</b>
          </Form.Item>
        </Form>
      </>
    )
  }

  const renderLandNew = ({ item, index }) => {
    // console.log("item", item)
    return (
      <>
        <Card style={{ width: '1000px' }}>
          <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({item.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Row>
          <Row gutter={32} justify={'center'}>
            <Col className='gutter-row' span={9}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='กลุ่มสินค้า'>
                <b>{getData1?.lands?.landLoanDetails?.productType}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='เลขที่ดินหรือระวาง'>
                <b>{item?.numberLandlawang}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่ไร่'>
                <b>{item?.rai}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  ไร่
                </span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ราคาประเมินที่ดิน'>
                <b>{currencyFormatOne(item?.landPrice)}</b> บาท/ตารางวา
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={7}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ประเภทเอกสาร'>
                <b>{item?.landLoanDetails?.productLoanLandType}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='จังหวัด'>
                <b>{item?.provinces}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่งาน'>
                <b>{item?.workArea}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  งาน
                </span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ราคารวม'>
                <b>{currencyFormatOne(item?.resultLandPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='เลขโฉนดที่ดิน'>
                <b>{item?.numberLand}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='อำเภอ'>
                <b>{item?.district}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่ตารางวา'>
                <b>{item?.squareWaArea}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </Card>
      </>
    )
  }
  const renderPriceApprove = ({ item, index }) => {
    return (
      <>
        <Card style={{ width: '1000px' }}>
          <Row justify={'left'}><b><u>ราคาประเมินที่ดิน ({item?.numberLand})</u></b></Row>
          <Row style={{ width: '100%' }}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ราคาต่ำสุด'
              >
                <b>{currencyFormatOne(item.approval?.minPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ราคาสูงสุด'
              >
                <b>{currencyFormatOne(item.approval?.maxPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='หมายเหตุ'
              >
                <b>{item.approval?.note}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}></span>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </>
    )
  }
  const renderMixNote = ({ item, index }) => { // แสดงที่ดินแบบใหม่ เป็น Array
    return (
      <>
        {
          index === 0 ?
            null :
            <>
              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Col className='gutter-row' span={24}>
                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                      <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </>
        }
      </>
    )
  }

  const renderTitleDeed = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            key={index}
            preview={{
              countRender: (index, total) => {
                const imageCounts3 = carimage3?.length || 0; // นับจำนวนภาพจริง
                const displayIndex = Math.min(index, imageCounts3);
                const displayTotal = Math.min(imageCounts3, imageCounts3);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive, // support after 5.21.0
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage3?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (indexs) => {
                setCurrent(indexs);
              },
            }}
          >
            <Row justify={'center'}>
              {carimage3?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
              <Space>
                <Image.PreviewGroup
                  preview={{
                    countRender: (index, total) => {
                      const imageCountnew3 = carimagenew3?.length || 0; // นับจำนวนภาพจริง
                      const displayIndex = Math.min(index, imageCountnew3);
                      const displayTotal = Math.min(imageCountnew3, imageCountnew3);
                      return `${displayIndex} / ${displayTotal}`;
                    }, // ใช้เพื่อแสดงจำนวนภาพ
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onActive,
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                          onReset,
                        },
                      },
                    ) => (
                      <>
                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                          {carimagenew3?.map((e, index) => {
                            return (
                              <>
                                <Image
                                  width={auto}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                />
                              </>
                            );
                          })}
                        </Row>
                        <Row style={{ zIndex: 2 }}>
                          <Space size={12} className="toolbar-wrapper">
                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                            <DownloadOutlined onClick={onDownload} />
                            <SwapOutlined rotate={90} onClick={onFlipY} />
                            <SwapOutlined onClick={onFlipX} />
                            <RotateLeftOutlined onClick={onRotateLeft} />
                            <RotateRightOutlined onClick={onRotateRight} />
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            <UndoOutlined onClick={onReset} />
                          </Space>
                        </Row>
                      </>
                    ),
                    onChange: (index) => {
                      setCurrent(index);
                    },
                  }}
                >
                  <Row justify={'center'}>
                    {carimagenew3?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={150}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                </Image.PreviewGroup>
              </Space>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderDivision = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const imageCounts10 = carimage10?.length || 0; // นับจำนวนภาพจริง
                const displayIndex = Math.min(index, imageCounts10);
                const displayTotal = Math.min(imageCounts10, imageCounts10);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage10?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row justify={'center'}>
              {carimage10?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
              <Space>
                <Image.PreviewGroup
                  preview={{
                    countRender: (index, total) => {
                      const imageCounts10 = carimagenew10?.length || 0; // นับจำนวนภาพจริง
                      const displayIndex = Math.min(index, imageCounts10);
                      const displayTotal = Math.min(imageCounts10, imageCounts10);
                      return `${displayIndex} / ${displayTotal}`;
                    }, // ใช้เพื่อแสดงจำนวนภาพ
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onActive,
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                          onReset,
                        },
                      },
                    ) => (
                      <>
                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                          {carimagenew10?.map((e, index) => {
                            return (
                              <>
                                <Image
                                  width={auto}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                />
                              </>
                            );
                          })}
                        </Row>
                        <Row style={{ zIndex: 2 }}>
                          <Space size={12} className="toolbar-wrapper">
                            <LeftOutlined onClick={() => onActive?.(-1)} />
                            <RightOutlined onClick={() => onActive?.(1)} />
                            <DownloadOutlined onClick={onDownload} />
                            <SwapOutlined rotate={90} onClick={onFlipY} />
                            <SwapOutlined onClick={onFlipX} />
                            <RotateLeftOutlined onClick={onRotateLeft} />
                            <RotateRightOutlined onClick={onRotateRight} />
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            <UndoOutlined onClick={onReset} />
                          </Space>
                        </Row>
                      </>
                    ),
                    onChange: (index) => {
                      setCurrent(index);
                    },
                  }}
                >
                  <Row justify={'center'}>
                    {carimagenew10?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={150}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                </Image.PreviewGroup>
              </Space>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderLawang = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const imageCount11new = carimage11?.length || 0; // นับจำนวนภาพจริง
                const displayIndex = Math.min(index, imageCount11new);
                const displayTotal = Math.min(imageCount11new, imageCount11new);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage11?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row justify={'center'}>
              {carimage11?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
              <Space>
                <Image.PreviewGroup
                  preview={{
                    countRender: (index, total) => {
                      const imageCount11new = carimagenew11?.length || 0; // นับจำนวนภาพจริง
                      const displayIndex = Math.min(index, imageCount11new);
                      const displayTotal = Math.min(imageCount11new, imageCount11new);
                      return `${displayIndex} / ${displayTotal}`;
                    }, // ใช้เพื่อแสดงจำนวนภาพ
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onActive,
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                          onReset,
                        },
                      },
                    ) => (
                      <>
                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                          {carimagenew11?.map((e, index) => {
                            return (
                              <>
                                <Image
                                  width={auto}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                />
                              </>
                            );
                          })}
                        </Row>
                        <Row style={{ zIndex: 2 }}>
                          <Space size={12} className="toolbar-wrapper">
                            <LeftOutlined onClick={() => onActive?.(-1)} />
                            <RightOutlined onClick={() => onActive?.(1)} />
                            <DownloadOutlined onClick={onDownload} />
                            <SwapOutlined rotate={90} onClick={onFlipY} />
                            <SwapOutlined onClick={onFlipX} />
                            <RotateLeftOutlined onClick={onRotateLeft} />
                            <RotateRightOutlined onClick={onRotateRight} />
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            <UndoOutlined onClick={onReset} />
                          </Space>
                        </Row>
                      </>
                    ),
                    onChange: (index) => {
                      setCurrent(index);
                    },
                  }}
                >
                  <Row justify={'center'}>
                    {carimagenew11?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={150}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                </Image.PreviewGroup>
              </Space>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderLandBuildings = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const imageCount1new = carimage1?.length || 0; // นับจำนวนภาพจริง
                const displayIndex = Math.min(index, imageCount1new);
                const displayTotal = Math.min(imageCount1new, imageCount1new);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage1?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row justify={'center'}>
              {carimage1?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
              <Space>

                <Image.PreviewGroup
                  preview={{
                    countRender: (index, total) => {
                      const imageCount1new = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                      const displayIndex = Math.min(index, imageCount1new);
                      const displayTotal = Math.min(imageCount1new, imageCount1new);
                      return `${displayIndex} / ${displayTotal}`;
                    }, // ใช้เพื่อแสดงจำนวนภาพ
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: {
                          onActive,
                          onFlipY,
                          onFlipX,
                          onRotateLeft,
                          onRotateRight,
                          onZoomOut,
                          onZoomIn,
                          onReset,
                        },
                      },
                    ) => (
                      <>
                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                          {carimagenew1?.map((e, index) => {
                            return (
                              <>
                                <Image
                                  width={auto}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                />
                              </>
                            );
                          })}
                        </Row>

                        <Row style={{ zIndex: 2 }}>
                          <Space size={12} className="toolbar-wrapper">
                            <LeftOutlined onClick={() => onActive?.(-1)} />
                            <RightOutlined onClick={() => onActive?.(1)} />
                            <DownloadOutlined onClick={onDownload} />
                            <SwapOutlined rotate={90} onClick={onFlipY} />
                            <SwapOutlined onClick={onFlipX} />
                            <RotateLeftOutlined onClick={onRotateLeft} />
                            <RotateRightOutlined onClick={onRotateRight} />
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            <UndoOutlined onClick={onReset} />
                          </Space>
                        </Row>
                      </>
                    ),
                    onChange: (index) => {
                      setCurrent(index);
                    },
                  }}
                >
                  <Row justify={'center'}>
                    {carimagenew1?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={150}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                </Image.PreviewGroup>
              </Space>
              <Divider />
            </>
            : null
        }
      </>
    )
  }

  return (
    <div>
      <Form

        form={form}
        onFinish={onUnApprove}
        autoComplete="off"
        initialValues={{ remember: true }}
      >
        <Card
          bordered={false}
          style={{ width: '100%' }}
        >
          <style>
            {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                .ant-image-preview-count {
                    display: none !important;
                }
                `}
          </style>
          <Row justify={'center'}>
            <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
              <b>รายละเอียด</b>
            </Col>
          </Row>
          <Row justify={'center'}>
            <Divider style={{ margin: 5 }} />
            <Row gutter={32} justify={'center'} >
              <Card style={{ width: '1000px' }}>
                <Row gutter={32}>
                  <Col span={12} className='gutter-row' style={{ textAlign: 'left' }}>
                    {
                      types === "car" ?
                        <div>
                          <b>{getData1?.carLoanDetails?.cars?.ISSUNO ? <span>เลขขสัญญา : {getData1?.carLoanDetails?.cars?.ISSUNO}</span> : null}</b>
                        </div>
                        :
                        <div>
                          <b>{getData1?.landLoanDetails?.land?.ISSUNO ? <span>เลขขสัญญา : {getData1?.landLoanDetails?.land?.ISSUNO}</span> : null}</b>
                        </div>
                    }
                    {
                      types === "car" ?
                        <div>
                          <b>{getData1?.carLoanDetailsRe?.GRDCOD ? <span>เกรด : {getData1?.carLoanDetailsRe?.GRDCOD}</span> : null}</b>
                        </div>
                        :
                        <div>
                          <b>{getData1?.landLoanDetailsRe?.GRDCOD ? <span>เกรด : {getData1?.landLoanDetailsRe?.GRDCOD}</span> : null}</b>
                        </div>
                    }
                  </Col>

                  <Col span={12} className='gutter-row' style={{ textAlign: 'right' }}>
                    <Form.Item>
                      {
                        types === "car" ?
                          <>
                            สถานะ : <b><Tag color="cyan">{getData1?.carLoanDetails?.approvalStatus}</Tag></b>
                          </>
                          :
                          <>
                            สถานะ : <b><Tag color="cyan">{getData1?.landLoanDetails?.approvalStatus}</Tag></b>
                          </>
                      }
                    </Form.Item>
                  </Col>
                </Row>
                {
                  getData1?.nationalId === 'นิติบุคคล' ?
                    <>
                      <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {/* <aside style={{ width: '90%' }}> */}
                        <Col className='gutter-row' span={8}>
                          <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                            <span>ประเภท : </span> <b>{getData1?.nationalId}</b>
                          </div>
                          <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                            <span>วันจดทะเบียน : </span> <b> {getData1?.birthdate}</b>
                          </div>

                        </Col>
                        <Col className='gutter-row' span={8}>
                          <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                            <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{getData1.identificationId}</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <b>

                              {
                                dataPhoneCus?.map((item, index) => {
                                  return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                })
                              }
                            </b>
                          </div>
                        </Col>
                        <Col className='gutter-row' span={5}>
                          <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                            <span>
                              {getData1?.snam} : </span> <b>{getData1?.firstname + ' ' + getData1?.lastname}</b>
                          </div>
                        </Col>
                        {
                          app === "รอวิเคราะห์" ?
                            <>
                              <Col className='gutter-row' span={3}>
                                <Row className="center">
                                  <Radio
                                    key={"4"}
                                    value={true}
                                    style={{ color: '#389e0d' }}
                                    checked={npCustomer === true}
                                    isPacked={true}
                                    onChange={onChangeRadioCus}
                                  >
                                    <b>ผ่าน</b>
                                  </Radio>
                                  <Radio
                                    key={"5"}
                                    value={false}
                                    style={{ color: '#cf1322' }}
                                    checked={npCustomer === false}
                                    isPacked={true}
                                    onChange={onChangeRadioCus}
                                  >
                                    <b>ไม่ผ่าน</b>
                                  </Radio>
                                </Row>
                              </Col>
                              <Col className='gutter-row' span={24}>
                                {
                                  npCustomer === false ?
                                    <>
                                      <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npCustomerNote"
                                        style={{ margin: 0, textAlign: 'left' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ !'
                                          },]}

                                      >
                                        <Input name="npCustomerNote" onChange={(e) => setNotPassData({ ...notPassData, npCustomerNote: e.target.value })} />
                                      </Form.Item>
                                    </> : null
                                }
                              </Col>
                            </>
                            : null
                        }

                        {/* </aside> */}
                      </Row>
                    </>
                    :
                    <>
                      <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                          >
                            <span>ชื่อ-นามสกุล :</span>
                            <b>{getData1?.snam + '   ' + getData1?.firstname + '   ' + getData1?.lastname}</b>
                          </Form.Item>

                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                          >
                            <span>ชื่อเล่น : </span>
                            <b>{getData1?.nickname}</b>
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                          >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{getData1?.identificationId}</b>
                          </Form.Item>
                          <Row gutter={32}>
                            {/* <Col className='gutter-row' span={24}> */}
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left', width: '300px' }}
                            >
                              <b>
                                {
                                  dataPhoneCus?.map((item, index) => {
                                    return renderItemPhones({ item, index, key: `{item.identificationId} - ${index}` })
                                  })
                                }
                              </b>
                            </Form.Item>
                            {/* </Col> */}

                          </Row>
                        </Col>
                        <Col className='gutter-row' span={5}>
                          <Form.Item
                            style={{ margin: 0, textAlign: 'left' }}
                          >
                            <span>เพศ : </span>
                            <b>{getData1?.gender}</b>
                          </Form.Item>

                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                          >
                            {dataSocials?.length ?
                              <>
                                <span>{dataSocials?.socialType} :</span>
                                <b>{dataSocials?.socialData}</b>
                              </>
                              : null
                            }

                          </Form.Item>
                        </Col>
                        {
                          app === "รอวิเคราะห์" ?
                            <>
                              <Col className='gutter-row' span={3}>
                                <Row className="center">
                                  <Radio
                                    key={"6"}
                                    value={true}
                                    style={{ color: '#389e0d' }}
                                    checked={npCustomer === true}
                                    isPacked={true}
                                    onChange={onChangeRadioCus}
                                  >
                                    <b>ผ่าน</b>
                                  </Radio>
                                  <Radio
                                    key={"7"}
                                    value={false}
                                    style={{ color: '#cf1322' }}
                                    checked={npCustomer === false}
                                    isPacked={true}
                                    onChange={onChangeRadioCus}
                                  >
                                    <b>ไม่ผ่าน</b>
                                  </Radio>

                                </Row>

                              </Col>
                              <Col className='gutter-row' span={24}>
                                {
                                  npCustomer === false ?
                                    <>
                                      <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npCustomerNote"
                                        style={{ margin: 0, textAlign: 'left' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ !'
                                          },]}

                                      >
                                        <Input name="npCustomerNote" onChange={(e) => setNotPassData({ ...notPassData, npCustomerNote: e.target.value })} />
                                      </Form.Item>
                                    </> : null
                                }
                              </Col>
                            </>
                            : null
                        }
                      </Row>
                    </>
                }
              </Card>
              {address?.length >= 1 ?
                <>
                  <Card style={{ width: '1000px' }}>
                    <Row justify={'left'}><b><u>รายละเอียดที่อยู่</u></b></Row>
                    <Row justify={'center'} gutter={32}>
                      <Col span={21}>
                        {
                          address?.map((item, index) => {
                            return renderItemAddress({ item, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                      </Col>

                      {/* <Col className='gutter-row' span={3}>
                        <Row className="center">
                          <Radio
                            key={"1"}
                            value={true}
                            style={{ color: '#389e0d' }}
                            checked={npAddress === true}
                            isPacked={true}
                            onChange={onChangeRadioAddress}
                          >
                            <b>ผ่าน</b>
                          </Radio>
                          <Radio
                            key={"2"}
                            value={false}
                            style={{ color: '#cf1322' }}
                            checked={npAddress === false}
                            isPacked={true}
                            onChange={onChangeRadioAddress}
                          >
                            <b>ไม่ผ่าน</b>
                          </Radio>
                        </Row>
                      </Col> */}
                    </Row>
                    {/* <Col className='gutter-row' span={24}>
                      {
                        npAddress === false ?
                          <>
                            <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npAddressNote"
                              style={{ margin: 0, textAlign: 'left' }}
                              rules={[
                                {
                                  required: true,
                                  message: 'กรุณากรอกสาเหตุ !'
                                },]}

                            >
                              <Input name="npAddressNote" onChange={(e) => setNotPassData({ ...notPassData, npAddressNote: e.target.value })} />
                            </Form.Item>
                          </> : null
                      }
                    </Col> */}
                  </Card>
                </>
                : null
              }
              {
                career !== undefined || career?.length > 0 ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>อาชีพ - รายได้</u></b></Row>
                      <Row justify={'center'} gutter={32}>
                        <Col span={21}>
                          {
                            career?.map((item, index) => {
                              return renderItemCareer({ item, index, key: `{item.identificationId} - ${index}` })
                            })
                          }
                        </Col>
                        {
                          app === "รอวิเคราะห์" ?
                            <>
                              <Col className='gutter-row' span={3}>
                                <Row className="center">
                                  <Radio
                                    key={"24"}
                                    value={true}
                                    style={{ color: '#389e0d' }}
                                    checked={npCareer === true}
                                    isPacked={true}
                                    onChange={onChangeRadioCareer}
                                  >
                                    <b>ผ่าน</b>
                                  </Radio>
                                  <Radio
                                    key={"25"}
                                    value={false}
                                    style={{ color: '#cf1322' }}
                                    checked={npCareer === false}
                                    isPacked={true}
                                    onChange={onChangeRadioCareer}
                                  >
                                    <b>ไม่ผ่าน</b>
                                  </Radio>
                                </Row>
                              </Col>
                              <Col className='gutter-row' span={24}>
                                {
                                  npCareer === false ?
                                    <>
                                      <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npCareerNote"
                                        style={{ margin: 0, textAlign: 'left' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ !'
                                          },]}

                                      >
                                        <Input name="npCareerNote" onChange={(e) => setNotPassData({ ...notPassData, npCareerNote: e.target.value })} />
                                      </Form.Item>
                                    </> : null
                                }
                              </Col>
                            </>
                            : null
                        }
                      </Row>
                    </Card>
                  </>
                  :
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>อาชีพ - รายได้</u></b></Row>
                      <Row justify={'center'} gutter={32}>
                        <Col span={21}>
                          <p>* ยังไม่ได้เพิ่มอาชีพ!</p>
                        </Col>
                        {
                          app === "รอวิเคราะห์" ?
                            <>
                              <Col className='gutter-row' span={3}>
                                <Row className="center">
                                  <Radio
                                    key={"24"}
                                    value={true}
                                    style={{ color: '#389e0d' }}
                                    checked={npCareer === true}
                                    isPacked={true}
                                    onChange={onChangeRadioCareer}
                                  >
                                    <b>ผ่าน</b>
                                  </Radio>
                                  <Radio
                                    key={"25"}
                                    value={false}
                                    style={{ color: '#cf1322' }}
                                    checked={npCareer === false}
                                    isPacked={true}
                                    onChange={onChangeRadioCareer}
                                  >
                                    <b>ไม่ผ่าน</b>
                                  </Radio>
                                </Row>
                              </Col>

                              <Col className='gutter-row' span={24}>
                                {
                                  npCareer === false ?
                                    <>
                                      <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npCareerNote"
                                        style={{ margin: 0, textAlign: 'left' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ !'
                                          },]}

                                      >
                                        <Input name="npCareerNote" onChange={(e) => setNotPassData({ ...notPassData, npCareerNote: e.target.value })} />
                                      </Form.Item>
                                    </>
                                    : null
                                }
                              </Col>
                            </>
                            : null
                        }
                      </Row>
                    </Card>
                  </>
              }
              {
                types === "car" ?
                  <>
                    {getData1?.carLoanDetails?.productLoanType === 'ย้ายไฟแนนซ์' ||
                      getData1?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                      <>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={7}>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }}>
                                <span>จากไฟแนนซ์ : </span>
                                <b>{getData1?.carLoanDetails?.cars?.oldLoanDetails?.issuno}</b>
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ยอดปิดไฟแนนซ์'>
                                <b>{currencyFormatOne(getData1?.carLoanDetails?.cars?.oldLoanDetails?.oldLoanAmount)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ค่างวด / เดือน'>
                                <b>{currencyFormatOne(getData1?.carLoanDetails?.cars?.oldLoanDetails?.oldMonthlyPayment)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }}>
                                <b>{getData1?.carLoanDetails?.cars?.oldLoanDetails?.oldLoanTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                            </Col>
                            {
                              app === "รอวิเคราะห์" ?
                                <>
                                  <Col className='gutter-row' span={3}>
                                    <Row className="center">
                                      <Radio
                                        key={"8"}
                                        value={true}
                                        style={{ color: '#389e0d' }}
                                        checked={npOldLoan === true}
                                        isPacked={true}
                                        onChange={onChangeRadioOldLoan}
                                      >
                                        <b>ผ่าน</b>
                                      </Radio>
                                      <Radio
                                        key={"9"}
                                        value={false}
                                        style={{ color: '#cf1322' }}
                                        checked={npOldLoan === false}
                                        isPacked={true}
                                        onChange={onChangeRadioOldLoan}
                                      >
                                        <b>ไม่ผ่าน</b>
                                      </Radio>
                                    </Row>
                                  </Col>
                                  <Col className='gutter-row' span={24}>
                                    {
                                      npOldLoan === false ?
                                        <>
                                          <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npOldLoanNote"
                                            style={{ margin: 0, textAlign: 'left' }}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                              },]}

                                          >
                                            <Input name="npAddressNote" onChange={(e) => setNotPassData({ ...notPassData, npOldLoanNote: e.target.value })} />
                                          </Form.Item>
                                        </> : null
                                    }
                                  </Col>
                                </>
                                : null
                            }
                          </Row>
                        </Card>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียดขอกู้</u></b></Row>
                          <Row justify={'center'} gutter={32}>
                            <Col className='gutter-row' span={7}>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='ยอดกู้'>
                                <b>{currencyFormatOne(getData1?.carLoanDetails?.loanAmount)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='จำนวนงวด'>
                                <b>{getData1?.carLoanDetails?.loanTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='ดอกเบี้ย'>
                                <b>{currencyFormatOne(getData1?.carLoanDetails?.interest)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='จำนวนผ่อนต่อเดือน'
                              >
                                <b>{currencyFormatOne(getData1?.carLoanDetails?.monthlyPayment)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='ผ่อนรวมดอกเบี้ย'
                              >
                                <b>{currencyFormatOne(~~getData1?.carLoanDetails?.loanAmount + ~~getData1?.carLoanDetails?.interest)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='ผู้เสนอเคส'
                              >
                                <b>{getData1?.carLoanDetails?.proposalBy}</b>

                              </Form.Item>
                              <b> <Form.Item
                                style={{ margin: 0, textAlign: 'center' }}
                                label='เรทรถ'
                              >
                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.carLoanDetails?.cars?.carPrice)}</u></b>

                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              </b>
                            </Col>

                            {
                              app === "รอวิเคราะห์" ?
                                <>
                                  <Col className='gutter-row' span={3}>
                                    <Row className="center">
                                      <Radio
                                        key={"8"}
                                        value={true}
                                        style={{ color: '#389e0d' }}
                                        checked={npLoan === true}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ผ่าน</b>
                                      </Radio>
                                      <Radio
                                        key={"9"}
                                        value={false}
                                        style={{ color: '#cf1322' }}
                                        checked={npLoan === false}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ไม่ผ่าน</b>
                                      </Radio>
                                    </Row>
                                  </Col>
                                  <Col className='gutter-row' span={24}>
                                    {
                                      npLoan === false ?
                                        <>
                                          <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npLoanNote"
                                            style={{ margin: 0, textAlign: 'left' }}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                              },]}

                                          >
                                            <Input name="npLoanNote" onChange={(e) => setNotPassData({ ...notPassData, npLoanNote: e.target.value })} />
                                          </Form.Item>
                                        </> : null
                                    }
                                  </Col>
                                </>
                                : null
                            }
                          </Row>
                        </Card>
                      </>
                      :
                      <>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียด{' ' + getData1?.carLoanDetails?.productLoanType} ({getData1?.carLoanDetails?.productType})</u></b></Row>
                          <Row justify={'center'} gutter={32}>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>ยอดกู้ : </span>
                                <b>{currencyFormatOne(getData1.carLoanDetails.loanAmount)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>จำนวนผ่อนต่อเดือน : </span>
                                <b>{currencyFormatOne(getData1.carLoanDetails.monthlyPayment)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>

                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>จำนวนงวด : </span>
                                <b>{getData1.carLoanDetails.loanTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                <b>{currencyFormatOne(~~getData1.carLoanDetails.loanAmount + ~~getData1.carLoanDetails.interest)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>

                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>ดอกเบี้ย : </span>
                                <b>{currencyFormatOne(getData1.carLoanDetails.interest)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>

                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <span>ผู้เสนอเคส : </span>
                                <b>{getData1.carLoanDetails.proposalBy}</b>

                              </Form.Item>
                              <b> <Form.Item
                                style={{ margin: 0, textAlign: 'left' }}
                              >
                                <span>เรทรถ : </span>
                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.carLoanDetails?.cars?.carPrice)}</u></b>

                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              </b>
                            </Col>
                            {
                              app === "รอวิเคราะห์" ?
                                <>
                                  <Col className='gutter-row' span={3}>
                                    <Row className="center">
                                      <Radio
                                        key={"10"}
                                        value={true}
                                        style={{ color: '#389e0d' }}
                                        checked={npLoan === true}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ผ่าน</b>
                                      </Radio>
                                      <Radio
                                        key={"11"}
                                        value={false}
                                        style={{ color: '#cf1322' }}
                                        checked={npLoan === false}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ไม่ผ่าน</b>
                                      </Radio>
                                    </Row>

                                  </Col>
                                  <Col className='gutter-row' span={24}>
                                    {
                                      npLoan === false ?
                                        <>
                                          <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npLoanNote"
                                            style={{ margin: 0, textAlign: 'left' }}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                              },]}

                                          >
                                            <Input name="npLoanNote" onChange={(e) => setNotPassData({ ...notPassData, npLoanNote: e.target.value })} />
                                          </Form.Item>
                                        </> : null
                                    }
                                  </Col>
                                </>
                                : null
                            }
                          </Row>
                        </Card>
                      </>
                    }
                  </>
                  : null
              }
              {
                types === "land" ?
                  <>
                    {getData1.landLoanDetails.productLoanLandType === 'ย้ายไฟแนนซ์' ||
                      getData1?.landLoanDetails?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                      <>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์</u></b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                              // label='จากไฟแนนซ์'
                              >
                                <span>จากไฟแนนซ์ : </span>
                                <b>{getData1?.landLoanDetails?.land?.oldLandLoanDetails?.issuno}</b>
                                {/* <b>{currencyFormatOne(getData1?.landLoanDetails?.loanAmountLand)}</b> */}

                              </Form.Item>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                              // label='จำนวนงวดที่ผ่อน'
                              >
                                <span>จำนวนงวดที่ผ่อน : </span>
                                <b>{getData1?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandLoanTerm}</b>
                                {/* <b>{getData1?.landOldLoanDetails?.oldLandLoanTerm}</b> */}
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                              // label='ยอดปิดไฟแนนซ์'
                              >
                                <span>ยอดปิดไฟแนนซ์ : </span>
                                <b>{currencyFormatOne(getData1?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandLoanTerm)}</b>
                                {/* <b>{currencyFormatOne(getData1?.landLoanDetails?.loanAmountLand)}</b> */}
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>


                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                              // label='ค่างวด / เดือน'
                              >
                                <span>ค่างวด / เดือน : </span>
                                <b>{currencyFormatOne(getData1?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandMonthlyPayment)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            {
                              app === "รอวิเคราะห์" ?
                                <>
                                  <Col className='gutter-row' span={3}>
                                    <Row className="center">
                                      <Radio
                                        key={"12"}
                                        value={true}
                                        style={{ color: '#389e0d' }}
                                        checked={npOldLoan === true}
                                        isPacked={true}
                                        onChange={onChangeRadioOldLoan}
                                      >
                                        <b>ผ่าน</b>
                                      </Radio>
                                      <Radio
                                        key={"13"}
                                        value={false}
                                        style={{ color: '#cf1322' }}
                                        checked={npOldLoan === false}
                                        isPacked={true}
                                        onChange={onChangeRadioOldLoan}
                                      >
                                        <b>ไม่ผ่าน</b>
                                      </Radio>
                                    </Row>

                                  </Col>
                                  <Col className='gutter-row' span={24}>
                                    {
                                      npOldLoan === false ?
                                        <>
                                          <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npOldLoanNote"
                                            style={{ margin: 0, textAlign: 'left' }}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                              },]}

                                          >
                                            <Input name="npCustomerNote" onChange={(e) => setNotPassData({ ...notPassData, npOldLoanNote: e.target.value })} />
                                          </Form.Item>
                                        </> : null
                                    }
                                  </Col>
                                </>
                                : null
                            }

                          </Row>
                        </Card>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียดขอกู้</u></b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                // label='ยอดจัด'
                                style={{ marginBottom: 0, }}
                              >
                                <span>ยอดจัด : </span>
                                <b>{currencyFormatOne(getData1?.landLoanDetails?.loanAmountLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                // label='ดอกเบี้ย'
                                style={{ marginBottom: 0, }}
                              >
                                <span>ดอกเบี้ย : </span>
                                <b>{currencyFormatOne(getData1?.landLoanDetails?.interestLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                // label='จำนวนงวด'
                                style={{ marginBottom: 0, }}
                              >
                                <span>จำนวนงวด : </span>
                                <b>{getData1?.landLoanDetails?.loanLandTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                              <Form.Item
                                // label='ราคารวม'
                                style={{ marginBottom: 0, }}
                              >
                                <span>ราคารวม : </span>
                                <b>{currencyFormatOne(~~getData1?.landLoanDetails?.loanAmountLand + ~~getData1?.landLoanDetails?.interestLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                              <Form.Item
                                // label='งวดละ'
                                style={{ marginBottom: 0, }}
                              >
                                {/* <span>เรทรถ : </span> */}
                                <b>{currencyFormatOne(getData1?.landLoanDetails?.monthlyPaymentLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                // label='ผู้เสนอเคสเช่าซื้อ'
                                style={{ marginBottom: 0, }}
                              >
                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                <b>{getData1?.landLoanDetails?.proposalBy}</b>
                              </Form.Item>
                            </Col>
                            {
                              app === "รอวิเคราะห์" ?
                                <>
                                  <Col className='gutter-row' span={3}>
                                    <Row className="center">
                                      <Radio
                                        key={"14"}
                                        value={true}
                                        style={{ color: '#389e0d' }}
                                        checked={npLoan === true}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ผ่าน</b>
                                      </Radio>
                                      <Radio
                                        key={"15"}
                                        value={false}
                                        style={{ color: '#cf1322' }}
                                        checked={npLoan === false}
                                        isPacked={true}
                                        onChange={onChangeRadioLoan}
                                      >
                                        <b>ไม่ผ่าน</b>
                                      </Radio>
                                    </Row>
                                  </Col>
                                  <Col className='gutter-row' span={24}>
                                    {
                                      npLoan === false ?
                                        <>
                                          <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npLoanNote"
                                            style={{ margin: 0, textAlign: 'left' }}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                              },]}

                                          >
                                            <Input name="npLoanNote" onChange={(e) => setNotPassData({ ...notPassData, npLoanNote: e.target.value })} />
                                          </Form.Item>
                                        </> : null
                                    }
                                  </Col>
                                </>
                                : null
                            }

                          </Row>
                        </Card>
                      </>
                      :
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Card style={{ width: '1000px' }}>
                            <Row justify={'left'}><b><u>รายละเอียด{getData1?.landLoanDetails?.productLoanLandType}</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                              <Col className='gutter-row' span={7}>
                                <Form.Item
                                  // label='ยอดจัด'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>ยอดจัด : </span>
                                  <b>{currencyFormatOne(getData1?.landLoanDetails?.loanAmountLand)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                                <Form.Item
                                  // label='ดอกเบี้ย'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>ดอกเบี้ย : </span>
                                  <b>{currencyFormatOne(getData1?.landLoanDetails?.interestLand)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col className='gutter-row' span={7}>
                                <Form.Item
                                  // label='จำนวนงวด'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>จำนวนงวด : </span>
                                  <b>{getData1?.landLoanDetails?.loanLandTerm}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    งวด
                                  </span>
                                </Form.Item>
                                <Form.Item
                                  // label='ราคารวม'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>ราคารวม : </span>
                                  <b>{currencyFormatOne(~~getData1?.landLoanDetails?.loanAmountLand + ~~getData1?.landLoanDetails?.interestLand)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col className='gutter-row' span={7}>
                                <Form.Item
                                  // label='งวดละ'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>งวดละ : </span>
                                  <b>{currencyFormatOne(getData1?.landLoanDetails?.monthlyPaymentLand)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                                <Form.Item
                                  // label='ผู้เสนอเคสเช่าซื้อ'
                                  style={{ marginBottom: 0, }}
                                >
                                  <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                  <b>{getData1?.landLoanDetails?.proposalBy}</b>
                                </Form.Item>
                              </Col>

                              {
                                app === "รอวิเคราะห์" ?
                                  <>
                                    <Col className='gutter-row' span={3}>
                                      <Row className="center">
                                        <Radio
                                          key={"16"}
                                          value={true}
                                          style={{ color: '#389e0d' }}
                                          checked={npLoan === true}
                                          isPacked={true}
                                          onChange={onChangeRadioLoan}
                                        >
                                          <b>ผ่าน</b>
                                        </Radio>
                                        <Radio
                                          key={"17"}
                                          value={false}
                                          style={{ color: '#cf1322' }}
                                          checked={npLoan === false}
                                          isPacked={true}
                                          onChange={onChangeRadioLoan}
                                        >
                                          <b>ไม่ผ่าน</b>
                                        </Radio>
                                      </Row>

                                    </Col>
                                    <Col className='gutter-row' span={24}>
                                      {
                                        npLoan === false ?
                                          <>
                                            <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npLoanNote"
                                              style={{ margin: 0, textAlign: 'left' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'กรุณากรอกสาเหตุ !'
                                                },]}

                                            >
                                              <Input name="npLoanNote" onChange={(e) => setNotPassData({ ...notPassData, npLoanNote: e.target.value })} />
                                            </Form.Item>
                                          </> : null
                                      }
                                    </Col>
                                  </>
                                  : null
                              }

                            </Row>
                          </Card>
                        </Row>
                      </>
                    }
                  </>
                  : null
              }
              {
                types === "car" ?
                  <>
                    {
                      getData1?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                        getData1?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                        getData1?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                        getData1?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ?
                        <Card style={{ width: '1000px' }}>
                          <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                          <Row style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >
                            <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                              <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(getData1?.carLoanDetailsRe?.priceChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>
                            <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                              <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(getData1?.carLoanDetailsRe?.overdue)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>
                            <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                              <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(getData1?.carLoanDetailsRe?.preChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span><br />
                              <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.carLoanDetailsRe?.preChange + ~~getData1?.carLoanDetailsRe?.overdue + ~~getData1?.carLoanDetailsRe?.priceChange
                              )}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>

                          </Row>
                        </Card>
                        :
                        null
                    }
                  </>
                  :
                  <>
                    {
                      getData1?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                        getData1?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                        getData1?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ||
                        getData1?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ?
                        <Card style={{ width: '1000px' }}>
                          <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                          <Row style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >
                            <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                              <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(getData1?.landLoanDetailsRe?.priceChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>
                            <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                              <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(getData1?.landLoanDetailsRe?.overdue)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>
                            <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                              <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(getData1?.landLoanDetailsRe?.preChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span><br />
                              <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.landLoanDetailsRe?.preChange + ~~getData1?.landLoanDetailsRe?.overdue + ~~getData1?.landLoanDetailsRe?.priceChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>

                          </Row>
                        </Card>
                        :
                        null
                    }
                  </>
              }
              {
                getData1?.carLoanDetails?.cars?.carsApproveCeo?.id > 0 ? (
                  <>
                    <Row justify={"center"} gutter={32}>
                      {/* <Card style={{}}> */}
                      <Card
                        style={{
                          width: "1000px",
                          backgroundColor: "lightyellow",
                        }}
                      >
                        <Row justify={"left"}>
                          <b>
                            <u style={{ backgroundColor: "#f39c12" }}>
                              เงื่อนไขการปรับโครงสร้าง (
                              {
                                getData1?.carLoanDetails?.cars?.carsApproveCeo
                                  ?.CONTNO
                              }
                              )
                            </u>
                          </b>
                        </Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                          <Image
                            width={100}
                            src={`${process.env.PUBLIC_URL}/logo.jpg`}
                            alt="My Image"
                          />
                        </Col>
                        <Row gutter={32} justify={"center"}>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="วันที่"
                            >
                              <b>{dateceo}</b>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="มูลหนี้เช่าซื้อคงเหลือรวม"
                            >
                              <b>{moonnee}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="หักส่วนลด"
                            >
                              <b>{hug}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="คงเหลือ"
                            >
                              <b>{kong}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จ่ายเงิน"
                            >
                              <b>{jaimoney}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จำนวนงวด"
                            >
                              <b>{ngod}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ผ่อน"
                            >
                              <b>{pon}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                งวด (รวมภาษีมูลค่าเพิ่ม)
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ยอดจัด"
                            >
                              <b style={{ fontSize: "20px" }}>
                                <u>{yodjut}</u>
                              </b>
                              {/* <b>{currencyFormatOne(getData1?.carLoanDetails?.cars?.carsApproveCeo?.approvedLoanAmount)}</b> */}
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                            <Form.Item
                              style={{ margin: 0, textAlign: "center" }}
                              label="ใบนี้หมดอายุ ณ วันที่"
                            >
                              <b style={{ color: "red", fontSize: "20px" }}>
                                <u>{exp}</u>
                              </b>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </>
                ) : null
              }
              {
                loanRe?.reLoanId > 0 ? (
                  <>
                    <Row justify={"center"} gutter={32}>
                      <Card style={{ width: "1000px" }}>
                        <Row justify={"left"}>
                          <b>
                            <u>รายละเอียดสัญญา ({isu})</u>
                          </b>
                        </Row>
                        <Row gutter={32} justify={"center"}>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ค่างวด"
                            >
                              <b>{currencyFormatOne(loanRe?.DAMT)}</b>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ยอดจัดครั้งที่แล้ว"
                            >
                              <b>{currencyFormatOne(loanRe?.tcshprc)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ราคาเช่าซื้อครั้งที่แล้ว"
                            >
                              <b>{currencyFormatOne(loanRe?.balanc)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ยอดที่ชำระมาแล้วทั้งหมด"
                            >
                              <b>{currencyFormatOne(loanRe?.totalpayment)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ล/น คงเหลือรวม"
                            >
                              <b>{currencyFormatOne(loanRe?.neekong)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="เบี้ยปรับ"
                            >
                              <b>{currencyFormatOne(loanRe?.beeypup)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ลูกหนี้อื่นๆ"
                            >
                              <b>{currencyFormatOne(loanRe?.OTHR)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จำนวนงวดที่ผ่อนมาแล้ว"
                            >
                              <b>{currencyFormatOne(loanRe?.NOPAY)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                งวด
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จำนวนงวดทั้งหมด"
                            >
                              <b>{currencyFormatOne(loanRe?.nopays)}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                งวด
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ตัดสด งวดที่"
                            >
                              <b>{currencyFormatOne(loanRe?.reqNo)}</b>
                            </Form.Item>
                            <Form.Item
                              style={{
                                marginBottom: 0,
                                textAlign: "center",
                                background: "#1677ff",
                              }}
                              label="จำนวนงวดที่ค้าง"
                            >
                              <b>{~~loanRe?.nopays - ~~loanRe?.NOPAY}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                งวด
                              </span>
                            </Form.Item>
                            <b>
                              <Form.Item
                                style={{ margin: 0, textAlign: "center" }}
                                label="ยอดรีไฟแนนซ์เพื่อจัดใหม่"
                              >
                                <b style={{ fontSize: "20px" }}>
                                  <u>{currencyFormatOne(loanRe?.re1)}</u>
                                </b>
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginLeft: "30px",
                                  }}
                                >
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                style={{ margin: 0, textAlign: "center" }}
                                label="ใบนี้หมดอายุ ณ วันที่"
                              >
                                <b style={{ color: "red", fontSize: "20px" }}>
                                  <u>
                                    {dayjs(loanRe?.newOfferDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </u>
                                </b>
                              </Form.Item>
                            </b>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </>
                ) : null
              }
              {
                dataApproved?.id > 0 ? (
                  <Card>
                    <Divider
                      orientation="center"
                      align={"middle"}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <b>คำอนุมัติใหม่</b>
                    </Divider>

                    <Row>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <div
                          label="ค่างวดที่ต้องชำระ"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>ค่างวดที่ต้องชำระ : </b>
                          {!dataApproved?.newDamt
                            ? "-"
                            : currencyFormatOne(dataApproved?.newDamt)}{" "}
                          บาท
                        </div>
                        <div
                          label="ค่าติดตาม"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>ค่าติดตาม : </b>
                          {!dataApproved?.followPay
                            ? "-"
                            : currencyFormatOne(dataApproved?.followPay)}{" "}
                          บาท
                        </div>
                        <div
                          label="เงินต้น"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>เงินต้น : </b>
                          {!dataApproved?.approvedLoanAmount
                            ? "-"
                            : currencyFormatOne(
                              dataApproved?.approvedLoanAmount
                            )}{" "}
                          บาท
                        </div>
                        <div
                          label="จำนวนงวด"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>จำนวนงวด : </b>
                          {!dataApproved?.approvedLoanTerm
                            ? null
                            : dataApproved?.approvedLoanTerm}{" "}
                          {" ถึง: "}
                          {!dataApproved?.approvedLoanTermTo
                            ? "-"
                            : dataApproved?.approvedLoanTermTo}{" "}
                          งวด
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ) : null
              }

              {types === "car" ?
                <>
                  <Card style={{ width: '1000px' }}>
                    <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                      <Col className='gutter-row' span={7}>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='ยี่ห้อ'
                        >
                          <span>ยี่ห้อ : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carBrand}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='เลขทะเบียน'
                        >
                          <span>เลขทะเบียน : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carPlateNumber}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='เลขเครื่อง'
                        >
                          <span>เลขเครื่อง : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carEngineNumber}</b>
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' span={7}>

                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='รุ่นสินค้า'
                        >
                          <span>รุ่นสินค้า : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carModel}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='เลขคัสซี'
                        >
                          <span>เลขคัสซี : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carChassisNumber}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='สี'
                        >
                          <span>สี : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carColor}</b>
                        </Form.Item>

                      </Col>
                      <Col className='gutter-row' span={7}>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='แบบรถ'
                        >
                          <span>แบบรถ : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carBaab}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='ปีรถ'
                        >
                          <span>ปีรถ : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carYear}</b>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='จังหวัด'
                        >
                          <span>จังหวัด : </span>
                          <b>{getData1?.carLoanDetails?.cars?.carProvince}</b>
                        </Form.Item>
                      </Col>

                      {
                        app === "รอวิเคราะห์" ?
                          <>
                            <Col className='gutter-row' span={3}>
                              <Row className="center">
                                <Radio
                                  key={"18"}
                                  value={true}
                                  style={{ color: '#389e0d' }}
                                  checked={npProduct === true}
                                  isPacked={true}
                                  onChange={onChangeRadioProduct}
                                >
                                  <b>ผ่าน</b>
                                </Radio>
                                <Radio
                                  key={"19"}
                                  value={false}
                                  style={{ color: '#cf1322' }}
                                  checked={npProduct === false}
                                  isPacked={true}
                                  onChange={onChangeRadioProduct}
                                >
                                  <b>ไม่ผ่าน</b>
                                </Radio>
                              </Row>

                            </Col>
                            <Col className='gutter-row' span={24}>
                              {
                                npProduct === false ?
                                  <>
                                    <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npProductNote"
                                      style={{ margin: 0, textAlign: 'left' }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'กรุณากรอกสาเหตุ !'
                                        },]}

                                    >
                                      <Input name="npProductNote" onChange={(e) => setNotPassData({ ...notPassData, npProductNote: e.target.value })} />
                                    </Form.Item>
                                  </> : null
                              }
                            </Col>
                          </>
                          : null
                      }

                    </Row>
                  </Card>
                </>
                :
                <>
                  {
                    newDataLand?.map((item, index) => {
                      return renderLandNew({ item, index, key: `{item.identificationId} - ${index}` })
                    })
                  }
                </>
              }
              {getData1?.landLoanDetails?.land?.approval?.priceStatus === true ?
                <>
                  {/* <Card style={{ width: '1000px' }}> */}
                  {
                    newDataLand?.map((item, index) => {
                      return renderPriceApprove({ item, index, key: `{item.identificationId} - ${index}` })
                    })
                  }
                  {
                    getData1?.landLoanDetails?.land?.approval?.mixNote === "" ||
                      getData1?.landLoanDetails?.land?.approval?.mixNote === undefined ||
                      getData1?.landLoanDetails?.land?.approval?.mixNote === null ?
                      null
                      :
                      <>
                        {
                          newDataLand?.map((item, index) => {
                            return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                      </>
                  }
                </>
                : null
              }
              {
                checkBroker ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>ค่าแนะนำ</u></b></Row>
                      <Row justify={'center'} gutter={32}>
                        <Col span={7}>
                          <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                            <span>ผู้แนะนำ : </span>
                            <b>{brokerName}</b>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                            <span>จำนวนเงิน : </span>
                            <b>{data630}</b>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} >
                            <span>ค่าอนุมัติ/บริการ : </span>
                            <b>{data627}</b>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </>
                  : null
              }
              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}>
                  <b>
                    <u>คนค้ำประกัน</u>
                  </b>
                </Row>
                {/* {
                  guarantorData?.length > 0 ?
                    <> */}
                      <Row justify={'center'}>
                        <Col span={24}><br /></Col>
                        <Col span={21}>
                          <Row gutter={32} justify={'center'}>
                            {
                              guarantorData?.map((item, index) => {
                                return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                              })
                            }
                          </Row>
                        </Col>
                        {
                          app === "รอวิเคราะห์" ?
                            <>
                              <Col span={3} style={{ textAlign: 'right' }}>
                                <Row className="center" style={{ textAlign: 'right' }} align={'middle'}>
                                  <Radio
                                    key={"24"}
                                    value={true}
                                    style={{ color: '#389e0d' }}
                                    checked={npGuarantor === true}
                                    isPacked={true}
                                    onChange={onChangeRadioGuarantor}
                                  >
                                    <b>ผ่าน</b>
                                  </Radio>
                                  <Radio
                                    key={"25"}
                                    value={false}
                                    style={{ color: '#cf1322' }}
                                    checked={npGuarantor === false}
                                    isPacked={true}
                                    onChange={onChangeRadioGuarantor}
                                  >
                                    <b>ไม่ผ่าน</b>
                                  </Radio>
                                </Row>
                              </Col>
                            </>
                            : null
                        }
                      </Row>
                    {/* </>
                    : null
                } */}
                <Col className='gutter-row' span={24}>
                  {
                    npGuarantor === false ?
                      <>
                        <Form.Item label="สาเหตุที่ไม่ผ่านการพิจารณา" name="npGuarantorNote"
                          style={{ margin: 0, textAlign: 'left' }}
                          rules={[
                            {
                              required: true,
                              message: 'กรุณากรอกสาเหตุ !'
                            },]}

                        >
                          <Input name="npGuarantorNote" onChange={(e) => setNotPassData({ ...notPassData, npGuarantorNote: e.target.value })} />
                        </Form.Item>
                      </> : null
                  }
                </Col>
              </Card>
              {
                app === "รอวิเคราะห์" ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      {/* <Row gutter={32} justify={'center'} style={{ marginTop: 15, marginBottom: 15, width: '1100px' }}> */}
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <Checkbox
                          key={"0"}
                          value={1}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ค้ำ-ไม่โอน"}
                          isPacked={true}
                          onChange={() => onChange("ค้ำ-ไม่โอน")}
                        >
                          ค้ำ-ไม่โอน
                        </Checkbox>
                        <Checkbox
                          key={"1"}
                          value={2}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ค้ำ-โอน"}
                          isPacked={true}
                          onChange={() => onChange("ค้ำ-โอน")}
                        >
                          ค้ำ-โอน
                        </Checkbox>
                        <Checkbox
                          key={"2"}
                          value={3}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ไม่ค้ำ-ไม่โอน"}
                          isPacked={true}
                          onChange={() => onChange("ไม่ค้ำ-ไม่โอน")}
                        >
                          ไม่ค้ำ-ไม่โอน
                        </Checkbox>
                        <Checkbox
                          key={"3"}
                          value={4}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ไม่ค้ำ-โอน"}
                          isPacked={true}
                          onChange={() => onChange("ไม่ค้ำ-โอน")}
                        >
                          ไม่ค้ำ-โอน
                        </Checkbox>
                      </Col>
                      {/* </Row> */}
                    </Card>
                  </>
                  :
                  <>
                    <Card style={{ width: '1000px' }}>
                      {/* <Row gutter={32} justify={'center'} style={{ marginTop: 15, marginBottom: 15, width: '1100px' }}> */}
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <Checkbox
                          key={"0"}
                          value={1}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ค้ำ-ไม่โอน"}
                          isPacked={true}
                        >
                          ค้ำ-ไม่โอน
                        </Checkbox>
                        <Checkbox
                          key={"1"}
                          value={2}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ค้ำ-โอน"}
                          isPacked={true}
                        >
                          ค้ำ-โอน
                        </Checkbox>
                        <Checkbox
                          key={"2"}
                          value={3}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ไม่ค้ำ-ไม่โอน"}
                          isPacked={true}
                        >
                          ไม่ค้ำ-ไม่โอน
                        </Checkbox>
                        <Checkbox
                          key={"3"}
                          value={4}
                          style={{ fontSize: '20px' }}
                          checked={selectedCheckbox === "ไม่ค้ำ-โอน"}
                          isPacked={true}
                        >
                          ไม่ค้ำ-โอน
                        </Checkbox>
                      </Col>
                      {/* </Row> */}
                    </Card>
                  </>
              }

              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>หมายเหตุเสนอเคส</u></b> </Row>
                <Form.Item name="offercaseNote" label="">
                  <TextArea style={{ color: 'blue' }} rows={5} disabled name="offercaseNote" />
                </Form.Item>
              </Card>
              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>หมายเหตุ</u></b> </Row>
                <Row gutter={32} justify={'center'}>
                  {
                    notes?.length >= 1 ?
                      <>
                        <Card style={{ width: '270px', textAlign: 'left' }}>
                          <div style={{ marginBottom: 0 }}>
                            <span>สาเหตุ : </span>
                            <b>{notes[lastIndex].cause}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>หมายเหตุ : </span>
                            <b>{notes[lastIndex].note}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>ผู้สร้างหมายเหตุ : </span>
                            <b>{notes[lastIndex].noteBy}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>สถานะที่ทำการหมายเหตุ : </span>
                            <b>{notes[lastIndex].status}</b>
                          </div>
                        </Card>
                      </>
                      : <>* ยังไม่เพิ่มหมายเหตุ!</>
                  }
                </Row>
              </Card>
              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>หมายเหตุเคสรี/ปรับ</u></b> </Row>
                <Row gutter={32} justify={'center'}>
                  {
                    notesRe?.length >= 1 ?
                      <>
                        <Card style={{ width: '270px', textAlign: 'left' }}>
                          <div style={{ marginBottom: 0 }}>
                            <span>สาเหตุ : </span>
                            <b>{notesRe[lastIndexRe].cause}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>หมายเหตุ : </span>
                            <b>{notesRe[lastIndexRe].note}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>ผู้สร้างหมายเหตุ : </span>
                            <b>{notesRe[lastIndexRe].noteBy}</b>
                          </div>
                          <div style={{ marginBottom: 0 }}>
                            <span>สถานะที่ทำการหมายเหตุ : </span>
                            <b>{notesRe[lastIndexRe].status}</b>
                          </div>
                        </Card>
                      </>
                      : <>* ยังไม่เพิ่มหมายเหตุ!</>
                  }
                </Row>
              </Card>
              {
                guarantor?.data?.typeLoan === "car" ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>รูปรถ</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical">
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const imageCount1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                const displayedIndex = Math.min(index, imageCount1);
                                const displayTotal = Math.max(imageCount1, imageCount1);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage1?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage1?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Space>
                      </Row>
                    </Card>
                    {
                      checkSendNEW ?
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รูปรถ</u> (ใหม่)</b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Space direction="vertical">
                              <Image.PreviewGroup
                                preview={{
                                  countRender: (index, total) => {
                                    const imageCountnew1 = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                    console.log("imageCountnew1", imageCountnew1)
                                    const displayedIndex = Math.min(index, imageCountnew1);
                                    const displayTotal = Math.max(imageCountnew1, imageCountnew1);
                                    return `${displayedIndex} / ${displayTotal}`;
                                  }, // ใช้เพื่อแสดงจำนวนภาพ
                                  toolbarRender: (
                                    _,
                                    {
                                      transform: { scale },
                                      actions: {
                                        onActive,
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                      },
                                    },
                                  ) => (
                                    <>
                                      <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimagenew1?.map((e, index) => {
                                          return (
                                            <>
                                              <Image
                                                width={auto}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                              />
                                            </>
                                          );
                                        })}
                                      </Row>
                                      <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                          {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                          <RightOutlined onClick={() => onActive?.(1)} /> */}
                                          <DownloadOutlined onClick={onDownload} />
                                          <SwapOutlined rotate={90} onClick={onFlipY} />
                                          <SwapOutlined onClick={onFlipX} />
                                          <RotateLeftOutlined onClick={onRotateLeft} />
                                          <RotateRightOutlined onClick={onRotateRight} />
                                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                          <UndoOutlined onClick={onReset} />
                                        </Space>
                                      </Row>
                                    </>

                                  ),
                                  onChange: (index) => {
                                    setCurrent(index);
                                  },
                                }}


                              >
                                <Row gutter={32} justify={'center'}>
                                  {carimagenew1?.map((e, index) => {
                                    return (
                                      <>
                                        <Image
                                          width={150}
                                          key={index}
                                          onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                          src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                          alt={`รูปภาพ ${index + 1}`}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </>
                                    );
                                  })}
                                </Row>
                              </Image.PreviewGroup>
                            </Space>
                          </Row>
                        </Card>
                        : null
                    }
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>รูปเอกสาร</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical">
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const imageCount2 = carimage2?.length || 0; // นับจำนวนภาพจริง
                                const displayedIndex = Math.min(index, imageCount2);
                                const displayTotal = Math.max(imageCount2, imageCount2);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage2?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>

                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage2?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Space>
                      </Row>
                    </Card>
                    {
                      checkSendNEW ?
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รูปเอกสาร</u> (ใหม่)</b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Space direction="vertical">
                              <Image.PreviewGroup
                                preview={{
                                  countRender: (index, total) => {
                                    const imageCountnew2 = carimagenew2?.length || 0; // นับจำนวนภาพจริง
                                    const displayedIndex = Math.min(index, imageCountnew2);
                                    const displayTotal = Math.max(imageCountnew2, imageCountnew2);
                                    return `${displayedIndex} / ${displayTotal}`;
                                  }, // ใช้เพื่อแสดงจำนวนภาพ
                                  toolbarRender: (
                                    _,
                                    {
                                      transform: { scale },
                                      actions: {
                                        onActive,
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                      },
                                    },
                                  ) => (
                                    <>
                                      <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimagenew2?.map((e, index) => {
                                          return (
                                            <>
                                              <Image
                                                width={auto}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                              />
                                            </>
                                          );

                                        })}
                                      </Row>
                                      <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                          {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                          <RightOutlined onClick={() => onActive?.(1)} /> */}
                                          <DownloadOutlined onClick={onDownload} />
                                          <SwapOutlined rotate={90} onClick={onFlipY} />
                                          <SwapOutlined onClick={onFlipX} />
                                          <RotateLeftOutlined onClick={onRotateLeft} />
                                          <RotateRightOutlined onClick={onRotateRight} />
                                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                          <UndoOutlined onClick={onReset} />
                                        </Space>
                                      </Row>
                                    </>
                                  ),
                                  onChange: (index) => {
                                    setCurrent(index);
                                  },
                                }}
                              >
                                <Row gutter={32} justify={'center'}>
                                  {carimagenew2?.map((e, index) => {
                                    return (
                                      <>
                                        <Image
                                          width={150}
                                          key={index}
                                          onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                          src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                          alt={`รูปภาพ ${index + 1}`}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </>
                                    );
                                  })}
                                </Row>
                              </Image.PreviewGroup>
                            </Space>
                          </Row>
                        </Card>
                        :
                        null
                    }
                    <Divider />
                  </>
                  :
                  <>
                    <Card style={{ width: '1000px' }}>
                      {
                        newDataLand?.map((item, index) => {
                          return renderTitleDeed({ item, index, key: `{item.identification} - ${index}` })
                        })
                      }
                      {
                        newDataLand?.map((item, index) => {
                          return renderDivision({ item, index, key: `{item.identification} - ${index}` })
                        })
                      }
                      {
                        newDataLand?.map((item, index) => {
                          return renderLawang({ item, index, key: `{item.identification} - ${index}` })
                        })
                      }
                      {
                        newDataLand?.map((item, index) => {
                          return renderLandBuildings({ item, index, key: `{item.identification} - ${index}` })
                        })
                      }
                    </Card>
                    <Divider />
                  </>
              }
              {
                dataFromTable?.ISSUNO !== "" ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>เอกสารขอรี/ปรับ/เปลี่ยน</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical">
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const imageCount14 = carimage14?.length || 0; // นับจำนวนภาพจริง
                                console.log("imageCountnew1", imageCount14)
                                const displayedIndex = Math.min(index, imageCount14);
                                const displayTotal = Math.max(imageCount14, imageCount14);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (

                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage14?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>

                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}


                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage14?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                )
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Space>
                      </Row>
                    </Card>
                    <Divider />
                  </>
                  : null
              }
            </Row>
            <Row gutter={32} justify={'center'}>
              <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                <Space>
                  <Button
                    style={{ background: '#bfbfbf', color: '#000000', }}
                    type='primary'
                    onClick={onChangeBack}
                  >
                    ย้อนกลับ
                  </Button>
                  {
                    guarantor?.data?.typeLoan === "car" ?
                      <>
                        {
                          getData1?.carLoanDetails?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                              <Button type="primary" onClick={handleCancel} style={{ background: "gray" }}>ปิดหน้าต่าง</Button>
                              <Button type='primary' onClick={showConfirmResend} icon={<SendOutlined />} >ส่งเคสใหม่</Button>
                            </>
                            :
                            <>
                              <Button type="primary" onClick={handleCancel} style={{ background: "gray" }}>ปิดหน้าต่าง</Button>
                              <Button type="primary" onClick={handleReject}>Reject</Button>
                              <Button type="primary" htmlType="submit" style={{ background: "red" }}>ไม่ผ่านการวิเคราะห์</Button>
                              {
                                checkSendNEW ?
                                  <Button type="primary" onClick={onApprovePRE}>ผ่านการวิเคราะห์ (Pre)</Button>
                                  :
                                  <Button type="primary" onClick={onApprove}>ผ่านการวิเคราะห์</Button>
                              }
                            </>
                        }
                      </>
                      :
                      <>
                        {
                          getData1?.landLoanDetails?.approvalStatus === "ไม่อนุมัติ" ?
                            <>
                              <Button type="primary" onClick={handleCancel} style={{ background: "gray" }}>ปิดหน้าต่าง</Button>
                              <Button type='primary' icon={<SendOutlined />} onClick={showConfirmResend}>ส่งเคสใหม่</Button>
                            </>
                            :
                            <>
                              <Button type="primary" onClick={handleCancel} style={{ background: "gray" }}>ปิดหน้าต่าง</Button>
                              <Button type="primary" onClick={handleReject}>Reject</Button>
                              <Button type="primary" htmlType="submit" style={{ background: "red" }}>ไม่ผ่านการวิเคราะห์</Button>
                              {
                                checkSendNEW ?
                                  <Button type="primary" onClick={onApprovePRE}>ผ่านการวิเคราะห์ (Pre)</Button>
                                  :
                                  <Button type="primary" onClick={onApprove}>ผ่านการวิเคราะห์</Button>
                              }
                            </>
                        }
                      </>
                  }
                  {/* <Button type="primary" htmlType="submit" style={{ background: "red" }}>ทดสอบ</Button> */}
                </Space>
              </Col>
            </Row>

          </Row>
        </Card >
      </Form >
      <Divider style={{ margin: 5 }} />
      {
        modalAddGuarantor ?
          <ModalAddGuarantor
            open={modalAddGuarantor}
            close={setModalAddGuarantor}
            getData={getData}
            // fucnAdd={fucnAdd}
            dataCusid={dataPost}
            getCarId={getCarId} />
          : null
      }
      {
        ModalEditData ?
          <ModalEditGuarantor
            open={ModalEditData}
            close={setModalEditData}
            // fucnEdit={fucnEdit}
            dataindex={index1}
            shootdata={dataedit}
            getCarId={getCarId} />
          : null
      }
      {
        modalView ?
          <ViewGuarantor
            open={modalView}
            close={setModalView}
            dataindex={index1}
            shootdata={dataedit} />
          : null
      }
      {
        modalnote ?
          <Note
            open={modalnote}
            close={setModalNote}
            shootdata={getData}
            appStatus={appStatus}
            // senddata={noted}
            setCheckApprove={setCheckApprove}
            setGetNotification={setGetNotification}
            sendback={sendback}
            valueCheckBox={selectedCheckbox}
            notPassData={notPassData}
            // sendNotetoConclusion={sendNotetoConclusion}
            handleCancel2={handleCancel}
          />
          : null
      }
      {
        openShowImage ?
          <ModalShowImages
            open={openShowImage}
            close={setOpenShowImage}
            carimage1={carshowMadol}
            nuarantorNew={nuarantorNew}
            nuarantorNum={nuarantorNum}
            setGuarantorNum={setGuarantorNum}
          />

          : null
      }
      {contextHolder}
    </div >
  )
};

export default Conclusion