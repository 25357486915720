import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row, Space, Spin, Table, notification, DatePicker, Checkbox, Modal } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { currencyFormatOne, msData, msPays, msError, msOK, msCancel } from "../file_mid/allFormat";
import DetailContno from "./DetailContno";

export default function Main() {
  const { confirm } = Modal
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  // const token = localStorage.getItem('token');
  const currentDateTime = dayjs();
  const oneDayAgo = currentDateTime.subtract(1, 'day');
  const [dataPost, setDataPost] = useState({ sec: 0, days1: oneDayAgo.format('YYYY-MM-DD'), days2: currentDateTime.format('YYYY-MM-DD') })
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [connt, setConnt] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    let mainData = {}
    setLoading(true)
    // const tk = JSON.parse(token)
    // const headers = {
    //   "Authorization": `Bearer ${tk}`,
    //   "Menu": JSON.stringify("4")
    // }
    mainData = { day1: dataPost.days1, day2: dataPost.days2, user: user, branch: branch }
    console.log("mainData", mainData)
    // await axios.post(checkinfoadmain, mainData, { headers: headers })
    // await axios.post("http://localhost:8070/api-db2/load-data-auto", mainData)
    await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/load-data-auto", mainData)
      .then(async (res) => {
        if (res.status === 200) {
          console.log("loadData 1 =>", res.data)
          setArrayTable(res.data)
          setAxiosData(res.data)
        } else {
          setArrayTable([])
          setAxiosData([])
          msData(api, "top")
        }
        setSelectedRows([])
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }
  const handleSubmit = () => {
    loadData()
  }
  const showConfirm = () => {
    confirm({
      title: 'คอมเฟิร์มทำรายการ...?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true)
        handleCheck()
      },
      onCancel() {
        msCancel(api, "top")
      },
  });
  }  
  const handleCheck = async () => {
    console.log("selectedRows", selectedRows)
    if (selectedRows.length > 0) {
      await axios.post("http://localhost:8070/api-db2/insert-payamt", selectedRows) // api-call
        .then(async (res) => {
          if (res.status === 200) {
            console.log("ok", res.data)
            msOK(api, "top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msPays(api, "top")
      setLoading(false)
    }
  }

  const search = (data) => {
    const greaterThanTen = axiosData.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(data.target.value)
    );
    setArrayTable(greaterThanTen)
  };
  // Handle selecting all rows
  const onSelectAllChange = (e) => {
    console.log("e.target.checked", e.target.checked)
    if (e.target.checked) {
      setSelectedRows(arrayTable); // Store all row data when selecting all
    } else {
      setSelectedRows([]); // Deselect all
    }
  };

  // Handle selecting individual row
  const onSelectChange = (record) => {
    if (selectedRows.find(row => row.keyId === record.keyId)) {
      setSelectedRows(selectedRows.filter(row => row.keyId !== record.keyId));
    } else {
      setSelectedRows([...selectedRows, record]); // Add full record instead of just uid
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onSelectAllChange}
          checked={selectedRows.length === arrayTable.length && arrayTable.length > 0}
          indeterminate={selectedRows.length > 0 && selectedRows.length < arrayTable.length}
        />
      ),
      dataIndex: 'select',
      key: 'select',
      align: 'center',
      width: "5%",
      render: (text, record) => (
        <Checkbox
          onChange={() => onSelectChange(record)}
          checked={selectedRows.find(row => row.keyId === record.keyId)}
        />
      ),
    },
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      key: 'money',
      title: "ยอดเงิน",
      dataIndex: "money",
      width: "auto",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.money)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่ชำระเข้ามา",
      dataIndex: "PAYMENTDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{dayjs(record.PAYMENTDATE).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "ลูกหนี้อื่นๆ",
      dataIndex: "AROTHR",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.AROTHR)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "รับฝาก",
      dataIndex: "TOTAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.TOTAMT)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'Action',
      title: "Action",
      fixed: 'right',
      align: 'center',
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Button onClick={() => {
            setIsModalInfo(true)
            setConnt(record)
          }}><SearchOutlined /></Button>
        </Space>
      ),
    },
  ];
  return <>
    <Card
      bordered={false}
      style={{
        width: 'auto',
      }}
    >
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <div className='text-center'>
              <h2>รับเงินเข้างวด ออโต้ test</h2>
            </div>
            <Row gutter={32} justify={'center'}>
              <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <>{"เลือกวันที่ต้องการ :"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={oneDayAgo}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    <>{"ถึง"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    <Button disabled={loading} type='primary' onClick={handleSubmit} >ค้นหาสัญญา</Button>
                    <Button style={{ marginRight: '15px' }} disabled={loading} onClick={showConfirm} >คอมเฟิร์มสัญญาที่จะตัดเข้าตารางดิว</Button>
                  </Space>
                </Col>
              </Row>
            </Row>
            <Divider style={{ margin: 5 }} />
            <Row gutter={32} style={{ margin: 10 }}>
              <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                <Input.Search
                  style={{ width: '250px' }}
                  placeholder="ค้นหาสัญญา...."
                  onChange={search}
                  name="search"
                  id="search"
                  size="large"
                // value={query}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Table
                  // pagination={false}
                  rowKey={(record) => record.uid}
                  scroll={{
                    x: 1500,
                    y: 400,
                  }}
                  dataSource={arrayTable}
                  columns={columns}
                >
                </Table>
                <Divider />
                {/* <Pagination
                  current={currentPage}
                  onChange={onChangePagination}
                  onShowSizeChange={onChangePagination}
                  defaultPageSize={10}
                  defaultCurrent={1}
                  total={testPage?.totalCount}
                /> */}
                {/* <Divider /> */}
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Card>
    {
      isModalInfo ?
        <DetailContno
          open={isModalInfo}
          close={setIsModalInfo}
          dataFromTable={connt}
        />
        : null
    }
    {contextHolder}
  </>
}

