import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button, Form, Divider, Row, Col, Card, Space } from 'antd';
import { useSelector } from 'react-redux';
import { addguarantors } from '../../../../file_mid/all_api';

export default function GEDetail({ close, prev, fucnEdit, saveclose, dataindex, cha, chp, theData }) {

    const customers = useSelector(state => state.customers)
    const Addresss = useSelector(state => state.Addresss)
    const Phones = useSelector(state => state.Phones)

    const [dataCustomer, setDataCustomer] = useState({})
    const [dataAddress, setDataAddresss] = useState([])
    const [dataPhones, setDataPhones] = useState([])

    const [getIndex, setGetIndex] = useState(dataindex);
    const [dataMix, setDataMix] = useState({})

    // const { confirm } = Modal
    const [form] = Form.useForm();

    console.log("theData", theData)
    console.log("customers.data", customers.data)
    // console.log("dataCustomer", dataCustomer)
    // console.log("dataAddress", dataAddress)
    // console.log("dataPhones", dataPhones)

    useEffect(() => {
        if (customers.data.customerId === undefined) {
            setDataCustomer(theData)
        } else if (customers.data.customerId !== undefined) {
            setDataCustomer(customers.data)
        }
        // else if (theData.customerId !== undefined) {
        //     setDataCustomer(theData)
        // }

        if (Addresss.data === undefined) {
            if (theData.dataAddress !== undefined) {
                setDataAddresss(theData.dataAddress)
            } else {
                setDataAddresss(theData.address)
            }
        } else if (Addresss.data !== undefined) {
            setDataAddresss(Addresss.data)
        }
        // else if (theData.dataAddress !== undefined) {
        //     setDataAddresss(theData.dataAddress)
        // }

        if (Phones.data === undefined) {
            if (theData.dataPhones !== undefined) {
                setDataPhones(theData.dataPhones)
            } else {
                setDataPhones(theData.phones)
            }
        } else if (Phones.data !== undefined) {
            setDataPhones(Phones.data)
        }

    }, [])
    // console.log("theData.dataPhones",theData.dataPhones)

    useEffect(() => {
        if (dataCustomer && dataAddress && dataPhones !== undefined) {
            setDataMix({ ...dataCustomer, dataAddress, dataPhones })
        }
        // console.log("1")
        setGetIndex(dataindex)
    }, [dataCustomer, dataAddress, dataPhones])

    const handleSubmit = () => {
        if (dataAddress === undefined) {
            cha('top')
        }
        if (dataPhones === undefined) {
            chp('top')
        }
        if (dataAddress && dataPhones !== undefined) {
            delete dataMix.status
            sendAxios()
            // console.log("dataMix", dataMix)
        }
    }

    const sendAxios = async () => {
        await axios.post(addguarantors, dataMix)
            .then(res => {
                if (res.status === 200) {
                    ExportToCard(res.data)
                }
            }).catch((err) => console.log(err))
    }
    const ExportToCard = (value) => {
        fucnEdit({ value, getIndex })
        saveclose()
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        prev()
    }

    const renderItem = ({ item, index }) => {

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        title={
                            (item.addressStatus === true || item.AddressStatus === 1 || item.AddressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
                        }
                        style={{ width: '300px', textAlign: 'center' }} >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                                <b>{item.soi}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                                <b>{item.subdistrict}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                                <b>{item.district}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                                <b>{item.zipCode}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                                <b>
                                    {(item.addressOnIdcard === true || item.AddressOnIdcard === 1 || item.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                    {(item.houseRegistration === true || item.HouseRegistration === 1 || item.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                    {(item.workAddress === true || item.WorkAddress === 1 || item.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                    {(item.otherAddress === true || item.OtherAddress === 1 || item.OtherAddress === true) && "อื่นๆ "}
                                    {(item.currentAddress === true || item.CurrentAddress === 1 || item.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                    {(item.sendDocuments === true || item.SendDocuments === 1 || item.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                                </b>
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    const renderPhone = ({ item, index }) => {

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index}
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                <b>{item.telp}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && (<b>ไม่ส่ง</b>)
                                }
                                {
                                    item.sendSms === "1" && (<b>ส่ง</b>)
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <Card>
                <Row>
                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียด</Col>
                    <Divider />
                </Row>
                <Form >
                    <Row>
                        <Col span={24} style={{ fontSize: '25px' }} >ข้อมูลส่วนตัว</Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                <b>{dataCustomer.identificationId}</b>
                            </Form.Item>
                            <Form.Item label="ชื่อ-สกุล" style={{ margin: 0 }}>
                                <b>{dataCustomer.snam}{dataCustomer.firstname} {dataCustomer.lastname}</b>
                            </Form.Item>
                            <Form.Item label="เกิดวันที่" style={{ margin: 0 }}>
                                <b>{dataCustomer.birthdate}</b>
                            </Form.Item>

                            <Form.Item label="ความสัมพันธ์กับผู้กู้" style={{ margin: 0 }}>
                                <b>{dataCustomer.description}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ชื่อเล่น" style={{ margin: 0 }}>
                                <b>{dataCustomer.nickname}</b>
                            </Form.Item>
                            <Form.Item label="สถานที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer.issuby}</b>
                            </Form.Item>
                            <Form.Item label="สาขา" style={{ margin: 0 }}>
                                <b>{dataCustomer.locat}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="เพศ" style={{ margin: 0 }}>
                                <b>{dataCustomer.gender}</b>
                            </Form.Item>
                            <Form.Item label="วันที่บัตรหมดอายุ" style={{ margin: 0 }}>
                                <b>{dataCustomer.expdt}</b>
                            </Form.Item>
                            <Form.Item label="วันที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer.issudt}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >รายละเอียดที่อยู่</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataAddress?.map((item, index) => {
                                        return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >ช่องทางการติดต่อ</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataPhones?.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className='gutter-row' span={24}>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }} onClick={handleSubmit}>บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </>
    )

};
