import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import { checklandpn, findlandpn, getImagess } from '../../file_mid/all_api';
import ModalShowImages from '../../ModalShowImageSlide/ModalShowImages';
import {
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from 'async';



export default function ModalDetail({ open, dataFromTable, close }) {

    const token = localStorage.getItem('token');
    const [dataLand, setDataLand] = useState(dataFromTable);
    const [newDataLand, setNewDataLand] = useState([]);
    // const [newDataLandnew, setNewDataLandNew] = useState([]);
    const [form] = Form.useForm()

    const [note, setNote] = useState([])
    // image
    const [openShowImage, setOpenShowImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // แยก type image car
    const [carimage1, setCarImage1] = useState([]);
    const [carimagenew1, setCarImagenew1] = useState([]);
    const [carimage2, setCarImage2] = useState([]);
    const [carimagenew2, setCarImagenew2] = useState([]);
    const [carimage3, setCarImage3] = useState([]);
    const [carimagenew3, setCarImagenew3] = useState([]);
    const [carimage4, setCarImage4] = useState([]);
    const [carimagenew4, setCarImagenew4] = useState([]);
    const [carimage5, setCarImage5] = useState([]);
    const [carimagenew5, setCarImagenew5] = useState([]);
    const [carimage6, setCarImage6] = useState([]);
    const [carimagenew6, setCarImagenew6] = useState([]);
    const [carimage7, setCarImage7] = useState([]);
    const [carimagenew7, setCarImagenew7] = useState([]);
    const [carimage8, setCarImage8] = useState([]);
    const [carimagenew8, setCarImagenew8] = useState([]);
    const [carimage9, setCarImage9] = useState([]);
    const [carimagenew9, setCarImagenew9] = useState([]);
    const [carimage10, setCarImage10] = useState([]);
    const [carimagenew10, setCarImagenew10] = useState([]);
    const [carimage11, setCarImage11] = useState([]);
    const [carimagenew11, setCarImagenew11] = useState([]);
    const [carimage12, setCarImage12] = useState([]);
    const [carimagenew12, setCarImagenew12] = useState([]);
    const [carimage14, setCarImage14] = useState([]);
    const [carimagenew14, setCarImagenew14] = useState([]);
    const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
    const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
    const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [current, setCurrent] = React.useState(0);
    const [selectedImages, setSelectedImages] = useState([]);


    console.log("carimage1", carimage1)
    console.log("carimage2", carimage2)
    console.log("carimage3", carimage3)
    console.log("carimage4", carimage4)
    console.log("carimage5", carimage5)
    console.log("carimage6", carimage6)
    console.log("carimage7", carimage7)
    console.log("carimage8", carimage8)
    console.log("carimage9", carimage9)
    console.log("carimage10", carimage10)
    console.log("carimage11", carimage11)
    console.log("carimage12", carimage12)
    console.log("carimage13", carimage8)
    console.log("carimage14", carimage14)
    console.log("dataLand", dataLand)
    console.log("newDataLand", newDataLand)
    useEffect(() => {
        setDataLand(dataFromTable)
        loadData()
        loadDataNew() // Array

    }, [])

    useEffect(() => {
        newDataLand.map((e, index) => {
            form.setFieldsValue({
                [`storyLand${index}`]: e?.storyLand,
            })
        })

    }, [newDataLand])

    const onDownload = () => {
        const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
        const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

        // ใช้ fetch ดึงรูปภาพจาก path
        fetch(imageObj.path)
            .then((response) => response.blob()) // เปลี่ยน response เป็น blob
            .then((blob) => {
                // สร้าง Blob URL สำหรับการดาวน์โหลด
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
                document.body.appendChild(link);
                link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
                URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
                link.remove(); // ลบ link ออกจาก DOM
            })
            .catch((error) => {
                console.error("Error downloading the image:", error);
            });
    };


    const handleSelectImage = (e, image) => {
        // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
        setSelectedImages(prevSelected => [...prevSelected, image]);
    };
   
    function extractFolderGroup(path) {
        const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
        return match ? match[1] : null;
    }

    const loadImage = async (value, data) => {
        var carimg1 = [];
        var carimg2 = [];
        var carimg3 = [];
        var carimg4 = [];
        var carimg5 = [];
        var carimg6 = [];
        var carimg7 = [];
        var carimg8 = [];
        var carimg9 = [];
        var carimg10 = [];
        var carimg11 = [];
        var carimg12 = [];
        var carimg14 = [];
        var carimgnew1 = [];
        var carimgnew2 = [];
        var carimgnew3 = [];
        var carimgnew4 = [];
        var carimgnew5 = [];
        var carimgnew6 = [];
        var carimgnew7 = [];
        var carimgnew8 = [];
        var carimgnew9 = [];
        var carimgnew10 = [];
        var carimgnew11 = [];
        var carimgnew12 = [];
        var carimgnew14 = [];
        if (value === 'land') {
            setImageBlobzz(data)
            data?.map((land, i) => {
                console.log("land", land)
                if (land.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
                        carimg1.push(land)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
                        carimgnew1.push(land)
                        setCarImagenew1(carimgnew4)
                    }
                    // carimg1.push(land)
                    // setCarImage1(carimg1)
                } else if (land.type === 3) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
                        carimg3.push(land)
                        setCarImage3(carimg3)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
                        carimgnew3.push(land)
                        setCarImagenew3(carimgnew3)
                    }
                    // carimg3.push(land)
                    // setCarImage3(carimg3)
                } else if (land.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
                        carimg4.push(land)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
                        carimgnew4.push(land)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(land)
                    // setCarImage4(carimg4)
                } else if (land.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(land);
                    if (group === '1') {
                        group1.push({ ...land, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...land, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...land, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...land, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...land, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...land, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (land.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
                        carimg6.push(land)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
                        carimgnew6.push(land)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(land)
                    // setCarImage6(carimg6)
                } else if (land.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
                        carimg7.push(land)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
                        carimgnew7.push(land)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(land)
                    // setCarImage7(carimg7)
                } else if (land.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
                        carimg8.push(land)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
                        carimgnew8.push(land)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(land)
                    // setCarImage8(carimg8)
                } else if (land.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
                        carimg9.push(land)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
                        carimgnew9.push(land)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(land)
                    // setCarImage9(carimg9)
                } else if (land.type === 10) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
                        carimg10.push(land)
                        setCarImage10(carimg10)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
                        carimgnew10.push(land)
                        setCarImagenew10(carimgnew10)
                    }
                    // carimg10.push(land)
                    // setCarImage10(carimg10)
                } else if (land.type === 11) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
                        carimg11.push(land)
                        setCarImage11(carimg11)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
                        carimgnew11.push(land)
                        setCarImagenew11(carimgnew11)
                    }
                    // carimg11.push(land)
                    // setCarImage11(carimg11)
                } else if (land.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
                        carimg14.push(land)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
                        carimgnew14.push(land)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(land)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
                        carimg12.push(land)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
                        carimgnew12.push(land)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(land)
                    // setCarImage12(carimg12)
                }
            })
        }

    }


    const loadData = async () => {
        await axios.post(checklandpn, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
            .then(res => {
                if (res.status === 200) {
                    // console.log("LandLand", res.data)
                    setNote(res.data.notes)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadDataNew = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    console.log("res", res.data)
                    setNewDataLand(res.data)
                    loadImage('land', res.data[0].img)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }


    const handleOk = () => {
        // console.log('ok', dataLand)
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };


    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    let color

    if (dataLand?.approvalStatus === "รอธุรการรับ") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "รออนุมัติราคาที่ดิน") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอเคาะราคา") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "เคาะราคาแล้ว") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอวิเคราะห์") {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === "ผ่านการวิเคราะห์") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "ขอเอกสารเพิ่ม") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "รอพิจารณา") {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === "ผ่านพิจารณา") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "รออนุมัติ") {
        color = "lime"
    }
    if (dataLand?.approvalStatus === "อนุมัติ") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "รอทำสัญญา") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "โทรติด") {
        color = "magenta"
    }
    if (dataLand?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
        color = "volcano"
    }
    if (dataLand?.approvalStatus === "ไม่อนุมัติ") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ไม่ผ่านพิจารณา") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ลูกค้าปฏิเสธ") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ปัดตก") {
        color = "red"
    }

    const renderNoteAD = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                key={index}
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item?.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้สร้างหมายเหตุ : </span>
                                        <b>{item?.noteBy}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item?.status}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เมื่อวันที่ : </span>
                                        <b>{dayjs(item?.noteDate).format("DD-MM-YYYY")}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }

    const renderLandData = ({ item, index }) => { //render Land Array
        return (
            <>
                <Col className='gutter-row' span={24}>
                    <>
                        <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                            <Col style={{ marginBottom: 0, textAlign: 'right' }}>
                                <Tag color={color}>{item.landLoanDetails.approvalStatus}</Tag>
                            </Col>
                            <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null} </u></b></Divider>
                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>กลุ่มสินค้า : </span>{<b>{item?.landLoanDetails.productType}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            ไร่
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งาน
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท / ตารางวา
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                </aside>
                            </Row>
                            <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                            <Form.Item name={`storyLand${index}`} label="">
                                <TextArea style={{ color: 'blue' }} rows={5} disabled />
                            </Form.Item>
                        </Card>
                    </>
                </Col>
            </>
        )
    }
    const renderCoordinates = ({ item, index }) => {
        return (
            <>
                <div>
                    <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
                    <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
                        {`${item.parcellat},${item.parcellon}`}
                    </a>
                </div>
            </>
        )
    }
    const renderApproval = ({ item, index }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{currencyFormatOne(item?.approval?.minPrice)} - {currencyFormatOne(item?.approval?.maxPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุการจดรวม : </span>{<b>{item?.approval?.mixNote}</b>}
                    </div>
                </aside>
                {/* <Divider /> */}
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Space>
                    <Image.PreviewGroup
                        preview={{
                            countRender: (index, total) => {
                                const imageCounts3 = carimage3?.length || 0; // นับจำนวนภาพจริง
                                const displayIndex = Math.min(index, imageCounts3);
                                const displayTotal = Math.min(imageCounts3, imageCounts3);
                                return `${displayIndex} / ${displayTotal}`;
                            }, // ใช้เพื่อแสดงจำนวนภาพ
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: {
                                        onActive,
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                    },
                                },
                            ) => (

                                <>
                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimage3?.map((e, index) => {
                                            return (
                                                <>
                                                    <Image
                                                        width={auto}
                                                        key={index}
                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index + 1}`}
                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                            <RightOutlined onClick={() => onActive?.(1)} />
                                            <DownloadOutlined onClick={onDownload} />
                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                            <SwapOutlined onClick={onFlipX} />
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                            <RotateRightOutlined onClick={onRotateRight} />
                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            <UndoOutlined onClick={onReset} />
                                        </Space>
                                    </Row>
                                </>
                            ),
                            onChange: (index) => {
                                setCurrent(index);
                            },
                        }}
                    >
                        <Row gutter={32} justify={'center'}>
                            {carimage3?.map((e, index) => {
                                return (
                                    <>
                                        <Image
                                            width={150}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </>
                                );
                            })}
                        </Row>
                    </Image.PreviewGroup>
                </Space>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Space>
                    <Image.PreviewGroup
                        key={index}
                        preview={{
                            countRender: (index, total) => {
                                const imageCounts10 = carimage10?.length || 0; // นับจำนวนภาพจริง
                                const displayIndex = Math.min(index, imageCounts10);
                                const displayTotal = Math.min(imageCounts10, imageCounts10);
                                return `${displayIndex} / ${displayTotal}`;
                            }, // ใช้เพื่อแสดงจำนวนภาพ
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: {
                                        onActive, // support after 5.21.0
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                    },
                                },
                            ) => (
                                <>
                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimage10?.map((e, index) => {
                                            return (
                                                <>
                                                    <Image
                                                        width={auto}
                                                        key={index}
                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index + 1}`}
                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                            <DownloadOutlined onClick={onDownload} />
                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                            <SwapOutlined onClick={onFlipX} />
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                            <RotateRightOutlined onClick={onRotateRight} />
                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            <UndoOutlined onClick={onReset} />
                                        </Space>
                                    </Row>
                                </>
                            ),
                            onChange: (indexs) => {
                                setCurrent(indexs);
                            },
                        }}
                    >
                        <Row justify={'center'}>
                            {carimage10?.map((e, index) => {
                                return (
                                    <>
                                        <Image
                                            width={150}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </>
                                );
                            })}
                        </Row>
                    </Image.PreviewGroup>
                </Space>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => {
        console.log("index", index)
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Space>
                    <Image.PreviewGroup
                        key={index}
                        preview={{
                            countRender: (index, total) => {
                                const imageCounts11 = carimage11?.length || 0; // นับจำนวนภาพจริง
                                const displayIndex = Math.min(index, imageCounts11);
                                const displayTotal = Math.min(imageCounts11, imageCounts11);
                                return `${displayIndex} / ${displayTotal}`;
                            }, // ใช้เพื่อแสดงจำนวนภาพ
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: {
                                        onActive, // support after 5.21.0
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                    },
                                },
                            ) => (
                                <>
                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimage11?.map((e, index) => {
                                            return (
                                                <>
                                                    <Image
                                                        width={auto}
                                                        key={index}
                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index + 1}`}
                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                            <DownloadOutlined onClick={onDownload} />
                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                            <SwapOutlined onClick={onFlipX} />
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                            <RotateRightOutlined onClick={onRotateRight} />
                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            <UndoOutlined onClick={onReset} />
                                        </Space>
                                    </Row>
                                </>
                            ),
                            onChange: (indexs) => {
                                setCurrent(indexs);
                            },
                        }}
                    >
                        <Row justify={'center'}>
                            {carimage11?.map((e, index) => {
                                return (
                                    <>
                                        <Image
                                            width={150}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </>
                                );
                            })}
                        </Row>
                    </Image.PreviewGroup>
                </Space >
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Space>
                    <Image.PreviewGroup
                        key={index}
                        preview={{
                            countRender: (index, total) => {
                                const imageCounts1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                const displayIndex = Math.min(index, imageCounts1);
                                const displayTotal = Math.min(imageCounts1, imageCounts1);
                                return `${displayIndex} / ${displayTotal}`;
                            }, // ใช้เพื่อแสดงจำนวนภาพ
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: {
                                        onActive, // support after 5.21.0
                                        onFlipY,
                                        onFlipX,
                                        onRotateLeft,
                                        onRotateRight,
                                        onZoomOut,
                                        onZoomIn,
                                        onReset,
                                    },
                                },
                            ) => (
                                <>
                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                        {carimage1?.map((e, index) => {
                                            return (
                                                <>
                                                    <Image
                                                        width={auto}
                                                        key={index}
                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index + 1}`}
                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Row style={{ zIndex: 2 }}>
                                        <Space size={12} className="toolbar-wrapper">
                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                            <DownloadOutlined onClick={onDownload} />
                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                            <SwapOutlined onClick={onFlipX} />
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                            <RotateRightOutlined onClick={onRotateRight} />
                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            <UndoOutlined onClick={onReset} />
                                        </Space>
                                    </Row>
                                </>
                            ),
                            onChange: (indexs) => {
                                setCurrent(indexs);
                            },
                        }}
                    >
                        <Row justify={'center'}>
                            {carimage1?.map((e, index) => {
                                return (
                                    <>
                                        <Image
                                            width={150}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </>
                                );
                            })}
                        </Row>
                    </Image.PreviewGroup>
                </Space>
                <Divider />
            </>
        )
    }
    return (
        <>
            <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1180px'}
                footer={[null]} >
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <style>
                            {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                `}
                        </style>
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            ชื่อการตลาดที่เสนอ : <Tag color={color}>{dataLand?.proposalBy}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={color}>{dataLand?.approvalStatus}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                <>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            newDataLand?.map((item, index) => {
                                                return renderLandData({ item, index })
                                            })
                                        }

                                    </Row>

                                </>
                                <Divider />
                                {
                                    dataLand?.parcellat ?
                                        <>
                                            <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >

                                                        {
                                                            newDataLand?.map((item, index) => {
                                                                return renderCoordinates({ item, index })
                                                            })
                                                        }
                                                        {/* <a href={`https://www.google.com/maps?q=${dataLand.parcellat},${dataLand.parcellon}`} target="_blank">
                                                            {`${dataLand.parcellat},${dataLand.parcellon}`}
                                                        </a> */}
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                <>
                                    <Row justify={'left'}><b><u>รายละเอียดยอดที่อนุมัติ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            newDataLand?.map((item, index) => {
                                                return renderApproval({ item, index })
                                            })
                                        }
                                    </Row>
                                </>
                                {
                                    note?.length > 0 ?
                                        <>
                                            <Divider />
                                            <Row justify={'left'}>
                                                <b><u>หมายเหตุ</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    note?.map((item, index) => {
                                                        return renderNoteAD({ item, index })
                                                    })
                                                }

                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }

                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                    </Space>
                                </Col>
                            </Card>
                        </div>
                    </Form>
                </Row>
                {
                    openShowImage ?
                        <ModalShowImages
                            open={openShowImage}
                            close={setOpenShowImage}
                            carimage1={carshowMadol}
                            nuarantorNew={nuarantorNew}
                            nuarantorNum={nuarantorNum}
                            setGuarantorNum={setGuarantorNum}
                        />

                        : null
                }
            </Modal >
        </>
    )
}
