import React, { useState, useEffect } from "react";
import { HomeFilled, SearchOutlined, ReadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Spin, Space, Form, Card, DatePicker, Select, Tag } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import "../css/Media.css";
// import { columns } from './columns/columns';
import ExcelJS from "exceljs";
import { findcase } from "../file_mid/all_api";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";
import ModalNote from "./Modal/Note";

const { Option } = Select;

export default function Main() {
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date(); // สร้างอ็อบเจ็กต์ Date สำหรับวันที่ปัจจุบัน
  const year = currentDate.getFullYear(); // ดึงปีปัจจุบัน
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // ดึงเดือนและจัดรูปแบบให้มี 2 หลัก
  const day = String(currentDate.getDate()).padStart(2, "0"); // ดึงวันและจัดรูปแบบให้มี 2 หลัก
  const formattedDate = `${year}-${month}-${day}`;
  const currentDateTime = dayjs();
  const [dataPost, setDataPost] = useState({
    sec: 0,
    days1: formattedDate,
    days2: formattedDate,
  });
  const [isModalEditer, setIsModalEditer] = useState(false);
  const [namead, setNamead] = useState([]);
  const [form] = Form.useForm();
  const [select_op, setSelect_op] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedValue2, setSelectedValue2] = useState();
  const [arrayTable, setArrayTable] = useState();
  const [data, setData] = useState([]);
  const [valueSelected, setValueSelected] = useState("ทั้งหมด");
  const [employeeSelected, setEmployeeCodeSelected] = useState("ทั้งหมด");
  const [branchSelected, setBranchSelected] = useState("ทั้งหมด");
  const [companySelected, setCompanySelected] = useState("ทั้งหมด");
  const [departmentSelected, setDepartmentSelected] = useState("ทั้งหมด");
  const [defu, setDefu] = useState("ทั้งหมด");
  const [cData, setCData] = useState([]);
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  // const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [isModalInfoADPGDC, setIsModalInfoADPGDC] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false);
  const [dataSend, setDataSend] = useState();
  const [dataCarId, setDataCarrId] = useState();
  const [nameMk, setNameMK] = useState([]);
  // const [nicknameAd, setNicknameAd] = useState([]);
  // const [nicknameMk, setNicknameMk] = useState([]);
  const [company, setCompany] = useState([])
  const [branch, setBranch] = useState([])
  const [nameMix, setNameMix] = useState([])

  const [dataCompany, setDataCompany] = useState([]);
  // const [dataBranch, setDataBranch] = useState([]);

  // const [dataCarId, setDataCarrId] = useState();

  // console.log('nameMk', nameMk)
  // console.log('namead', namead)
  // console.log("arrayTable", arrayTable);
  // console.log('data', data)
  // console.log('select_op', select_op)
  // console.log('nicknameAd', nicknameAd)
  // console.log('nicknameMk', nicknameMk)
  // console.log('nameMix', nameMix)
  // console.log('dataCompany', dataCompany)
  // console.log('dataBranch', dataBranch)
  // console.log('company', company)
  // console.log('branch', branch)

  useEffect(() => {
    loadData();
    //  loadCountData()
  }, []);

  const loadData = async () => {
    setLoading(true);
    setDefu({ ...namead, label: "ทั้งหมด" });
    const tk = JSON.parse(token);
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("5"),
    };
    var mainData = {
      startdate: dataPost.days1,
      enddate: dataPost.days2,
      status: "2023-01-12",
      ad: "2023-01-12",
    };
    await axios
      .post(findcase, mainData, { headers: headers })
      .then((res) => {
        // console.log("รายงานPG", res.data);
        setArrayTable(res.data);
        setDataCompany(res.data)
        // setDataBranch(res.data)
        // setAxiosData(res.data)
        setData(res.data);
        const resultArray = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
        const resultArray2 = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
        // const resultNicknameMk = [];
        // const resultNicknameAd = [];
        const resultCompany = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
        const resultBranch = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
        const resultArray1 = [
          {
            label1: "ทั้งหมด",
            label: `${"ทั้งหมด"} (${res.data.length || 0})`,
            value: "ทั้งหมด",
          },
        ];
        setSelectedValue({
          label1: "ทั้งหมด",
          label: `${"ทั้งหมด"} (${res.data.length || 0})`,
          value: "ทั้งหมด",
        });
        // นับจำนวนของแต่ละสถานะ
        const countByApprovalStatus = {};
        // if (res.data !== null) {
        //   res.data.map((data, index) => {
        //     console.log("not Show Report KSM", data)
        //     if (data.team === "C") {
        //       var num = index
        //     }
        //     delete res.data[num]
        //     // setArrayTable(res.data)
        //     // setAxiosData(res.data)
        //   })
        // }

        res.data.forEach((item) => {
          if (!countByApprovalStatus[item.approvalStatus]) {
            countByApprovalStatus[item.approvalStatus] = 1;
          } else {
            countByApprovalStatus[item.approvalStatus]++;
          }
        });
        // console.log("countByApprovalStatus", countByApprovalStatus);

        res.data.forEach((item) => {
          // console.log("item", item);
          // ถ้า item.reviewedBy หรือ item.approvalStatus ไม่ได้ถูกเพิ่มลงใน resultArray แล้ว
          if (
            !resultArray.some(
              (resultItem) => resultItem.value === item.reviewedBy
            )
          ) {
            resultArray.push({
              label: item.nickname
                ? `${item.reviewedBy} (${item.nickname})`
                : item.reviewedBy,
              value: item.reviewedBy,
            });
          }

          if (
            !resultArray2.some(
              (resultItem) => resultItem.value === item.proposalBy
            )
          ) {
            resultArray2.push({
              label: item.nameMk
                ? `${item.proposalBy} (${item.nameMk})`
                : item.proposalBy,
              value: item.proposalBy,
            });
          }

          // if (
          //   !resultNicknameAd.some(
          //     (resultItem) => resultItem.label === item.nickname //ชื่อเล่นธุรการ
          //   )
          // ) {
          //   resultNicknameAd.push({
          //     label: item.nickname,
          //     value: item.nickname,
          //   });
          // }
          // if (
          //   !resultNicknameMk.some(
          //     (resultItem) => resultItem.label === item.nameMk //ชื่อเล่นการตลาด
          //   )
          // ) {
          //   resultNicknameMk.push({
          //     label: item.nameMk,
          //     value: item.nameMk,
          //   });
          // }
          if (
            !resultCompany.some(
              (resultItem) => resultItem.label === item.company //ชื่อบริษัท
            )
          ) {
            resultCompany.push({
              label: item.company,
              value: item.company,
            });
          }
          if (
            !resultBranch.some(
              (resultItem) => resultItem.label === item.branch //ชื่อสาขา
            )
          ) {
            resultBranch.push({
              label: item.branch,
              value: item.branch,
            });
          }

          if (
            !resultArray1.some(
              (resultItem) => resultItem.label1 === item.approvalStatus
            )
          ) {
            resultArray1.push({
              label1: item.approvalStatus,
              label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus]
                })`,
              value: item.approvalStatus,
            });
            // console.log("aa", resultArray1);
          }
        });

        const name = resultArray.concat(resultArray2)
        const employeeAll = name.filter((item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
        );


        // setNicknameMk(resultNicknameMk);
        // setNicknameAd(resultNicknameAd);
        setBranch(resultBranch)
        setCompany(resultCompany)
        setNameMK(resultArray2);
        setNamead(resultArray);
        setNameMix(employeeAll);
        setSelect_op(resultArray1);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };



  // const search = (data) => {
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  // const countMap = {};
  // data.forEach((item) => {
  //   const reviewedBy = item.reviewedBy;
  //   // ทำการนับจำนวนและบวกเพิ่มเรื่อย ๆ
  //   countMap[reviewedBy] = (countMap[reviewedBy] || 0) + 1;
  // });
  // console.log("countMap", countMap);

  // const totalReviews = Object.values(countMap).reduce((acc, count) => acc + count, 0);
  // console.log("Total Reviews:", totalReviews);

  const nameAd = namead; //รหัสธุรการ
  // console.log("nameAd", nameAd);
  const backc = {
    "น้ำ(อารยา)": "#e6f4ff",
    "น้ำ 3": "#fffbe6",
    เหมย: "#fffbe6",
    หยก: "#fff7e6",
    ออรี่: "#e6fffb",
    ชมพู่: "#e6fffb",
    เอิร์น: "#f0f5ff",
  };
  const textc = {
    MIT00034: "#1677ff",
    MIT00026: "#e39f23",
    MIT00092: "#e39f23",
    MIT00075: "#d86d38",
    MIT00109: "#3fbccf",
    MIT00115: "#384ecb",
    MIT00117: "#afc222",
  };

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const handleChangeEmployeeCode = (value) => { //รหัสพนักงาน
    // รหัสพนักงาน
    //valueselect1 คือ รหัสพนักงาน
    //valueselect คือ สถานะ

    setEmployeeCodeSelected(value);
    let filteredData;

    if (value === "ทั้งหมด" && valueSelected === "ทั้งหมด") {
      filteredData = data;
    } else if (value === "ทั้งหมด") {
      filteredData = data;
    } else if (value === value) {
      filteredData = data.filter(
        (item) => item.reviewedBy === value || item.proposalBy === value
      );
    } else if (valueSelected === "ทั้งหมด") {
      filteredData = data.filter(
        (item) => item.reviewedBy === value || item.proposalBy === value
      );
    } else {
      filteredData = data.filter(
        (item) =>
          (item.reviewedBy === value || item.proposalBy === value) &&
          item.approvalStatus === valueSelected
      );
    }

    setArrayTable(filteredData);
    // setArrayTable(filteredData)
    const countByApprovalStatus = {};
    // const resultArray = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
    // const resultArray2 = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
    const resultArray1 = [
      {
        label1: "ทั้งหมด",
        label: `${"ทั้งหมด"} (${filteredData.length || 0})`,
        value: "ทั้งหมด",
      },
    ];
    setSelectedValue({
      label1: "ทั้งหมด",
      label: `${"ทั้งหมด"} (${filteredData.length || 0})`,
      value: "ทั้งหมด",
    });

    filteredData.forEach((item) => {
      if (!countByApprovalStatus[item.approvalStatus]) {
        countByApprovalStatus[item.approvalStatus] = 1;
      } else {
        countByApprovalStatus[item.approvalStatus]++;
      }
    });

    // filteredData.forEach((item) => {
    //   if (
    //     !resultArray.some((resultItem) => resultItem.label === item.reviewedBy)
    //   ) {
    //     resultArray.push({ label: item.reviewedBy, value: item.reviewedBy });
    //     // console.log("!!", resultArray);
    //   }

    //   if (
    //     !resultArray2.some((resultItem) => resultItem.label === item.proposalBy)
    //   ) {
    //     resultArray2.push({ label: item.proposalBy, value: item.proposalBy });
    //     // console.log("!!", resultArray2);
    //   }
    // });

    filteredData.forEach((item) => {
      if (
        !resultArray1.some(
          (resultItem) => resultItem.label1 === item.approvalStatus
        )
      ) {
        resultArray1.push({
          label1: item.approvalStatus,
          label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus] || 0
            })`,
          value: item.approvalStatus,
        });
      }
    });

    setSelect_op(resultArray1);
  };

  const handleSelectDepartment = (value) => {//เลือกแผนก

    setDepartmentSelected(value); //แผนก
    setEmployeeCodeSelected("ทั้งหมด"); //รหัสพนักงาน

    let filteredDepartment;

    if (value === "ทั้งหมด") {
      filteredDepartment = dataCompany;
    } else if (value === "AD") {
      filteredDepartment = dataCompany?.filter((item) => (item.reviewedBy === namead)
      );
    } else if (value === "MK") {
      filteredDepartment = dataCompany?.filter((item) => (item.proposalBy === nameMk)
      );
    }
    // console.log("filteredDepartment", filteredDepartment);
    // setArrayTable(filteredDepartment);
  };

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleSelectCompany = (value) => {  //เลือกบริษัท
    setCompanySelected(value); //บริษัท
    setDepartmentSelected("ทั้งหมด"); //แผนก
    setBranchSelected("ทั้งหมด"); //สาขา
    setEmployeeCodeSelected('ทั้งหมด')
    // setSelectedValue("ทั้งหมด")

    let filteredCompany;

    if (value === "ทั้งหมด") {
      filteredCompany = data;
    } else {
      filteredCompany = data.filter(item => item.company === value);
    }
    setDataCompany(filteredCompany);
    setArrayTable(filteredCompany);

    const resultArray = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
    const resultArray2 = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];

    filteredCompany.forEach((item) => {

      if (
        !resultArray.some((resultItem) => resultItem.value === item.reviewedBy)
      ) {
        resultArray.push({
          label: item.nickname
            ? `${item.reviewedBy} (${item.nickname})`
            : item.reviewedBy,
          value: item.reviewedBy,
        });
      }

      if (
        !resultArray2.some((resultItem) => resultItem.value === item.proposalBy)
      ) {
        resultArray2.push({
          label: item.nameMk
            ? `${item.proposalBy} (${item.nameMk})`
            : item.proposalBy,
          value: item.proposalBy,
        });
      }
    });

    setNameMK(resultArray2);
    setNamead(resultArray);

    const countByApprovalStatus = {};

    const resultArray1 = [
      {
        label1: "ทั้งหมด",
        label: `${"ทั้งหมด"} (${filteredCompany.length || 0})`,
        value: "ทั้งหมด",
      },
    ];
    setSelectedValue({
      label1: "ทั้งหมด",
      label: `${"ทั้งหมด"} (${filteredCompany.length || 0})`,
      value: "ทั้งหมด",
    });

    filteredCompany.forEach((item) => {
      if (!countByApprovalStatus[item.approvalStatus]) {
        countByApprovalStatus[item.approvalStatus] = 1;
      } else {
        countByApprovalStatus[item.approvalStatus]++;
      }
    });

    filteredCompany.forEach((item) => {
      if (
        !resultArray1.some(
          (resultItem) => resultItem.label1 === item.approvalStatus
        )
      ) {
        resultArray1.push({
          label1: item.approvalStatus,
          label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus] || 0
            })`,
          value: item.approvalStatus,
        });
      }
    });
    setSelect_op(resultArray1);

  };

  const filterDataByCompany = (companySelect) => {
    // console.log('companySelect',companySelect)
    if (companySelect === "ONE") {
      return [{ value: "ทั้งหมด", label: "ทั้งหมด" }, ...branch.filter(item => ["MIT", "UD", "S4", "LEX"].includes(item.value))];
    } else if (companySelect === "KSM") {
      return [{ value: "ทั้งหมด", label: "ทั้งหมด" }, ...branch.filter(item => ["K1001", "K1002", "K1003", "K1004", "K1005", "K1006", "K1007", "K1008", "K1009"].includes(item.value))];
    } else if (companySelect === "ทั้งหมด") {
      return branch;
    } else {
      return [];
    }
  };
  // กรองข้อมูลตาม companySelected
  const branchFilter = filterDataByCompany(companySelected);
  // console.log('branchFilter',branchFilter)


  const handleSelectBranch = (value) => {  //เลือกสาขา

    setBranchSelected(value);
    form.setFieldsValue({
      username: "",
      status: "",
    });
    setEmployeeCodeSelected("ทั้งหมด");
    setValueSelected("ทั้งหมด");

    let filteredBranch;

    if (value === "ทั้งหมด") {
      filteredBranch = dataCompany;
    } else {
      filteredBranch = dataCompany?.filter((item) => item.branch === value);
    }

    setArrayTable(filteredBranch);

    const resultArray = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];
    const resultArray2 = [{ label: "ทั้งหมด", value: "ทั้งหมด" }];

    filteredBranch.forEach((item) => {

      if (
        !resultArray.some((resultItem) => resultItem.value === item.reviewedBy)
      ) {
        resultArray.push({
          label: item.nickname
            ? `${item.reviewedBy} (${item.nickname})`
            : item.reviewedBy,
          value: item.reviewedBy,
        });
      }

      if (
        !resultArray2.some((resultItem) => resultItem.value === item.proposalBy)
      ) {
        resultArray2.push({
          label: item.nameMk
            ? `${item.proposalBy} (${item.nameMk})`
            : item.proposalBy,
          value: item.proposalBy,
        });
      }
    });

    setNameMK(resultArray2);
    setNamead(resultArray);

    const countByApprovalStatus = {};
    const resultArray1 = [
      {
        label1: "ทั้งหมด",
        label: `${"ทั้งหมด"} (${filteredBranch.length || 0})`,
        value: "ทั้งหมด",
      },
    ];
    setSelectedValue({
      label1: "ทั้งหมด",
      label: `${"ทั้งหมด"} (${filteredBranch.length || 0})`,
      value: "ทั้งหมด",
    });

    filteredBranch.forEach((item) => {
      if (!countByApprovalStatus[item.approvalStatus]) {
        countByApprovalStatus[item.approvalStatus] = 1;
      } else {
        countByApprovalStatus[item.approvalStatus]++;
      }
    });

    filteredBranch.forEach((item) => {
      if (
        !resultArray1.some(
          (resultItem) => resultItem.label1 === item.approvalStatus
        )
      ) {
        resultArray1.push({
          label1: item.approvalStatus,
          label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus] || 0
            })`,
          value: item.approvalStatus,
        });
      }
    });
    setSelect_op(resultArray1);

  };


  const handleChange = (value) => {  //สถานะ
    //สถานะ

    setSelectedValue(value);

    let filteredData;

    if (value === "ทั้งหมด" && employeeSelected === "ทั้งหมด") {
      filteredData = dataCompany;
      console.log('1');

    } else if (employeeSelected === "ทั้งหมด") {
      filteredData = dataCompany.filter((item) => item.approvalStatus === value);
      console.log('2');

    } else if (employeeSelected === "ทั้งหมด" && value === valueSelected) {
      filteredData = dataCompany;
      console.log('3');

    } else if (value === "ทั้งหมด") {
      filteredData = dataCompany.filter((item) =>
        (item.reviewedBy === employeeSelected && item.approvalStatus) ||
        (item.proposalBy === employeeSelected && item.approvalStatus));
      console.log("5");

    } else {
      filteredData = dataCompany.filter((item) =>
        (item.reviewedBy === employeeSelected && item.approvalStatus === value) ||
        (item.proposalBy === employeeSelected && item.approvalStatus === value)
      );
      console.log('4');

    }
    setArrayTable(filteredData);
  };

  const filterDataByNameMix = (companySelected) => {
    if (companySelected && departmentSelected === "ทั้งหมด") {
      if (companySelected === "KSM") {
        return nameMix.filter(item => item.value.startsWith("K"));
      } else if (companySelected === "ONE") {
        return nameMix.filter(item => !item.value.startsWith("K"));
      } else if (companySelected === "ทั้งหมด") {
        return nameMix;
      } else {
        return [];
      }
    } else if (companySelected && departmentSelected === "AD") {
      if (companySelected === "KSM") {
        return namead.filter(item => item.value.startsWith("K"));
      } else if (companySelected === "ONE") {
        return namead.filter(item => !item.value.startsWith("K"));
      } else if (companySelected === "ทั้งหมด") {
        return namead;
      } else {
        return [];
      }
    } else if (companySelected && departmentSelected === "MK") {
      if (companySelected === "KSM") {
        return nameMk.filter(item => item.value.startsWith("K"));
      } else if (companySelected === "ONE") {
        return nameMk.filter(item => !item.value.startsWith("K"));
      } else if (companySelected === "ทั้งหมด") {
        return nameMk;
      } else {
        return [];
      }
    }

  };

  const nameFilter = filterDataByNameMix(companySelected);
  // console.log('nameFilter',nameFilter);D

  //   const filterDataByNameMix = (companySelected) => {
  //     const departmentMap = {
  //         "ทั้งหมด": [...nameMix, ...namead, ...nameMk], // รวมข้อมูลทุกแผนก
  //         "AD": namead,
  //         "MK": nameMk
  //     };

  //     const filterByCompany = (data, companySelected) => {
  //         if (companySelected === "KSM") {
  //             return data.filter(item => item.value.startsWith("K"));
  //         } else if (companySelected === "ONE") {
  //             return data.filter(item => !item.value.startsWith("K"));
  //         } else if (companySelected === "ทั้งหมด") {
  //             return data; // แสดงข้อมูลทั้งหมด
  //         }
  //         return [];
  //     };

  //     const dataToFilter = departmentMap[departmentSelected] || nameMix; // ใช้ nameMix เป็นค่าเริ่มต้นถ้าไม่มีแผนกที่ตรง
  //     return companySelected ? filterByCompany(dataToFilter, companySelected) : [];
  // };

  // const nameFilter = filterDataByNameMix(companySelected);

  const handle_button_Change = async () => {
    await loadData();
    setValueSelected("ทั้งหมด");
    setEmployeeCodeSelected("ทั้งหมด");
    setBranchSelected("ทั้งหมด");
    setCompanySelected("ทั้งหมด");
    setDepartmentSelected("ทั้งหมด");
  };

  const test = async (
    result34,
    result26,
    result75,
    result92,
    result109,
    result115,
    result117,
    result122,
    resultk01,
    resultk02,
    resultk03,
    resultk065
  ) => {
    arrayTable?.map((e) => {
      // console.log("e", e.reviewedBy);
      var data = {};
      data.carInput = dayjs(e.carInput).format("YYYY-MM-DD HH:mm:ss");
      data.team = e.team;
      data.approvalStatus = e.approvalStatus;
      data.nameMk = e.nameMk;
      data.nickname = e.nickname;
      data.firstName = `${e.snam} ${e.firstName} ${e.lastName}`;
      data.reviewedBy = e.reviewedBy;
      data.proposalBy = e.proposalBy;
      data.dataType = e.dataType === "car" ? "รถ" : "ที่ดิน";
      data.carPlateNumber = e.carPlateNumber;
      if (e.reviewedBy === "MIT00034") {
        //1
        result34.push(data);
      } else if (e.reviewedBy === "MIT00026") {
        //2
        result26.push(data);
      } else if (e.reviewedBy === "MIT00075") {
        //3
        result75.push(data);
      } else if (e.reviewedBy === "MIT00092") {
        //4
        result92.push(data);
      } else if (e.reviewedBy === "MIT00109") {
        //5
        result109.push(data);
      } else if (e.reviewedBy === "MIT00115") {
        //6
        result115.push(data);
      } else if (e.reviewedBy === "MIT00117") {
        //7
        result117.push(data);
      } else if (e.reviewedBy === "MIT00122") {
        //8
        result122.push(data);
      } else if (e.reviewedBy === "K0000001") {
        //9
        resultk01.push(data);
      } else if (e.reviewedBy === "K0000002") {
        //10
        resultk02.push(data);
      } else if (e.reviewedBy === "K0000003") {
        //11
        resultk03.push(data);
      } else if (e.reviewedBy === "K0000065") {
        //12
        resultk065.push(data);
      } else {
        console.log("err");
      }
    });
    return (
      result34,
      result26,
      result75,
      result92,
      result109,
      result115,
      result117,
      result122,
      resultk01,
      resultk02,
      resultk03,
      resultk065
    );
  };

  // console.log("valueselect1",valueselect1)

  const exportToExcel = async () => {
    var result34 = [];
    var result26 = [];
    var result75 = [];
    var result92 = [];
    var result109 = [];
    var result115 = [];
    var result117 = [];
    var result122 = [];
    var resultk01 = [];
    var resultk02 = [];
    var resultk03 = [];
    var resultk065 = [];
    await test(
      result34,
      result26,
      result75,
      result92,
      result109,
      result115,
      result117,
      result122,
      resultk01,
      resultk02,
      resultk03,
      resultk065
    );

    const workbook = new ExcelJS.Workbook();
    //  const worksheet = workbook.addWorksheet('Data');

    // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
    const addSheet = (
      sheetName,
      dataSource,
      headers_colums_name,
      headers_thai
    ) => {
      const worksheet = workbook.addWorksheet(sheetName);
      // กำหนดความกว้างของแต่ละคอลัมน์
      worksheet.columns = [
        { key: "วันที่เสนอ", width: 20 },
        { key: "รหัสพนักงาน(AD)", width: 13 },
        { key: "ชื่อเล่น", width: 10 },
        { key: "ทีม", width: 8 },
        { key: "รหัสพนักงาน(MK)", width: 13 },
        { key: "การตลาด", width: 10 },
        { key: "ชื่อ-สกุล(ผู้กู้)", width: 50 },
        { key: "ประเภทสินค้า", width: 12 },
        { key: "เลขทะเบียน / เลขโฉนดที่ดิน", width: 30 },
        { key: "สถานะ", width: 20 },
      ];
      worksheet.addRow(headers_thai);
      dataSource.forEach((row) => {
        const rowData = headers_colums_name.map((column) => row[column]);
        worksheet.addRow(rowData);
      });
    };

    // หัวข้อใน Excel
    // const headers_thai = ['วันที่เสนอ', 'ชื่อการตลาด', 'ชื่อธุรการที่รับเคส', 'ชื่อ-สกุล(ผู้กู้)', 'ประเภทสินค้า', 'เลขทะเบียน / เลขโฉนดที่ดิน', 'สถานะ'];
    // const headers_colums_name = ['carInput', 'nameMk', 'nickname', 'firstName', 'dataType', 'carPlateNumber', 'approvalStatus'];

    const headers_thai = [
      "วันที่เสนอ",
      "รหัสพนักงาน(AD)",
      "ชื่อเล่น(AD)",
      "ทีม",
      "รหัสพนักงาน(MK)",
      "ชื่อเล่น(MK)",
      "ชื่อ-สกุล(ผู้กู้)",
      "ประเภทสินค้า",
      "เลขทะเบียน / เลขโฉนดที่ดิน",
      "สถานะ",
    ];
    const headers_colums_name = [
      "carInput",
      "reviewedBy",
      "nickname",
      "team",
      "proposalBy",
      "nameMk",
      "firstName",
      "dataType",
      "carPlateNumber",
      "approvalStatus",
    ];
    // // นำหัวข้อลงในแถวแรกของ Excel

    // // เพิ่มข้อมูลลงในแต่ละชีท
    if (result34?.length > 0) {
      addSheet("MIT00034 หยก", result34, headers_colums_name, headers_thai);
    }
    if (result26?.length > 0) {
      addSheet("MIT00026 น้ำ(อารยา)", result26, headers_colums_name, headers_thai);
    }
    if (result75?.length > 0) {
      addSheet("MIT00075 เหมย", result75, headers_colums_name, headers_thai);
    }
    if (result92?.length > 0) {
      addSheet("MIT00092 ออรี่", result92, headers_colums_name, headers_thai);
    }
    if (result109?.length > 0) {
      addSheet("MIT00109 น้ำ3", result109, headers_colums_name, headers_thai);
    }
    if (result115?.length > 0) {
      addSheet("MIT00115 เอิน", result115, headers_colums_name, headers_thai);
    }
    if (result117?.length > 0) {
      addSheet("MIT00117 ชมพู่", result117, headers_colums_name, headers_thai);
    }
    if (result122?.length > 0) {
      addSheet("MIT00122 หม่อน", result122, headers_colums_name, headers_thai);
    }
    if (resultk01?.length > 0) {
      addSheet("K0000001 สุ", resultk01, headers_colums_name, headers_thai);
    }
    if (resultk02?.length > 0) {
      addSheet("K0000002 แป๋ม", resultk02, headers_colums_name, headers_thai);
    }
    if (resultk03?.length > 0) {
      addSheet("K0000003 แป๋ม", resultk03, headers_colums_name, headers_thai);
    }
    if (resultk065?.length > 0) {
      addSheet("K0000065 เฟิร์น", resultk065, headers_colums_name, headers_thai);
    }

    // บันทึกไฟล์ Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const geekblueLoanTypes = [
    "ผ่านการวิเคราะห์",
    "ถือเล่มมา",
    "ถือโฉนด",
    "ถือโฉนด(ที่ดิน)",
    "ซื้อ-ขาย",
    "ปรับโครงสร้าง",
    "ที่ดิน+บ้าน",
    "ฝากโฉนด",
    "Pre-Aaprove-ฝากโฉนด",
    "Pre-Aaprove-Ploan",
  ];
  const magentaLoanTypes = [
    "Pre-Aaprove",
    "Pre-Aaprove-ถือเล่มมา",
    "Pre-Aaprove-ซื้อ-ขาย",
    "Pre-Aaprove-ย้ายไฟแนนซ์",
    "Pre-Aaprove-รีโอน",
    "เปลี่ยนสัญญา",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "รี+เปลี่ยนสัญญา",
    "ปรับ+เปลี่ยนสัญญา",
    "Pre-Aaprove-รี-Ploan",
    "รี-3",
    "Pre-Aaprove-ปรับโครงสร้าง",
    "Pre-Aaprove-รี",
    "Pre-Aaprove-รี",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "Pre-Aaprove-รี+เปลี่ยนสัญญา",
    "Pre-Aaprove-Ploan",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "โทรติด",
  ];
  const greenLoanTypes = ["อนุมัติ", "ย้ายไฟแนนซ์", "ย้ายไฟแนนซ์"];
  const redLoanTypes = [
    "ไม่อนุมัติ",
    "ลูกค้าปฏิเสธ",
    "ไม่ผ่านการวิเคราะห์",
    "Reject",
  ];
  const goldLoanTypes = ["รอธุรการรับ", "ลูกค้าคอนเฟิร์ม", "รอทำสัญญา"];
  const limeapprovalStatus = ["รอตรวจสอบเอกสาร", "รออนุมัติ"];

  const columns = [
    {
      title: (
        <Row justify={"center"}>
          <Col>ลำดับที่</Col>
        </Row>
      ),
      dataIndex: "index",
      key: "index",
      align: "center",
      fixed: true,
      width: "3%",
      render: (text, object, index) => <b>{index + 1}</b>,
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>วันที่</Col>
        </Row>
      ),
      dataIndex: "carInput",
      key: "carInput",
      align: "center",
      fixed: true,
      width: "8%",

      render: (text, record) => (
        <Space size="middle">
          {record.carInput !== "0001-01-01T00:00:00Z" ? (
            <Space>
              <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
            </Space>
          ) : null}
        </Space>
      ),
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>รหัสพนักงาน(AD)</Col>
        </Row>
      ),
      dataIndex: "reviewedBy",
      key: "reviewedBy",
      align: "center",
      // fixed: true,
      width: "8%",

      render: (text, record) => (
        // console.log("record: " + record.reviewes_by),
        <b>{record.reviewedBy}</b>
      ),
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>ชื่อเล่น(AD)</Col>
        </Row>
      ),
      dataIndex: "nickname",
      key: "nickname",
      align: "center",
      // fixed: true,
      width: "7%",

      render: (text, record) => <> {record.nickname} </>,
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>ทีม</Col>
        </Row>
      ),
      dataIndex: "team",
      key: "team",
      align: "center",
      width: "5%",
    },
    // {
    //     title: <Row justify={"center"}><Col>ผู้เสนอ</Col></Row>,
    //     dataIndex: "proposalBy",
    //     key: 'proposalBy',
    //     align: 'center',
    //     width: "8%",

    // },
    {
      title: (
        <Row justify={"center"}>
          <Col>รหัสพนักงาน(MK)</Col>
        </Row>
      ),
      dataIndex: "proposalBy",
      key: "proposalBy",
      align: "center",
      width: "8%",
      render: (text, record) => <b> {record.proposalBy} </b>,
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>ชื่อเล่น(MK)</Col>
        </Row>
      ),
      dataIndex: "nameMk",
      key: "nameMk",
      align: "center",
      width: "8%",
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>ชื่อ-สกุล(ผู้กู้)</Col>
        </Row>
      ),
      dataIndex: "firstName",
      key: "firstName",
      align: "left",
      // fixed: true,
      width: "15%",

      render: (text, record) => (
        <b>
          {record.snam} {record.firstName} {record.lastName}
        </b>
      ),
    },
    {
      title: (
        <Row justify={"center"}>
          <Col>เลขทะเบียน / เลขโฉนดที่ดิน</Col>
        </Row>
      ),
      dataIndex: "proposalBy",
      key: "proposalBy",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <>
          {record.dataType === "car" ? (
            <>
              ทะเบียน {record.carPlateNumber} ( {record.carProvince} )
            </>
          ) : (
            <>
              โฉนดที่ดิน {record.carPlateNumber} ( {record.carProvince} )
            </>
          )}
        </>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "approvalStatus",
      align: "center",
      width: "10%",
      render: (text, record) => {
        let color;
        let color2;

        if (record?.approvalStatus === "รออนุมัติราคารถ") {
          color = "orange";
        }
        if (record?.approvalStatus === "รอวิเคราะห์") {
          color = "cyan";
        }

        if (record?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
          color = "volcano";
        }
        if (limeapprovalStatus.includes(record?.approvalStatus)) {
          color = "lime";
        }
        if (goldLoanTypes.includes(record?.approvalStatus)) {
          color = "gold";
        }
        if (redLoanTypes.includes(record?.approvalStatus)) {
          color = "red";
        }
        if (greenLoanTypes.includes(record?.approvalStatus)) {
          color = "green";
        }
        if (greenLoanTypes.includes(record?.productLoanType)) {
          color2 = "green";
        }
        if (magentaLoanTypes.includes(record?.approvalStatus)) {
          color = "magenta";
        }
        if (magentaLoanTypes.includes(record?.productLoanType)) {
          color2 = "magenta";
        }
        if (geekblueLoanTypes.includes(record?.approvalStatus)) {
          color = "geekblue";
        }
        if (geekblueLoanTypes.includes(record?.productLoanType)) {
          color2 = "geekblue";
        }

        return (
          <>
            <Tag color={color}>{record.approvalStatus.toUpperCase()}</Tag>
            <Space>
              {record.productLoanType === "ย้ายไฟแนนซ์" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ถือเล่มมา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ถือโฉนด" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ย้ายไฟแนนซ์" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ปรับโครงสร้าง" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ซื้อ-ขาย" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-ถือเล่มมา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-ซื้อ-ขาย" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-รีโอน" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "รี+เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "ปรับ+เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-รี-Ploan" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "รี-3" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-รี" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-Ploan" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ? (
                <Tag color={color2}>{record.productLoanType}</Tag>
              ) : null}
            </Space>
            <Space>
              {record.car === "car" ? (
                <Tag color={"purple"}>{record.car.toUpperCase()}</Tag>
              ) : record.car === "land" ? (
                <Tag color={"green"}>{record.car.toUpperCase()}</Tag>
              ) : null}
            </Space>
          </>
        );
      },
    },
    {
      title: "รายละเอียด",
      // dataIndex: "approvalStatus",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setDataSend(record);
            setIsModalInfoADPGDC(true);
          }}
        >
          <SearchOutlined />
        </Button>
      ),
    },
    {
      title: "หมายเหตุ",
      // dataIndex: "approvalStatus",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setDataCarrId({
              carLandId: record.carId,
              typeLoan: record.dataType,
            });
            setIsModalNote(true);
          }}
        >
          <ReadOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Form form={form}>
          <Row gutter={32} style={{ textAlign: "center" }}>
            <Col>
              <Spin spinning={loading} size="large" tip=" Loading... ">
                <div className="text-center">
                  <h2>รายงาน</h2>
                </div>
                <Row gutter={24} justify={"center"} style={{ margin: 10 }}>
                  <Col
                    className="gutter-row"
                    span={24}
                    style={{ textAlign: "center" }}
                  >
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        defaultValue={currentDateTime}
                        style={{ height: "40px" }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({
                              ...dataPost,
                              days1: e.format("YYYY-MM-DD"),
                            });
                          }
                        }}
                      />
                      <>{"ถึง"}</>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        defaultValue={currentDateTime}
                        style={{ height: "40px" }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({
                              ...dataPost,
                              days2: e.format("YYYY-MM-DD"),
                            });
                          }
                        }}
                      />
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        disabled={isLoading}
                        onClick={handle_button_Change}
                      >
                        ค้นหา
                      </Button>
                      {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}

                      <Button onClick={exportToExcel}>Export to Excel</Button>
                    </Space>
                  </Col>
                </Row>
                <Row
                  gutter={8}
                  justify={"center"}
                  style={{ marginTop: "15px" }}
                >
                  <Col
                    className="gutter-row"
                    style={{ textAlign: "center" }}
                  // span={8}
                  >
                    <Form.Item
                      label="เลือกบริษัท : "
                      style={{ marginRight: 8 }}
                    >
                      <Space>
                        <Select
                          value={companySelected}
                          style={{ width: "160px" }}
                          onChange={handleSelectCompany}
                          options={company}
                        >
                          {/* <Option value="ทั้งหมด">ทั้งหมด</Option>
                          <Option value="ONE">ONE</Option>
                          <Option value="KSM">KSM</Option> */}
                        </Select>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="เลือกแผนก : " style={{ marginRight: 8 }}>
                      <Space>
                        <Select
                          value={departmentSelected}
                          style={{ width: "160px" }}
                          onChange={handleSelectDepartment}
                        >
                          <Option value="ทั้งหมด">ทั้งหมด</Option>
                          <Option value="AD">ธุรการ</Option>
                          <Option value="MK">การตลาด</Option>
                        </Select>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={32} justify={'center'}>
              <Radio.Group value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                {
                  nameAd.sort((a, b) => {
                    const order = [
                      "MIT00075",
                      "น้ำ3",
                      "เหมย",
                      "หยก",
                      "ออรี่",
                      "ชมพู่",
                      "เอิร์น",
                    ];

                    return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                  })
                    .map((item) => (
                      <Radio.Button
                        style={{
                          width: '160px',
                          border: '2px solid',
                          color: textc[item.ApprovalStatus]
                        }}
                        value={item.name}>
                        {item.name}
                        <b>{item.totalCount}</b>
                      </Radio.Button>
                    ))}

              </Radio.Group>
            </Row> */}

                <Row gutter={32} justify={"center"}>
                  <Col
                    className="gutter-row"
                    style={{ textAlign: "right" }}
                    span={8}
                  >
                    <Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
                      <span>เลือกสาขา : </span>
                      <Select
                        value={branchSelected}
                        onChange={handleSelectBranch}
                        style={{ width: "200px" }}
                        options={branchFilter}
                      >
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    style={{ textAlign: "center" }}
                    span={8}
                  >
                    <Form.Item
                      name="username"
                      style={{ marginBottom: 0, textAlign: "center" }}
                    >
                      <span>เลือกรหัสพนักงาน : </span>
                      <Select
                        // defaultValue={{
                        //   label: 'ทั้งหมด',
                        //   value: 'ทั้งหมด',
                        // }}
                        value={employeeSelected}
                        // value={defu}
                        onChange={handleChangeEmployeeCode}
                        style={{ width: "200px" }}
                        options={nameFilter}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col
                    className="gutter-row"
                    style={{ textAlign: "left" }}
                    span={8}
                  >
                    <Form.Item
                      name="status"
                      style={{ marginBottom: 0, textAlign: "left" }}
                    >
                      <span>เลือกสถานะ : </span>
                      <Select
                        value={selectedValue}
                        onChange={handleChange}
                        style={{ width: "200px" }}
                        options={select_op}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={32} style={{ margin: 10 }}>
              <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                <Input.Search
                  style={{ width: '250px' }}
                  placeholder="ค้นหา...."
                  onChange={search}
                  name="search"
                  id="search"

                />
              </Col>
            </Row> */}

                <Row gutter={32} style={{ marginTop: "15px" }}>
                  <Col span={24}>
                    <Table
                      rowKey={(record) => record.uid}
                      scroll={{
                        x: 1500,
                        y: 400,
                      }}
                      dataSource={arrayTable}
                      columns={columns}
                    ></Table>
                  </Col>
                </Row>

                <Col span={24} style={{ textAlign: "center" }}>
                  <Space>
                    <Button href="/" type="primary">
                      <HomeFilled />
                      หน้าหลัก
                    </Button>
                  </Space>
                </Col>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Card>
      {isModalInfoADPGDC ? (
        <ModalInfoADPGDC
          open={isModalInfoADPGDC}
          close={setIsModalInfoADPGDC}
          dataFromTable={dataSend}
          typeLoans={dataSend?.dataType}
          checkPosi={"pg"}
        />
      ) : null}
      {isModalNote ? (
        <ModalNote
          open={isModalNote}
          close={setIsModalNote}
          dataFromTable={dataCarId}
        />
      ) : null}
    </>
  );
}
