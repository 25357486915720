import { Space } from 'antd';
import dayjs from "dayjs";

const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "-"
    }
  }

const columns = [
    // {
    //   title: "ลำดับ",
    //   dataIndex: "index",
    //   key: 'index',
    //   width: "auto",
    //   align: 'center',
    //   render: (text, object, index) => index + 1
    // },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "งวดที่",
      dataIndex: "NOPAY",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "เงินต้นคงเหลือ",
      dataIndex: "TONBALANCE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.TONBALANCE)}</>
        </Space>
      ),
    },
    {
      title: "จำนวนวัน",
      dataIndex: "DELAYDAY",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "วันกำหนดชำระ",
      dataIndex: "DUEDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{dayjs(record.DUEDATE).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "ค่างวด",
      dataIndex: "DUEAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUEAMT)}</>
        </Space>
      ),
    },
    {
      title: "ดอกเบี้ย",
      dataIndex: "DUEINTEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUEINTEFF)}</>
        </Space>
      ),
    },
    {
      title: "เงินต้น",
      dataIndex: "DUETONEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUETONEFF)}</>
        </Space>
      ),
    },
    {
      title: "ค่าทวงถาม",
      dataIndex: "FOLLOWAMT",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "PAYDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            record.PAYDATE !== "" && record.PAYDATE !== "0001-01-01T00:00:00Z" ?
              <>{dayjs(record.PAYDATE).format("DD-MM-YYYY")}</>
              : "-"
          }
        </Space>
      ),
    },
    {
      title: "ชำระค่างวด",
      dataIndex: "PAYAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYAMT)}</>
        </Space>
      ),
    },
    {
      title: "ชำระดอกเบี้ย",
      dataIndex: "PAYINTEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYINTEFF)}</>
        </Space>
      ),
    },
    {
      title: "ชำระเงินต้น",
      dataIndex: "PAYTON",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYTON)}</>
        </Space>
      ),
    },
    {
      title: "ชำระค่าทวงถาม",
      dataIndex: "PAYFOLLOW",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYFOLLOW)}</>
        </Space>
      ),
    },
  ];


  const columnsDetail = [
    // {
    //   title: "ลำดับ",
    //   dataIndex: "index",
    //   key: 'index',
    //   width: "auto",
    //   align: 'center',
    //   render: (text, object, index) => index + 1
    // },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "งวดที่",
      dataIndex: "NOPAY",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    // {
    //   title: "เงินต้นคงเหลือ",
    //   dataIndex: "TONBALANCE",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <>{currencyFormatOne(record.TONBALANCE)}</>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "จำนวนวัน",
    //   dataIndex: "DELAYDAY",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    // },
    // {
    //   title: "วันกำหนดชำระ",
    //   dataIndex: "DUEDATE",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    //   render: (text, record) => (
    //     <>{dayjs(record.DUEDATE).format("DD-MM-YYYY")}</>
    //   ),
    // },
    // {
    //   title: "ค่างวด",
    //   dataIndex: "DUEAMT",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <>{currencyFormatOne(record.DUEAMT)}</>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "ดอกเบี้ย",
    //   dataIndex: "DUEINTEFF",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <>{currencyFormatOne(record.DUEINTEFF)}</>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "เงินต้น",
    //   dataIndex: "DUETONEFF",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <>{currencyFormatOne(record.DUETONEFF)}</>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "ค่าทวงถาม",
    //   dataIndex: "FOLLOWAMT",
    //   key: 'index',
    //   align: 'center',
    //   width: "auto",
    // },
    {
      title: "วันที่ชำระ",
      dataIndex: "PAYDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            record.PAYDATE !== "" && record.PAYDATE !== "0001-01-01T00:00:00Z" ?
              <>{dayjs(record.PAYDATE).format("DD-MM-YYYY")}</>
              : "-"
          }
        </Space>
      ),
    },
    {
      title: "ชำระค่างวด",
      dataIndex: "PAYAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYAMT)}</>
        </Space>
      ),
    },
    {
      title: "ชำระดอกเบี้ย",
      dataIndex: "PAYINTEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYINTEFF)}</>
        </Space>
      ),
    },
    {
      title: "ชำระเงินต้น",
      dataIndex: "PAYTON",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYTON)}</>
        </Space>
      ),
    },
    {
      title: "ชำระค่าทวงถาม",
      dataIndex: "PAYFOLLOW",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYFOLLOW)}</>
        </Space>
      ),
    },
  ];



export { columns, columnsDetail };